import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import bankAccountLexicons from "./lexicons/product-group/productGroupLexicon.js";
import TicketTemplate from "./TicketTemplate.js";
import DynamicField from "./DynamicField.js";
import Ticket from "./Ticket.js";
import Filter from "../../utils/Filter.js";
import {concatenateUrls} from "juis-router/NavigationUtils.js";
import {createTextAreaEditor} from "../../components/fieldEditors/editorFactories.js";
import {imageThumbnailCellFactory} from "../../components/ImageThumbnail.js";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field().asString().withLabel("Url Code");
    this.title = new Field().withLabel("Title").asTranslatableString();
    this.subtitle = new Field().withLabel("Subtitle").asTranslatableString();
    this.infoText = new Field().withLabel("InfoText").asTranslatableString().withEditorFactory(createTextAreaEditor);
    this.submitButtonText = new Field().withLabel("Submit Button Text").asTranslatableString();
    this.public = new Field().asBoolean().withLabel("Public");
    this.ticketTemplate = new Field(TicketTemplate).withLabel("Template");
    this.fields = new Field([DynamicField]).withLabel("Fields")
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Ticket));
    this.mainImage = new Field().asFile().withLabel("Image").withCellFactory(imageThumbnailCellFactory);
    this.makeTranslatable();
    setDefaults(this, {defaultField: this.title});

    this.getPublicByCode = (code, appCode) => this.getOptional(Filter.and(
        Filter.eq(this.code, code),
        Filter.eq(this.app.code, appCode)
    ), {url: "customer-forms/public"}).asPromise();

    this.getAllPublic = (appCode) => this.getList({
        filter: Filter.eq(this.app.code, appCode),
        url: "customer-forms/public"
    });

    this.submit = (ticket) => ticket.save({url: concatenateUrls(this.getEndpointUrl(), "submit")});


    new Linguist(bankAccountLexicons).withAudience(this);
}, "CustomerForm", "customer-forms");
