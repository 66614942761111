import {getDropDownFactory} from "../../components/fieldEditors/editorFactories.js";
import TagSelect from "../../components/dropdown/TagSelect.js";
import TextComponent from "../../components/TextComponent.js";
import LOCALES from "./locales.js";
import localeCellFactory from "./localeCellFactory.js";


const getLocaleDropdownFactory = () => getDropDownFactory({
    placeholder: "Locale",
    itemFactory: localeCellFactory
});

/**
 * Try to find a valid locale for the value, or return undefined.
 * @param value
 * @returns {string|*}
 */
const localeSetterCallback = value => {
    if (!value) {
        return null;
    }
    if (Object.keys(LOCALES).includes(value)) {
        return value;
    }
    return Object.entries(LOCALES).find(entry => entry[1].toLowerCase().startsWith(value.toLowerCase()))[0]
        || Object.keys(LOCALES).find(locale => locale.toLowerCase().startsWith(value.toLowerCase()));
};

const getLocaleDropdown = () => {
    const dropdown = new TagSelect();
    dropdown.setItemFactory((locale) => new TextComponent(LOCALES[locale]));
    dropdown.setTagFactory((locale) => new TextComponent(LOCALES[locale]));
    Object.keys(LOCALES).forEach(dropdown.addOption);
    return dropdown;
};

export {getLocaleDropdownFactory, LOCALES, getLocaleDropdown, localeSetterCallback};
