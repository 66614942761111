import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import Filter from "../../utils/Filter.js";
import {createLink} from "../../bulma_components/Link.js";
import ProductGroup from "./ProductGroup";
import Contact from "./Contact";
import salesPriceRuleLexicons from "./lexicons/sales-price-rule/salesPriceRuleLexicons";
import Linguist from "../../../lib/JuiS/Linguist";
import {createTextAreaEditor, getDropDownFactory} from "../../components/fieldEditors/editorFactories";
import Method from "../../utils/Method";
import Product from "./Product";

export default new Model(function () {
    const linguist = new Linguist(salesPriceRuleLexicons);
    this.app = new Field(App);
    this.code = new Field().asString();
    this.name = new Field().asString().asRequired();
    this.description = new Field().asString().withEditorFactory(createTextAreaEditor);
    this.enabled = new Field().asBoolean();
    this.productGroups = new Field([ProductGroup]);
    this.supplier = new Field(Contact).withOptionsLoader((searchString, rule) => {
        let filter;
        const loader = Contact.getDefaultOptionsLoader();
        if (rule.app.suppliers) {
            filter = Filter.eq(Contact.contactGroup, rule.app.suppliers);
        }
        return loader(searchString, rule, filter);
    });
    this.discountPercentage = new Field().asNumber(0, 100, 1).withDefaultValueCallback(() => 0);
    let priceBaseTranslator = linguist.getRebasedLinguist("priceBase.values").t;
    this.priceBase = new Field()
        .asEnum(["SALES_PRICE", "REFERENCE_SALES_PRICE", "PURCHASE_PRICE", "REFERENCE_PURCHASE_PRICE"])
        .withCellFactory(priceBaseTranslator)
        .withEditorFactory(getDropDownFactory({
            itemFactory: priceBaseTranslator
        }))
        .withDefaultValueCallback(() => "SALES_PRICE");

    this.staticPriceChange = new Field().asNumber().withDefaultValueCallback(() => 0);
    this.percentagePriceChange = new Field().asNumber(0, 100, 1).withDefaultValueCallback(() => 0);
    this.roundToWholeNumber = new Field().asBoolean();


    setDefaults(this, {
        defaultField: this.name,
        linkFactory: salesPriceRule => createLink(`/${salesPriceRule.app.code}/sales-price-rules/${salesPriceRule.code}/`, salesPriceRule.name),
    });

    linguist.withAudience(this);
    this.isAvailableForProduct = new Method(function (product) {
        return this.enabled
            && !!this.getBasePrice(product)
            && product.productGroups.some(productGroup => this.productGroups.some(discountProductGroups => productGroup === discountProductGroups))
            && (!this.supplier || this.supplier === product.supplier);
    });
    this.getProductFilter = new Method(function () {
        let filters = [];
        if (this.supplier) {
            filters.push(Filter.eq(Product.suppliers, this.supplier));
        }
        filters.push(Filter.in(Product.groups, this.productGroups));
        switch (this.priceBase) {
            case "SALES_PRICE":
                filters.push(Filter.ne(Product.price, 0));
                break;
            case "REFERENCE_SALES_PRICE":
                filters.push(Filter.ne(Product.referencePrice, 0));
                break;
            case "PURCHASE_PRICE":
                filters.push(Filter.ne(Product.purchasePrice, 0));
                break;
            case "REFERENCE_PURCHASE_PRICE":
                filters.push(Filter.ne(Product.referencePurchasePrice, 0));
                break;
        }
        return Filter.every(filters);
    });
    this.getBasePrice = new Method(function (product) {
        let base;
        switch (this.priceBase) {
            case "SALES_PRICE":
                base = product.price;
            case "REFERENCE_SALES_PRICE":
                base = product.referencePrice;
            case "PURCHASE_PRICE":
                base = product.purchasePrice;
            case "REFERENCE_PURCHASE_PRICE":
                base = product.referencePurchasePrice;
        }
        return base * ((100 + this.percentagePriceChange) / 100) + this.staticPriceChange;
    });
    this.calculatePrice = new Method(function (product) {
        return this.getBasePrice(product) * ((100 - this.discountPercentage) / 100);
    });

}, "SalesPriceRule", "sales-price-rules");
