import {NavbarDropdown} from "../../bulma_components/navbar/Navbar.js";
import SessionHandler from "../../SessionHandler.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import navbarLexicons from "./lexicons/navbarLexiconx.js";
import HTML from "../../utils/HTML.js";
import Component from "juis-components/Component.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import Container from "juis-components/Container.js";
import {createLink} from "../../bulma_components/Link.js";
import TextComponent from "../TextComponent.js";
import {createIcon} from "../../bulma_components/Icon.js";
import Notification from "../../hewecon/models/Notification.js";
import ThemeSelector from "./ThemeSelector";


const linguist = new Linguist(navbarLexicons).withBaseKey("userMenu");


const createWakeLockItem = () => {
    return new Container(function () {
        this.icon = createIcon("eye");
        this.text = new TextComponent(linguist.t("wakeLock"));
        this.setTag = (text, cssClass) => {
            this.tag = new TextComponent(text, HTML.SPAN, [BULMA_CSS_CLASSES.TAG, cssClass, "ml-2"]);
        };
        this.getNode().setAttribute("title", linguist.t("wakeLockTooltip"));
        this.getNode().setStyle("cursor", "pointer");
        let previousWakeLock;
        this.registerDomEvents("click");
        this.on("click", function () {
            if (previousWakeLock && !previousWakeLock.released) {
                previousWakeLock.release();
                previousWakeLock = null;
                this.setTag(linguist.t("disabled"), BULMA_CSS_CLASSES.IS_WARNING);
                return;
            }
            navigator.wakeLock.request("screen").then(wakeLock => {
                previousWakeLock = wakeLock;
                wakeLock.addEventListener("release", () => this.setTag(linguist.t("disabled"), BULMA_CSS_CLASSES.IS_WARNING));
                this.setTag(linguist.t("enabled"), BULMA_CSS_CLASSES.IS_SUCCESS);
            }).catch(() => {
                previousWakeLock = null;
                this.setTag(linguist.t("notAllowed"), BULMA_CSS_CLASSES.IS_DANGER);
            });
        });
    }, [BULMA.NAVBAR.ITEM]);
};

const defer = (callback, timeout) => {
    return new Promise((resolve) => setTimeout(() => resolve(callback()), timeout));
};

const fetchNotifications = () => {
    const maxRows = 5;
    const orderBy = "created desc";
    return defer(() => Notification.getList({maxRows, orderBy})
        .then(response => response.data), 1000);

};
const createNotificationBell = (notifications, user) => {
    return new NavbarDropdown(function () {
        this.icon = "bell";
        this.isArrowless = true;
        this.isRight = true;
        notifications.forEach(notification => {
            this.addNotification(notification);
        });
        this.addDivider();
        this.addItem(`/users/${user.username}/notifications`, "All notifications");
    });
};

export default () => {
    const user = SessionHandler.getCurrentUser();
    return new Container(function () {
        fetchNotifications().then(notifications => {
            if (notifications.length === 0) {
                return;
            }
            this.insertBefore(createNotificationBell(notifications, user));
        });

        this.userDropdown = new Container(function () {
            this.link = new Container(function () {
                this.username = new Container(function () {
                    user.getAvatar(32).then(optionalComponent => optionalComponent.peek(avatarComponent => {
                        this.insertBefore(avatarComponent);
                    }));
                    this.text = new TextComponent(user.publicName || user.username, HTML.SPAN, ["ml-2"]);
                }, [BULMA.IS_FLEX, "is-align-items-center"]);
                this.theme = new ThemeSelector();
                this.getNode().setStyle("gap", "2rem");
            }, [BULMA.IS_FLEX, "p-3", "is-align-items-center", "is-justify-content-space-between"]);
            if (this.dropdown) {
                this.dropdown.destroy();
            }
            this.dropdown = new Container(function () {
                this.addItem = function (url, text, icon, color) {
                    let item = createLink(url, text, icon, color);
                    item.autoBlur = true;
                    item.getNode().addCssClass(BULMA.NAVBAR.ITEM);
                    this.appendChild(item);
                    return item;
                };
                this.addDivider = () => this.appendChild(new Component(undefined, [BULMA.NAVBAR.DIVIDER], HTML.HR));
                if (!window.matchMedia("(display-mode: standalone)").matches) {
                    this.addItem(`/users/${user.username}/apps`, linguist.t("apps"), "blender-phone");
                    this.addDivider();
                } else if (navigator.wakeLock && user.username === "superadmin") {
                    this.appendChild(createWakeLockItem());
                }
                if (user.anonymous) {
                    this.addItem(`/create-account`, linguist.t("signUp"), "user-plus");
                } else {
                    this.addItem(`/users/${user.username}/settings/`, linguist.t("userSettings"), "user-cog");
                }
                this.addDivider();
                let signOutLink = this.addItem("#", linguist.t("signOut"), "sign-out-alt");
                signOutLink.on("click", () => SessionHandler.logout());
            }, [BULMA.NAVBAR.DROPDOWN, BULMA.IS_RIGHT]);
        }, [BULMA.NAVBAR.ITEM, BULMA.HAS_DROPDOWN, BULMA.IS_HOVERABLE]);
    }, [BULMA.NAVBAR.END]);
}
