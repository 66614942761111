import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import BillType from "./BillType.js";
import UserRole from "./UserRole";
import {getDropDownFactory} from "../../components/fieldEditors/editorFactories";
import billTypePermissionLexicons from "./lexicons/bill-type-permission/billTypePermissionLexicons";

export default new Model(function () {
    this.app = new Field(App);
    this.userRole = new Field(UserRole).withEditorFactory(getDropDownFactory());
    this.read = new Field().asBoolean();
    this.write = new Field().asBoolean();
    this.billType = new Field(BillType);

    this.excludeFromCachedDescendants();
    setDefaults(this, {
        defaultField: this.userRole,
    });
    const linguist = new Linguist(billTypePermissionLexicons).withAudience(this);
}, "BillTypePermission", "bill-type-permissions");
