import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import Stock from "./Stock.js";
import Product from "./Product.js";
import Aggregation from "../../utils/Aggregation.js";
import App from "./App.js";
import BillItem from "./BillItem.js";
import TicketProduct from "./TicketProduct.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import stockProductLexicons from "./lexicons/stock-product/stockProductLexicons.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import PromiseButton from "../../components/PromiseButton.js";
import {EVENTS} from "../../components/table-view/TableView2.js";
import BulmaUtils from "../../bulma_components/BulmaUtils.js";
import simpleInlineQuantityEditorFactory from "../../components/cell-factories/simpleInlineQuantityEditorFactory.js";
import inlineQuantityEditorFactoryWithTransactionButton
    from "../../components/cell-factories/inlineQuantityEditorFactoryWithTransactionButton.js";

let StockProduct = new Model(function () {
    this.app = new Field(App);
    this.stock = new Field(Stock).withLabel("Stock").asReadOnly();
    this.product = new Field(Product).withLabel("Product").asReadOnly();
    this.quantity = new Field()
        .asNumber()
        .withLabel("Quantity")
        .withCellFactory((quantity, stockProduct) => {
            if (stockProduct.product && stockProduct.product.unit) {
                return quantity + " " + stockProduct.product.unit;
            }
            return quantity;
        })
        .asReadOnly();
    this.totalPriceVatExcluded = new Field().asMoney(this.app.currencyCode).asReadOnly().withLabel("Value (VAT excl.)");
    this.totalPriceVatIncluded = new Field().asMoney(this.app.currencyCode).asReadOnly().withLabel("Value (VAT incl.)");
    this.totalPurchasePriceVatExcluded = new Field().asMoney(this.app.currencyCode).asReadOnly().withLabel("Purchase Value (VAT excl.)");
    this.totalPurchasePriceVatIncluded = new Field().asMoney(this.app.currencyCode).asReadOnly().withLabel("Purchase Value (VAT incl.)");

    this.unlinkButton = new Field().asVirtual().withLabel(" ").withCellFactory((ignore, stockProduct) => {
        return new PromiseButton(function () {
            this.icon = "unlink";
            this.tooltip = BulmaUtils.getTooltipProperty(this);
            this.callback = () => this.triggerOnce(EVENTS.BEFORE_DELETE, stockProduct);
            const refresh = () => {
                this.disabled = stockProduct.quantity !== 0;
                if (this.disabled) {
                    this.tooltip = "";
                } else {
                    this.tooltip = linguist.t("unlink", {stockProduct});
                }
            };
            stockProduct.on("change", refresh);
            refresh();
        }, [BULMA.IS_SMALL, BULMA.TYPOGRAPHY.HAS_TEXT_DANGER, BULMA.HAS_TOOLTIP_LEFT]);
    });


    this.inlineTransaction = new Field()
        .withLabel("Transaction")
        .asVirtual()
        .withCellFactory(inlineQuantityEditorFactoryWithTransactionButton);

    this.simpleInlineTransaction = new Field()
        .withLabel("Transaction")
        .asVirtual()
        .withCellFactory(simpleInlineQuantityEditorFactory);

    this.productCount = Aggregation.sum(this.quantity, "Product count");

    const linguist = new Linguist(stockProductLexicons).withAudience(this);

    this.totalPriceVatExcludedSum = Aggregation.sum(this.totalPriceVatExcluded);
    this.totalPriceVatIncludedSum = Aggregation.sum(this.totalPriceVatIncluded);
    this.totalPurchasePriceVatExcludedSum = Aggregation.sum(this.totalPurchasePriceVatExcluded);
    this.totalPurchasePriceVatIncludedSum = Aggregation.sum(this.totalPurchasePriceVatIncluded);

    const isRelated = (stock, product, stockProduct) =>
        stockProduct.product === product && stockProduct.stock === stock;
    this.refreshQuantities = (stock, product) => {
        BillItem.cache
            .findAll((billItem => billItem.product === product))
            .filter(billItem => billItem.knownStockQuantities)
            .forEach(billItem => billItem.knownStockQuantities[stock.id] = undefined);
        TicketProduct.cache
            .findAll((ticketProduct => ticketProduct.product === product))
            .filter(ticketProduct => ticketProduct.knownStockQuantities)
            .forEach(ticketProduct => ticketProduct.knownStockQuantities[stock.id] = undefined);
        const stockProduct = StockProduct.cache.find(stockProduct => isRelated(stock, product, stockProduct));
        if (stockProduct) {
            stockProduct.reload();
        } else {
            StockProduct.invalidateListFetcherCache();
        }
    };
}, "StockProduct", "stock-products");

export default StockProduct;
