import Model from "../../utils/RestModel.js";
import DynamicField from "./DynamicField";
import Field from "../../utils/Field";
import BillType from "./BillType";
import Product from "./Product";
import App from "./App";

export default new Model(function () {
    this.app = new Field(App);
    this.field = new Field(DynamicField).withOptionsLoader(DynamicField.getOptionsLoaderForModel(Product));
    this.alternativeFields = new Field([DynamicField]).withOptionsLoader(DynamicField.getOptionsLoaderForModel(Product));
    this.searchType = new Field().asEnum(["TEXT", "BUTTON_LIST"]);
    this.billType = new Field().asEnum(BillType);
}, "BillTypeProductSearchField");
