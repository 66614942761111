import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import TicketType from "./TicketType.js";
import {createChildEntityEditor, getDropDownFactory} from "../../components/fieldEditors/editorFactories.js";
import Contact from "./Contact.js";
import App from "./App.js";
import TicketStatus from "./TicketStatus.js";
import DynamicField from "./DynamicField.js";
import Ticket from "./Ticket.js";
import {getFieldSuggestions} from "../../utils/fieldUtils.js";

let Column = new Model(function () {
    this.app = new Field(App);
    this.ordinal = new Field()
        .asNumber()
        .withLabel("Ordinal");
    this.title = new Field()
        .asString()
        .withLabel("Title");
    this.daysUntilHidden = new Field()
        .asNumber(0, 9999, 1)
        .withLabel("Days visible")
        .withHelp("Hide tickets with no status changes for a long time.");
    this.statuses = new Field([TicketStatus])
        .withLabel("Statuses")
        .withHelp("Chose one or more statuses to show in this column.");
    this.getStatusForTicketType = function (type) {
        return this.statuses.find(status => type.statuses.includes(status));
    }
}, "Column");


let Board = new Model(function () {
    this.app = new Field(App);
    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Code")
        .withCellFactory((code, board) => createLink(`/${board.app.code}/boards/${board.code}`, code));

    this.description = new Field()
        .asString()
        .withLabel("Description");

    this.name = new Field()
        .asString()
        .withLabel("Name");

    this.fields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Ticket))
        .withLabel("Fields");

    this.showChildStatuses = new Field().asBoolean().withLabel("Show child statuses");

    this.typeFilter = new Field([TicketType])
        .withLabel("Ticket type filter")
        .withHelp("Leave empty for no filtering");

    this.statusFilter = new Field([TicketStatus])
        .withLabel("Ticket status filter")
        .withHelp("Leave empty for no filtering");

    this.parentStatusFilter = new Field([TicketStatus])
        .withLabel("parent status filter")
        .withHelp("Leave empty for no filtering");

    this.workerFilter = new Field([Contact])
        .withLabel("Worker filter")
        .withHelp("Leave empty for no filtering");

    this.customerFilter = new Field([Contact])
        .withLabel("Customer filter")
        .withHelp("Leave empty for no filtering");

    this.typeToggles = new Field([TicketType])
        .withLabel("Ticket type toggles")
        .withHelp("Leave empty for no toggles");

    this.statusToggles = new Field([TicketStatus])
        .withLabel("Ticket status toggles")
        .withHelp("Leave empty for no toggles");

    this.workerToggles = new Field([Contact])
        .withLabel("Worker toggles")
        .withHelp("Leave empty for no toggles");

    this.toggleAllWorkers = new Field().asBoolean().withLabel("Toggle all workers");

    this.customerToggles = new Field([Contact])
        .withLabel("Customer toggles")
        .withHelp("Leave empty for no toggles");

    this.COLUMN_TYPES = {
        STATUS: "STATUS",
        WORKER: "WORKER",
        WORK_STATION: "WORK_STATION",
        WEEKLY_DEADLINES: "WEEKLY_DEADLINES",
    };

    this.columnType = new Field()
        .asNullable()
        .asEnum([this.COLUMN_TYPES.STATUS, this.COLUMN_TYPES.WORKER, this.COLUMN_TYPES.WORK_STATION,
            this.COLUMN_TYPES.WEEKLY_DEADLINES])
        .withLabel("Column type");

    this.columns = new Field([Column])
        .withLabel("Columns")
        .asCascading()
        .sortedBy(Column.ordinal)
        .withEntityEditorFields((entity) => {
            if (entity.columnType === this.COLUMN_TYPES.STATUS) {
                return [Column.title, Column.statuses, Column.daysUntilHidden, Column.ordinal];
            } else {
                return [Column.daysUntilHidden, Column.ordinal];
            }
        })
        .withEditorFactory(createChildEntityEditor);

    this.showCreators = new Field()
        .asBoolean()
        .withLabel("Show creators");

    this.filterFields = new Field([Field.STRING])
        .withEditorFactory(getDropDownFactory())
        .withOptionsLoader(searchString => getFieldSuggestions(Ticket, searchString))
        .withLabel("Filter fields");

    this.excludeFromCachedDescendants();
}, "Board", "boards", true);

export {Board as default};
