import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Property from "juis-components/Property.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";

const FilterTag = extendComponent(Container, function () {
    this.text = new Property((text) => {
        this.getNode().setText(text);
    });
    let active = false;
    this.isActive = () => active;
    this.registerDomEvents("click");
    this.toggle = () => {
        active = !active;
        if (active) {
            this.getNode().addCssClass(BULMA.IS_ACTIVE);
            this.trigger(FilterTag.ACTIVATE);
        } else {
            this.getNode().removeCssClass(BULMA.IS_ACTIVE);
            this.trigger(FilterTag.DEACTIVATE);
        }
    };
    this.on("click", this.toggle);
}, [BULMA.TAG, BULMA.IS_UNSELECTABLE]);

FilterTag.ACTIVATE = Symbol();
FilterTag.DEACTIVATE = Symbol();

export default FilterTag;
