import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import HTML from "../../../utils/HTML";
import BULMA from "../../../bulma_components/bulmaCssClasses";
import TextComponent from "../../../components/TextComponent.js";
import Property from "juis-components/Property.js";
import Linguist from "../../../../lib/JuiS/Linguist.js";
import frontPageLexicons from "./lexicons/frontPageLexicons";
import "./style.scss";

const getFeatureCard = (image) => new Container(function () {
    this.imageWrapper = new Container(function () {
        this.getNode().addWrapper(HTML.FIGURE, [BULMA.IMAGE, "is-16by9"]);
        this.getNode().addWrapper(HTML.DIV, [BULMA.CARD.IMAGE]);
        this.getNode().setAttribute("src", `src/hewecon/views/front-page/${image}.avif`);
        this.getNode().setAttribute("alt", image);
    }, [], HTML.IMG);
    this.content = new Container(function () {
        this.titleComponent = new TextComponent("", HTML.P, [BULMA.TITLE, BULMA.TYPOGRAPHY.HAS_TEXT_WEIGHT_BOLD, BULMA.IS_4]);
        this.descriptionComponent = new TextComponent("", HTML.P);
    }, [BULMA.CARD.CONTENT]);
    this.title = new Property((title) => this.content.titleComponent.text = title, "");
    this.description = new Property((description) => this.content.descriptionComponent.text = description, "");
    this.getNode().addWrapper(HTML.DIV, [BULMA.COLUMN, BULMA.COLUMNS_SIZE.IS_HALF, "is-one-third-widescreen"]);
}, [BULMA.CARD.CARD, "feature-card"]);

export default extendComponent(Container, function () {
    this.invoicing = getFeatureCard("invoicing");
    this.payments = getFeatureCard("payments");
    this.contacts = getFeatureCard("contacts");
    this.stock = getFeatureCard("stock");
    this.tickets = getFeatureCard("tickets");
    this.pwa = getFeatureCard("pwa");

    new Linguist(frontPageLexicons)
        .withBaseKey("features")
        .addAudience(this);
}, [BULMA.COLUMNS, BULMA.IS_MULTILINE]);
