import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Checkbox from "./Checkbox.js";
import HTML from "../utils/HTML.js";
import TextComponent from "../components/TextComponent.js";

const createCheckboxContainer = (entity, label) => {
    return new Container(function () {
        this.checkbox = new Checkbox();
        this.checkbox.getNode().addWrapper(HTML.TD);
        this.label = label;
        this.label.getNode().addWrapper(HTML.TD);
        this.getNode().setStyle("height", "3em");
    }, [], HTML.TR);
}

const labelFactory = (entity) => {
    let text;
    if (entity) {
        text = entity.name || entity;
    } else {
        text = "N/A";
    }
    let label = new TextComponent(text);
    label.getNode().setStyle("margin-left", "1em");
    return label;
}

export default extendComponent(Container, function (self) {
    // Indexes in these two arrays should be kept in sync
    let entities = [];
    let checkboxContainers = [];

    this.labelFactory = labelFactory;

    this.addOption = (entity) => {
        const checkboxContainer = createCheckboxContainer(entity, this.labelFactory(entity));
        entities.push(entity);
        checkboxContainers.push(checkboxContainer);
        self.appendChild(checkboxContainer);
        return checkboxContainer.checkbox;
    }

    this.getValue = function () {
        let value = [];
        checkboxContainers.forEach((checkboxContainer, index) => {
            if (checkboxContainer.checkbox.getValue()) {
                value.push(entities[index]);
            }
        });
        return value;
    };
    let getCheckBoxForEntity = (entity) => {
        let index = entities.findIndex(existingEntity => existingEntity.equals(entity));
        if (index !== -1) {
            return checkboxContainers[index].checkbox;
        } else {
            return this.addOption(entity);
        }
    }
    this.setValue = function (value) {
        checkboxContainers.forEach(container => container.checkbox.setValue(false));
        value.forEach(entity => {
            getCheckBoxForEntity(entity).setValue(true);
        });
    }
}, [], HTML.TABLE);
