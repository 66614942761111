import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Filter from "../../utils/Filter.js";
import FilterTag from "./FilterTag.js";
import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import DynamicFieldOption from "../../hewecon/models/DynamicFieldOption.js";

const FilterGroup = extendComponent(Container, function () {
    let filters = [];
    this.addTextTag = (text, filter) => this.addTag(createTextTag(text), filter);
    this.addTag = (tag, filter) => {
        this.appendChild(tag);
        tag.on(FilterTag.ACTIVATE, () => {
            filters.push(filter);
        });
        tag.on(FilterTag.DEACTIVATE, () => {
            const index = filters.indexOf(filter);
            if (index !== -1) filters.splice(index, 1);
        });
        return tag;
    };
    this.getFilter = () => Filter.someOrTrue(filters);
}, ["filter-group"]);

const createTextTag = (text) => {
    return new FilterTag(function () {
        this.text = text;
    });
};

const defaultTagFactory = (value, field) => {
    let content;
    if (value instanceof RestModel) {
        content = value.getDefaultItem();
    } else if (field.getModelType()) {
        content = field.getModelType().getDefaultItem(null);
    } else if (field instanceof Field) {
        content = field.getCellFactory()(value);
    } else if (field instanceof RestModel && !value) {
        content = field.getCellFactory()(null);
    }
    if (typeof content === "string") {
        return createTextTag(content);
    } else {
        return new FilterTag(function () {
            this.content = content;
        });
    }
};

const FilterGroups = extendComponent(Container, function () {
    const filterGroups = [];

    let rowContainers = [];
    const getRowContainerForChild = (childIndex, append) => {
        let rowIndex = Math.floor(childIndex / 2);
        if (!rowContainers[rowIndex]) {
            let rowContainer = new Container();
            rowContainer.addCssClass("filter-group-row");
            rowContainers[rowIndex] = rowContainer;
            append(rowContainers[rowIndex]);
        }
        return rowContainers[rowIndex];
    };
    let childCount = 0;
    this.overrideMethod("appendChild", (overridden, child) => {
        let row = getRowContainerForChild(childCount, overridden);
        row.appendChild(child);
        childCount++;
        return child;
    });

    this.createFieldToggles = (filterField, displayField, values = []) => {
        const tagFactory = (value) => defaultTagFactory(value, displayField);
        return this.createToggles(filterField, tagFactory, values);
    };

    this.createToggles = (filterField, tagFactory = defaultTagFactory, values = [], groupBy) => {
        const filterGroup = new FilterGroup();
        if (groupBy) {
            let map = new Map();
            values.forEach(value => {
                let group = groupBy(value);
                if (!map.has(group)) {
                    map.set(group, [value]);
                } else {
                    map.get(group).push(value);
                }
            });
            map.forEach(values => filterGroup.addTag(tagFactory(values[0], filterField), Filter.in(filterField, values)));
        } else {
            values.forEach(value => filterGroup.addTag(tagFactory(value, filterField), Filter.eq(filterField, value)));
        }
        filterGroup.overrideMethod("addTag", (overridden, value, filter) =>
            overridden(tagFactory(value, filterField), filter instanceof Filter ? filter : Filter.eq(filterField, value)));
        filterGroups.push(filterGroup);
        this.appendChild(filterGroup);
        return filterGroup;
    };

    this.createFilterGroup = () => {
        const filterGroup = new FilterGroup();
        filterGroups.push(filterGroup);
        this.appendChild(filterGroup);
        return filterGroup;
    };

    this.createFilterGroupForField = (toggleField) => {
        const filterGroup = new FilterGroup();
        filterGroups.push(filterGroup);
        this.appendChild(filterGroup);

        if (toggleField.getOptions().length > 0) {
            toggleField.getOptions().forEach((option) => {
                if (option instanceof DynamicFieldOption) {
                    filterGroup.addTag(defaultTagFactory(option), Filter.eq(toggleField.name, option.value));
                } else {
                    filterGroup.addTextTag(option, Filter.eq(toggleField, option));
                }
            });
        }

        return filterGroup;
    };

    this.createMonthToggles = (field, locale = "en-US", filterGetter = (field, month) => Filter.month(field, month)) => {
        const filterGroup = new FilterGroup(function () {
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach(month => {
                let options = {month: "short"};
                let date = Date.UTC(2000, month - 1, 1, 0, 0, 0);
                let text = new Intl.DateTimeFormat(locale, options).format(date);
                this.addTag(createTextTag(text), filterGetter(field, month));
            });
        });
        filterGroups.push(filterGroup);
        this.appendChild(filterGroup);
    };

    this.createYearToggles = (availableYears, field, locale = "en-US") => {
        let formatOptions = {year: "numeric"};
        if (availableYears.length > 5) {
            formatOptions = {year: "2-digit"};
        }
        const filterGroup = new FilterGroup(function () {
            availableYears.map(year => {
                let date = new Date().setFullYear(year);
                let text = new Intl.DateTimeFormat(locale, formatOptions).format(date);
                this.addTag(createTextTag(text), Filter.eq(field, year));
            });
        });
        filterGroups.push(filterGroup);
        this.appendChild(filterGroup);
    };

    this.getFilter = () => Filter.every(filterGroups.map(group => group.getFilter()));
});

export default FilterGroups;
