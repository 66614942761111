import Container from "juis-components/Container.js";
import RestModel from "../../utils/RestModel.js";
import Filter from "../../utils/Filter.js";
import Table from "../table/Table.js";
import Pagination from "../../bulma_components/Pagination.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import StatisticsBox from "./StatisticsBox.js";
import getSearchField from "./getSearchField.js";
import EditorCard from "./EditorCard.js";
import {Toggle} from "../../bulma_components/Tabs.js";
import Field from "../../utils/Field.js";
import Aggregation from "../../utils/Aggregation.js";
import {notificationEvents} from "../notification/Notification.js";
import DynamicField from "../../hewecon/models/DynamicField.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import tableViewLexicons from "./lexicons/tableViewLexicons.js";
import ExportCard from "./ExportCard.js";
import FilterGroups from "./FilterGroups.js";
import FilterTag from "./FilterTag.js";
import Title from "./Title.js";
import "./style.css";
import {EVENTS as BULMA_EVENTS} from "../../bulma_components/BulmaComponent.js";
import PromiseThrottler from "../../utils/PromiseThrottler.js";
import SuggestionFilter from "../../utils/SuggestionFilter.js";
import {ACTIONS} from "../Actions.js";

const EVENTS = {
    BEFORE_DELETE: "beforeDelete",
    NEW_COUNT: "newCount",
    META_DATA: "metaData",
    READY: "ready",
};

const maxRows = 20;
const TableView = extendComponent(Container, function () {
    const linguist = new Linguist(tableViewLexicons);
    let currentPage = 1;
    let orderBy = [];
    let toggleFilter = Filter.true;
    let suggestionFilter = null;
    let loadDataIsCalled = false;
    let filters = [];
    let filterFields = [];
    let model;
    let listGetter;
    let queryParameters;

    const setData = (data, totalCount, firstRow = 0) => {
        totalCount = totalCount || data.length;
        this.content.table.setContent(data);
        this.content.pagination.totalCount = totalCount;
        this.content.pagination.firstRow = firstRow;
        this.content.pagination.refreshButtons();
        this.trigger(EVENTS.NEW_COUNT, totalCount);
    };

    const setSearchFilter = (searchInput) => {
        if (!searchInput) {
            suggestionFilter = null;
            return;
        }
        suggestionFilter = new SuggestionFilter(searchInput, [], filterFields);
    };

    this.setSearchFilterFields = function (fields, additionalControl) {
        if (fields.length > 0) {
            let field = showSearchField();
            if (additionalControl) {
                field.appendChild(additionalControl);
            }
        }
        filterFields = [...fields];
    };

    let statisticsBox;
    this.addAggregation = (aggregation) => {
        if (typeof aggregation === "string") {
            aggregation = model[aggregation];
        }
        if (!(aggregation instanceof Aggregation)) {
            throw new Error("Invalid argument. Expected an instance of Aggregation, but got " + (typeof aggregation));
        }
        if (!statisticsBox) {
            statisticsBox = new StatisticsBox();
            this.addToggleView(statisticsBox, "percent");
        }
        statisticsBox.showAggregation(aggregation);
    };

    this.addFilter = (filter) => {
        filters.push(filter);
        return filter;
    };

    this.setFilter = (filter) => {
        filters = [filter];
    };

    this.setQueryParameter = (key, value) => {
        if (!queryParameters) {
            queryParameters = {};
        }
        queryParameters[key] = value;
    };

    this.removeQueryParameter = (key) => {
        if (!queryParameters) {
            return;
        }
        delete queryParameters[key];
    };

    this.setOrderBy = (field, isDesc, clearHistory = false) => {
        if (clearHistory) {
            orderBy = [];
        }
        this.content.table.markFieldAsSorted(field, isDesc);
        let newColumn;
        if (typeof field === "string" && model[field]?.getOrderBy) {
            field = model[field];
        }
        if (field instanceof RestModel) {
            field = field.getOrderBy();
        }
        if (field instanceof Field || field instanceof DynamicField) {
            if (field.getOrderBy()) {
                newColumn = field.getOrderBy();
            } else {
                newColumn = field.getName();
            }
        } else {
            newColumn = field;
        }
        orderBy = orderBy.filter(other => {
            let otherColumn = other.split(" ")[0];
            return newColumn !== otherColumn;
        });
        if (isDesc) {
            newColumn = newColumn + " desc";
        }
        let length = orderBy.unshift(newColumn);
        if (length > 3) {
            orderBy.pop();
        }
    };

    this.getOrderByString = () => {
        return orderBy.join(", ") || "id desc";
    };

    this.setModel = (newModel, fetcher) => {
        if (model) {
            throw new Error("Model can only be set once");
        }
        if (loadDataIsCalled) {
            throw new Error("Model can not be set after calling loadData");
        }
        if (!(newModel instanceof RestModel)) {
            throw new Error("Model should be an instance of RestModel");
        }
        model = newModel;
        listGetter = fetcher || model.getList;
        if (this.autoLoad) {
            const reload = (entity) => {
                if (this.getCurrentFilter().test(entity)) {
                    this.loadData();
                }
            };
            model.on(model.EVENTS.AFTER_INSERT, (entity) => {
                if (!this.getCurrentFilter().test(entity)) {
                    return;
                }
                this.loadData().then(responseEntities => {
                    if (!document.body.contains(this.getNode().getActualElement())) {
                        return;
                    }
                    if (!responseEntities.includes(entity)) {
                        this.trigger(notificationEvents.NOTIFY, {
                            components: linguist.toList("entityCreated", {component: entity.getDefaultCell()}),
                            nextListenable: this,
                            cssClass: BULMA_CSS_CLASSES.IS_SUCCESS
                        });
                    }
                });
            });
            model.on(model.EVENTS.AFTER_DELETE, reload);
        }
    };

    this.getModel = () => model;

    this.addField = (field, settings = {}, ...args) => {
        if (typeof field === "string") {
            if (!model[field]) {
                throw new Error(`No such field [${field}] on model [${model.getName()}]`);
            }
            field = model[field];
        }
        if (typeof settings !== "object") {
            settings = {};
        }
        if (field instanceof DynamicField) {
            const dynamicField = field;
            field = dynamicField.getField(model);
        }
        this.content.table.addField(field, settings, ...args);
    };

    let isReady = false;
    this.triggerReady = () => {
        if (!isReady) {
            isReady = true;
            this.trigger(EVENTS.READY);
        }
    };

    this.aggregationContext = null;
    const throttler = new PromiseThrottler().withCallback(() => {
        const firstRow = maxRows * (currentPage - 1);
        const filter = this.getCurrentFilter();
        return listGetter({
            firstRow,
            maxRows,
            orderBy: this.getOrderByString(),
            filter,
            suggestionFilter,
            includeAggregations: true,
            queryParameters
        }).then(response => {
            setData(response.data, response.metadata.totalCount, response.metadata.firstRow);
            if (statisticsBox) {
                statisticsBox.setAggregationData(response.metadata.aggregations || [], this.aggregationContext);
            }
            this.triggerReady();
            this.trigger(EVENTS.META_DATA, response.metadata);
            return response.data;
        });
    });

    this.loadData = () => throttler.load();

    const createOnce = (creator) => {
        let value;
        return (...params) => {
            if (!value) {
                value = creator(...params);
            }
            return value;
        };
    };

    this.getSearchText = () => "";
    let getTop = createOnce(() => this.insertOrderedChild(new Container(undefined, ["split-view"]), 0));
    let getTitle = createOnce(() => getTop().insertOrderedChild(new Title(), 0));
    this.getSearchBox = createOnce(() =>
        getTop().insertOrderedChild(new Container((searchBox) => {
            searchBox.getNode().setStyle("min-height", "4em");
        }, [BULMA.IS_PULLED_RIGHT, "is-fullwidth-mobile"]), 1));
    let showSearchField = createOnce(() => {
        let searchField = getSearchField((searchText) => {
            setSearchFilter(searchText);
            currentPage = 1;
            return this.loadData();
        });
        this.getSearchText = () => searchField.getValue();
        this.getSearchBox().insertOrderedChild(searchField, 0);
        return searchField;
    });
    this.getFilterGroups = createOnce(() => {
        let filterGroups = new FilterGroups(groups => {
            groups.on([FilterTag.ACTIVATE, FilterTag.DEACTIVATE], () => {
                toggleFilter = groups.getFilter();
                currentPage = 1;
                this.loadData();
            });
        });
        this.getSearchBox().getNode().addCssClass(BULMA.BOX);

        this.getSearchBox().insertOrderedChild(filterGroups, 1);
        return filterGroups;
    });

    let getToolbar = createOnce(() => getTop().insertOrderedChild(new Container(
        (toolbar) => toolbar.getNode().addCssClass("toolbar")
    ), 2));
    let getTabs = createOnce(() => getTop().insertOrderedChild(new Toggle(function () {
        this.canDeactivate = true;
    }), 3));

    this.addToToolbar = (component) => getToolbar().appendChild(component);
    this.getAccordion = createOnce(() => {
        return getTop().insertOrderedChild(new Container(function () {
            this.overrideMethod("appendChild", (overridden, child) => {
                child.overrideMethod("expand", (overridden) => {
                    this.moveFirst(child);
                    return overridden.call(child);
                });
                return overridden.call(this, child);
            });
            this.getNode().setStyle("margin-bottom", "0.5em");
            this.collapse = () => this.forEachChild(child => child.collapse());
        }, ["no-split"]), 4);
    });
    this.extractAccordion = () => {
        const top = getTop();
        const accordion = this.getAccordion();
        top.removeChild(accordion);
        return accordion;
    };

    this.setTitle = (title) => getTitle().text = title;
    this.setIcon = (icon) => getTitle().icon = icon;
    this.setColor = (color) => getTitle().color = color;
    this.addToggleView = (view, icon) => {
        this.getAccordion().appendChild(view);
        if (icon) {
            let tab = getTabs().addIconTab(icon);
            view.on("collapse", () => tab.deactivate());
            tab.on(BULMA_EVENTS.ACTIVATE, () => view.expand());
            tab.on(BULMA_EVENTS.DEACTIVATE, () => view.collapse());
            return tab;
        }
    };

    let editorCard;
    this.enableCopier = (settings) => {
        let copier = new EditorCard();
        copier.createNewEntitiesAutomatically = false;
        copier.setModel(model);
        copier.submitText = linguist.t("saveCopy");
        settings.fields.forEach(copier.addField);
        this.getAccordion().appendChild(copier);
        let original;
        if (settings.saveCallback) {
            copier.saveCallback = (copy) => {
                settings.saveCallback(copy, original);
            };
        }
        this.on(ACTIONS.COPY, newOriginal => {
            original = newOriginal;
            copier.title = settings.title || linguist.t("copy", {original});
            let copy = original.copy([model.id, model.code], settings.defaultProperties);
            copy.listenOnce(copy.EVENTS.AFTER_INSERT, () => {
                this.loadData();
            });
            copier.setEntity(copy);
            copier.expand();
        });
    };
    this.enableEditor = (settings) => {
        editorCard = new EditorCard();
        editorCard.title = settings.title || linguist.t("edit");
        editorCard.submitText = linguist.t("saveChanges");
        if (this.setModel) {
            editorCard.setModel(model);
        }
        if (settings.fields) {
            settings.fields.forEach(editorCard.addField);
        }
        if (settings.saveCallback) {
            editorCard.saveCallback = settings.saveCallback;
        }
        if (settings.hasOwnProperty("createNewEntitiesAutomatically")) {
            editorCard.createNewEntitiesAutomatically = settings.createNewEntitiesAutomatically;
        }

        this.addToggleView(editorCard);
        this.on(ACTIONS.EDIT, (entity) => {
            editorCard.setEntity(entity);
            editorCard.expand();
        });
        this.enableDelete(settings);
    };

    this.enableDelete = (settings = {}) => {
        this.on(EVENTS.BEFORE_DELETE, (entity) => {
            const innerSettings = {...settings};
            if (!innerSettings.deleteCallback) {
                innerSettings.deleteCallback = () => entity.delete();
            }
            if (!innerSettings.getDeleteText) {
                innerSettings.getDeleteText = () => "";
            }
            const text = innerSettings.getDeleteText(entity);
            const components = text ? undefined : linguist.toList("deletePrompt", {value: entity.getDefaultCell()});
            const cssClass = BULMA.IS_DANGER;
            return this.triggerOnce(notificationEvents.PROMPT, {text, components, cssClass}).then(isSure => {
                if (isSure) {
                    return innerSettings.deleteCallback(entity);
                } else {
                    return Promise.resolve();
                }
            }).catch((error) => {
                console.error(error);
                this.trigger(notificationEvents.NOTIFY, {
                    text: "Something went wrong!",
                    cssClass: BULMA_CSS_CLASSES.IS_DANGER,
                });
            });
        });
    };

    /**
     * Convenience method for adding a toggle for creating a instance of an object. These toggles can be added manually
     * but as this type was so common, it was best to put it here to avoid duplicate code.
     * @param model
     * @param fields
     * @param defaultProperties
     * @param icon
     * @param title
     */
    this.addCreator = (model, fields, defaultProperties, icon = "plus", title = linguist.t("create")) => {
        let creator = new EditorCard();
        creator.setModel(model);
        creator.title = title;
        creator.defaultProperties = defaultProperties;
        fields.forEach(creator.addField);
        this.addToggleView(creator, icon);
        return creator;
    };

    this.enableExport = (exportFields, selectedExportFields = []) => {
        let exportCard = new ExportCard();
        this.on(EVENTS.META_DATA, (metadata) => {
            exportCard.setTotalCount(metadata["totalCount"]);
            exportCard.setFilter(this.getCurrentFilter());
            exportCard.setSuggestionFilter(this.getSuggestionFilter());
            exportCard.setQueryParameters(this.getCurrentQueryParameters());
            exportCard.setOrderBy(this.getOrderByString());
        });
        exportCard.setModel(model);
        exportCard.setSelectableColumns(exportFields);
        exportCard.setColumns(selectedExportFields);
        this.addToggleView(exportCard, "download");
    };

    this.getCurrentFilter = () => Filter.every([toggleFilter, ...filters]);
    this.getSuggestionFilter = () => suggestionFilter;
    this.getCurrentQueryParameters = () => queryParameters;

    this.setTableComponent = (table, content = this.content) => {
        table.addCssClass(BULMA.IS_FULLWIDTH, BULMA.IS_HOVERABLE, BULMA.IS_NARROW, BULMA.IS_STRIPED);
        table.on("orderAsc", field => {
            this.setOrderBy(field.getOrderBy());
            currentPage = 1;
            return this.loadData();
        });
        table.on("orderDesc", field => {
            this.setOrderBy(field.getOrderBy(), true);
            currentPage = 1;
            return this.loadData();
        });
        throttler.on(PromiseThrottler.IS_IDLE, () => table.isLoading = false);
        throttler.on(PromiseThrottler.IS_LOADING, () => table.isLoading = true);
        content.table = table;
    };

    this.content = this.insertOrderedChild(new Container((content) => {
        this.setTableComponent(new Table(), content);
        content.getNode().setStyle("clear", "both");
        content.pagination = new Pagination(pagination => {
            pagination.firstRow = 0;
            pagination.maxRows = maxRows;
            pagination.on("pageSelected", page => {
                currentPage = page;
                content.table.tableHead.getNode().getActualElement().scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "nearest"
                });
                this.loadData().finally(error => {
                    pagination.refreshButtons();
                });
            });
        });
    }), 2);

    this.waitFor(this.when(EVENTS.READY));
}, ["table-view"]);

export {TableView as default, EVENTS};
