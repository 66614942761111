import Linguist from "../../lib/JuiS/Linguist";

const getRegionName = (region) => {
    if (region) {
        try {
            return new Intl.DisplayNames(Linguist.getLanguage(), {type: "region"}).of(region);
        } catch (e) {
            console.error(region + " is not a valid region name");
            return region;
        }

    }
    return "None";
};

const countryCodes = ["FI", "NO", "SE"];

export {getRegionName, countryCodes};