import LoadingPage from "./LoadingPage.js";
import NotFoundPage from "./NotFoundPage.js";
import ErrorPage from "./ErrorPage.js";
import Router from "juis-router/Router.js";

const dynamicImport = (path) => {
    return import(/* webpackChunkName: "[request]" */ `./${path}/index.js`);
};

export default (defaultLink) => {
    const router = new Router(dynamicImport, new LoadingPage(), new NotFoundPage(), new ErrorPage());
    router.setViewsBasePath("/src/hewecon/views/");
    router.addComponent(/^\/tickets\/([a-zA-Z0-9\-]+)/, "tickets/ticket-page");

    router.addComponent(/^\/boards\/([a-zA-Z0-9\-]+)\/settings/, "boards/board-settings");
    router.addComponent(/^\/boards\/([a-zA-Z0-9\-]+)/, "boards/board-page", {urlParameterNames: ["boardCode"]});
    router.addComponent(/^\/boards/, "boards/board-list");

    router.addComponent(/^\/emails/, "emails");
    router.addComponent(/^\/sales-price-rules\/([a-zA-Z0-9\-]+)/, "sales-price-rules");
    router.addComponent(/^\/sales-price-rules/, "sales-price-rules/list");

    router.addComponent(/^\/dynamic-fields\/models\/([a-zA-Z]+)\/?/, "dynamic-fields", {urlParameterNames: ["model"]});
    router.addComponent(/^\/dynamic-fields\/models\/?/, "dynamic-field-models");
    router.addComponent(/^\/dynamic-fields/, "dynamic-fields");
    router.addComponent(/^\/dynamic-field-options\/([a-zA-Z0-9\-]+)/, "dynamic-field-options");
    router.addComponent(/^\/d-e\/settings\/([a-zA-Z0-9\-]+)\/?/, "dynamic-entity-tab-view/settings");
    router.addComponent(/^\/d-e\/([a-zA-Z0-9\-]+)\/([a-zA-Z0-9\-]+)\/?/, "dynamic-entity-tab-view", {
        urlParameterNames: [
            "entityTabViewCode",
            "entityCode"
        ]
    });
    router.addComponent(/^\/d-e\/([a-zA-Z0-9\-]+)\/?/, "dynamic-entity-tab-view/none-selected");
    router.addComponent("/d-e/", "dynamic-entity-tab-view/list");

    router.addComponent(/^\/ticket-lists\/([a-zA-Z0-9\-]+)\/settings/, "ticket-lists/ticket-list-settings", {});
    router.addComponent(/^\/ticket-lists\/([a-zA-Z0-9\-]+)/, "tickets/ticket-list-page", {urlParameterNames: ["ticketListCode"]});
    router.addComponent(/^\/ticket-lists/, "ticket-lists/ticket-lists-list");

    router.addComponent(/^\/product-lists\/([a-zA-Z0-9\-]+)/, "products/product-list");
    router.addComponent(/^\/product-lists/, "products/settings/product-lists");
    router.addComponent(/^\/vat-groups/, "products/settings/vat-groups");
    router.addComponent(/^\/product-groups\/([a-zA-Z0-9\-]+)/, "products/product-list/group");
    router.addComponent(/^\/product-groups/, "products/product-group-list", {});
    router.addComponent(/^\/products\/([a-zA-Z0-9\-]+)\/?/, "products/product-view", {urlParameterNames: ["productCode"]});
    router.addComponent(/^\/products/, "products/product-list/generic");
    router.addComponent(/^\/product-stock-alerts/, "products/product-list/stock-alert");
    router.addComponent(/^\/product-views/, "products/settings/product-view");

    router.addComponent(/^\/resources\/([a-zA-Z0-9\-]+)/, "resources/resource-view", {urlParameterNames: ["resourceCode"]});

    router.addComponent(/^\/settings\/resources/, "resources");
    router.addComponent(/^\/settings\/resource-groups/, "resource-groups");
    router.addComponent(/^\/resource-groups\/([a-zA-Z0-9\-]+)\/?/, "resource-groups/resource-list", {urlParameterNames: ["resourceGroupCode"]});

    router.addComponent(/^\/stock-product-lists\/([a-zA-Z0-9\-]+)/, "stock-product-list");
    router.addComponent(/^\/stock-product-lists/, "settings/stock-product-list");
    router.addComponent(/^\/stocks\/([a-zA-Z0-9\-]+)/, "stock/stock-product-list");
    router.addComponent(/^\/stocks/, "stock/stock-list");
    router.addComponent(/^\/stock-locations/, "stock/stock-location-list");

    router.addComponent(/^\/sales-reports\/([a-zA-Z0-9\-]+)\/?/, "products/sales-reports");
    router.addComponent(/^\/sales-reports/, "products/settings/sales-reports");

    router.addComponent(/^\/production-lists/, "stock/production-list/settings");
    router.addComponent(/^\/production/, "stock/production-list");

    router.addComponent(/^\/contacts\/([a-zA-Z0-9\-]+)\/?/, "contacts/contact-view", {urlParameterNames: ["contactCode"]});
    router.addComponent(/^\/contact-groups\/([a-zA-Z0-9\-]+)/, "contacts", {urlParameterNames: ["contactGroupCode"]});
    router.addComponent(/^\/contact-lists\/([a-zA-Z0-9\-]+)/, "contacts/lists");
    router.addComponent("contact-lists", "contacts/lists/settings");
    router.addComponent("/contact-groups", "contacts/groups");

    router.addComponent(/^\/bills\/([a-zA-Z0-9\-]+)-NEW/, "bills/bill-view", {"isNew": true});
    router.addComponent(/^\/bills\/([a-zA-Z0-9\-]+)$/, "bills/bill-view");

    router.addComponent(/^\/bill-types\/([a-zA-Z0-9\-]+)\/setup-wizard/, "bills/invoice-setup-wizard", {urlParameterNames: ["billTypeCode"]});
    router.addComponent(/^\/bill-types\/([a-zA-Z0-9\-]+)\/bills/, "bills", {urlParameterNames: ["billTypeCode"]});
    router.addComponent(/^\/bill-types\/([a-zA-Z0-9\-]+)\/settings/, "bills/bill-types/settings");
    router.addComponent(/^\/bill-types\/([a-zA-Z0-9\-]+)/, "bills/bill-types/view", {urlParameterNames: ["billTypeCode"]});
    router.addComponent(/^bills\/$/, "bills");
    router.addComponent("/bill-types", "bills/bill-types/list");
    router.addComponent(/^\/bill-templates\/([a-zA-Z0-9\-]+)/, "bills/templates");
    router.addComponent("/bill-templates", "bills/templates/list");
    router.addComponent(/^\/bill-lists\/([a-zA-Z0-9\-]+)/, "bills/bill-lists/bills");
    router.addComponent("/bill-lists", "bills/bill-lists");
    router.addComponent("/outstanding-receivables", "bills/outstanding-receivables");

    router.addComponent(/^\/subscriptions\/?$/, "subscriptions");
    router.addComponent(/^\/subscriptions\/NEW/, "subscriptions/subscription-view", {"isNew": true});
    router.addComponent(/^\/subscriptions\/([a-zA-Z0-9\-]+)/, "subscriptions/subscription-view", {urlParameterNames: ["subscriptionCode"]});
    router.addComponent("/subscriptions", "subscriptions");

    router.addComponent("/bank-accounts", "bills/bank-accounts");
    router.addComponent("/payments", "bills/payments");

    router.addComponent("/user-roles", "user-roles");

    router.addComponent("/settings/customer-forms", "customer-forms/settings");
    router.addComponent(/^\/public-customer-forms\/([a-z\d\-]+)\/?/, "customer-forms/public-form");
    router.addComponent("/customer-forms", "customer-forms");

    router.addComponent(/^\/contact-details\/?$/, "contact-details");
    router.addComponent(/^\/settings\/ticket-templates\/([a-zA-Z0-9\-]+)\/?/, "tickets/ticket-template-page");
    router.addComponent(/^\/settings\/ticket-types\/([a-zA-Z0-9\-]+)\/?/, "tickets/ticket-type-page");
    router.addComponent("/settings/ticket-types/", "tickets/ticket-type-list");
    router.addComponent("/settings/app/", "settings/app");
    router.addComponent(/^\/settings\/menus\/([a-zA-Z0-9\-]+)\/?/, "settings/menus/menu-editor");
    router.addComponent(/^\/settings\/menu-items\/([a-zA-Z0-9\-]+)\/?/, "settings/menus/menu-item-editor");
    router.addComponent("/settings/menus/", "settings/menus/menu-list");
    router.addComponent(/time-periods\/([a-zA-Z0-9\-]+)\/?/, "settings/time-periods/time-period-editor");
    router.addComponent("/time-periods/", "settings/time-periods/time-period-list");
    router.addComponent("/settings/upload-csv/", "admin/upload-csv");
    router.addComponent("/settings-cards", "settings");
    router.addComponent("/integrations/dropbox", "integrations/dropbox");
    router.addComponent("/not-implemented", "not-implemented");
    router.addComponent("/scanner", "scanner");
    if (defaultLink) {
        router.setFallbackRoute(defaultLink);
    }
    return router;
}
