import RestServer from "./RestServer.js";
import {removeByValue} from "juis-commons/JuisUtils.js";

function EventLogger(window) {
    const expectedErrors = [];
    this.ignoreNextError = (identify = () => true, timeout = 0) => {
        const wrappedIdentity = (...args) => {
            let identified = identify(...args);
            if (identified) {
                removeByValue(expectedErrors, wrappedIdentity);
            }
            return identified;
        };
        expectedErrors.push(wrappedIdentity);
        if (timeout) {
            setTimeout(() => removeByValue(expectedErrors, wrappedIdentity), timeout);
        }
    };
    this.logError = (error) => {
        if (typeof error === "string") {
            error = {"message": error};
        }
        if (!expectedErrors.some(identify => identify(error))) {
            RestServer.post("error", error)
                .then(response => console.info("Error logged on server with id: ", response.data.logId))
                .catch((e) => console.error(e));
        } else {
            console.info("Ignored error", error);
        }
    };

    this.isScriptError = error => error.message?.toLowerCase().startsWith("script error");

    window.onerror = (message, fileName, line, col, error) => {
        this.logError({message, fileName, line, col, stackTrace: error?.stack, page: document.URL});
    };
    window.addEventListener("unhandledrejection", (event) => {
        this.logError({
            message: event.reason.message,
            line: event.reason.lineNumber,
            stack: event.reason.stack,
            fileName: event.reason.fileName,
            page: document.URL
        });
    });
}

let eventLogger;
const EventLoggerSingleton = function () {
    if (eventLogger) {
        return eventLogger;
    }
    eventLogger = new EventLogger(window);
    return eventLogger;
};

export default EventLoggerSingleton;
