import TextComponent from "../../components/TextComponent.js";
import Linguist from "../../../lib/JuiS/Linguist.js";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default (value) => {
    let languageNames = new Intl.DisplayNames([Linguist.getLanguage()], {type: "language"});
    return new TextComponent(value ? capitalizeFirstLetter(languageNames.of(value)) : "Not set");
}
