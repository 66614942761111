import EventLoggerSingleton from "./EventLogger.js";
import sessionHandlerSingleton from "../SessionHandler.js";
import RestModel from "./RestModel.js";

const eventLogger = new EventLoggerSingleton();
const PromiseQueue = function () {
    let queue = Promise.resolve();
    let idMap = new Map();
    let waitTime = 0;

    const wait = () => {
        return new Promise(resolve => {
            setTimeout(resolve, waitTime);
        });
    };

    const addToQueue = (promiseFunction) => {
        queue = queue.finally(() => wait().then(promiseFunction));
    };

    this.setWaitTime = (newWaitTime) => waitTime = newWaitTime;

    this.add = (promiseFunction, ...params) => {
        addToQueue(() => promiseFunction(...params));
    };
    /**
     * Alternative way of adding to queue by providing an id. If the same ID is already queued the queued one will be replaced
     * An Id is unqueued when the callback function starts executing.
     * @param id
     * @param promiseFunction
     * @param params
     */
    this.addWithId = (id, promiseFunction, ...params) => {
        if (!idMap.has(id)) {
            addToQueue(() => {
                const mappedFunction = idMap.get(id);
                idMap.delete(id);
                return mappedFunction();
            });
        }
        idMap.set(id, () => promiseFunction(...params));
    };
};
const modificationHandlerQueue = new PromiseQueue();
modificationHandlerQueue.setWaitTime(2000);
const blacklist = [];
const handleServerSideModification = (entityIdentifier) => {
    if (!sessionHandlerSingleton.getCurrentUser()) {
        return; // If user has logged out, do nothing
    }
    const model = RestModel.getByName(entityIdentifier.model);
    if (!model) {
        console.warn("Model " + entityIdentifier.model + " not found");
        return;
    }
    const cachedEntity = model.cache.getById(entityIdentifier.id);
    if (!cachedEntity && entityIdentifier.modificationType === "DELETE") {
        return; // An entity we don't know about was deleted. No need to do anything here
    }
    const handleError = error => {
        blacklist.push(identifierToString(entityIdentifier));
        const errorMessage = {
                message: error.message,
                stack: error.stack,
                page: "web-push",
                blacklist: blacklist.join(", "),
                entityIdentifier: identifierToString(entityIdentifier)
            }
        ;
        eventLogger.logError(errorMessage);
    };
    if (cachedEntity) {
        if (entityIdentifier.modified && (new Date(entityIdentifier.modified) <= cachedEntity.modified)) {
            // We already have the same or a newer version
            return;
        }
        return cachedEntity.reload()
            .then(entity => {
                if (!entity.deleted) {
                    model.trigger(model.EVENTS.AFTER_UPDATE, entity);
                    entity.trigger(model.EVENTS.AFTER_UPDATE);
                }
            })
            .catch(handleError);
    } else {
        return model.getById(entityIdentifier.id)
            .then(entity => {
                model.invalidateListFetcherCache();
                if (entity) {
                    if (entityIdentifier.modificationType === "CREATE") {
                        model.trigger(model.EVENTS.AFTER_INSERT, entity);
                        entity.trigger(model.EVENTS.AFTER_INSERT);
                    } else if (entityIdentifier.modificationType === "UPDATE") {
                        model.trigger(model.EVENTS.AFTER_UPDATE, entity);
                        entity.trigger(model.EVENTS.AFTER_UPDATE);
                    }
                }
            }).catch(handleError);
    }
};

const identifierToString = (entityIdentifier) => {
    return entityIdentifier.model + entityIdentifier.id;
};
const handleServerSideModificationInQueue = (entityIdentifier) => {
    const identifierString = identifierToString(entityIdentifier);
    if (!blacklist.includes(identifierString)) {
        modificationHandlerQueue.addWithId(identifierString, () => handleServerSideModification(entityIdentifier));
    }
};
const handleModificationEvents = (app) => {
    navigator.serviceWorker?.addEventListener("message", (event) => {
        if (event.data.type === "ServerSideModification") {
            if (!event.data.appData || event.data.appData.id === app.id) {
                handleServerSideModificationInQueue(event.data.entityIdentifier);
            }
        }
    });
};

export default handleModificationEvents;
