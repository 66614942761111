const HTLM = {
    ARTICLE: "ARTICLE",
    CANVAS: "CANVAS",
    DIV: "DIV",
    FIGURE: "FIGURE",
    SPAN: "SPAN",
    LABEL: "LABEL",
    BUTTON: "BUTTON",
    NAV: "NAV",
    ASIDE: "ASIDE",
    TR: "TR",
    TD: "TD",
    TEMPLATE: "TEMPLATE",
    TABLE: "TABLE",
    TH: "TH",
    THEAD: "THEAD",
    TBODY: "TBODY",
    TFOOT: "TFOOT",
    HEADER: "HEADER",
    FOOTER: "FOOTER",
    HR: "HR",
    LI: "LI",
    UL: "UL",
    P: "P",
    A: "A",
    I: "I",
    SECTION: "SECTION",
    IMG: "IMG",
    FORM: "FORM",
    H1: "h1",
    H2: "h2",
    H3: "h3",
    H4: "h4",
    PRE: "pre",
    OUTPUT: "output",
    PROGRESS: "progress",
    CODE: "code",
    SELECT: "select",
    OPTION: "option",
};
export default HTLM;
