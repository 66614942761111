import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import ProductGroup from "./ProductGroup.js";
import Container from "juis-components/Container.js";
import {
    createTextAreaEditor,
    getDropDownFactory,
    iconPickerFactory
} from "../../components/fieldEditors/editorFactories.js";
import App from "./App.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import TextComponent from "../../components/TextComponent.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import productLexicons from "./lexicons/product/productLexicons.js";
import {createTextNode} from "juis-components/ComponentUtils.js";
import {createIcon} from "../../bulma_components/Icon.js";
import BulmaUtils from "../../bulma_components/BulmaUtils.js";
import Method from "../../utils/Method.js";
import Progress from "../../components/progress/Progress";
import Contact from "./Contact";
import Filter from "../../utils/Filter";
import ProductPrice from "./ProductPrice";

let linguist = new Linguist(productLexicons);
const getStockQuantityCell = (quantity, product) => {
    const textComponent = new TextComponent();
    if (product.unit) {
        textComponent.text = quantity + " " + product.unit;
    } else {
        textComponent.text = quantity;
    }
    if (quantity < (product.minimumQuantity || 0)) {
        textComponent.getNode().addCssClass(BULMA_CSS_CLASSES.TYPOGRAPHY.HAS_TEXT_DANGER);
    }
    if (product.minimumQuantity) {
        return new Container(function () {
            this.quantity = textComponent;
            this.minimum = new TextComponent(` / ${product.minimumQuantity}`);
        });
    }
    return textComponent;
};

let Product = new Model(function () {
    this.app = new Field(App);
    this.code = new Field().asString().withLabel("Url Code");
    this.productNumber = new Field().asString().withLabel("Product number / code");
    this.name = new Field().asString().withLabel("Name").asRequired()
        .withCellFactory((name, product) => name || (product.variationOf ? product.variationOf.name : ""));
    this.description = new Field().asString().withLabel("Description").withEditorFactory(createTextAreaEditor);
    this.info = new Field().asString().withLabel("Product info").withEditorFactory(createTextAreaEditor);
    this.icon = new Field().asString().withLabel("Icon")
        .withEditorFactory(iconPickerFactory)
        .withCellFactory((icon, product) => icon || (product.variationOf ? product.variationOf.icon : ""));
    this.unit = new Field().asString().withLabel("Unit")
        .withCellFactory((unit, product) => unit || (product.variationOf ? product.variationOf.unit : ""));
    this.brand = new Field().asString().withLabel("Brand")
        .withCellFactory((brand, product) => brand || (product.variationOf ? product.variationOf.brand : ""));
    this.model = new Field().asString().withLabel("Model")
        .withCellFactory((model, product) => model || (product.variationOf ? product.variationOf.model : ""));
    this.year = new Field().asNumber(1900, 2099, 1).withLabel("Year")
        .withCellFactory((year, product) => year || (product.variationOf ? product.variationOf.year : ""));

    this.price = new Field(ProductPrice)
        .withDefaultValueCallback((product) => ProductPrice.getEmpty(product.app))
        .asCascading();
    this.purchasePrice = new Field(ProductPrice)
        .withDefaultValueCallback((product) => ProductPrice.getEmpty(product.app))
        .asCascading()
        .withLabel("Purchase Price");
    this.referencePurchasePrice = new Field(ProductPrice)
        .withDefaultValueCallback((product) => ProductPrice.getEmpty(product.app))
        .asCascading()
        .withLabel("Reference purchase Price");
    this.referencePrice = new Field(ProductPrice)
        .withDefaultValueCallback((product) => ProductPrice.getEmpty(product.app))
        .asCascading()
        .withLabel("Reference Price");


    this.priceMargin = new Field().asVirtual(undefined, (product) => {
        if (product.price?.priceVatExcluded && product.purchasePrice?.priceVatExcluded) {
            return 1 - (product.purchasePrice.priceVatExcluded / product.price.priceVatExcluded);
        }
        return "∞";
    }).withCellFactory((margin, product) => {
        if (typeof margin === "number") {
            return new Progress(function () {
                this.setValue(margin);
                this.bindListener(product.on("change", () => this.setValue(product.priceMargin)));
            });
        }
        return margin;
    });
    this.variationOf = new Field(this).withLabel("Variation\u{00A0}of");
    this.variationQuantity = new Field()
        .asNumber()
        .asReadOnly()
        .withLabel("Variations");
    this.groups = new Field([ProductGroup])
        .withOptionsLoader((searchString, product) => {
            const notSiblingToSpecific = Filter.notIn(ProductGroup.parent, product.groups
                .filter(group => group.specific)
                .map(group => group.parent)
            );
            const childGroups = Filter.in(ProductGroup.parent, product.groups);
            const parentGroups = Filter.isNull(ProductGroup.parent);
            const notSpecificSibling = Filter.or(Filter.eq(ProductGroup.specific, false), notSiblingToSpecific);
            const filter = Filter.and(Filter.or(childGroups, parentGroups), notSpecificSibling);
            return ProductGroup.getListForDropdown(searchString, [ProductGroup.name], product.app, filter);
        })
        .withEditorFactory((field, entity) => getDropDownFactory({refreshOnChange: [field]})(field, entity))
        .withLabel("Groups");
    this.stockQuantity = new Field()
        .asNumber()
        .asReadOnly()
        .withCellFactory(getStockQuantityCell)
        .withLabel("Total stock");
    this.minimumQuantity = new Field()
        .asNumber()
        .withCellFactory((quantity, product) => {
            if (quantity === null) {
                return "";
            }
            if (product.unit) {
                return quantity + " " + product.unit;
            }
            return quantity;
        })
        .withLabel("Stock minimum");
    this.shared = new Field().asBoolean().withLabel("Shared")
        .withHelp("If checked, other users on Hewecon can see this product");
    this.grossWeight = new Field().asNumberWithUnit("kilogram").withLabel("Gross weight");
    this.netWeight = new Field().asNumberWithUnit("kilogram").withLabel("Net weight");
    this.packageWeight = new Field().asNumberWithUnit("kilogram").withLabel("Package weight");
    this.defaultContainerWeight = new Field().asNumberWithUnit("kilogram").withLabel("Default container weight");
    this.suppliers = new Field([Contact]).withOptionsLoader((searchString, product) =>
        Contact.getDefaultOptionsLoader()(searchString, product, Filter.eq(Contact.contactGroup, product.app.suppliers))
    ).withLabel("Suppliers");
    this.calculatePriceFromNetWeight = new Field().asBoolean();
    this.extraFields = new Field().asJson();
    this.getIcon = function () {
        return this.icon || this.groups.map(group => group.icon).find(icon => icon) || "box";
    };
    this.getProductName = function () {
        let variationName = this.variationOf?.name ? this.variationOf.name + " · " : "";
        if (this.productNumber && this.name) {
            return this.productNumber + " · " + this.name;
        }
        return variationName + this.name || this.productNumber || "[Unnamed]";
    };
    this.getUrl = function () {
        return `/${this.app.code}/products/${this.code}`;
    };
    this.hasAnyGroup = new Method(function (groups) {
        return this.groups.some(group => groups.includes(group));
    });
    this.SEARCH_FIELDS = [this.productNumber, this.name, this.description, this.info, this.variationOf.name];
    setDefaults(this, {
        filterFields: this.SEARCH_FIELDS,
        orderByField: this.name,
        linkFactory: (product) => createLink(product.getUrl(), product.getProductName()),
        itemFactory: (product) => new Container(function () {
            let icon = product.icon || product.groups[0]?.icon;
            let tooltip = product.groups[0] ? ProductGroup.name.getTranslatedValueFor(product.groups[0]) : "";
            let color = product.groups[0]?.color;
            if (icon || color || tooltip) {
                this.icon = createIcon(icon);
                if (color) {
                    this.icon.color = color;
                }
                if (tooltip) {
                    this.icon.tooltip = BulmaUtils.getTooltipProperty(this.icon);
                    this.icon.addCssClass(BULMA_CSS_CLASSES.HAS_TOOLTIP_RIGHT);
                    this.icon.tooltip = tooltip;
                }
            }
            this.text = createTextNode(product.getProductName());
        }),
        editorFactory: getDropDownFactory({
            searchable: true
        })
    });
    linguist.withAudience(this);

    this.canDelete = new Method(function () {
        return this.stockQuantity === 0;
    });

}, "Product", "products");

export default Product;
