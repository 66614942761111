import {arrayWrap} from "juis-commons/JuisUtils.js";
import Field from "./Field.js";

export default function SuggestionFilter(searchString, orderBy, fields) {
    this.searchString = searchString;
    this.fields = arrayWrap(fields).map(field => {
        if (typeof field === "string") {
            return field;
        }
        if (field instanceof Field) {
            if (field.isVirtual()) {
                return field.getOrderBy();
            } else {
                return field.getName();
            }
        }
        throw new Error("Unknown field type: " + typeof field + " " + field);
    });
    this.orderBy = arrayWrap(orderBy).map(field => field.getOrderBy ? field.getOrderBy() : field);
}
