import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import Bill from "./Bill.js";
import {getSecondLevelFields} from "../../utils/fieldUtils.js";
import App from "./App.js";
import BillType from "./BillType.js";
import setDefaults from "./utils/setDefaults.js";
import getIconCellFactory from "./utils/getIconCellFactory.js";
import {iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Url Code");

    this.name = new Field()
        .asString()
        .withLabel("Name");

    this.title = new Field()
        .asTranslatableString()
        .withLabel("Title");

    this.icon = new Field()
        .asString()
        .withEditorFactory(iconPickerFactory)
        .withLabel("Icon");

    this.color = new Field()
        .asColor()
        .withLabel("Color");

    this.description = new Field()
        .asString()
        .withLabel("Description");

    this.billTypes = new Field([BillType]).withLabel("Bill types");

    this.columns = new Field()
        .asEnumList(getSecondLevelFields(Bill))
        .withLabel("Columns");

    this.filterFields = new Field()
        .asEnumList(getSecondLevelFields(Bill))
        .withLabel("Filter fields");

    this.makeTranslatable();

    this.aggregations = new Field()
        .asEnumList(Bill.getAggregations())
        .withLabel("Aggregations");

    this.excludeFromCachedDescendants();

    setDefaults(this, {
        filterFields: [this.name, this.description],
        linkFactory: (billList) =>
            createLink(`/${billList.app.code}/bill-lists/${billList.code}`, this.title.getTranslatedValueFor(billList), billList.icon),
        itemFactory: getIconCellFactory(this.icon, this.name, this.color),
    });
}, "BillList", "bill-lists");
