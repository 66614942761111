/** @typedef {{string, (string|Lexicon)}} Lexicon */

/** @typedef {string} LanguageCode */

import parseEntityTemplate from "../../src/utils/parseEntityTemplate.js";

/** @typedef {Object|null|undefined} Audience */

const allLinguists = [];
const urlLanguage = new URLSearchParams(window.location.search).get("lang");
const browserLanguage = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage);
let globalLanguage = (urlLanguage || browserLanguage || "en_US").replace("-", "_");
document.documentElement.setAttribute("lang", globalLanguage.substring(0, 2));
const defaultDateFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
};

/**
 *
 * @param {string} string
 * @returns {string}
 */
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 *
 * @param {{LanguageCode, Lexicon}} lexicons
 * @constructor
 */
function Linguist(lexicons = {}) {
    allLinguists.push(this);

    const findLexicon = (language) => {
        if (lexicons[language]) {
            return lexicons[language];
        }
        const partialMatch = Object.entries(lexicons)
            .filter(([languageCode, lexicon]) => languageCode.substring(0, 2) === language.substring(0, 2))
            .map(([languageCode, lexicon]) => lexicon)[0];
        if (partialMatch) {
            return partialMatch;
        }
        return lexicons["en_US"] || Object.values(lexicons)[0] || {};
    };

    const audiences = [];
    let language = globalLanguage;
    let lexicon = findLexicon(language);

    /**
     *
     * @param {string} key
     * @param {Lexicon} subLexicon
     * @param {object} environment
     * @returns {string|undefined}
     */
    const findPhrase = (key, subLexicon = lexicon, environment = {}) => {
        const phrase = findSubObject(key, subLexicon);
        if (typeof phrase === "string") {
            return phrase.replace(/\${([^}]*)}/g, (m, $1) =>
                findVariableTranslation($1, environment));
        }
    };

    /**
     *
     * @param {string} key
     * @param {Lexicon} subLexicon
     * @param {object} environment
     * @returns {[*]}
     */
    const findComponentList = (key, subLexicon = lexicon, environment = {}) => {
        const phrase = findSubObject(key, subLexicon);
        if (typeof phrase === "string") {
            return parseToList(phrase, environment, language);
        }
    };

    const parseToList = (template, environment, locales = "default") => {
        if (!template) {
            return [];
        }
        const parts = [];
        const initialParts = template.split(/(\${([^}]*)})/g);
        // This regex creates two items in the array for each variable. So we need to remove the other.
        initialParts.forEach((part, index) => {
            if (part.startsWith("${") && part.endsWith("}")) {
                return;
            }
            if (index > 0 && initialParts[index - 1].startsWith("${") && initialParts[index - 1].endsWith("}")) {
                return parts.push(findVariableTranslation(part, environment));
            }
            parts.push(part);
        });
        return parts;
    };

    const findVariableTranslation = (key, environment) => {
        let pipes = [];
        if (key.includes("|")) {
            pipes = key.split("|");
            key = pipes.shift();
        }
        if (key.includes(".")) {
            const head = key.substring(0, key.lastIndexOf("."));
            const tail = key.substring(key.lastIndexOf(".") + 1);
            const parentObj = findSubObject(head, environment);
            if (parentObj["getTranslatedValueFor"]) {
                let value = parentObj.getTranslatedValueFor(tail, Linguist.getLanguage());
                return runPipes(value, pipes);
            }
        }
        let value = findSubObject(key, environment, "");
        return runPipes(value, pipes, environment);
    };

    const runPipes = (value, pipes, environment) => {
        pipes.forEach(pipe => {
            switch (pipe) {
                case "toLowerCase":
                    value = value.toLowerCase();
                    break;
                case "numberFormat":
                    value = new Intl.NumberFormat(getLocale(language), environment["numberFormatOptions"] || {})
                        .format(value);
                    break;
                case "dateFormat":
                    value = new Intl.DateTimeFormat(getLocale(language), environment["dateFormatOptions"] || defaultDateFormatOptions)
                        .format(value);
                    break;
                default:
                    throw new Error(`Unknown linguist pipe: ${pipe}`);
            }
        });
        return value;
    };

    const findSubObject = (key, obj, fallback = {}) => {
        if (!obj) {
            return fallback;
        }
        if (!key) {
            return obj;
        }
        const dotIndex = key.indexOf(".");
        if (dotIndex === -1) {
            return obj[key] ?? fallback;
        } else {
            const keyHead = key.substring(0, dotIndex);
            const subObj = obj[keyHead];
            if (subObj) {
                const subKey = key.substring(dotIndex + 1);
                return findSubObject(subKey, subObj, fallback);
            } else {
                return fallback;
            }
        }
    };

    /**
     *
     * @param {LanguageCode} newLanguage
     */
    this.setLanguage = (newLanguage) => {
        language = newLanguage;
        lexicon = findLexicon(language);
        translateForAllAudiences();
    };

    /**
     *
     * @param {string,[string]} key
     * @param {object} environment
     * @returns {string}
     */
    this.t = (key, environment = {}) => {
        if (Array.isArray(key)) {
            key = key.join(".");
        }
        if (typeof key["getTranslatedValueFor"] === "function") {
            return key["getTranslatedValueFor"](environment);
        }
        return findPhrase(key, lexicon, environment) ?? key.substring(key.indexOf(".") + 1);
    };

    /**
     *
     * @param {string} key
     * @param {object} environment
     * @returns {[*]}
     */
    this.toList = (key, environment = {}) => {
        return findComponentList(key, lexicon, environment);
    };

    const tellAudience = (audience, key, translation) => {
        if (audience[key] !== undefined) {
            return audience[key] = translation;
        }
        const setter = audience["set" + capitalizeFirstLetter(key)] ?? audience["with" + capitalizeFirstLetter(key)];
        if (setter) {
            setter.call(audience, translation);
        }
    };

    const getTranslation = (key, lexicon, environment) => {
        return parseEntityTemplate(lexicon[key], environment, language);
    };

    const translateForAudience = (subAudience, subLexicon = lexicon, subEnvironment = {}) => {
        if (!subAudience) {
            return;
        }
        Object.entries(subLexicon).forEach(([key, subSubLexicon]) => {
            if (typeof subSubLexicon === "string") {
                tellAudience(subAudience, key, getTranslation(key, subLexicon, subEnvironment));
            } else if (typeof subSubLexicon === "object") {
                let subSubAudience = subAudience[key];
                if (typeof subSubAudience === "object") {
                    translateForAudience(subSubAudience, subSubLexicon, subEnvironment[key] ?? {});
                }
            }
        });
    };

    /**
     *
     * @param {Audience} audience
     * @param {string} key
     * @param {object} environment
     */
    this.addAudience = (audience, key = "", environment = {}) => {
        audiences.push({audience, key, environment});
        translateForAudience(audience, findSubObject(key, lexicon), environment);
    };

    /**
     *
     * @param {string} baseKey
     */
    this.setBaseKey = (baseKey) => {
        const parts = baseKey.split(".");
        if (parts.length > 1) {
            parts.forEach(part => this.setBaseKey(part));
            return;
        }
        lexicon = lexicon[baseKey];
        let mappedLexicons = {};
        Object.entries(lexicons).forEach(([language, lexicon]) => mappedLexicons[language] = lexicon[baseKey] ?? {});
        lexicons = mappedLexicons;
    };

    const translateForAllAudiences = () => {
        audiences.forEach(({audience, key, environment}) => {
            let subLexicon = findSubObject(key, lexicon);
            translateForAudience(audience, subLexicon, environment);
        });
    };

    this.withAudience = (audience) => {
        this.addAudience(audience);
        return this;
    };

    this.withBaseKey = (baseKey) => {
        this.setBaseKey(baseKey);
        return this;
    };

    this.getRebasedLinguist = (baseKey) => {
        const rebasedLexicons = baseKey.split(".").reduce((accumulatedLexicons, baseKeyPart) => {
            let mappedLexicons = {};
            Object.entries(accumulatedLexicons).forEach(([language, accumulatedLexicon]) => mappedLexicons[language] = accumulatedLexicon[baseKeyPart] ?? {});
            return mappedLexicons;
        }, lexicons);
        return new Linguist(rebasedLexicons);
    };
    this.getLanguage = () => getLocale(globalLanguage);
}

const getLocale = (language) => {
    return language.replace("_", "-");
};

/**
 *
 * @param {LanguageCode} language
 */
Linguist.setLanguage = (language) => {
    globalLanguage = language.replace("-", "_");
    document.documentElement.setAttribute("lang", globalLanguage.substring(0, 2));
    allLinguists.forEach(linguist => {
        linguist.setLanguage(globalLanguage);
    });
};

/**
 *
 * @returns {LanguageCode}
 */
Linguist.getLanguage = () => getLocale(globalLanguage);


export default Linguist;
