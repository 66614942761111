function getDigitBeforeChar(string, char) {
    const regexp = new RegExp(`(?<group>-?\\d+)${char}`);
    const match = string.match(regexp);
    if (!match) {
        return "0";
    }
    return string.match(regexp).groups.group;
}

function parsePeriod(value) {
    if (!value) {
        return {
            years: 0,
            months: 0,
            days: 0
        }
    }
    return {
        years: getDigitBeforeChar(value, "Y"),
        months: getDigitBeforeChar(value, "M"),
        days: getDigitBeforeChar(value, "D"),
    }
}

export {parsePeriod}
