import Component from "juis-components/Component.js";
import HTML from "../../../utils/HTML.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import Property from "juis-components/Property.js";


export default extendComponent(Component, function () {
    this.imageData = new Property((newImageData) => {
        let dataUrl = URL.createObjectURL(newImageData);
        this.getNode().setAttribute("src", dataUrl);
    });
    this.size = new Property(newSize => {
        this.getNode().setStyle("width", newSize + "px");
        this.getNode().setStyle("height", newSize + "px");
    }, 80);
    this.getNode().setStyle("border-radius", "5px");
    this.getNode().addWrapper(HTML.FIGURE);
}, [], HTML.IMG);
