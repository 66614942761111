import Input from "../../bulma_components/Input.js";
import Button from "../../bulma_components/Button.js";
import PromiseThrottler from "../../utils/PromiseThrottler.js";
import Container from "juis-components/Container.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import HTML from "../../utils/HTML.js";

export default function (callback) {
    const loader = new PromiseThrottler().withCallback(callback).defer(250);
    loader.on(PromiseThrottler.IS_LOADING, () => searchButton.asLoading());
    loader.on(PromiseThrottler.IS_IDLE, () => searchButton.asIdle());

    const load = () => loader.load(filterInput.getValue().trim());

    const filterInput = new Input((input) => {
        input.type = Input.Types.SEARCH;
        input.on("input", load);
        input.getNode().setAttribute("name", "search");
        input.getNode().addWrapper(HTML.SPAN, [BULMA.FIELD.CONTROL, BULMA.FIELD.IS_EXPANDED]);
    });

    const searchButton = new Button((button) => {
        button.icon = "search";
        button.on("click", load);
        button.asLoading = () => button.getNode().addCssClass(BULMA.IS_LOADING);
        button.asIdle = () => button.getNode().removeCssClass(BULMA.IS_LOADING);
        button.getNode().addWrapper(HTML.SPAN, [BULMA.FIELD.CONTROL]);
    });

    return new Container(function () {
        this.input = filterInput;
        this.button = searchButton;
        this.createExpandButton = (expandCallback) => {
            this.expandButton = new Button((button) => {
                button.icon = "chevron-down";
                button.on("click", expandCallback);
                button.getNode().addWrapper(HTML.SPAN, [BULMA.FIELD.CONTROL]);
            });
        };
        this.getValue = () => filterInput.getValue();
    }, [BULMA.FIELD.FIELD, BULMA.FIELD.HAS_ADDONS]);
}
