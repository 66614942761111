import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import ResourceGroup from "./ResourceGroup.js";
import TicketType from "./TicketType.js";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field().asString().withLabel("Url Code");
    this.name = new Field().asString().withLabel("Name");
    this.description = new Field().asString().withLabel("Description");
    this.ticketType = new Field(TicketType).withLabel("Ticket Type");
    this.resourceGroup = new Field(ResourceGroup).withLabel("Resource Group");
    setDefaults(this, {defaultField: this.name});
}, "ResourceRequirement", "resource-requirements");

