import Listenable from "juis-commons/Listenable.js";

const IS_LOADING = "promise-throttler-is-loading";
const IS_IDLE = "promise-throttler-is-idle";

/**
 * Wrapper to throttle function calls that returns a promise.
 * If the previous promise is not yet resolved, new calls are deferred. If there are multiple calls made while waiting,
 * only the latest call and parameter will be executed.
 * @mixes Listenable
 */
const PromiseThrottler = function () {
    let searchReady = true;
    let searchAgainPromise;
    let searchAgainPromiseResolver;
    let deferredPromiseResolvers = [];
    let parameter;
    let callback;
    let isNew = true;
    let loading = false
    let setLoading = (newLoading) => {
        if (newLoading === loading) {
            return;
        }
        if (newLoading) {
            this.trigger(IS_LOADING);
        } else {
            this.trigger(IS_IDLE);
        }
        loading = newLoading;
    };

    /**
     *
     * @param newCallback Function that consumes one parameter and returns a promise
     * @returns {PromiseThrottler}
     */
    this.withCallback = newCallback => {
        callback = newCallback;
        return this;
    };
    this.isNew = () => isNew;
    /**
     * Set minimum time between calls regardless of promise resolve times. Default is zero.
     * @param delay in milliseconds
     * @returns {PromiseThrottler}
     */
    this.defer = (delay) => {
        const load = this.load;
        let deferTimeout;
        this.load = (newParameter) => {
            setLoading(true);
            parameter = newParameter;
            if (deferTimeout) {
                clearTimeout(deferTimeout);
            }
            deferTimeout = setTimeout(() => load(parameter).then((loadPromise => {
                deferredPromiseResolvers.forEach((resolver => resolver(loadPromise)));
                deferredPromiseResolvers = [];
            })), delay);
            return new Promise(resolve => deferredPromiseResolvers.push(resolve));
        };
        return this;
    };
    this.load = (newParameter) => {
        isNew = false;
        parameter = newParameter;
        if (searchReady) {
            setLoading(true);
            searchReady = false;
            return Promise.resolve()
                .then(() => callback(parameter))
                .finally(() => {
                    searchReady = true;
                    if (searchAgainPromise) {
                        searchAgainPromise = null;
                        this.load(parameter).then(searchAgainPromiseResolver);
                    } else {
                        setLoading(false);
                    }
                });
        } else {
            if (!searchAgainPromise) {
                searchAgainPromise = new Promise(resolve => searchAgainPromiseResolver = resolve);
            }
            return searchAgainPromise;
        }
    };
}
PromiseThrottler.IS_LOADING = IS_LOADING;
PromiseThrottler.IS_IDLE = IS_IDLE;
Listenable.apply(PromiseThrottler.prototype);
export default PromiseThrottler;
