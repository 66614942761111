import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import TicketType from "./TicketType.js";
import setDefaults from "./utils/setDefaults.js";
import getIconCellFactory from "./utils/getIconCellFactory.js";
import {getDropDownFactory, iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import {createColorIcon, createIconCell} from "../../bulma_components/Icon.js";
import TextComponent from "../../components/TextComponent.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import HTML from "../../utils/HTML.js";
import Method from "../../utils/Method.js";
import PromiseButton from "../../components/PromiseButton.js";

let TicketStatus = new RestModel(function () {
    this.app = new Field(App);
    this.ticketType = new Field(TicketType).withLabel("Ticket type");
    this.id = new Field()
        .asReadOnly()
        .asNumber();
    this.name = new Field()
        .asTranslatableString()
        .withLabel("Name");
    this.actionName = new Field()
        .asTranslatableString()
        .withLabel("Action name");
    this.actionIcon = new Field()
        .asString()
        .withCellFactory(createIconCell)
        .withEditorFactory(iconPickerFactory)
        .withLabel("Action icon");
    this.action = new Field()
        .asVirtual(this.actionName)
        .withLabel("Action")
        .withCellFactory((value, status) => {
            if (status.actionIcon || status.actionName) {
                return getIconCellFactory(this.actionIcon, this.actionName, this.color)(status);
            }
        });
    this.ordinal = new Field()
        .asNumber()
        .withLabel("Ordinal");
    this.code = new Field()
        .asString()
        .withLabel("Url Code");
    this.color = new Field()
        .asColor()
        .withLabel("Color");
    this.TYPES = {
        INITIAL: "INITIAL",
        INTERMEDIATE: "INTERMEDIATE",
        FINAL: "FINAL",
    };
    const typeCssClasses = {};
    typeCssClasses[this.TYPES.INITIAL] = BULMA.IS_INFO;
    typeCssClasses[this.TYPES.INTERMEDIATE] = BULMA.IS_WARNING;
    typeCssClasses[this.TYPES.FINAL] = BULMA.IS_SUCCESS;
    this.type = new Field()
        .asEnum([this.TYPES.INITIAL, this.TYPES.INTERMEDIATE, this.TYPES.FINAL])
        .withCellFactory((value) => new TextComponent(value, HTML.DIV, [BULMA.TAG, typeCssClasses[value]]))
        .withDefaultValueCallback(() => this.TYPES.INITIAL)
        .withLabel("Type");
    this.makeTranslatable();

    this.hasAction = new Method(function () {
        return !!(this.actionName || this.actionIcon);
    });

    this.canSetStatus = new Method(function (ticket) {
        return ticket.status !== this;
    });

    this.getActionButton = new Method(function (ticket) {
        return new PromiseButton((button) => {
            button.icon = createColorIcon(this.actionIcon || "circle", this.color);
            button.hideTextDesktop = true;
            button.text = this.actionName;
            button.getNode().setAttribute("title", this.actionName);
            button.callback = () => {
                ticket.status = this;
                return ticket.save();
            };
            button.onError = (e) => {
                throw e;
            };
            if (!this.canSetStatus(ticket)) {
                button.disabled = true;
            }
        }, [BULMA.IS_SMALL]);
    });

    const cellFactory = (entity) => {
        return getIconCellFactory(this.ticketType.icon, this.name, this.color, this.ticketType.name)(entity);
    };
    this.excludeFromCachedDescendants();
    setDefaults(this, {
        orderByField: this.ordinal,
        filterFields: [this.name],
        exportField: this.name,
        cellFactory,
        optionsLoader: (searchString, entity, filter) =>
            TicketStatus.getListForDropdown(searchString, this.name, entity.app, filter, this.name),
        editorFactory: getDropDownFactory({
            itemFactory: (status) => TicketStatus.getDefaultItemFactory()(status),
            tagFactory: (status) => TicketStatus.getDefaultItemFactory()(status),
        })
    });
}, "TicketStatus", "ticket-statuses", true);


export {TicketStatus as default};
