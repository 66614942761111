import {extendComponent} from "juis-components/ComponentUtils.js";
import BULMA_CSS_CLASSES from "./bulmaCssClasses.js";
import Container from "juis-components/Container.js";
import Component from "juis-components/Component.js";

const EVENTS = {
    ACTIVATE: "bulma_component_activate",
    DEACTIVATE: "bulma_component_deactivate"
};
const bulmaMixin = function () {
    let hidden = false;
    this.hide = () => {
        hidden = true;
        this.getNode().addCssClass(BULMA_CSS_CLASSES.IS_HIDDEN);
    };
    this.show = () => {
        hidden = false;
        this.getNode().removeCssClass(BULMA_CSS_CLASSES.IS_HIDDEN);
    };
    this.toggleHidden = (toggle) => {
        if (toggle === undefined) {
            toggle = !hidden;
        }
        return toggle ? this.hide() : this.show();
    };
    let active = false;
    this.activate = () => {
        this.getNode().addCssClass(BULMA_CSS_CLASSES.IS_ACTIVE);
        active = true;
        this.trigger(EVENTS.ACTIVATE);
    };
    this.deactivate = () => {
        this.getNode().removeCssClass(BULMA_CSS_CLASSES.IS_ACTIVE);
        active = false;
        this.trigger(EVENTS.DEACTIVATE);
    };
    this.isActive = () => active;
};

const BulmaComponent = extendComponent(Component, function () {
    bulmaMixin.call(this);
});

const BulmaContainer = extendComponent(Container, function () {
    bulmaMixin.call(this);
});

export {BulmaComponent, BulmaContainer, EVENTS}
