import Container from "juis-components/Container.js";
import {extendComponent, joinComponents} from "juis-components/ComponentUtils.js";
import HTML from "../../utils/HTML.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import ExpandableContainer from "../../bulma_components/ExpandableContainer.js";
import Card from "../../bulma_components/Card.js";
import {Component} from "juis-components/Component.js";
import TextComponent from "../TextComponent.js";

export default extendComponent(joinComponents(Card, ExpandableContainer), function () {
    let aggregations = [];
    const createLabel = function (value, aggregation, context) {
        return new Container(function () {
            this.label = new TextComponent(aggregation.label, HTML.SPAN, []);
            if (aggregation.aggregation === "count") {
                value = value.toString();
            } else {
                value = aggregation.getCellFactory() ? aggregation.getCellFactory()(value, context) : value;
            }
            if (value instanceof Component) {
                this.value = value;
            } else {
                this.value = new TextComponent(value, HTML.SPAN, [BULMA.TYPOGRAPHY.HAS_TEXT_WEIGHT_BOLD]);
            }
        });
    };
    this.setAggregationData = (aggregationDatas, aggregationContext) => {
        this.content.destroyAllChildren();
        aggregationDatas.forEach(aggregationData => {
            let aggregation = aggregations.find(aggregation =>
                aggregationData.field === aggregation.field.getName() &&
                aggregationData.aggregation === aggregation.aggregation);
            if (aggregation) {
                this.content.appendChild(createLabel(aggregationData.result, aggregation, aggregationContext));
            }
        });
    };
    this.showAggregation = (aggregation) => {
        aggregations.push(aggregation);
    };
    this.clear = () => aggregations = [];
    this.collapse(true);
}, [BULMA.CARD.CARD, "statistics-box"]);
