import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import {getDropDownFactory, iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import StockProduct from "./StockProduct.js";
import ProductGroup from "./ProductGroup.js";
import Stock from "./Stock.js";
import App from "./App.js";
import {fieldDropdownSettings, getFieldSuggestions, getSecondLevelFields} from "../../utils/fieldUtils.js";
import setDefaults from "./utils/setDefaults.js";
import getIconCellFactory from "./utils/getIconCellFactory.js";
import DynamicField from "./DynamicField.js";
import Product from "./Product.js";
import Transaction from "./Transaction.js";

const getUrl = (stockProductList) => {
    if (stockProductList.stock) {
        return `/${stockProductList.app.code}/stocks/${stockProductList.stock.code}`;
    } else {
        return `/${stockProductList.app.code}/stock-product-lists/${stockProductList.code}`;
    }
}

const createStockProductListLink = (stockProductList) => {
    return createLink(getUrl(stockProductList), stockProductList.name, stockProductList.icon, stockProductList.color);
};

let StockProductList = new Model(function () {
    this.app = new Field(App);
    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Code");

    this.name = new Field()
        .asString()
        .withLabel("Name");

    this.icon = new Field()
        .asString()
        .withEditorFactory(iconPickerFactory)
        .withLabel("Icon");

    this.color = new Field()
        .asColor()
        .withLabel("Color");

    this.description = new Field()
        .asString()
        .withLabel("Description");

    this.columns = new Field()
        .asEnumList(getSecondLevelFields(StockProduct))
        .withEditorFactory(getDropDownFactory(fieldDropdownSettings))
        .withLabel("Columns");

    this.transactionFields = new Field([Field.STRING])
        .withEditorFactory(getDropDownFactory())
        .withOptionsLoader(searchString => getFieldSuggestions(Transaction, searchString))
        .withLabel("Deprecated Transaction fields");

    this.dynamicTransactionFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Transaction))
        .withLabel("Transaction fields");

    this.filterFields = new Field([Field.STRING])
        .withEditorFactory(getDropDownFactory())
        .withOptionsLoader(searchString => getFieldSuggestions(StockProduct, searchString))
        .withLabel("Filter fields");

    this.groupToggles = new Field([ProductGroup])
        .withLabel("Product group toggles")
        .withHelp("Leave empty for no toggles");

    this.toggleAllGroups = new Field()
        .asBoolean()
        .withLabel("Toggle all groups")
        .withHelp("Select if you wish to always include all product groups in the toggles");

    this.productEditorFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Product))
        .withLabel("Product editor fields");

    this.dynamicColumns = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(StockProduct))
        .withLabel("Dynamic Columns");

    this.stock = new Field(Stock).withLabel("Stock");

    this.aggregations = new Field()
        .asEnumList(StockProduct.getAggregations())
        .withLabel("Aggregations");

    setDefaults(this, {
        filterFields: [this.name],
        linkFactory: createStockProductListLink,
        itemFactory: getIconCellFactory(this.icon, this.name, this.color)
    });
}, "StockProductList", "stock-product-lists", true);

export {StockProductList as default};
