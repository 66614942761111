import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import BankAccount from "./BankAccount.js";
import Bill from "./Bill.js";
import Filter from "../../utils/Filter.js";
import Aggregation from "../../utils/Aggregation.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import lexicons from "./lexicons/payment/paymentLexicons.js";
import setDefaults from "./utils/setDefaults.js";
import TextComponent from "../../components/TextComponent.js";

export default new Model(function () {
    this.id = new Field()
        .asReadOnly()
        .asNumber();
    this.app = new Field(App);
    this.amount = new Field().asMoney(this.bill?.currencyCode || this.app.currencyCode).withLabel("Amount");
    this.date = new Field().asDate(Linguist.getLanguage()).withLabel("Date");
    this.year = new Field()
        .asNumber()
        .asReadOnly()
        .withLabel("Year");
    this.message = new Field().asString().withLabel("Message");
    this.referenceNumber = new Field().asString().withLabel("Reference Number");
    this.transactionIdentification = new Field().asString().withLabel("Transaction ID");
    this.payer = new Field().asString().withLabel("Payer");
    this.account = new Field(BankAccount).withLabel("Account");
    this.bill = new Field(Bill).withLabel("Bill")
        .withOptionsLoader((searchString, payment) => {
            let searchFilter = Filter.some(
                Filter.like(Bill.number, searchString),
                Filter.like(Bill.referenceNumber, searchString),
                Filter.like(Bill.recipientName, searchString)
            );
            let appFilter = Filter.eq(this.app, payment.app);
            let filter = Filter.and(searchFilter, appFilter);
            let orderBy = this.date.getName() + " desc";
            return Bill.getList({orderBy, filter}).then(response => response.data);
        });
    this.distinctYears = Aggregation.distinct(this.year);
    this.total = Aggregation.sum(this.amount, "Total paid");
    this.count = Aggregation.count(this.id, "Number of payments");

    this.on(this.EVENTS.CHANGE, (eventData) => {
        if (eventData.field === this.bill && eventData.entity.bill) {
            eventData.entity.amount = eventData.entity.bill.totalPriceVatIncluded - eventData.entity.bill.totalPaid;
            eventData.entity.account = eventData.entity.bill.type.accounts[0];
            eventData.entity.payer = eventData.entity.bill.recipientName;
            if (!eventData.entity.referenceNumber) {
                eventData.entity.referenceNumber = eventData.entity.bill.referenceNumber;
            }
        } else if (eventData.field === this.referenceNumber && !eventData.entity.bill) {
            Bill.getOptional(Filter.and(
                Filter.eq(Bill.referenceNumber, eventData.entity.referenceNumber),
                Filter.eq(Bill.app, eventData.entity.app))
            ).map(bill => eventData.entity.bill = bill);
        }
    });
    this.on([this.EVENTS.AFTER_INSERT, this.EVENTS.AFTER_UPDATE, this.EVENTS.AFTER_DELETE])
        .filter(payment => payment.bill)
        .do(payment => payment.bill.reload());
    new Linguist(lexicons).withAudience(this);
    setDefaults(this, {
        cellFactory: (payment) => {
            const style = "currency";
            const currency = payment.bill?.currencyCode || payment.app.currencyCode;
            let valueString = new Intl.NumberFormat("default", {style, currency}).format(payment.amount);
            return new TextComponent(valueString);
        },
        filterFields: [this.referenceNumber, this.payer]
    });
}, "Payment", "payments");
