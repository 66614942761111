import {extendComponent} from "juis-components/ComponentUtils.js";
import HTML from "../utils/HTML.js";
import BULMA from "./bulmaCssClasses.js";
import Component from "juis-components/Component.js";
import TextComponent from "../components/TextComponent.js";
import Container from "juis-components/Container.js";
import {createIcon} from "./Icon.js";

export default extendComponent(Container, function () {
    let header = this.appendChild(new Container(function () {
        this.title = new TextComponent("", HTML.P, "card-header-title");
        this.close = new Container(icon => {
            icon.content = new Component(() => {
            }, ["fas", "fa-times-circle"], HTML.I);
            icon.registerDomEvents("click");
            icon.on("click", () => this.trigger("close"));
        }, ["card-header-icon"], HTML.SPAN);
    }, ["cardHeader"], "header"));
    header.getNode().togglePlaceholder(true);
    this.setTitle = (title) => {
        header.title.text = title;
        header.getNode().togglePlaceholder(!title);
    };
    let iconComponent;
    this.setIcon = (icon, color) => {
        if (!iconComponent) {
            iconComponent = createIcon(icon);
            iconComponent.getNode().addCssClass("fa-2x");
            iconComponent.getNode().addCssClass("ml-4");
            iconComponent.getNode().addWrapper(HTML.SPAN, "card-header-icon");
            header.insertBefore(iconComponent);
        }
        iconComponent.icon = icon;
        iconComponent.color = color;
    };
    this.replaceCloseButton = (replacement) => {
        header.close = replacement;
    };
    this.content = new Container(function () {
    }, [BULMA.CARD.CONTENT]);
    this.footer = new Container(undefined, ["cardFooter"], "footer");
}, ["card"]);
