import {extendComponent} from "juis-components/ComponentUtils.js";
import Button from "../../bulma_components/Button.js";
import Property from "juis-components/Property.js";
import "./ColorButton.css";
import tinycolor from "tinycolor2";

export default extendComponent(Button, function () {
    this.backgroundColor = new Property((value) => {
        this.getNode().setStyle("background-color", value);
        let color = tinycolor(value);
        if (!this.textColor) {
            this.textColor = tinycolor.mostReadable(value, ["#4a4a4a"], {
                includeFallbackColors: true,
                level: "AAA",
                size: "small"
            }).toHexString();
        }
        if (color.isDark()) {
            this.getNode().addCssClass("is-dark");
        }
    });
    this.textColor = new Property((value) => {
        this.getNode().setStyle("color", value);
    });

}, ["color-button"]);
