import Container from "juis-components/Container.js";
import HTML from "./utils/HTML.js";
import BULMA from "./bulma_components/bulmaCssClasses.js";
import TextComponent from "./components/TextComponent.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import {createIcon} from "./bulma_components/Icon.js";
import LoadingIndicator from "./hewecon/components/LoadingIndicator.js";

export default extendComponent(Container, function () {
    this.getNode().addWrapper(HTML.SECTION, [BULMA.SECTION]);

    this.icon = createIcon("hourglass-half", BULMA.TYPOGRAPHY.HAS_TEXT_INFO);
    this.icon.isLarge = true;

    this.text = new TextComponent("Waiting", HTML.DIV, "title");
    this.setText = (text) => this.text.text = text;

    this.loader = new LoadingIndicator();
}, [BULMA.HAS_TEXT_CENTERED]);
