import Model from "../../utils/RestModel.js";
import ProductItemBase from "./ProductItemBase.js";
import Field from "../../utils/Field.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import ticketProductLexicons from "./lexicons/ticket-product/ticketProductLexicons.js";
import Ticket from "./Ticket.js";
import Filter from "../../utils/Filter.js";
import Stock from "./Stock.js";
import setDefaults from "./utils/setDefaults.js";
import Product from "./Product.js";
import {getDropDownFactory} from "../../components/fieldEditors/editorFactories.js";


let TicketProduct = new Model(function () {
    ProductItemBase.call(this);
    this.on("change", (event) => {
        if (event.field === TicketProduct.product && event.value) {
            const product = event.value;
            const ticketProduct = event.entity;
            ticketProduct.copyValuesFromProduct(product);
        }
    });
    this.createFields = function (model = this) {
        model.ticket = new Field(Ticket).withLabel("Ticket");
        model.billable = new Field().asBoolean().withLabel("Billable");
        model.vatPercentage.asRequired();
        model.price.asRequired();
        model.stock.withFilterGetter((ticketProduct) => {
            if (ticketProduct.ticket?.parentStock) {
                return Filter.eq(Stock.parent, ticketProduct.ticket.parentStock);
            }
            return Filter.true;
        });

        // A virtual product field that only lists base products excluding any variations.
        // Changes are written to the normal product field.
        // If there are no product variations in the database, then using this field would behave the same as the
        // product field.
        model.productExcludingVariations = new Field(Product)
            .asVirtual(model.product, (ticketProduct) => ticketProduct.product)
            .withLabel("Product excluding variations")
            .withFilterGetter(() => Filter.isNull(Product.variationOf))
            .withFilterGetter(model.getProductFilter)
            .withSetterCallback((productExcludingVariations, ticketProduct) => ticketProduct.product = productExcludingVariations);

        // A virtual product field for selecting a base product.
        // The product field is filtered to only list variations of the product selected here.
        // Changes to this field are not persisted, the only effect is filtering the dropdown in the product field.
        model.variationOf = new Field(Product)
            .withLabel("Variation of")
            .withFilterGetter(() => Filter.gt(Product.variationQuantity, 0))
            .withFilterGetter((transaction) => {
                if (transaction.stock?.productGroups.length > 0) {
                    return Filter.in(Product.groups, transaction.stock.productGroups);
                }
                return Filter.true;
            })
            .asVirtual();
        model.product.withEditorFactory(getDropDownFactory({
            refreshOnChange: [model.variationOf],
            itemFactory: (product, ticketProduct) => {
                if (ticketProduct.variationOf) {
                    return Product.name.getCell(product);
                } else {
                    return product.getDefaultItem();
                }
            }
        }));
    };

    this.ticketProductCellFactory = (ticketProduct) => {
        if (ticketProduct.product) {
            return Product.getDefaultItemFactory()(ticketProduct.product, ticketProduct);
        }
        if (ticketProduct.name) {
            return TicketProduct.name.getCell(ticketProduct);
        }
        return "";
    };
    this.getProductFilter.override(function () {
        let groups = this.ticket.type.productsGroups;
        if (groups.length > 0) {
            return Filter.in(Product.groups, groups);
        }
        return Filter.true;
    });
    this.createFields();

    let optionsLoader = (searchString, ticket, filter) => {
        return Product.getListForDropdown(searchString, Product.SEARCH_FIELDS, ticket.app, filter, Product.name)
            .then(products => products.map(product => {
                const ticketProduct = new TicketProduct();
                ticketProduct.product = product;
                ticketProduct.ticket = ticket;
                ticketProduct.quantity = 0;
                return ticketProduct;
            }));
    };
    setDefaults(this, {
        cellFactory: this.ticketProductCellFactory,
        optionsLoader
    });

    new Linguist(ticketProductLexicons).withAudience(this);
}, "TicketProduct");

export default TicketProduct;
