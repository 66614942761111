import BULMA from "./bulmaCssClasses.js";
import Property from "juis-components/Property.js";

export default {
    // The bulma tooltip is no longer supported and not included as a dependency.
    // TODO function should be replaced. For now it simply uses HTML title as a fallback
    getTooltipProperty: (component) => {
        const TOOL_TIP_ATTRIBUTE = "data-tooltip";
        component.flashTooltip = (timeout) => {
            component.getNode().addCssClass(BULMA.HAS_TOOLTIP_ACTIVE);
            setTimeout(() => component.getNode().removeCssClass(BULMA.HAS_TOOLTIP_ACTIVE), timeout);
        };

        return new Property(tooltipText => {
            if (tooltipText) {
                component.getNode().setAttribute(TOOL_TIP_ATTRIBUTE, tooltipText);
                component.getNode().setAttribute("title", tooltipText);
            } else {
                component.getNode().removeAttribute(TOOL_TIP_ATTRIBUTE);
                component.getNode().removeAttribute("title");
            }
        });
    }
};
