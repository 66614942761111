import Linguist from "../../../lib/JuiS/Linguist.js";
import navbarLexicons from "./lexicons/navbarLexiconx.js";
import Container from "juis-components/Container.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import {createLink} from "../../bulma_components/Link.js";
import HTML from "../../utils/HTML.js";
import ThemeSelector from "./ThemeSelector";

const linguist = new Linguist(navbarLexicons).withBaseKey("loginMenu");
export default () => {
    return new Container(function () {
        this.createAccountButton = createLink("/create-account", linguist.t("createAccount"));
        this.createAccountButton.getNode().addCssClass(BULMA.BUTTON);
        this.createAccountButton.getNode().addCssClass(BULMA.TYPOGRAPHY.HAS_TEXT_WEIGHT_SEMIBOLD);

        this.loginButton = createLink("/login", linguist.t("signIn"));
        this.loginButton.getNode().addCssClass(BULMA.IS_PRIMARY);
        this.loginButton.getNode().addCssClass(BULMA.BUTTON);
        this.theme = new ThemeSelector();
        this.getNode().addWrapper(HTML.DIV, [BULMA.NAVBAR.ITEM, BULMA.IS_MARGINLESS]);
        this.getNode().addWrapper(HTML.DIV, [BULMA.NAVBAR.END]);
    }, ["buttons", "is-justify-content-space-between"]);
}
