import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import HTML from "../utils/HTML.js";
import BULMA from "../bulma_components/bulmaCssClasses.js";
import Input from "../bulma_components/Input.js";
import {createIcon} from "../bulma_components/Icon.js";
import Button from "../bulma_components/Button.js";
import Component from "juis-components/Component.js";
import TextComponent from "./TextComponent.js";
import Checkbox from "../bulma_components/Checkbox.js";
import Property from "juis-components/Property.js";

const FORM_EVENTS = {
    FORM_SUBMIT: "form-submit"
};

const Form = extendComponent(Container, function () {
    const DOM_SUBMIT = "submit";
    const fields = {};
    let titleComponent;
    this.titleContainer = new Container(null, ["mb-4"]);
    this.title = new Property(titleText => {
        if (!titleComponent) {
            titleComponent = new TextComponent(titleText, HTML.DIV, [BULMA.TITLE, BULMA.HAS_TEXT_CENTERED, BULMA.IS_4, "mb-6"]);
            this.titleContainer.appendChild(titleComponent);
        } else {
            titleComponent.text = titleText;
        }
    });

    let subtitleComponent;
    this.subtitle = new Property(titleText => {
        if (!subtitleComponent) {
            subtitleComponent = new TextComponent(titleText, HTML.DIV, [BULMA.SUBTITLE, BULMA.HAS_TEXT_CENTERED]);
            this.titleContainer.appendChild(subtitleComponent);
        } else {
            subtitleComponent.text = titleText;
        }
    });

    this.logo = new Property(logoUrl => {
        const logoComponent = new Component((image) => {
            image.getNode().setStyle("max-width", "100%");
            image.getNode().setStyle("max-height", "3em");
            image.getNode().setAttribute("src", logoUrl);
        }, [], HTML.IMG);
        this.titleContainer.appendChild(logoComponent);

    });
    this.addField = function (name, options) {
        let formField = new Container(function () {
            if (options.label) {
                this.label = new TextComponent(options.label, HTML.LABEL, [BULMA.LABEL]);
            }
            this.control = new Container(function () {
                if (options.type === Input.Types.CHECKBOX) {
                    this.input = new Checkbox();
                } else if (options.type === "textarea") {
                    this.input = new Component(function () {
                        this.registerDomEvents("input");
                        this.registerDomEvents("change");
                        this.getValue = () => this.getNode().getElementProperty("value");
                        this.setValue = (value) => this.getNode().setElementProperty("value", value);
                    }, ["textarea"], "textarea");
                } else if (options.type === "select") {
                    this.input = new Container(function () {
                        this.registerDomEvents("change");
                        this.getNode().addWrapper(HTML.DIV, [BULMA.SELECT, BULMA.IS_FULLWIDTH]);
                        Object.entries(options.values || []).forEach(([key, value]) => {
                            this.appendChild(new Component(function () {
                                this.getNode().setInnerHtml(value);
                                this.getNode().setAttribute("value", key);
                            }, [], HTML.OPTION));
                        });
                        this.getValue = () => this.getNode().getElementProperty("value");
                        this.setValue = (value) => this.getNode().setElementProperty("value", value);
                    }, [], HTML.SELECT);
                } else {
                    this.input = new Input(function () {
                        this.type = options.type || Input.Types.TEXT;
                        if (options.placeholder) {
                            this.placeholder = options.placeholder;
                        }
                        if (options.autocomplete) {
                            this.autocomplete = options.autocomplete;
                        }
                    });
                }
                if (options.value) {
                    this.input.setValue(options.value);
                }
                if (options.disabled) {
                    this.input.disabled = true;
                }
                if (options.icon) {
                    this.icon = createIcon(options.icon, BULMA.IS_SMALL, BULMA.IS_LEFT);
                    this.getNode().addCssClass(BULMA.FIELD.HAS_ICONS_LEFT);
                }
                if (options.help) {
                    this.help = new TextComponent(options.help, HTML.P, [BULMA.HELP]);
                }
                if (options.hidden) {
                    this.getNode().setStyle("display", "none");
                }
            }, [BULMA.FIELD.CONTROL]);
        }, [BULMA.FIELD.FIELD]);
        fields[name] = formField;
        this.insertBefore(formField, this.submitField);
        return formField;
    };
    this.setErrorText = function (errorText) {
        this.error.text = errorText;
    };

    this.getValue = function () {
        let value = {};
        Object.entries(fields).forEach(entry => {
            value[entry[0]] = entry[1].control.input.getValue();
        });
        return value;
    };

    this.setValue = function (value) {
        Object.entries(fields).forEach(entry => entry[1].control.input.setValue(value[entry[0]]));
    };

    this.clear = function () {
        Object.values(fields).forEach(value => {
            value.control.input.setValue("");
        });
        this.setErrorText("");
    };
    this.clearOnSubmit = true;

    this.setSubmitButton = function (text, icon, ...cssClasses) {
        this.submitField.button.text = text;
        this.submitField.button.icon = icon;
        cssClasses.forEach(cssClass => this.submitField.button.getNode().addCssClass(cssClass));
    };

    this.submitField = new Container(function () {
        this.button = new Button(function () {
            this.type = Input.Types.SUBMIT;
            this.text = "Submit";
            this.icon = "check";
        }, [BULMA.BUTTON, BULMA.IS_PRIMARY]);
    }, [BULMA.FIELD.FIELD]);

    this.error = new TextComponent("", HTML.SPAN, [BULMA.TYPOGRAPHY.HAS_TEXT_DANGER]);

    this.registerDomEvents(DOM_SUBMIT);
    this.preventDefaultDomEvent(DOM_SUBMIT);
    this.on(DOM_SUBMIT, (data, event) => {
        this.submitField.button.isLoading = true;
        event.stopPropagation();
        this.triggerOnce(FORM_EVENTS.FORM_SUBMIT, this.getValue())
            .then(() => {
                if (this.clearOnSubmit) this.clear();
            })
            .catch(error => {
                this.setErrorText(error.message);
                console.error(error);
            })
            .finally(() => this.submitField.button.isLoading = false);
    });
}, [], HTML.FORM);

const CenteredForm = extendComponent(Form, function () {
    this.getNode().addWrapper(HTML.DIV, [BULMA.HERO_BODY]);
    this.getNode().addWrapper(HTML.SECTION, [BULMA.HERO, "is-fullheight-with-navbar-and-footer"]);
}, [BULMA.BOX, BULMA.CONTAINER]);

export {Form as default, CenteredForm, FORM_EVENTS};
