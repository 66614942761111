import BULMA from "../../bulma_components/bulmaCssClasses.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import Property from "juis-components/Property.js";
import Card from "../../bulma_components/Card.js";
import ExpandableContainer from "../../bulma_components/ExpandableContainer.js";
import Button from "../../bulma_components/Button.js";
import {extendComponent, joinComponents} from "juis-components/ComponentUtils.js";
import HTML from "../../utils/HTML.js";
import Field from "../../utils/Field.js";
import FieldContainer2 from "../FieldContainer2.js";
import {createControls} from "../../bulma_components/FormMixin.js";
import Dropdown from "../../bulma_components/Dropdown.js";
import {notificationEvents} from "../notification/Notification.js";
import DynamicField from "../../hewecon/models/DynamicField.js";
import TextComponent from "../TextComponent.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import tableViewLexicons from "./lexicons/tableViewLexicons.js";
import {EVENTS as BULMA_EVENTS} from "../../bulma_components/BulmaComponent.js";

const EVENTS = {
    CANCELLED: Symbol(),
    SUBMITTED: Symbol()
};

const EditorCard = extendComponent(joinComponents(Card, ExpandableContainer), function () {
    const linguist = new Linguist(tableViewLexicons).withBaseKey("editorCard");
    let model;
    let entityFactory;
    const createEntity = () => {
        if (entityFactory) {
            return entityFactory.call(this, this.defaultProperties);
        }
        let properties = {};
        Object.assign(properties, this.defaultProperties);
        let entity = new model(properties);
        entity.makeDirty();
        return entity;
    };

    const refreshTitle = () => {
        if (this.getEntity()) {
            if (this.getEntity().id) {
                this.title = this.editTitle || this.title;
            } else {
                this.title = this.createTitle || this.title;
            }
        }
    };
    this.createNewEntitiesAutomatically = new Property(undefined, true);
    this.title = new Property(title => this.setTitle(title));
    this.createTitle = new Property(refreshTitle);
    this.editTitle = new Property(refreshTitle);
    this.submitText = new Property((newText) => {
        submitButton.text = newText;
    });

    this.content = new FieldContainer2(undefined, [BULMA.CARD.CONTENT, BULMA.IS_MARGINLESS]);
    this.content.readOnly = false;

    this.saveCallback = (entity) => entity.save();

    const validateThatFieldExists = (fieldName) => {
        if (!model) {
            throw new Error(`Cannot determine field ${fieldName} when the model has not been set.`);
        }
        if (!model[fieldName]) {
            throw new Error(`Unknown field ${fieldName} on model ${model.getName()}`);
        }
    };

    this.addField = (field, settings) => {
        if (!(field instanceof Field)) {
            if (field instanceof DynamicField) {
                let dynamicLabel = field.getLabel();
                field = field.getField(model);
                settings = {getLabel: () => dynamicLabel, ...settings};
            } else {
                validateThatFieldExists(field);
                field = model[field];
            }
        }
        const entity = this.getEntity();
        if (entity && entity.canEditField) {
            this.content.addField(field, {...settings, readOnly: !entity.canEditField(field)});
        } else {
            this.content.addField(field, settings);
        }
    };
    this.setFields = (fields) => {
        this.content.resetFields();
        fields.forEach(this.addField);
    };
    this.addAdminField = (field, settings) => {
        if (!(field instanceof Field)) {
            validateThatFieldExists(field);
            field = model[field];
        }
        this.content.addAdminField(field, settings);
    };
    this.removeField = (field) => {
        this.content.removeField(field);
    };

    this.setEntity = newEntity => {
        this.content.setEntity(newEntity);
        refreshTitle();
        if (this.readOnly === undefined && typeof newEntity.canEdit === "function") {
            if (!newEntity.canEdit()) {
                this.readOnly = true;
            }
            if (newEntity.canEditField) {
                this.content.getFields()
                    .forEach(field => {
                        this.content.setFieldReadOnly(field, newEntity.canEditField(field));
                    });
            }
        }
    };
    this.getEntity = () => {
        return this.content.getEntity();
    };


    this.setModel = (newModel) => model = newModel;
    this.setEntityFactory = (newEntityFactory) => entityFactory = newEntityFactory;
    this.editSingleEntity = (entity) => {
        this.setModel(entity.getModel());
        this.setEntity(entity);
        this.setEntityFactory(() => entity);
    };
    this.editSingleEntityReadOnly = (entity) => {
        this.readOnly = true;
        this.setModel(entity.getModel());
        this.setEntity(entity);
        this.setEntityFactory(() => entity);
    };

    this.readOnly = new Property((value) => {
        this.content.readOnly = !!value;
        this.footer.getNode().togglePlaceholder(!!value);
    });

    this.defaultProperties = new Property((defaultProperties) => {
        this.refresh();
    });

    this.refresh = function () {
        if (this.createNewEntitiesAutomatically) {
            this.setEntity(createEntity());
        }
        this.footer.errorMessage.text = "";
    };

    let dropdown;
    this.addSubmitOption = (item) => {
        if (!dropdown) {
            dropdown = addDropdownToSubmit();
        }
        dropdown.addItem(item);
    };

    const addDropdownToSubmit = () => {
        this.footer.removeChild(submitButton);
        let dropdown = new Dropdown((dropdown) => {
            dropdown.addTriggerCss(BULMA.IS_PRIMARY);
        });
        let controls = createControls(submitButton, dropdown);
        controls.getNode().setStyle("margin-right", "1em");
        controls.getNode().setStyle("margin-bottom", "0");
        this.footer.insertBefore(controls, this.footer.cancelButton);
        return dropdown;
    };

    this.footer.getNode().addCssClass("hewecon-card-foot");

    this.footer.errorMessage = new TextComponent("", HTML.DIV, [BULMA.TYPOGRAPHY.HAS_TEXT_DANGER, "error-message"]);

    let submitButton = new Button((button) => {
        button.text = linguist.t("submit");
        button.on("click", () => this.submit());
    }, [BULMA.IS_PRIMARY]);
    this.footer.appendChild(submitButton);

    // Validate all fields on this card, even if they are not changed and including any dynamic fields that are present.
    // Saving an entity will also validate automatically, but then only fields that are defined on the model and of
    // those; only the ones that have dirty values.
    // This method ensures that all fields shown on the card are valid before submitting.
    const validateFields = (entity) => this.content.getFields().forEach(field => field.validate(entity));

    this.submit = (beforeSubmit = () => {
    }) => {
        submitButton.isLoading = true;
        let entity = this.getEntity();
        Promise.resolve()
            .then(() => beforeSubmit(entity))
            .then(() => validateFields(entity))
            .then(() => this.saveCallback(entity))
            .then(() => this.trigger(EVENTS.SUBMITTED, entity))
            .catch(error => {
                this.footer.errorMessage.text = error.message ? error.message : error;
                console.error(error);
            })
            .finally(() => submitButton.isLoading = false);
    };

    this.autoClose = true;
    this.on([EVENTS.SUBMITTED, EVENTS.CANCELLED, BULMA_EVENTS.ACTIVATE], this.refresh);
    this.on(EVENTS.SUBMITTED, (entity) => {
        if (this.autoClose) {
            this.trigger("close");
        } else {
            this.trigger(notificationEvents.NOTIFY, {
                text: `Changes for ${entity.identificationString} were successfully saved.`,
                cssClass: BULMA_CSS_CLASSES.IS_SUCCESS,
                timeout: 3000,
            });
        }
    });
    this.canceable = new Property(value => {
        if (value) {
            this.footer.cancelButton = new Button((button) => {
                button.text = linguist.t("cancel");
                button.on("click", () => {
                    if (this.autoClose) {
                        this.trigger("close");
                        this.trigger(EVENTS.CANCELLED);
                    }
                    this.content.getFields().forEach(this.getEntity().resetField);
                });
            });
        } else {
            if (this.footer.cancelButton) {
                this.footer.removeChild(this.footer.cancelButton);
            }
        }
    }, true);

    this.collapse(true);
});
EditorCard.EVENTS = EVENTS;
export default EditorCard;
