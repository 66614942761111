const BULMA_CSS_CLASSES = {
    DROPDOWN: {
        ITEM: "dropdownItem",
        MENU: "dropdownMenu",
        TRIGGER: "dropdownTrigger",
        DIVIDER: "dropdownDivider",
        DROPDOWN: "dropdown",
        CONTENT: "dropdownContent",
        IS_HOVERABLE: "is-hoverable",
    },
    NAVBAR: {
        ITEM: "navbarItem",
        MENU: "navbarMenu",
        END: "navbarEnd",
        START: "navbarStart",
        NAVBAR: "navbar",
        BRAND: "navbarBrand",
        BURGER: "navbarBurger",
        LINK: "navbarLink",
        DIVIDER: "navbarDivider",
        DROPDOWN: "navbarDropdown",
        HAS_DROPDOWN: "has-dropdown",
        FIXED_TOP: "isFixedTop",
    },
    LEVEL: {
        ITEM: "levelItem",
        LEVEL: "level",
        LEFT: "level-left",
        RIGHT: "level-right",
    },
    PAGINATION: {
        LINK: "paginationLink",
        ELLIPSIS: "paginationEllipsis",
        CURRENT: "isCurrent",
        NEXT: "paginationNext",
        PREVIOUS: "paginationPrevious",
        LIST: "paginationList",
        PAGINATION: "pagination",
    },
    TYPOGRAPHY: {
        HAS_TEXT_GREY_LIGHTER: "has-text-grey-lighter",
        HAS_TEXT_GREY: "has-text-grey",
        HAS_TEXT_WEIGHT_SEMIBOLD: "has-text-weight-semibold",
        HAS_TEXT_WEIGHT_LIGHT: "has-text-weight-light",
        HAS_TEXT_WEIGHT_BOLD: "has-text-weight-bold",
        HAS_TEXT_SUCCESS: "has-text-success",
        HAS_TEXT_LINK: "has-text-link",
        HAS_TEXT_INFO: "has-text-info",
        HAS_TEXT_DANGER: "has-text-danger",
        HAS_TEXT_PRIMARY: "has-text-primary",
        HAS_TEXT_DARK: "has-text-dark",
        IS_SIZE_1: "is-size-1",
        IS_SIZE_2: "is-size-2",
        IS_SIZE_3: "is-size-3",
        IS_SIZE_4: "is-size-4",
        IS_SIZE_5: "is-size-5",
        IS_SIZE_6: "is-size-6",
        IS_SIZE_7: "is-size-7",
    },
    COLUMNS_SIZE: {
        IS_ONE_QUARTER: "is-one-quarter",
        IS_ONE_QUARTER_DESKTOP: "is-one-quarter-desktop",
        IS_ONE_QUARTER_WIDESCREEN: "is-one-quarter-widescreen",
        IS_ONE_THIRD: "is-one-third",
        IS_TWO_THIRDS: "is-two-thirds",
        IS_HALF: "is-half",
    },
    COLORS: {
        HAS_BACKGROUND_LIGHT: "has-background-light",
        HAS_BACKGROUND_GREY_LIGHT: "has-background-grey-light",
        HAS_BACKGROUND_GREY_LIGHTER: "has-background-grey-lighter",
        HAS_BACKGROUND_WHITE_TER: "has-background-white-ter",
        HAS_BACKGROUND_WHITE: "has-background-white",
        HAS_BACKGROUND_WHITE_BIS: "has-background-white-bis",
        HAS_BACKGROUND_PRIMARY: "has-background-primary",
    },
    CARD: {
        CARD: "card",
        HEADER: "card-header",
        HEADER_TITLE: "card-header-title",
        HEADER_ICON: "card-header-icon",
        CONTENT: "card-content",
        IMAGE: "card-image",
    },
    MESSAGE: {
        MESSAGE: "message",
        HEADER: "message-header",
        BODY: "message-body",
    },
    HERO: "hero",
    HERO_BODY: "hero-body",
    BUTTON: "button",
    BUTTONS: "buttons",
    IMAGE: "image",
    TOOLTIP: "tooltip",
    HAS_TOOLTIP_ACTIVE: "has-tooltip-active",
    HAS_TOOLTIP_LEFT: "has-tooltip-left",
    HAS_TOOLTIP_RIGHT: "has-tooltip-right",
    HAS_TOOLTIP_ARROW: "has-tooltip-arrow",
    MODAL: {
        MODAL: "modal",
        CLOSE: "modal-close",
        CONTENT: "modal-content",
        BACKGROUND: "modal-background",
    },
    DELETE: "delete",
    IS_HOVERABLE: "isHoverable",
    HAS_DROPDOWN: "hasDropdown",
    HAS_TEXT_RIGHT: "has-text-right",
    HAS_TEXT_CENTERED: "has-text-centered",
    FIELD: {
        FIELD: "field",
        BODY: "field-body",
        LABEL: "field-label",
        HAS_ADDONS: "has-addons",
        CONTROL: "control",
        HAS_ICONS_LEFT: "has-icons-left",
        IS_EXPANDED: "is-expanded",
    },
    HELP: "help",
    IS_ACTIVE: "isActive",
    SECTION: "section",
    CONTAINER: "container",
    COLUMN: "column",
    IS_NARROW: "is-narrow",
    COLUMNS: "columns",
    IS_CENTERED: "is-centered",
    BOX: "box",
    MEDIA: "media",
    INPUT: "input",
    PROGRESS: "progress",
    TAG: "tag",
    TAGS: "tags",
    HAS_ADDONS: "has-addons",
    IS_DELETE: "is-delete",
    LABEL: "label",
    TITLE: "title",
    SUBTITLE: "subtitle",
    IS_MARGINLESS: "m-0",
    IS_PADDINGLESS: "p-0",
    IS_SHADOWLESS: "is-shadowless",
    IS_MULTILINE: "is-multiline",
    IS_MOBILE: "is-mobile",
    IS_UNSELECTABLE: "is-unselectable",
    IS_LOADING: "isLoading",
    IS_CLEARFIX: "is-clearfix",
    IS_SMALL: "isSmall",
    IS_TEXT: "is-text",
    IS_SELECTED: "is-selected",
    ICON: "icon",
    IS_UP: "isUp",
    IS_RIGHT: "isRight",
    IS_LEFT: "is-left",
    IS_PULLED_LEFT_TABLET: "is-pulled-left-tablet",
    IS_PULLED_RIGHT_TABLET: "is-pulled-right-tablet",
    ML_AUTO: "ml-auto",
    IS_PULLED_RIGHT: "is-pulled-right",
    IS_PULLED_LEFT: "is-pulled-left",
    IS_FULLWIDTH: "is-fullwidth",
    IS_FULLHEIGHT: "is-fullheight",
    IS_DANGER: "is-danger",
    IS_FLEX: "is-flex",
    FLEX_WRAP: "is-flex-wrap-wrap",
    FLEX_SPACE_AROUND: "is-justify-content-space-around",
    FLEX_SPACE_BETWEEN: "is-justify-content-space-between",
    IS_INVISIBLE: "is-invisible",
    IS_LARGE: "is-large",
    IS_HIDDEN: "is-hidden",
    IS_HIDDEN_TOUCH: "is-hidden-touch",
    IS_HIDDEN_DESKTOP: "is-hidden-desktop",
    IS_HIDDEN_MOBILE: "is-hidden-mobile",
    IS_HIDDEN_TABLET: "is-hidden-tablet",
    IS_BLOCK: "is-block",
    IS_SUCCESS: "is-success",
    IS_LINK: "is-link",
    IS_PRIMARY: "is-primary",
    IS_INFO: "is-info",
    IS_WARNING: "is-warning",
    IS_INVERTED: "is-inverted",
    IS_RADIUSLESS: "is-radiusless",
    IS_LIGHT: "is-light",
    IS_OUTLINED: "is-outlined",
    IS_NORMAL: "is-normal",
    IS_HORIZONTAL: "is-horizontal",
    IS_1: "is-1",
    IS_2: "is-2",
    IS_3: "is-3",
    IS_4: "is-4",
    IS_5: "is-5",
    IS_6: "is-6",
    IS_INLINE_BLOCK: "is-inline-block",
    NOTIFICATION: "notification",
    TABLE: "table",
    IS_STRIPED: "is-striped",
    IS_TOGGLE_ROUNDED: "is-toggle-rounded",
    IS_TOGGLE: "is-toggle",
    TABS: "tabs",
    SELECT: "select"
};
export default BULMA_CSS_CLASSES;
