import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import Product from "./Product.js";
import Stock from "./Stock.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import HTML from "../../utils/HTML.js";
import TextComponent from "../../components/TextComponent.js";
import NamedTimePeriod from "./NamedTimePeriod.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import productionLexicons from "./lexicons/production/productionLexicons.js";

let getQuarter = function (date) {
    if (!date) {
        return "";
    }
    return `Q${date.getMonth() / 3 + 1} ${date.getFullYear()}`;
};

const linguist = new Linguist(productionLexicons);
let Production = new Model(function () {
    this.product = new Field(Product).withLabel("Product").asRequired();
    this.stock = new Field(Stock).withLabel("Stock").asRequired();
    this.parentStock = new Field(Stock).withLabel("Parent Stock").asRequired();
    this.location = new Field(Stock).asVirtual(this.stock).withLabel("Location").withCellFactory((ignore, production) =>
        production.stock ? Production.stock.getCell(production) : Production.parentStock.getCell(production));
    this.quantity = new Field()
        .asNumber()
        .asSummable()
        .withLabel("Quantity")
        .withCellFactory((quantity, production) => {
            const cssClasses = [];
            if (quantity < 0) {
                cssClasses.push(BULMA_CSS_CLASSES.TYPOGRAPHY.HAS_TEXT_DANGER);
            }
            let text = Intl.NumberFormat(Linguist.getLanguage(), {}).format(quantity);
            if (production?.product?.unit) {
                text += " " + production.product.unit;
            }
            return new TextComponent(text, HTML.SPAN, cssClasses);
        });
    this.day = new Field().asTimestamp("default", {year: 'numeric', month: 'long', day: 'numeric'});
    this.week = new Field().asTimestamp("default", {year: 'numeric', month: 'long', day: 'numeric'});
    this.month = new Field().asTimestamp("default", {year: 'numeric', month: 'long'});
    this.quarter = new Field().asTimestamp().withCellFactory(getQuarter);
    this.year = new Field().asTimestamp("default", {year: 'numeric'});
    this.period = new Field(NamedTimePeriod).withLabel("Period");
    this.netWeight = new Field().asSummable().asNumberWithUnit("kilogram").withLabel("Net Weight");
    this.averageWeight = new Field().asNumberWithUnit("kilogram").withLabel("Avg. Weight");
    this.quote = new Field().asNumber().withLabel("Quote");
    this.weightQuote = new Field().asNumber().withLabel("Weight Quote");

    linguist.withAudience(this);

    this.QUERY_PARAMETERS = {
        "GROUP_BY_PARENT": "groupByParentStock",
        "INTERVAL": "interval",
    };
}, "Production", "production");


export default Production;
