import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Component from "juis-components/Component.js";
import Property, {CssProperty} from "juis-components/Property.js";
import HTML from "../utils/HTML.js";
import BULMA from "./bulmaCssClasses.js";
import {camelCaseToDash} from "juis-commons/JuisUtils.js";

function getFontAwesomeClassName(name) {
    return "fa-" + camelCaseToDash(name);
}

function createIcon(icon, ...classNames) {
    return createColorIcon(icon, undefined, ...classNames);
}

function createColorIconForEntity(entity, tooltip) {
    return new Icon(function () {
        if (entity.icon) {
            this.icon = entity.icon;
        } else {
            this.icon = "circle";
        }
        if (entity.iconForegroundColor) {
            this.getNode().setStyle("color", entity.iconForegroundColor);
        }
        if (entity.iconBackgroundColor) {
            this.getNode().setStyle("background-color", entity.iconBackgroundColor);
        }
        if (tooltip) {
            this.getNode().setAttribute("title", tooltip);
        }
    });
}

function createColorIcon(icon, color, ...classNames) {
    return new Icon(function () {
        if (icon) {
            this.icon = icon;
        } else {
            this.icon = "circle";
        }
        if (color) {
            this.getNode().setStyle("color", color);
        }
    }, [...classNames]);
}

function createIconCell(icon) {
    if (icon) {
        return new Icon(function () {
            this.icon = icon;
            this.getNode().addWrapper(HTML.TD, [BULMA.HAS_TEXT_CENTERED]);
        }, [], HTML.DIV);
    }
}

let Icon = extendComponent(Container, function () {
    this.isSmall = new CssProperty("fa-sm");
    this.isMedium = new CssProperty("is-medium", "fa-lg");
    this.isLarge = new CssProperty("is-large", "fa-lg");
    this.is2x = new CssProperty("is-large", "fa-2x");
    this.is3x = new CssProperty("is-large", "fa-3x");
    this.icon = new Property((value, oldValue) => {
        if (oldValue) {
            this.content.getNode().removeCssClass(getFontAwesomeClassName(oldValue));
        }
        this.content.getNode().addCssClass(getFontAwesomeClassName(value));
    });
    this.color = new Property(color => this.getNode().setStyle("color", color));
    this.transform = new Property(value => this.content.getNode().setAttribute("data-fa-transform", value));

    this.content = new Component(() => {
    }, [], HTML.I);
    this.style = new Property((style, previousValue) => {
        if (previousValue) {
            this.content.getNode().removeCssClass(previousValue);
        }
        this.content.getNode().addCssClass(style);
    }, "fas");
}, [BULMA.ICON], HTML.DIV);

export {Icon as default, createIcon, createIconCell, createColorIcon, createColorIconForEntity};
