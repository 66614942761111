import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import HTML from "../../utils/HTML.js";
import Component from "juis-components/Component.js";
import {createSimpleField} from "../../components/fieldEditors/editorFactories.js";
import Container from "juis-components/Container.js";
import Input from "../../bulma_components/Input.js";
import {getLocaleDropdownFactory} from "../translations/localeDropdownFactory.js";
import Button from "../../bulma_components/Button.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import BillTemplate from "./BillTemplate.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import LOCALES from "../translations/locales.js";
import localeCellFactory from "../translations/localeCellFactory.js";

const TranslationEditor = extendComponent(Container, function () {
    const rowEditors = [];
    this.setValue = newValue => {
        if (newValue) {
            Object.entries(newValue).forEach(entry => addRowEditor(...entry));
        }
    };
    this.getValue = () => {
        let value = {};
        rowEditors
            .map(editor => editor.getValue())
            .filter(rowValue => rowValue.key)
            .forEach(rowValue => value[rowValue.key] = rowValue.value);
        return value;
    };

    const addRowEditor = (key, value) => {
        let rowEditor = new TranslationRowEditor();
        rowEditor.setValue(key, value);
        rowEditors.push(rowEditor);
        this.insertBefore(rowEditor, this.plusButton);
    };
    const addEmptyRowEditor = () => {
        rowEditors.push(this.insertBefore(new TranslationRowEditor(), this.plusButton));
    };

    this.plusButton = new Button(function () {
        this.icon = "plus";
        this.on("click", addEmptyRowEditor);
    }, [BULMA.IS_SUCCESS]);

    this.getNode().setStyle("width", "100%");
}, [], HTML.TABLE);

const TranslationRowEditor = extendComponent(Container, function () {
    this.keyEditor = new Input();
    this.keyEditor.getNode().addWrapper(HTML.TD);
    this.valueEditor = new Input();
    this.valueEditor.getNode().addWrapper(HTML.TD);
    this.setValue = (key, value) => {
        this.keyEditor.setValue(key);
        this.valueEditor.setValue(value);
    };
    this.getValue = () => {
        return {key: this.keyEditor.getValue(), value: this.valueEditor.getValue()};
    };
}, [], HTML.TR);

export default new Model(function () {
    this.billTemplate = new Field(BillTemplate).withLabel("Key");

    this.languageCode = new Field()
        .asString()
        .withOptions(Object.keys(LOCALES))
        .withCellFactory(localeCellFactory)
        .withEditorFactory(getLocaleDropdownFactory())
        .withLabel("Language Code");

    this.translationMap = new Field()
        .asJson()
        .withCellFactory(translationMap => {
            let htmlTable = "";
            Object.entries(translationMap).forEach(entry => {
                htmlTable += `<tr><td>${entry[0]}</td><td>${entry[1]}</td></tr>`;
            });
            return new Component(function () {
                this.getNode().setInnerHtml(htmlTable);
            }, [], HTML.TABLE);
        })
        .withEditorFactory((field, translation) => {
            let input = new TranslationEditor();
            input.setValue(translation[field.getName()]);
            return createSimpleField(field, translation, input);
        })
        .withLabel("Translations");
}, "BillTemplateTranslation", "bill-template-translation");
