import Container from "juis-components/Container.js";
import Field from "../../utils/Field.js";
import HTML from "../../utils/HTML.js";
import Property from "juis-components/Property.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import RestModel from "../../utils/RestModel.js";
import Component from "juis-components/Component.js";


const Row = extendComponent(Container, function () {
    let columns;

    this.setColumns = (newColumns) => {
        columns = newColumns;
    };

    this.entity = new Property((entity) => {
        createInstanceListeners();
        this.createTableCells(columns);
    });

    let createInstanceListeners = () => {
        this.entityListener = this.entity.on(["refresh", "afterUpdate"], () => {
            this.createTableCells(columns);
            this.highlight();
            this.trigger(Row.EVENTS.REFRESH);
        });
        this.bindListener(this.entityListener);
    };

    this.pauseAllEntityListeners = () => {
        this.entityListener.pause();
    };

    this.resumeAllEntityListeners = () => {
        this.entityListener.resume();
    };

    let getTableCell = (field, entity) => {
        if (field instanceof Field) {
            return field.getCell(entity);
        } else if (field instanceof RestModel) {
            return field.getDefaultCellFactory()(entity);
        } else if (typeof field === "function") {
            return field(entity);
        } else if (field === null) {
            return new Component(undefined, [], HTML.TD);
        } else {
            throw new Error("Cannot create table cell for field: " + field);
        }
    };

    let cells = [];
    this.createTableCells = (columns) => {
        cells.forEach(cell => this.removeChild(cell));
        cells = [];
        columns.forEach(createTableCell);
    };

    let createTableCell = (column) => {
        let tableCell = getTableCell(column, this.entity);
        if (tableCell.getNode().getTagName() !== HTML.TD) {
            if (tableCell.getNode().getTagName() === HTML.SPAN) {
                tableCell.getNode().changeType(HTML.TD);
            } else {
                tableCell.getNode().addWrapper(HTML.TD);
            }
        }

        if (column instanceof Field && column.getType() === Field.NUMBER) {
            tableCell.getNode().addCssClass("has-text-right-tablet");
        }
        if (column instanceof Field || column instanceof RestModel) {
            tableCell.getNode().getElement().setAttribute("data-header", column.getLabel() || column.getName());
        }
        cells.push(tableCell);
        tableCell.getNode().addCssClass("juis");
        this.appendChild(tableCell);
    };

    this.highlight = (type) => {
        this.registerDomEvents("animationend");
        let cssClass = "highlight-table-row";
        if (type === "delete") {
            cssClass = "highlight-table-row-delete";
        }
        this.getNode().addCssClass(cssClass);
        this.on("animationend", (ignore, event) => {
            this.getNode().removeCssClass(cssClass);
            event.stopPropagation();
        });
    };
}, [], HTML.TR);
Row.EVENTS = {REFRESH: Symbol()};
export default Row;
