import TextComponent from "./TextComponent.js";
import {createTextNode} from "juis-components/ComponentUtils.js";

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

export default function (phoneNumber) {
    if (!phoneNumber) {
        return createTextNode("");
    }
    let uiPhoneNumber = replaceAll(phoneNumber, " ", "\u00A0");
    let linkPhoneNumber = replaceAll(phoneNumber, " ", "");
    let textComponent = new TextComponent(uiPhoneNumber, "A");
    textComponent.getNode().setAttribute("href", "tel:" + linkPhoneNumber);
    textComponent.getNode().setAttribute("rel", "nofollow");
    return textComponent;
}
