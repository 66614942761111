import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import DynamicField from "./DynamicField.js";
import BillList from "./BillList.js";
import TicketList from "./TicketList.js";
import {createLink} from "../../bulma_components/Link.js";
import Contact from "./Contact.js";
import setDefaults from "./utils/setDefaults.js";
import {iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import Method from "../../utils/Method.js";
import Container from "juis-components/Container.js";
import {createIcon} from "../../bulma_components/Icon.js";
import TextComponent from "../../components/TextComponent.js";
import TicketType from "./TicketType";

export default new Model(function () {
    this.app = new Field(App);
    this.id = new Field().asReadOnly().asNumber();
    this.deleted = new Field().asBoolean();
    this.name = new Field().asString().withLabel("Name");
    this.code = new Field().asString().withLabel("Url Code");
    this.description = new Field().asString().withLabel("Description");
    this.icon = new Field().asString().withLabel("Icon").withEditorFactory(iconPickerFactory);
    this.color = new Field().asColor().withLabel("Color");
    this.contactListColumns = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Contact))
        .withLabel("Columns");
    this.editorFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Contact))
        .withLabel("Editor fields");
    this.workLogFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Contact))
        .withLabel("Work log fields");
    this.billLists = new Field([BillList]).withLabel("Bill lists");
    this.assigneeTicketLists = new Field([TicketList]).withLabel("Assignee ticket lists");
    this.customerTicketLists = new Field([TicketList]).withLabel("Customer ticket lists");
    this.public = new Field().asBoolean();
    this.badgeTicketType = new Field(TicketType);
    this.excludeFromCachedDescendants();


    /**
     * Get the current user's contact from this group if it exists.
     * This method can be called on lazy loaded ContactGroups. Therefor a reference to the relevant App must be provided.
     * @param {SessionHandler} sessionHandler
     * @param {App} app
     * @param {String} [roleType]
     * @return {Promise<Contact|undefined>} A promise for the relevant contact or undefined if none was found.
     */
    this.getMyself = new Method(function (sessionHandler, app, roleType = undefined) {
        return sessionHandler.getRolesForApp(app)
            .filter(role => this.equals(role?.contact?.contactGroup))
            .filter(role => !roleType || role.roleType === roleType)
            .map(role => role.contact)[0];
    });

    setDefaults(this, {
        orderByField: this.name,
        filterFields: [this.name, this.description],
        linkFactory: (contactGroup) =>
            createLink(`/${contactGroup.app.code}/contact-groups/${contactGroup.code}`, contactGroup.name,
                contactGroup.icon, contactGroup.color),
        itemFactory: (contactGroup) =>
            new Container(function () {
                if (contactGroup.icon || contactGroup.color) {
                    this.icon = createIcon(contactGroup.icon);
                    if (contactGroup.color) {
                        this.icon.getNode().setStyle("color", contactGroup.color);
                    }
                }
                this.text = new TextComponent(contactGroup.name);
            }),
    });
}, "ContactGroup", "contact-groups");
