import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import TicketType from "./TicketType.js";
import Contact from "./Contact.js";
import App from "./App.js";
import TicketStatus from "./TicketStatus.js";
import DynamicField from "./DynamicField.js";
import setDefaults from "./utils/setDefaults.js";
import Ticket from "./Ticket.js";
import {fieldDropdownSettings, getSecondLevelFields} from "../../utils/fieldUtils.js";
import {getDropDownFactory} from "../../components/fieldEditors/editorFactories.js";
import ContactGroup from "./ContactGroup.js";
import Filter from "../../utils/Filter.js";
import Method from "../../utils/Method.js";

let TicketList = new Model(function () {
    this.app = new Field(App);
    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Code")
        .withCellFactory((code, ticketList) => createLink(`/${ticketList.app.code}/ticket-lists/${ticketList.code}`, code));

    this.name = new Field()
        .asTranslatableString()
        .withLabel("Name");

    this.title = new Field()
        .asTranslatableString()
        .withLabel("Title");

    this.columnFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Ticket))
        .withLabel("Columns");

    this.toggleFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Ticket))
        .withLabel("Toggle fields");

    this.ticketType = new Field(TicketType).withLabel("Ticket type")
        .withHelp("Leave empty if this list is not for a specific ticket type.");

    this.parentType = new Field(TicketType).withLabel("Parent Type");
    this.assigneeGroupToggles = new Field(ContactGroup).withLabel("Assignee toggles");
    this.aggregations = new Field([DynamicField]).withLabel("Aggregations");

    this.typeFilter = new Field([TicketType])
        .withLabel("Ticket type filter")
        .withHelp("Leave empty for no filtering");

    this.statusFilter = new Field([TicketStatus])
        .withLabel("Ticket status filter")
        .withHelp("Leave empty for no filtering");

    this.workerFilter = new Field([Contact])
        .withLabel("Worker filter")
        .withHelp("Leave empty for no filtering");

    this.customerFilter = new Field([Contact])
        .withLabel("Customer filter")
        .withHelp("Leave empty for no filtering");

    this.typeToggles = new Field([TicketType])
        .withLabel("Ticket type toggles")
        .withHelp("Leave empty for no toggles");

    this.statusToggles = new Field([TicketStatus])
        .withFilterGetter(ticketList => ticketList.ticketType ? Filter.eq(TicketStatus.ticketType, ticketList.ticketType) : Filter.true)
        .withLabel("Ticket status toggles")
        .withHelp("Leave empty for no toggles");

    this.workerToggles = new Field([Contact])
        .withLabel("Worker toggles")
        .withHelp("Leave empty for no toggles");

    this.customerToggles = new Field([Contact])
        .withLabel("Customer toggles")
        .withHelp("Leave empty for no toggles");

    this.filterFields = new Field()
        .asEnumList(getSecondLevelFields(Ticket))
        .withEditorFactory(getDropDownFactory(fieldDropdownSettings))
        .withLabel("Filter fields");

    this.yearToggles = new Field().asBoolean().withLabel("Year toggles");
    this.monthToggles = new Field().asBoolean().withLabel("Month toggles");
    this.parentStatusToggles = new Field().asBoolean().withLabel("Parent status toggles");
    this.invoiceEnabled = new Field().asBoolean().withLabel("Invoice enabled");
    this.exportEnabled = new Field().asBoolean().withLabel("Export enabled");

    this.orderBy = new Field()
        .asEnum(getSecondLevelFields(Ticket))
        .withEditorFactory(getDropDownFactory(fieldDropdownSettings))
        .withLabel("Order by");
    this.orderAscending = new Field().asBoolean().withLabel("Ascending order");

    this.getLogo = new Method(function () {
        return this.ticketType?.icon || this.typeFilter[0]?.logo;
    });

    this.getCustomerGroups = new Method(function () {
        let types = this.typeFilter;
        if (this.ticketType && !types.includes(this.ticketType)) {
            types.push(this.ticketType);
        }
        let customerGroups = types.flatMap(type => type.customers);
        return [...new Set(customerGroups)]; // Distinct
    });

    this.excludeFromCachedDescendants();
    this.makeTranslatable();

    setDefaults(this, {
        defaultField: this.name,
        linkFactory: ticketList => createLink(`/${ticketList.app.code}/ticket-lists/${ticketList.code}`, this.name.getTranslatedValueFor(ticketList)),
    });
}, "TicketList", "ticket-lists", true);


export {TicketList as default};
