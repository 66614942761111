import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Property from "juis-components/Property.js";

let Checkbox = extendComponent(Container, function () {
    this.value = new Property((value) => {
        if (value) {
            this.getNode().addCssClass("checked");
        } else {
            this.getNode().removeCssClass("checked");
        }
        this.trigger("change", value);
    }, false);

    this.getNode().setAttribute("tabindex", 0);
    this.registerDomEvents("click");
    this.on("click", () => {
        if (this.disabled) {
            return;
        }
        this.value = !this.value;
    });
    this.registerDomEvents("keydown");
    this.on("keydown", (event) => {
        if (this.disabled) {
            return;
        }
        if (event.keyCode === 32) {
            this.value = !this.value;
            event.domEvent.preventDefault();
        }
    });
    this.disabled = new Property((newValue) => {
        if (newValue) {
            this.getNode().setAttribute("disabled", true);
            this.getNode().removeAttribute("tabIndex");
        } else {
            this.getNode().removeAttribute("disabled");
            this.getNode().setAttribute("tabIndex", 0);
        }
    }, false);
    this.getValue = () => this.value;
    this.setValue = (value) => this.value = value;
    this.getNode().setInnerHtml("&zwnj;");
    this.getNode().setStyle("line-height", "unset");
}, ["checkbox"]);

export default Checkbox;
