import {extendComponent} from "juis-components/ComponentUtils.js";
import HTML from "../../../utils/HTML.js";
import BULMA from "../../../bulma_components/bulmaCssClasses.js";
import Container from "juis-components/Container.js";
import Linguist from "../../../../lib/JuiS/Linguist.js";
import frontPageLexicons from "./lexicons/frontPageLexicons";
import TextComponent from "../../../components/TextComponent";
import "./style.scss";
import TagSelect from "../../../components/dropdown/TagSelect.js";
import Input from "../../../bulma_components/Input.js";
import PromiseButton from "../../../components/PromiseButton.js";
import RestServer from "../../../utils/RestServer.js";
import App from "../../models/App.js";
import SessionHandler from "../../../SessionHandler.js";
import {REQUEST_NAVIGATE} from "juis-router/Events.js";
import {notificationEvents} from "../../../components/notification/Notification";

const t = new Linguist(frontPageLexicons).t;

const AppCreator = extendComponent(Container, function () {
    this.field = new Container(function () {
        this.templateList = new TagSelect(function () {
            this.getNode().addWrapper(HTML.P, [BULMA.FIELD.CONTROL]);
            this.setTagFactory(template => App.name.getTranslatedValueFor(template));
            this.setItemFactory(template => new Container(function () {
                this.title = new TextComponent(App.name.getTranslatedValueFor(template), HTML.DIV, [
                    BULMA.TYPOGRAPHY.IS_SIZE_6,
                    BULMA.TYPOGRAPHY.HAS_TEXT_WEIGHT_BOLD,
                ]);
                this.description = new TextComponent(App.description.getTranslatedValueFor(template), HTML.DIV);
            }));
            RestServer.get("apps/templates").then(response => {
                response.forEach(appdata => {
                    this.addOption(appdata, appdata.code);
                });
                this.setValue(response[0].code);
            });
            this.getNode().setStyle("min-width", "12em");
        });
        this.appName = new Input(function () {
            this.getNode().addWrapper(HTML.P, [BULMA.FIELD.CONTROL, BULMA.FIELD.IS_EXPANDED]);
            this.placeholder = t("nameApp");

        });
        const createNewAppCode = () => {
            return RestServer.post("apps/demo", {
                name: this.appName.getValue(),
                templateCode: this.templateList.getValue().code
            }).then((response) => response.data.code);
        };
        this.submitButton = new PromiseButton(function () {
            this.getNode().addWrapper(HTML.P, [BULMA.FIELD.CONTROL]);
            this.text = t("createApp");
            this.disabled = true;
            this.callback = () =>
                createNewAppCode()
                    .then(newAppCode => SessionHandler.loadSession().then(() => this.triggerOnce(REQUEST_NAVIGATE, {url: `/${newAppCode}`})))
                    .catch(error => {
                        this.trigger(notificationEvents.NOTIFY, {
                            text: error.message,
                            cssClass: BULMA.IS_DANGER
                        });
                        throw error;
                    });
        }, [BULMA.IS_PRIMARY]);
        this.on(["input", "change"], () => {
            this.submitButton.disabled = this.appName.getValue().length < 3 || !this.templateList.getValue();
        });
    }, [BULMA.FIELD.FIELD, BULMA.FIELD.HAS_ADDONS, "is-flex-grow-1", BULMA.IS_MARGINLESS, "explode-mobile"]);

    this.getNode().addWrapper(HTML.DIV, [BULMA.SECTION, "pt-0"]);
}, [BULMA.BOX, BULMA.CONTAINER, "is-flex-tablet", "is-justify-content-space-between", "app-creator-box"]);
export default AppCreator;