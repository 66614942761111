import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Hero from "./Hero";
import Linguist from "../../../../lib/JuiS/Linguist";
import frontPageLexicons from "./lexicons/frontPageLexicons";
import Features from "./Features";
import HTML from "../../../utils/HTML";
import BULMA from "../../../bulma_components/bulmaCssClasses";
import StatisticsComponent from "./StatisticsComponent";
import TextComponent from "../../../components/TextComponent";
import {createIcon} from "../../../bulma_components/Icon";
import AppCreator from "./AppCreator";

const t = new Linguist(frontPageLexicons).t;
let FrontPage = extendComponent(Container, function () {
    this.hero = new Hero();
    this.appCreator = new AppCreator();
    this.features = new Features(function () {
        this.getNode().addWrapper(HTML.DIV, [BULMA.CONTAINER]);
        this.getNode().addWrapper(HTML.SECTION, [BULMA.SECTION]);
    });
    this.stats = new StatisticsComponent(function () {
        this.getNode().addWrapper(HTML.DIV, [BULMA.CONTAINER, BULMA.HERO_BODY]);
        this.getNode().addWrapper(HTML.SECTION, [BULMA.HERO, BULMA.IS_PRIMARY, BULMA.IS_BLOCK, "my-6"]);
    });

    this.contact = new Container(function () {

        this.title = new TextComponent(t("contactus"), HTML.DIV, [BULMA.TITLE, BULMA.IS_2]);
        this.company = new TextComponent("Ab Hewecon Oy - 3314905-5", HTML.DIV);
        this.address = new TextComponent("Russnargatan 34, 65230 Vasa, Finland", HTML.DIV);
        this.email = new Container(function () {
            this.part1 = new TextComponent("henrik");
            this.part2 = new TextComponent(" at ");
            this.part3 = new TextComponent("hewecon");
            this.part4 = new TextComponent(".");
            this.part5 = new TextComponent("com");
        });
        this.slack = new Container(function () {
            this.getNode().setAttribute("href", "https://join.slack.com/t/hewecon/shared_invite/zt-21wzs2jfq-hRFnZWn8iD3Ehn8y8dM_mg");
            this.getNode().setAttribute("target", "_blank");
            this.getNode().setAttribute("rel", "noopener noreferrer");
            this.icon = createIcon("slack");
            this.icon.style = "fab";
            this.text = new TextComponent("Slack");
        }, [BULMA.BUTTON, "mt-6"], HTML.A);
        this.getNode().addWrapper(HTML.SECTION, BULMA.SECTION);
    }, [BULMA.CONTAINER, BULMA.HAS_TEXT_CENTERED]);
});
export {FrontPage as default};
