import {createTextNode} from "juis-components/ComponentUtils.js";
import {
    createCheckboxEditor,
    createColorEditor,
    createDateEditor,
    createDurationEditor,
    createFileEditor,
    createImageFile,
    createMoneyEditor,
    createNumberEditor,
    createPeriodEditor,
    createReadOnly,
    createStringEditor,
    createTimestampEditor,
    createTranslatableStringEditor,
    getDropDownFactory
} from "../components/fieldEditors/editorFactories.js";
import Validation from "./Validation.js";
import formatDuration from "./formatDuration.js";
import {sortByMappedValue} from "juis-commons/JuisUtils.js";
import RestModel from "./RestModel.js";
import Linguist from "../../lib/JuiS/Linguist.js";
import {createBlankLink} from "../bulma_components/Link.js";
import Filter from "./Filter.js";
import Method from "./Method.js";
import DynamicField from "../hewecon/models/DynamicField.js";
import {imageThumbnailCellFactory} from "../components/ImageThumbnail.js";
import CurrencyCodes from "../hewecon/utils/currency/CurrencyCodes.js";

function getChildEntityForField(entity, field) {
    let fieldsModel = entity;
    if (field.getName().includes(".")) {
        let fieldModelPath = field.getName().substring(0, field.getName().lastIndexOf("."));
        fieldsModel = entity[fieldModelPath];
    }
    return fieldsModel;
}

function Field(type) {
    let handler = {};
    let proxy = new Proxy(this, handler);
    let owner;
    let virtual = false;
    let required = false;
    let orderByField = undefined;
    let nullable = false;
    let writeOnly = false;
    let defaultValueCallback = undefined;
    let setterCallback = undefined;
    let sortedBy = undefined;
    let entityEditorFields = () => [];
    let cascading = false;
    let summable = false;
    let valueCallback;
    let tableSettings = {};
    this.readOnly = false;
    let icon = null;

    this.cellFactory = (value, entity) => createTextNode(value);

    this.min = undefined;
    this.max = undefined;
    this.step = undefined;
    let name = undefined;
    let help = undefined;
    let options = undefined;
    let lazyOptions = undefined;
    let labelText = undefined;
    let genitiveLabelText = undefined;
    let validationRules = [];
    let instance = this;
    let optionsLoader = undefined;
    let editorFactory = createReadOnly;
    let creatorFactory;
    let numberFormat = {};
    let nameField;
    let filterGetter = () => Filter.true;
    this.getFilter = (entity, parent) => {
        return filterGetter(entity, parent);
    };
    this.withNameField = function (newNameField, shouldCreateNamedEntities) {
        nameField = newNameField;
        this.shouldCreateNamedEntities = () => shouldCreateNamedEntities;
        return this;
    };
    this.getNameField = () => nameField;
    this.shouldCreateNamedEntities = () => false;

    this.validate = function (entity) {
        const value = entity[name];
        if (!required && (value === null || value === undefined || value === "")) {
            // Don't validate missing values if no value is required
            return;
        }
        validationRules.forEach(validate => validate(this, entity));
    };
    this.setName = function (newName) {
        name = newName;
        return this;
    };
    this.getName = () => name;
    this.getValueFor = function (entity) {
        return entity ? entity[this.getName()] : undefined;
    };
    this.getTranslatedValueFor = function (entity, languageCode = Linguist.getLanguage()) {
        entity = getChildEntityForField(entity, this);
        if (!entity.lexiconEntries) {
            return this.getValueFor(entity);
        }
        let name = this.getNameTail();
        let lexiconEntry = entity.lexiconEntries.find(entry => entry.key === name && entry.language?.startsWith(languageCode));
        return lexiconEntry?.translation || this.getValueFor(entity);
    };
    this.getLazyValueFor = (entity) => entity["$" + name];
    this.proxyBreakout = () => this;
    this.equals = function (other) {
        if (!other) {
            return false;
        }
        if (other instanceof DynamicField) {
            return (other.model === owner.getName() && other.name === this.getName());
        }
        return this.proxyBreakout() === other.proxyBreakout() && this.getName() === other.getName();
    };
    this.getNameHead = function () {
        if (this.getName().includes(".")) {
            return this.getName().substring(0, this.getName().lastIndexOf("."));
        }
        return "";
    };
    this.getNameTail = () => {
        if (this.getName().includes(".")) {
            return this.getName().substring(this.getName().lastIndexOf("."));
        }
        return this.getName();
    };

    this.coerce = (value) => {
        if (value === null) {
            return value;
        }
        if (this.getModelType() && !value) {
            if (this.isModelList()) {
                return [];
            } else {
                return null;
            }
        }
        if (Array.isArray(this.getType()) && !value) {
            return [];
        }
        switch (type) {
            case Field.NUMBER:
                if (typeof value === "string" && value.trim() === "") {
                    return null;
                }
                if (typeof value === "string") {
                    const parsedNumber = parseFloat(value);
                    if (Number.isNaN(parsedNumber)) {
                        throw new TypeError(`Could not parse number for field ${this.getName()} from value "${value}"}`);
                    }
                    return parsedNumber;
                }
                return value;
            case Field.TIMESTAMP:
            case Field.DATE:
                if (value instanceof Date) {
                    return value;
                }
                if (typeof value === "string") {
                    return new Date(value);
                }
                if (typeof value === "number") {
                    const date = new Date();
                    date.setUTCMilliseconds(value);
                    return date;
                }
            default:
                return value;
        }
    };

    this.getLabel = () => labelText;
    this.getType = () => type;
    let ownerPromise = new Promise(resolve => {
        this.setOwner = (newOwner) => {
            if (owner) {
                throw new Error("Field can only have one owner");
            }
            owner = newOwner;
            resolve(owner);
            return this;
        };
    });
    this.getOwner = () => owner;

    this.getModelType = () => {
        let modelType = null;
        if (type instanceof RestModel) {
            modelType = type;
        } else if (this.isModelList()) {
            modelType = type[0];
        }
        return modelType;
    };
    this.isModelList = () => Array.isArray(this.getType()) && this.getType()[0] instanceof RestModel;
    this.getEntityEditorFields = (entity) =>
        Array.isArray(entityEditorFields) ? entityEditorFields : entityEditorFields(entity);
    this.getDefaultValue = (entity) => this.hasDefaultValue() ? defaultValueCallback(entity) : undefined;
    this.hasDefaultValue = () => !!defaultValueCallback;
    this.hasValueCallback = () => !!valueCallback;
    this.hasSetterCallback = () => !!setterCallback;
    this.getSetterCallback = () => setterCallback;
    this.getValue = (entity) => valueCallback ? valueCallback.call(entity, entity) : undefined;
    this.isVirtual = () => virtual;
    this.isSummable = () => summable;
    this.isRequired = () => required;
    this.isReadOnly = () => this.readOnly;
    this.getNumberFormat = () => numberFormat;

    this.getImplicitOrderBy = function () {
        if (this.getModelType()) {
            return this.getName() + "." + this.getModelType().getOrderBy()?.getOrderBy();
        } else if (!virtual) {// Cannot order virtual fields when not explicitly specified
            return this.getName();
        }
    };

    this.getOrderBy = function () {
        if (!orderByField) {
            return this.getImplicitOrderBy();
        }
        let fieldName = this.getName();
        let index = fieldName.lastIndexOf(".");
        if (index > -1) {
            fieldName = fieldName.substring(0, index + 1) + orderByField.getName();
        } else {
            fieldName = orderByField.getName();
        }
        return fieldName;
    };

    this.isWriteOnly = () => writeOnly;
    this.getHelp = () => help;
    this.getCellFactory = () => this.cellFactory;
    this.hasSortFunction = () => !!sortedBy;
    this.getSortFunction = () => sortByMappedValue(entity => entity[sortedBy.getName()]);
    this.getOptions = () => options || [];
    this.getOptionsPromise = function (filterString, parent) {
        let field = this;
        let entity = field.getNameHead() ? parent[field.getNameHead()] : parent;
        return new Promise((resolve, reject) => {
            if (options) {
                if (filterString) {
                    let startsWith = options.filter(value => value.toUpperCase().startsWith(filterString.toUpperCase()));
                    let includes = options
                        .filter(value => !value.toUpperCase().startsWith(filterString.toUpperCase()))
                        .filter(value => value.toUpperCase().includes(filterString.toUpperCase()));
                    resolve([...startsWith, ...includes]);
                } else {
                    resolve(options);
                }
                return;
            }
            if (lazyOptions) {
                options = lazyOptions(entity);
                resolve(options);
            } else if (optionsLoader) {
                let result = optionsLoader.call(field, filterString, parent, field.getFilter(entity, parent));
                if (result.then) {
                    result.then(resolve).catch(reject);
                } else {
                    resolve(result);
                }
            } else if (this.getModelType()?.getDefaultOptionsLoader()) {

            } else {
                throw new Error(`${this.getName()} does not provide any options`);
            }
        });
    };
    this.withCreatorFactory = function (factory) {
        creatorFactory = factory;
        return this;
    };
    this.getCreatorFactory = () => creatorFactory;
    this.asReadOnly = function () {
        editorFactory = createReadOnly;
        instance.readOnly = true;
        return this;
    };
    this.asSummable = function () {
        summable = true;
        return this;
    };
    this.asCascading = function () {
        cascading = true;
        return this;
    };
    this.isCascading = function () {
        return cascading;
    };
    this.hasEditorFactory = () => !!editorFactory;

    this.getEditor = function (entity) {
        return editorFactory.call(instance, instance, getChildEntityForField(entity, this));
    };

    this.getCell = function (entity) {
        let field = this;
        let fieldName = this.getName();
        let childEntity = entity;
        let value;
        if (entity === null) {
            value = null;
        } else {
            value = field.getValue(entity) ?? entity[fieldName];
        }
        if (fieldName.includes(".")) {
            let fieldNameHead = fieldName.substring(0, fieldName.lastIndexOf("."));
            childEntity = entity[fieldNameHead];
        }
        let cell = field.cellFactory(value, childEntity, field);
        if (typeof cell === "string"
            || typeof cell === "number"
            || typeof cell === "boolean"
            || cell === null
            || cell === undefined) {
            if (typeof cell === "string") {
                cell = cell.replace("  ", " \u00A0");
            }
            cell = createTextNode(cell);
        }
        return cell;
    };

    this.getEditorFactory = () => editorFactory;

    this.getFormComponent = function (entity, overrides) {
        let fieldWithOverrides = {};
        Object.assign(fieldWithOverrides, this);
        Object.assign(fieldWithOverrides, overrides);
        return this.getEditorFactory()(fieldWithOverrides, entity);
    };

    this.withMin = function (min) {
        this.min = min;
        validationRules.push(Validation.minimum(min));
        return this;
    };

    this.withMax = function (max) {
        this.max = max;
        validationRules.push(Validation.maximum(max));
        return this;
    };

    this.withValidationRule = function (rule) {
        validationRules.push(rule);
        return this;
    };

    this.asCurrency = function () {
        const itemFactory = (currencyCode) => {
            let currency = new Intl.DisplayNames([Linguist.getLanguage()], {type: "currency"}).of(currencyCode);
            return currency.charAt(0).toUpperCase() + currency.slice(1);
        };
        return this.asEnum(CurrencyCodes)
            .withCellFactory(itemFactory)
            .withEditorFactory(getDropDownFactory({itemFactory, searchable: false}))
            .withDefaultValueCallback(() => CurrencyCodes[0]);
    };

    this.asNumber = function (min, max, step) {
        type = Field.NUMBER;
        if (min) {
            this.withMin(min);
        }
        if (max) {
            this.withMax(max);
        }
        instance.step = step;
        editorFactory = createNumberEditor;
        return this.withSetterCallback((value) => {
            if (value && typeof value !== "number") {
                return parseFloat(value);
            }
            return value;
        }).withCellFactory(function (value) {
            if (value !== null && value !== undefined && Object.values(this.getNumberFormat()).length > 0) {
                value = new Intl.NumberFormat(Linguist.getLanguage(), this.getNumberFormat()).format(value);
            }
            return createTextNode(value);
        });
    };
    this.asNumberWithUnit = function (unit, unitDisplay = "short") {
        return this.asNumber().withCellFactory(function (value) {
                return new Intl.NumberFormat(Linguist.getLanguage(), {
                    ...this.getNumberFormat(),
                    style: "unit",
                    unit,
                    unitDisplay
                }).format(value);
            }
        );
    };
    this.withNumberFormatting = function (newFormatting) {
        numberFormat = {...numberFormat, ...newFormatting};
        return this;
    };
    this.asString = function () {
        type = Field.STRING;
        editorFactory = createStringEditor;
        return this.withSetterCallback((value) => {
            if (value && typeof value !== "string") {
                return value + "";
            }
            return value;
        });
    };
    this.asFile = function () {
        type = Field.FILE;
        editorFactory = createFileEditor;
        return this.withCellFactory(createBlankLink);
    };
    this.asImage = function () {
        type = Field.FILE;
        editorFactory = createImageFile;
        return this.withCellFactory(imageThumbnailCellFactory);
    };
    this.asTranslatableString = function () {
        type = Field.STRING;
        editorFactory = createTranslatableStringEditor;
        this.cellFactory = (value, entity) => {
            let lexiconEntry = entity?.lexiconEntries
                .find(entry => entry.key === name && entry.language === Linguist.getLanguage());
            return createTextNode(lexiconEntry?.translation || value);
        };
        return this.withSetterCallback((value) => {
            if (value && typeof value !== "string") {
                return value + "";
            }
            return value;
        });
    };
    this.withIcon = function (newIcon) {
        icon = newIcon;
        return this;
    };
    this.getIcon = function () {
        return icon;
    };

    this.asMoney = function (currencyCodeField) {
        const getCurrencyCode = (entity) => currencyCodeField instanceof Field ? currencyCodeField.getValueFor(entity) : currencyCodeField;
        type = Field.NUMBER;
        summable = true;
        editorFactory = createMoneyEditor;
        return this
            .withCellFactory((value, entity) => {
                if (!entity || entity.deleted || value === null || value === undefined) {
                    return "";
                }
                const currency = getCurrencyCode(entity);
                if (!currency) {
                    return new Intl.NumberFormat(Linguist.getLanguage(), {}).format(value);
                }
                return new Intl.NumberFormat(Linguist.getLanguage(), {style: "currency", currency}).format(value);
            })
            .withSetterCallback((value) => {
                if (value && typeof value !== "number") {
                    if (value.replace) {
                        value = value.replace(",", ".");
                    }
                    return parseFloat(value);
                }
                return value;
            });
    };
    this.asColor = function () {
        type = Field.STRING;
        editorFactory = createColorEditor;
        return this;
    };
    this.asDate = function (locale = "default", options = {dateStyle: "medium"}) {
        type = Field.DATE;
        editorFactory = createDateEditor;
        return this.withCellFactory(date => {
            if (date === null || date === undefined) {
                return "";
            }
            if (locale === "default") {
                if (Linguist.getLanguage()) {
                    locale = Linguist.getLanguage();
                }
            }
            return new Intl.DateTimeFormat(locale, options).format(date);
        });
    };
    this.asPeriod = function () {
        type = Field.PERIOD;
        editorFactory = createPeriodEditor;
        return this.withCellFactory(value => {
            if (value === null || value === undefined) {
                return "N/A";
            }
            return value;
        });
    };
    this.asTimestamp = function (locales = "default", options = {
        dateFormat: "medium",
        timeFormat: "medium"
    }) {
        type = Field.TIMESTAMP;
        editorFactory = createTimestampEditor;
        return this.withCellFactory(date => {
            if (date === null || date === undefined) {
                return "";
            }
            if (locales === "default") {
                if (Linguist.getLanguage()) {
                    locales = Linguist.getLanguage();
                }
            }
            return new Intl.DateTimeFormat(locales, options).format(date);
        });
    };
    this.asDuration = function () {
        type = Field.DURATION;
        editorFactory = createDurationEditor;
        return this.withCellFactory(formatDuration);
    };
    this.asBoolean = function () {
        type = Field.BOOLEAN;
        editorFactory = createCheckboxEditor;
        this.cellFactory = (value) => createTextNode(value ? "✓" : "");
        return this;
    };
    this.asEnum = function (values) {
        type = type || Field.ENUM;
        if (typeof values === "function") {
            lazyOptions = values;
        } else {
            options = values;
        }
        return this.withEditorFactory((field, entity) => getDropDownFactory({
            searchable: false
        })(field, entity));
    };
    this.asJson = function () {
        type = Field.JSON;
        return this;
    };
    this.asEnumList = function (values) {
        type = type || [Field.ENUM];
        if (typeof values === "function") {
            lazyOptions = values;
        } else {
            options = values;
        }
        return this.withEditorFactory((field, entity) => getDropDownFactory({
            itemFactory: (option) => option,
            searchable: true
        })(field, entity));
    };
    this.asNullable = function () {
        nullable = true;
        return this;
    };
    this.takeDefaultPropertiesFromModel = function (model) {
        optionsLoader = function (searchString, ownerEntity, filter) {
            let defaultOptionsLoader = model.getDefaultOptionsLoader();
            if (!defaultOptionsLoader) {
                return Promise.resolve([]);
            }
            return defaultOptionsLoader(searchString, ownerEntity, filter);
        };
        editorFactory = function (field, entity) {
            let defaultEditorFactory = model.getDefaultEditorFactory();
            if (defaultEditorFactory) {
                return defaultEditorFactory(field, entity);
            }
            return createReadOnly(field, entity);
        };
        this.cellFactory = function (value, entity) {
            let defaultCellFactory = Array.isArray(this.getType()) ?
                model.getDefaultListCellFactory() : model.getDefaultCellFactory();
            if (!defaultCellFactory) {
                return createTextNode(value);
            }
            return defaultCellFactory(value, entity);
        };

        return this;
    };
    let foreignRelationField;
    this.withForeignRelationField = function (newForeignRelationField) {
        foreignRelationField = newForeignRelationField;
        return this;
    };
    this.getForeignRelationField = () => foreignRelationField;
    this.hasForeignRelationField = () => !!foreignRelationField;
    this.sortedBy = function (field) {
        sortedBy = field;
        return this;
    };
    this.withSetterCallback = function (callback) {
        setterCallback = callback;
        return this;
    };
    this.asVirtual = function (newOrderByField, newValueCallback) {
        virtual = true;
        orderByField = newOrderByField;
        valueCallback = newValueCallback;
        return this;
    };
    this.asRequired = function () {
        validationRules.push(Validation.hasValue());
        required = true;
        return this;
    };
    this.withOrderByField = function (newOrderByField) {
        if (newOrderByField instanceof Field) {
            orderByField = newOrderByField;
        } else if (typeof newOrderByField === "function") {
            ownerPromise.then(owner => orderByField = newOrderByField(owner));
        } else {
            throw new Error(`Unknown order by field ${newOrderByField} in ${this.getName()}`);
        }
        return this;
    };
    this.asWriteOnly = function () {
        writeOnly = true;
        return this;
    };
    this.withEntityEditorFields = function (fields) {
        entityEditorFields = fields;
        return this;
    };
    this.withCellFactory = function (factory) {
        instance.cellFactory = factory;
        return this;
    };
    this.withEditorFactory = function (newEditorFactory) {
        editorFactory = newEditorFactory;
        return this;
    };
    this.withFilterGetter = function (newFilterGetter) {
        const oldFilterGetter = filterGetter;
        if (newFilterGetter instanceof Method) {
            let method = newFilterGetter;
            newFilterGetter = (entity, parent) => method.run(entity, parent);
        }
        filterGetter = (entity, parent) => Filter.and(oldFilterGetter(entity, parent), newFilterGetter(entity, parent));

        return this;
    };
    this.withOptionsLoader = function (newOptionsLoader) {
        optionsLoader = newOptionsLoader;
        return this;
    };
    this.withOptions = function (newOptions) {
        options = newOptions;
        return this;
    };
    this.withLabel = function (label) {
        labelText = label;
        return this;
    };
    this.withGenitiveLabel = function (label) {
        genitiveLabelText = label;
        return this;
    };
    this.withHelp = function (helpText) {
        help = helpText;
        return this;
    };
    this.withDefaultValueCallback = function (callback) {
        defaultValueCallback = callback;
        return this;
    };
    this.withTableSettings = function (newTableSettings) {
        tableSettings = newTableSettings;
        return this;
    };

    const nameGetterProxies = {};
    const getNameGetterProxy = function (child, currentName) {
        if (!nameGetterProxies[child.getName()]) {
            nameGetterProxies[child.getName()] = {};
        }
        let nameGetterProxy = nameGetterProxies[child.getName()][currentName];
        if (!nameGetterProxy) {
            nameGetterProxy = createNameGetterProxy(child, currentName);
            nameGetterProxies[child.getName()][currentName] = nameGetterProxy;
        }
        return nameGetterProxy;
    };

    let concatenateLabels = (targetLabel) => {
        if (targetLabel.charAt(1) !== targetLabel.charAt(1).toUpperCase()) {
            targetLabel = targetLabel.charAt(0).toLowerCase() + targetLabel.slice(1);
        }
        return (genitiveLabelText || labelText) + " " + targetLabel;
    };

    const createNameGetterProxy = function (child, currentName) {
        let handler = {};
        handler.get = (target, propertyName) => {
            let newName = currentName + "." + target.getName();
            if (propertyName === "getName") {
                return () => newName;
            }
            if (propertyName === "getLabel" && labelText && target.getLabel()) {
                return () => concatenateLabels(target.getLabel());
            }
            if (target.getModelType() && target.getModelType()[propertyName] instanceof Field) {
                return getNameGetterProxy(target.getModelType()[propertyName], newName);
            }
            return Reflect.get(target, propertyName);
        };
        return new Proxy(child, handler);
    };

    const createArrayIndexProxy = function (field, index) {
        return new Proxy(field, {
            get: (target, propertyName) => {
                if (propertyName === "getName") {
                    return () => target.getName() + "." + index;
                }
                if (propertyName === "getType") {
                    return Reflect.get(target, "getModelType");
                }
                if (target.getModelType()[propertyName] instanceof Field) {
                    return getNameGetterProxy(target.getModelType()[propertyName], target.getName() + "." + index);
                }
                return Reflect.get(target, propertyName);
            }
        });
    };

    const isNumeric = /^\d+$/;
    const isNamedChild = /^_[^.]+$/;
    handler.get = (target, propertyName) => {
        if (target[propertyName]) {
            return Reflect.get(target, propertyName);
        }
        const head = propertyName.substring(0, propertyName.indexOf("."));
        if (Array.isArray(target.getType())) {
            if (isNumeric.test(propertyName) || isNamedChild.test(propertyName)) {
                return createArrayIndexProxy(proxy, propertyName);
            } else if (isNumeric.test(head) || isNamedChild.test(head)) {
                const tail = propertyName.substring(propertyName.indexOf(".") + 1);
                target = createArrayIndexProxy(proxy, head);
                propertyName = tail;
            }
        }
        if (target.getModelType() && target.getModelType()[propertyName] instanceof Field) {
            return getNameGetterProxy(target.getModelType()[propertyName], target.getName());
        }
        return Reflect.get(target, propertyName);
    };

    if (this.getModelType()) {
        this.takeDefaultPropertiesFromModel(this.getModelType());
    }

    return proxy;
}

Field.NUMBER = "Number";
Field.STRING = "String";
Field.FILE = "File";
Field.IMAGE = "Image";
Field.BOOLEAN = "Boolean";
Field.DATE = "Date";
Field.PERIOD = "Period";
Field.TIMESTAMP = "Timestamp";
Field.DURATION = "Duration";
Field.ENUM = "Enum";
Field.JSON = "Json";

export default Field;
