import en_US from "./en_US.json";
import sv_FI from "./sv_FI.json";
import fi_FI from "./fi_FI.json";
import productLexicons from "./../product/productLexicons.js";

export default {
    en_US: {...productLexicons.en_US, ...en_US},
    sv_FI: {...productLexicons.sv_FI, ...sv_FI},
    fi_FI: {...productLexicons.fi_FI, ...fi_FI}
}
