import {extendComponent} from "juis-components/ComponentUtils.js";
import Property, {CssProperty} from "juis-components/Property.js";
import Icon from "./Icon.js";
import {BulmaComponent} from "./BulmaComponent.js";
import HTML from "../utils/HTML.js";
import BULMA_CSS_CLASSES from "./bulmaCssClasses.js";
import TextComponent from "../components/TextComponent.js";
import "./style/button.scss";

export default extendComponent(BulmaComponent, function () {
    let iconComponent;
    let textComponent;
    const updateIconAndText = () => {
        removeContent();
        if (this.icon) {
            if (typeof this.icon === "string") {
                iconComponent = new Icon((icon) => {
                    icon.icon = this.icon;
                }, ["isSmall", ...this.iconCssClasses]);
            } else {
                iconComponent = this.icon;
            }
            this.getNode().appendChild(iconComponent.getNode());
            if (this.text) {
                textComponent = new TextComponent(this.text, HTML.SPAN, "button-text");
                this.getNode().appendChild(textComponent.getNode());
            }
        } else if (this.text) {
            this.getNode().setText(this.text);
        }
    };
    this.registerDomEvents("click");
    this.isLoading = new CssProperty(BULMA_CSS_CLASSES.IS_LOADING);
    this.type = new Property(function (value) {
        this.getNode().setAttribute("type", value);
    }, "button");
    this.text = new Property(updateIconAndText);
    this.hideTextDesktop = new CssProperty("hide-text-desktop");

    let removeContent = () => {
        if (iconComponent) {
            this.getNode().removeChild(iconComponent.getNode());
            iconComponent = null;
        }
        if (textComponent) {
            this.getNode().removeChild(textComponent.getNode());
            textComponent = null;
        }
        this.innerHTML = "";
    };

    this.icon = new Property(updateIconAndText);

    this.iconCssClasses = new Property((cssClasses = []) => {
        this.refreshProperty("icon");
    }, [], true);
    this.disabled = new Property((newValue) => {
        if (newValue) {
            this.getNode().setAttribute("disabled", true);
        } else {
            this.getNode().removeAttribute("disabled");
        }
    }, false);
}, ["button"], "Button");
