import BillItem from "../models/BillItem.js";

const createBillItem = (bill) => {
    const item = new BillItem();
    item.bill = bill;
    item.quantity = 0;
    return item;
};

const createBillItemsFromTicket = function (ticket, bill) {
    let items = [];
    let headerText = ticket.getBillItemsHeader(bill);
    if (headerText) {
        let header = createBillItem(bill);
        header.textItem = true;
        header.name = headerText;
        items.push(header);
    }
    if (ticket.mainProduct && ticket.mainProduct.billable) {
        let mainProductItem = createBillItem(bill);
        mainProductItem.product = ticket.mainProduct.product;
        mainProductItem.copyValuesFromProductAggregate(ticket.mainProduct, bill.getFieldsToCopyFromProducts());
        mainProductItem.quantity = ticket.mainProduct.quantity;
        mainProductItem.stock = null;
        items.push(mainProductItem);
    }
    let productsSubheader = ticket.getBillItemsProductListSubHeader(bill);
    if (productsSubheader) {
        let subHeader = createBillItem(bill);
        subHeader.textItem = true;
        subHeader.name = productsSubheader;
        items.push(subHeader);
    }
    ticket.ticketProducts
        .filter(ticketProduct => ticketProduct.billable)
        .forEach(ticketProduct => {
            let productItem = createBillItem(bill);
            productItem.product = ticketProduct.product;
            productItem.copyValuesFromProductAggregate(ticketProduct, bill.getFieldsToCopyFromProducts());
            productItem.quantity = ticketProduct.quantity;
            productItem.stock = null;
            items.push(productItem);
        });
    if (ticket.type.workLogFields.length > 0) {
        return ticket.$workLogs
            .then((workLogs => createWorkLogBillItems(ticket, bill, workLogs, !!productsSubheader)))
            .then(workLogItems => [...items, ...workLogItems]);
    } else {
        return Promise.resolve(items);
    }
};

const createWorkLogBillItems = (ticket, bill, workLogs, addEmptyLine = false) => {
    const items = [];
    let workLogSubheader = ticket.getBillItemsWorkLogSubHeader(bill);
    if (workLogSubheader) {
        addEmptyLine = true;
        let subHeader = createBillItem(bill);
        subHeader.textItem = true;
        subHeader.name = workLogSubheader;
        items.push(subHeader);
    }
    workLogs.filter(workLog => workLog.billable).forEach(workLog => {
        let productItem = createBillItem(bill);
        productItem.product = workLog.product;
        productItem.copyValuesFromWorkLog(workLog);
        items.push(productItem);
    });
    if (addEmptyLine) {
        // If the ticket has sub headers, then add an empty line after them to clearly separate from other billed
        // tickets on the same bill
        let emptyLine = createBillItem(bill);
        emptyLine.textItem = true;
        emptyLine.name = "";
        items.push(emptyLine);
    }
    return items;
};

export default {createBillItemsFromTicket};
