import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createTextAreaEditor} from "../../components/fieldEditors/editorFactories.js";
import Component from "juis-components/Component.js";
import HTML from "../../utils/HTML.js";
import App from "./App.js";
import BillTemplateTranslation from "./BillTemplateTranslation.js";
import {createLink} from "../../bulma_components/Link.js";
import setDefaults from "./utils/setDefaults.js";

export default new Model(function () {
        this.app = new Field(App).withLabel("Owner").withCellFactory(app => app ? app.name : "HEWECON");
        this.code = new Field()
            .asReadOnly()
            .asString()
            .withLabel("Url Code");

        this.name = new Field()
            .asString()
            .withLabel("Name");

        this.description = new Field()
            .asString()
            .withLabel("Description");

        this.translations = new Field([BillTemplateTranslation]).asCascading();
        this.width = new Field().asNumber().withLabel("Width [mm]");
        this.height = new Field().asNumber().withLabel("Height [mm]");
        this.margin = new Field().asNumber().withLabel("Margin [mm]");

        this.htmlString = new Field()
            .asString()
            .withEditorFactory(createTextAreaEditor)
            .withCellFactory((value) => new Component(function () {
                this.getNode().setText(value);
                this.getNode().setStyle("line-height", "1em");
            }, [], HTML.PRE))
            .withHelp("Use an external HTML editor and copy the code here")
            .withLabel("Template");

        setDefaults(this, {
            filterFields: [this.name, this.description],
            linkFactory: printTemplate => createLink(`${printTemplate.code}`, printTemplate.name),
            itemField: this.name
        });
    }, "BillTemplate", "bill-templates"
);
