import Filter from "./Filter.js";
import Optional from "../../lib/JuiS/Optional.js";

export default function LazyFieldLoader(rootInstance) {
    const fieldsPerModel = {};
    const modelsPerName = {};

    this.add = function (field, instance) {
        const modelName = instance.getModel().getName();
        if (!modelsPerName[modelName]) {
            modelsPerName[modelName] = instance.getModel();
        }
        if (!fieldsPerModel[modelName]) {
            fieldsPerModel[modelName] = [];
        }
        if (!fieldsPerModel[modelName][field.getName()]) {
            fieldsPerModel[modelName][field.getName()] = [];
        }
        fieldsPerModel[modelName][field.getName()].push(instance);
    };

    const loadValuesForFieldWithForeignRelation = (field, instances) => {
        const foreignField = field.getForeignRelationField();
        return foreignField.getOwner().getAll(Filter.in(foreignField, instances))
            .then(lazyLoadedChildren => {
                instances.forEach(instance => {
                    const ownChildren = lazyLoadedChildren.filter(lazyLoadedChild => instance.equals(foreignField.getValueFor(lazyLoadedChild)));
                    const cleanProperties = {};
                    cleanProperties[field.getName()] = ownChildren;
                    instance.refresh(cleanProperties);
                });
            });
    };

    const loadLazyFieldForInstances = (field, instances) => {
        const fieldName = field.getName();
        const withoutId = instances.some(instance => !instance.hasValue(field));
        if (withoutId) {
            return Promise.all(instances
                .filter(instance => !instance.hasValue(field))
                .map(instance => instance.fetchCustomEndpoint(instance.id, fieldName)
                    .then(entityData => {
                        const model = field.getModelType();
                        return Optional.of(model.cache.getById(entityData.id))
                            .peek(instance => instance.refresh(entityData))
                            .orElseGet(() => model.createInstance(entityData));
                    })
                    .then(lazyLoadedChild => instance.setProperty(fieldName, lazyLoadedChild, {clean: true}))
                ));
        }
        const idList = instances
            .map(instance => instance[fieldName])
            .flatMap(value => value)
            .map(child => child.id);
        return field.getModelType().getAll(Filter.in("id", idList));
    };

    this.loadLazyValues = function () {
        const promises = Object.entries(fieldsPerModel).flatMap(entry => {
            let [modelName, fields] = entry;
            let owner = modelsPerName[modelName];
            return Object.entries(fields).flatMap(entry => {
                let [fieldName, instances] = entry;
                const field = owner[fieldName];
                if (field.hasForeignRelationField()) {
                    return loadValuesForFieldWithForeignRelation(field, instances);
                } else {
                    return loadLazyFieldForInstances(field, instances);
                }
            });
        });
        return Promise.all(promises).then(() => {
            rootInstance.setLazy(false);
            childInstances.forEach(child => child.setLazy(false));
            return rootInstance;
        });
    };


    rootInstance.getFields()
        .filter(field => !field.isVirtual())
        .filter(field => field.getModelType())
        .filter(field => !rootInstance.hasLoadedValue(field))
        .forEach(field => this.add(field, rootInstance));
    let childInstances = rootInstance.getCascadingEntities();
    childInstances
        .filter(childInstance => childInstance.hasId())
        .forEach(childInstance => {
            childInstance.getFields()
                .filter(field => !field.isVirtual())
                .filter(field => field.getModelType())
                .filter(field => !childInstance.hasLoadedValue(field))
                .forEach(field => this.add(field, childInstance));
        });
}
