import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import PhoneNumber from "../../components/PhoneNumber.js";
import {createLink} from "../../bulma_components/Link.js";
import App from "./App.js";
import {getLocaleDropdownFactory, localeSetterCallback} from "../translations/localeDropdownFactory.js";
import Bill from "./Bill.js";
import {addressSuggestionInputFactory, getDropDownFactory} from "../../components/fieldEditors/editorFactories.js";
import ContactGroup from "./ContactGroup.js";
import UserRole from "./UserRole.js";
import TextComponent from "../../components/TextComponent.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import contactLexicons from "./lexicons/contact/contactLexicons.js";
import LOCALES from "../translations/locales.js";
import localeCellFactory from "../translations/localeCellFactory.js";
import Method from "../../utils/Method.js";
import SessionHandler from "../../SessionHandler.js";
import Container from "juis-components/Container.js";
import {createColorIcon} from "../../bulma_components/Icon.js";
import HTML from "../../utils/HTML.js";
import SalesPriceRule from "./SalesPriceRule";
import Ticket from "./Ticket";
import {countryCodes, getRegionName} from "../../utils/IntlUtils";


let Contact = new Model(function () {
    const linguist = new Linguist(contactLexicons);
    this.app = new Field(App);
    this.code = new Field().asString().asReadOnly();
    this.name = new Field().asString().withLabel("Name");
    this.personName = new Field().asString().withLabel("Contact person");
    this.companyName = new Field().asString().withLabel("Company");
    this.phoneNumber = new Field().asString().withLabel("Phone").withCellFactory(value => new PhoneNumber(value));
    this.street2 = new Field().asString().withLabel("Street 2");
    this.zipCode = new Field().asString().withLabel("Zip Code");
    this.city = new Field().asString().withLabel("City");
    this.street = new Field().asString().withLabel("Street")
        .withEditorFactory(addressSuggestionInputFactory(this.city, this.zipCode, linguist));
    this.state = new Field().asString().withLabel("State");

    this.country = new Field().asString()
        .withOptions(countryCodes)
        .withLabel("Country")
        .withEditorFactory(getDropDownFactory({itemFactory: (country) => new TextComponent(getRegionName(country))}))
        .withCellFactory((country) => new TextComponent(getRegionName(country)));

    this.email = new Field().asString().withLabel("E-mail");
    this.badgeTickets = new Field([Ticket]);
    this.invoiceEmail = new Field().asString().withLabel("Invoicing E-mail");
    this.paymentTerm = new Field()
        .withOptions(Object.keys(Bill.PAYMENT_TERMS))
        .withSetterCallback((value, contact) => {
            if (Object.keys(Bill.PAYMENT_TERMS).includes(value)) {
                return value;
            }
            switch (value) {
                case 7:
                case "7":
                    return "NET_7";
                case 10:
                case "10":
                    return "NET_10";
                case 14:
                case "14":
                    return "NET_14";
                case 30:
                case "30":
                    return "NET_30";
                case 60:
                case "60":
                    return "NET_60";
                case 90:
                case "90":
                    return "NET_90";
                default:
                    return null;
            }
        })
        .withEditorFactory(getDropDownFactory({
            placeholder: "Payment term",
            itemFactory: (paymentTerm) => new TextComponent(Bill.PAYMENT_TERMS[paymentTerm])
        }))
        .withCellFactory((paymentTerm) => new TextComponent(Bill.PAYMENT_TERMS[paymentTerm]))
        .withLabel("Payment term");
    this.locale = new Field().asString()
        .withLabel("Preferred language")
        .withOptions(Object.keys(LOCALES))
        .withCellFactory(localeCellFactory)
        .withSetterCallback(localeSetterCallback)
        .withEditorFactory(getLocaleDropdownFactory());
    this.finvoiceIdentifier = new Field().asString().withLabel("Finvoice Identifier");
    this.finvoiceIntermediator = new Field().asString().withLabel("Finvoice Intermediator");
    this.contactGroup = new Field(ContactGroup).withLabel("Group");
    this.userRole = new Field(UserRole).withLabel("User");
    this.salesPriceRules = new Field([SalesPriceRule]).withLabel("Sales Price Rules").withEditorFactory(getDropDownFactory({
        searchable: false
    }));
    this.businessId = new Field().asString().withLabel("Business ID");
    this.vatNumber = new Field().asString().withLabel("VAT number");
    this.fullAddress = new Field()
        .asVirtual(this.city, (contact) => [
            contact.street,
            contact.zipCode,
            contact.city
        ].filter(part => part !== null).join(" ").trim())
        .withLabel("Address");

    this.getNameString = function () {
        return this.name || this.companyName || this.personName;
    };
    setDefaults(this, {
        orderByField: this.name,
        itemFactory: (contact) => {
            return new Container(function () {
                if (contact?.contactGroup?.icon) {
                    this.icon = createColorIcon(contact?.contactGroup?.icon, contact?.contactGroup?.color);
                }
                this.text = new TextComponent(contact.getNameString(), HTML.SPAN, ["ml-1"]);
            });
        },
        linkFactory: (contact) => createLink(`/${contact.app.code}/contacts/${contact.code}`, contact.getNameString(),
            contact?.contactGroup?.icon, contact?.contactGroup?.color),
        filterFields: [this.name, this.personName, this.companyName],
        defaultField: this.name
    });
    this.canDelete = new Method(function () {
        return SessionHandler.getRolesForApp(this.app).some(userRole => UserRole.ROLE_TYPES.EMPLOYEE !== userRole.role);
    });
    linguist.withAudience(this);
}, "Contact", "contacts");


export default Contact;
