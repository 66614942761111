import Field from "../../utils/Field.js";
import Container from "juis-components/Container.js";
import {getLocaleDropdownFactory} from "../translations/localeDropdownFactory.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import heweconUserLexicons from "./lexicons/hewecon-user/heweconUserLexicons.js";
import LOCALES from "../translations/locales.js";
import localeCellFactory from "../translations/localeCellFactory.js";
import UserRole from "./UserRole.js";
import Method from "../../utils/Method.js";
import Optional from "../../../lib/JuiS/Optional.js";
import GravatarImage from "../components/user-batch/GravatarImage.js";
import setDefaults from "./utils/setDefaults.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import {createTextNode} from "juis-components/ComponentUtils.js";

const linguist = new Linguist(heweconUserLexicons);
export default function () {
    this.id = new Field().asReadOnly().asNumber();
    this.username = new Field().asReadOnly().asString();
    this.appCodeList = new Field();
    this.publicName = new Field().asString();
    this.multiAvatarCode = new Field().asString();
    this.gravatarHash = new Field().asString().asReadOnly();
    this.pdfNotificationSeen = new Field().asBoolean();
    this.anonymous = new Field().asBoolean();
    this.silentMode = new Field().asBoolean();
    this.language = new Field().asString()
        .withOptions(Object.keys(LOCALES))
        .withCellFactory(localeCellFactory)
        .withEditorFactory(getLocaleDropdownFactory());

    this.nameBatch = new Field().asVirtual(this.publicName).withCellFactory((ignore, user) => {
        return new Container((container) => {
            if (user.publicName) {
                container.name = this.publicName.getCell(user);
            } else {
                container.name = createTextNode(user.username);
            }
            user.getAvatar(32).then(optionalComponent => optionalComponent.peek(avatar => {
                avatar.getNode().addWrapperCssClass(BULMA_CSS_CLASSES.IS_INLINE_BLOCK);
                avatar.getNode().addWrapperCssClass("mr-3");
                avatar.getNode().setStyle("vertical-align", "middle");
                container.insertBefore(avatar);
                container.name.getNode().setStyle("vertical-align", "bottom");
            }));
        });
    });
    this.roles = new Field([UserRole]);
    this.getAvatar = new Method(function (size = 80) {
        if (!this.gravatarHash) {
            return Promise.resolve(Optional.of());
        }
        return fetch(`https://s.gravatar.com/avatar/${this.gravatarHash}?d=404&s=${size}`)
            .then(response => {
                if (!response.ok) {
                    return Optional.of();
                }
                return response.blob().then(imageData => {
                    return Optional.of(new GravatarImage(function () {
                        this.imageData = imageData;
                        this.size = size;
                    }));
                });
            })
            .catch((e) => {
                console.error("Could not fetch from gravatar", e);
                return Optional.of();
            });
    });
    linguist.withAudience(this);
    setDefaults(this, {defaultField: this.nameBatch});
};
