import {extendComponent} from "juis-components/ComponentUtils.js";
import Input from "../../bulma_components/Input.js";
import {createIcon} from "../../bulma_components/Icon.js";
import Base from "../dropdown/Base.js";
import icons from "./icons.json";
import Container from "juis-components/Container.js";
import TextComponent from "../TextComponent.js";

export default extendComponent(Base, function () {
    let tags = [];

    const iconFactory = key => {
        let icon = createIcon(key);
        icon.isLarge = true;
        icon.getNode().setStyle("display", "inline-block");
        // Reset font-size to same as set by icon because dropdown-item css class will change it
        icon.getNode().setStyle("font-size", "1.33333em");
        return icon;
    };

    this.setItemFactory(iconFactory);

    const addTag = (key) => {
        let tag = new Container(function () {
            this.icon = createIcon(key);
            this.text = new TextComponent(key);
            this.icon.getNode().setStyle("margin-left", "0.5em");
            this.icon.getNode().setStyle("margin-right", "0.5em");
        });
        tags.push(tag);
        this.insertBefore(tag, searchInput || this.triggerButton);
        if (searchInput) {
            searchInput.setValue("");
            this.search("");
        }
    };

    this.overrideMethod("search", (overridden, searchString) => {
        this.showDropdown();
        return overridden.call(this, searchString);
    });
    this.getNode().removeAttribute("tabindex");
    let searchInput = new Input((input) => {
        input.on("input", () => this.search(input.getValue()));
        input.dontPropagate("change");
        input.registerDomEvents("keydown");
        input.on("keydown", function (eventData, event) {
            switch (eventData.keyCode) {
                case 27: // Escape
                    input.setValue("");
                    break;
                case 8: // Backspace
                    if (input.getValue() !== "") {
                        event.stopPropagation();
                    }
                    break;
            }
        });
        input.getNode().removeCssClass("input");
    }, ["juis-select-search"]);
    this.insertBefore(searchInput, this.triggerButton);


    const toggleSearch = () => {
        if (!searchInput) {
            return
        }
        if (this.getValue()) {
            searchInput.getNode().togglePlaceholder(true, "Search input");
            this.getNode().setAttribute("tabindex", "0");
        } else {
            searchInput.getNode().togglePlaceholder(false);
            this.getNode().setAttribute("tabindex", "-1");
            searchInput.focus();
        }
    };
    toggleSearch();

    this.setOptionsLoader((searchString) => {
        return Promise.resolve(icons.filter(iconName => iconName.includes(searchString.toLowerCase())));
    });

    this.on("change", () => {
        tags.forEach(tag => this.removeChild(tag));
        tags = [];
        this.getSelection().forEach(addTag);
        toggleSearch();
    });
    this.on("expand", (ignore, event) => event.stopPropagation());
    this.on("collapse", (ignore, event) => event.stopPropagation());
});
