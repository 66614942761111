import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {getPriceWithVatEditorFactory} from "../../components/fieldEditors/editorFactories.js";
import App from "./App.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import productLexicons from "./lexicons/product/productLexicons.js";
import setDefaults from "./utils/setDefaults";
import VatGroup from "./VatGroup";

let linguist = new Linguist(productLexicons);

let ProductPrice = new Model(function () {
    this.app = new Field(App);
    this.priceIncludesVat = new Field().asBoolean().withLabel("Price includes VAT");
    this.vatGroup = new Field(VatGroup).asRequired().withLabel("Vat Group");
    this.currencyCode = new Field().asCurrency();
    this.enteredPrice = new Field().asMoney(this.currencyCode).withLabel("Price")
        .withEditorFactory(getPriceWithVatEditorFactory(this.priceIncludesVat, this.vatPercentage, this.currencyCode));
    this.priceVatIncluded = new Field().asMoney(this.currencyCode).withLabel("Price incl VAT").asReadOnly();
    this.priceVatExcluded = new Field().asMoney(this.currencyCode).withLabel("Price excl VAT").asReadOnly();


    this.on(ProductPrice.EVENTS.CHANGE, (event) => {
        let productPrice = event.entity;
        if (!productPrice.id && productPrice.app && event.field === this.app) {
            if (productPrice.vatPercentage === undefined) {
                productPrice.vatPercentage = productPrice.app.defaultVat;
                productPrice.priceIncludesVat = productPrice.app.pricesIncludeVat;
            }
            if (productPrice.currencyCode === undefined) {
                productPrice.currencyCode = productPrice.app.currencyCode;
            }
        }
    });

    this.getEmpty = (app) => {
        let empty = new ProductPrice();
        empty.app = app;
        empty.priceIncludesVat = app.pricesIncludeVat;
        empty.vatGroup = app.defaultVat;
        empty.currencyCode = app.currencyCode;
        return empty;
    };

    setDefaults(this, {
        defaultField: this.enteredPrice,
        editorFactory: (parentField, parentEntity, asd) => {
            let enteredPriceWithOverrides = {};
            Object.assign(enteredPriceWithOverrides, this.enteredPrice);
            Object.assign(enteredPriceWithOverrides, {readOnly: parentField.readOnly, getLabel: parentField.getLabel});
            let productPrice = parentField.getValueFor(parentEntity);
            if (productPrice === null) {
                productPrice = this.getEmpty(parentEntity.app);
                parentEntity[parentField.getName()] = productPrice;
            }
            return getPriceWithVatEditorFactory(this.priceIncludesVat, this.vatGroup, this.currencyCode)(enteredPriceWithOverrides, productPrice);
        }
    });
    linguist.withAudience(this);
}, "ProductPrice");

export default ProductPrice;
