import RestServer, {generateHeadersForListRequest, generateIsCachedHeader, getRestApiUrl} from "./RestServer.js";
import EntityCache from "./EntityCache.js";
import Field from "./Field.js";
import Filter from "./Filter.js";
import Optional from "../../lib/JuiS/Optional.js";
import Aggregation from "./Aggregation.js";
import {createIcon} from "../bulma_components/Icon.js";
import Container from "juis-components/Container.js";
import BULMA from "../bulma_components/bulmaCssClasses.js";
import SessionHandler, {SESSION_HANDLER_EVENTS} from "../SessionHandler.js";
import {getFirstLevelFields} from "./fieldUtils.js";
import App from "../hewecon/models/App.js";
import createArrayProxy from "./createArrayProxy.js";
import HeweconUser from "../hewecon/models/HeweconUser.js";
import SuggestionFilter from "./SuggestionFilter.js";
import {concatenateUrls} from "juis-router/NavigationUtils.js";
import Listenable from "juis-commons/Listenable.js";
import {NOT_FOUND, NOT_UNIQUE} from "juis-commons/Errors.js";
import LexiconEntry from "../hewecon/models/LexiconEntry.js";
import Method from "./Method.js";
import LazyFieldLoader from "./LazyFieldLoader.js";

const METADATA = "metadata";
const DATA = "data";
let excludeFromCachedDescendants = [];
const MODELS_BY_NAME = {};

function RestModel(callback, name, url) {
    const fields = {};
    const aggregations = {};
    const model = this;
    const handler = {};
    let translatable = false;
    const modelProxy = new Proxy(function () {
    }, handler);
    const getNextUnsavedIdentifier = (() => {
        let counter = 0;
        return () => "unsaved-" + name + "-" + ++counter;
    })();

    this.getEndpointUrl = () => url;

    const assertWriteEnabled = function (fieldName) {
        if (fields[fieldName].readOnly === true) {
            throw new TypeError(`Field ${fieldName} on model ${name} is readonly`);
        }
    };

    const postInstance = (instance, settings = {}) => {
        let content = {id: instance.getId(), ...instance.getDirtyProperties()};
        return RestServer.post(settings.url || url, content, generateIsCachedHeader(modelProxy))
            .then(response => {
                this.invalidateListFetcherCache();
                return response;
            });
    };

    this.getExcludeFromCache = () => [];

    const separateFiles = (content) => {
        let result = {
            files: Object.fromEntries(Object.entries(content).filter(entry => entry[1] instanceof File)),
            properties: Object.fromEntries(Object.entries(content)
                .filter(entry => (entry[0] !== "extraFields"))
                .filter(entry => !(entry[1] instanceof File)))
        };
        if (content["extraFields"]) {
            let separatedExtraFields = separateFiles(content["extraFields"]);
            Object.entries(separatedExtraFields["files"]).forEach(([key, value]) => {
                result.files["extra-fields/" + key] = value;
            });
            result.properties = {...result.properties, extraFields: separatedExtraFields.properties};
        }
        return result;
    };

    const patchInstance = (instance, proxy, settings = {}) => {
        let {files, properties} = separateFiles(instance.getDirtyProperties());
        let excludedFromCache = this.getExcludeFromCache(proxy);
        return RestServer.patch(settings.url || url, instance.getId(), {id: instance.getId(), ...properties}, generateIsCachedHeader(modelProxy, excludedFromCache))
            .then(response => {
                this.invalidateListFetcherCache();
                return Promise.all(Object.entries(files)
                    .map(entry => RestServer.upload(getRestApiUrl(url, instance.getId(), entry[0]), entry[1]))
                ).then((responseList) => {
                    return responseList[responseList.length - 1] ?? response;
                });
            });
    };

    const deleteInstance = (instance) => {
        return RestServer.delete(url, instance.getId())
            .then((response) => getInstance(response.data))
            .then(response => {
                this.invalidateListFetcherCache();
                this.cache.remove(response);
                return response;
            });
    };

    this.EVENTS = {
        BEFORE_SAVE: "beforeSave",
        LOAD: "load",
        REFRESH: "refresh",
        CHANGE: "change",
        AFTER_UPDATE: "afterUpdate",
        AFTER_INSERT: "afterInsert",
        AFTER_DELETE: "afterDelete",
        AFTER_INVALIDATE_LIST_CACHE: "afterInvalidateListCache",
        INITIALIZED: "initialized",
        CREATE: "create"
    };

    const instanceProxies = new WeakSet();

    this.createInstance = function (newCleanProperties = {}, createContext) {
        let dirtyProperties = {};
        let cleanProperties = {};
        const instance = {};
        let isLocked = false;
        let isDeleted = false;

        instance.isLockedForRequest = () => isLocked;
        instance.lockForRequest = () => {
            if (isLocked) {
                throw new Error(`Instance of ${model.getName()} is already locked`);
            }
            isLocked = true;
            instance.getCascadingEntities(false)
                .forEach(entity => entity.lockForRequest());
        };
        instance.unlockAfterRequest = () => {
            isLocked = false;
            instance.getCascadingEntities(false).forEach(entity => entity.unlockAfterRequest());
        };

        const setDefaultFieldValues = (newCleanProperties = cleanProperties) => {
            if (instance.hasId()) {
                return;
            }
            const fieldsWithValues = [...Object.keys(dirtyProperties), ...Object.keys(newCleanProperties)];
            this.getFields()
                .filter(field => field.hasDefaultValue())
                .filter(field => !fieldsWithValues.includes(field.getName()))
                .forEach(field => dirtyProperties[field.getName()] = field.getDefaultValue(proxy));
        };

        const assertNotLocked = function () {
            if (isLocked) {
                throw new Error(
                    `Instance #${instance.getId()} of ${name} is locked due to a pending request to the server`);
            }
        };

        const assertNotDeleted = function () {
            if (isDeleted) {
                throw new Error(`Instance #${instance.getId()} of ${name} is deleted`);
            }
        };

        const isEqual = function (a, b) {
            if (a instanceof RestModel && b instanceof RestModel) {
                return a.getModel() === b.getModel() && a.id === b.id;
            }
            return a === b;
        };

        instance.validate = function () {
            Object.values(fields)
                .filter(field => this.hasDirtyField(field))
                .forEach(field => field.validate(proxy));
            Object.values(fields)
                .filter(field => field.isCascading())
                .filter(field => instance.hasValue(field))
                .flatMap(field => field.isModelList() ? proxy[field.getName()] : [proxy[field.getName()]])
                .filter(child => child !== null)
                .forEach(child => child.validate());
        };

        instance.equals = function (other) {
            return isEqual(this, other);
        };

        let lazy = false;
        instance.setLazy = (lazyState) => {
            lazy = lazyState;
        };
        instance.isLazy = (field) => {
            if (lazy || !field) {
                return lazy;
            }
            return field.getModelType() && instance.hasId() && !field.isVirtual() && !instance.hasLoadedValue(field);
        };

        let hasInvalidCache = false;
        instance.invalidateCache = () => {
            hasInvalidCache = true;
        };
        instance.hasInvalidCache = () => hasInvalidCache;

        const handleItemLists = (modelList, propertyName) => {
            const field = fields[propertyName];
            if (Array.isArray(modelList)) {
                if (field.hasSortFunction()) {
                    modelList = modelList.sort(field.getSortFunction());
                }
                return createArrayProxy(modelList, () => {
                    let entity = proxy;
                    let value = modelList;
                    proxy.trigger(model.EVENTS.CHANGE, {field, value, entity});
                    modelProxy.trigger(model.EVENTS.CHANGE, {field, value, entity});
                    dirtyProperties[propertyName] = modelList;
                }, field.getNameField(), field.shouldCreateNamedEntities());
            }
            return modelList;
        };

        const assignDirtyValue = function (propertyName, value) {
            const cleanValue = cleanProperties[propertyName];
            const oldValue = dirtyProperties[propertyName] || cleanValue;
            const field = fields[propertyName];
            const entity = proxy;
            if (field.hasSetterCallback()) {
                value = field.getSetterCallback().call(field, value, entity);
            }
            if (value === undefined) {
                console.error(`Cannot set undefined on field ${propertyName} on model ${name}`);
            } else {
                dirtyProperties[propertyName] = value;
            }
            proxy.trigger(model.EVENTS.CHANGE, {field, value, oldValue, cleanValue, entity});
            modelProxy.trigger(model.EVENTS.CHANGE, {field, value, oldValue, cleanValue, entity});
        };

        const set = function (target, propertyName, newValue) {
            if (typeof propertyName === "symbol") {
                return Reflect.set(target, propertyName, newValue);
            }
            if (propertyName.includes(".")) {
                const propertyNameParts = propertyName.split(".");
                const realPropertyName = propertyNameParts.pop();
                const realTarget = propertyNameParts.reduce((entity, member) => {
                    if (entity !== null && entity !== undefined) {
                        let currentTarget = Reflect.get(entity, member);
                        if (currentTarget === null && entity.getModel()[member]?.hasDefaultValue()) {
                            return entity.getModel()[member].getDefaultValue(entity);
                        }
                        return currentTarget;
                    } else {
                        return null;
                    }
                }, proxy);
                return Reflect.set(realTarget, realPropertyName, newValue);
            }
            if (!fields.hasOwnProperty(propertyName)) {
                return Reflect.set(target, propertyName, newValue);
            }
            if (!isEqual(cleanProperties[propertyName], newValue) || !isEqual(dirtyProperties[propertyName], newValue)) {
                assertNotDeleted();
                if (instance.isLockedForRequest() && !fields[propertyName].isVirtual()) {
                    throw new Error(
                        `Cannot write "${newValue}" to ${propertyName} on ${name} while it has a pending server request`);
                }
                assertWriteEnabled(propertyName);
                assignDirtyValue(propertyName, newValue);
            }
            return true;
        };

        instance.getCleanValueForField = function (field) {
            return cleanProperties[field.getName()];
        };
        const getValueForField = function (field) {
            let value;
            if (field.hasValueCallback()) {
                return field.getValue(proxy);
            }
            if (field.isVirtual()) {
                return dirtyProperties[field.getName()];
            }
            if (dirtyProperties.hasOwnProperty(field.getName())) {
                value = dirtyProperties[field.getName()];
            } else {
                if (lazy && !instance.hasValue(field)) {
                    throw new Error(`Cannot access field '${field.getName()}' on a lazy loaded ${model.getName()}.`);
                }
                value = cleanProperties[field.getName()];
            }
            if (value === undefined) {
                if (proxy.hasId()) {
                    console.error(`Cannot access lazy field ${field.getName()} on ${name}`);
                } else if (field.hasDefaultValue()) {
                    value = field.getDefaultValue(proxy);
                    dirtyProperties[field.getName()] = value;
                } else if (Array.isArray(field.getType())) {
                    value = [];
                    dirtyProperties[field.getName()] = value;
                }
            }
            value = field.coerce(value);
            value = handleItemLists(value, field.getName());
            if (field.getType() === Field.JSON) {
                let entity = proxy;
                value = new Proxy(value || {}, {
                    set: (target, p, value) => {
                        let returnValue = Reflect.set(target, p, value);
                        proxy.trigger(model.EVENTS.CHANGE, {field, target, entity});
                        modelProxy.trigger(model.EVENTS.CHANGE, {field, target, entity});
                        dirtyProperties[field.getName()] = target;
                        return returnValue;
                    }
                });
            }
            return value;
        };

        const valuePromises = {};
        instance.refreshLazyField = function (field) {
            return getLazyValueForField(field, true);
        };
        const getLazyValueForField = function (field, forceReload = false) {
            if (!forceReload && (instance.hasLoadedValue(field) || !instance.hasId())) {
                return Promise.resolve(getValueForField(field));
            }
            if (!forceReload && valuePromises[field.getName()]) {
                return valuePromises[field.getName()];
            }
            const relatedModel = field.getModelType();
            if (field.hasForeignRelationField()) {
                valuePromises[field.getName()] = relatedModel.getAll(Filter.eq(field.getForeignRelationField(), proxy));
            } else if (instance.hasValue(field)) {
                let child = getValueForField(field);
                if (Array.isArray(field.getType())) {
                    const idList = child.map(lazyEntity => lazyEntity.id);
                    valuePromises[field.getName()] = relatedModel.getAll(Filter.in("id", idList)).then(() => child);
                } else {
                    valuePromises[field.getName()] = child.reload().then(() => child);
                }
            } else {
                valuePromises[field.getName()] = model.fetchCustomEndpoint(proxy.id, field.getName())
                    .then(json => field.getModelType().createInstance(json));
            }
            return valuePromises[field.getName()].then(value => {
                const newCleanProperties = {};
                newCleanProperties[field.getName()] = value;
                setCleanProperties(newCleanProperties);
                return value;
            });
        };

        let get = function (target, fieldName) {
            if (typeof fieldName != "symbol" && fieldName.includes(".")) {
                return fieldName.split(".").reduce((value, member) => {
                    if (value !== null && value !== undefined) {
                        const reflectedValue = Reflect.get(value, member);
                        if (reflectedValue === undefined && Array.isArray(value)) {
                            return value.map(v => Reflect.get(v, member));
                        }
                        return reflectedValue;
                    } else {
                        return null;
                    }
                }, proxy);
            }
            if (fields.hasOwnProperty(fieldName)) {
                let field = fields[fieldName];
                return getValueForField(field);
            }
            if (fieldName.startsWith && fieldName.startsWith("$")) {
                let field = fields[fieldName.substring(1)];
                if (!field) {
                    throw new Error("Unknown lazy field: " + fieldName);
                }
                return getLazyValueForField(field);
            }
            if (instance.hasOwnProperty(fieldName) || typeof fieldName === "symbol") {
                return Reflect.get(instance, fieldName);
            }
            if (methods.hasOwnProperty(fieldName)) {
                const method = Reflect.get(methods, fieldName);
                return (...args) => method.run(proxy, ...args);
            }
            return Reflect.get(model, fieldName);
        };

        let getPrototypeOf = function (target) {
            return modelProxy;
        };

        const proxy = new Proxy(instance, {set, get, getPrototypeOf});
        instanceProxies.add(proxy);
        const handleResponseData = (newCleanProperties, event) => {
            const listeners = this.getFields()
                .filter(field => field.isCascading())
                .map(field => field.getModelType())
                .filter((type, index, self) => self.findIndex(otherType => otherType === type) === index) // Distinct
                .map((childModel => {
                    // Trigger same event in cascading children that are refreshed
                    return childModel.on(this.EVENTS.REFRESH, (child) => {
                        if (child !== proxy) {
                            child.trigger(event);
                            childModel.trigger(event, child);
                        }
                    });
                }));
            instance.refresh(newCleanProperties);
            listeners.forEach(listener => listener.destruct());
            dirtyProperties = {};
            instance.unlockAfterRequest();
            modelProxy.trigger(event, proxy);
            proxy.trigger(event);
            return proxy;
        };

        const handleRequestError = e => {
            instance.unlockAfterRequest();
            throw e;
        };

        const validateResponse = response => {
            if (response.error) {
                instance.unlockAfterRequest();
                throw response.error;
            }
            return response;
        };

        const setCleanProperties = (newCleanProperties, refreshContext) => {
            let ownContext = false;
            if (!refreshContext) {
                ownContext = true;
                refreshContext = getRefreshContext();
            }
            const shouldTriggerChangeEvents = newCleanProperties["modified"] !== cleanProperties["modified"] || newCleanProperties["modified"] === undefined;
            Object.entries(newCleanProperties)
                .filter(entry => fields.hasOwnProperty(entry[0]))
                .forEach(entry => {
                    const [fieldName, value] = entry;
                    if (value === undefined) {
                        return;
                    }
                    const field = fields[fieldName];
                    const hasOldValue = dirtyProperties[fieldName] !== undefined || cleanProperties[fieldName] !== undefined;
                    const oldValue = dirtyProperties[fieldName] ?? cleanProperties[fieldName];
                    let modelType = field.getModelType();
                    if (value && modelType && !(value instanceof RestModel)
                        && !(Array.isArray(value) && value[0] instanceof RestModel)) {
                        let instantiatedValue;
                        if (Array.isArray(field.getType())) {
                            instantiatedValue = value.map(child => new modelType(child, refreshContext));
                        } else {
                            instantiatedValue = new modelType(value, refreshContext);
                        }
                        cleanProperties[fieldName] = instantiatedValue;
                    } else {
                        cleanProperties[fieldName] = newCleanProperties[fieldName];
                    }
                    delete dirtyProperties[fieldName];
                    if (shouldTriggerChangeEvents && hasOldValue && cleanProperties[fieldName] !== oldValue) {
                        if (Array.isArray(cleanProperties[fieldName]) && Array.isArray(oldValue)) {
                            if (cleanProperties[fieldName].some((value, index) =>
                                    value !== oldValue[index] || (
                                        value instanceof RestModel && !value.isLazy() && value?.modified !== oldValue[index]?.modified
                                    )
                            )) {
                                refreshContext.addChange(proxy, field, oldValue);
                            }
                        } else if (field.getType() === Field.JSON) {
                            if (JSON.stringify(cleanProperties[fieldName]) !== JSON.stringify(oldValue)) {
                                refreshContext.addChange(proxy, field, oldValue);
                            }
                        } else {
                            refreshContext.addChange(proxy, field, oldValue);
                        }
                    }
                });
            if (ownContext) {
                refreshContext.setDone();
            }
        };

        instance.getId = () => cleanProperties["id"] || 0;

        instance.hasId = () => !!instance.getId();

        instance.isDirty = (ancestors = []) => {
            if (ancestors.includes(instance)) {
                return false;
            }
            ancestors.push(instance);
            return Object.keys(dirtyProperties).length > 0
                || Object.entries(cleanProperties)
                    .filter(entry => fields[entry[0]].isCascading())
                    .map(entry => entry[1])
                    .filter(property => property instanceof RestModel)
                    .some(childEntity => childEntity.isDirty(ancestors))
                || Object.entries(cleanProperties)
                    .filter(entry => fields[entry[0]].isCascading())
                    .map(entry => entry[1])
                    .filter(property => Array.isArray(property))
                    .some(childEntityArray => childEntityArray
                        .filter(childEntity => childEntity instanceof RestModel)
                        .some(childEntity => childEntity.isDirty(ancestors)));
        };

        instance.makeDirty = () => Object.entries(cleanProperties).forEach(entry => {
            assignDirtyValue(entry[0], entry[1]);
            if (entry[1] instanceof RestModel && !entry[1].hasId()) {
                entry[1].makeDirty();
            } else if (Array.isArray(entry[1]) && entry[1][0] instanceof RestModel) {
                entry[1].forEach(childEntry => {
                    if (!childEntry.hasId()) {
                        childEntry.makeDirty();
                    }
                });
            }
        });

        instance.resetField = (field) => {
            if (!instance.hasId() || !instance.hasDirtyField(field)) {
                return;
            }
            let fieldName = field instanceof Field ? field.getName() : field;
            if (fieldName.includes(".")) {
                const propertyNameParts = fieldName.split(".");
                const realFieldName = propertyNameParts.pop();
                const realTarget = propertyNameParts.reduce((model, member) => {
                    if (model !== null && model !== undefined) {
                        return Reflect.get(model, member);
                    } else {
                        return null;
                    }
                }, proxy);
                realTarget.resetField(realFieldName);
            } else {
                let oldValue = proxy[fieldName];
                delete dirtyProperties[fieldName];
                const cleanValue = proxy[fieldName];
                const value = cleanValue;
                const entity = proxy;
                proxy.trigger(this.EVENTS.CHANGE, {field, value, oldValue, cleanValue, entity});
            }
        };

        instance.revert = () => {
            const oldValues = {...dirtyProperties};
            dirtyProperties = {};
            Object.entries(oldValues).forEach(entry => {
                let [propertyName, oldValue] = entry;
                const cleanValue = cleanProperties[propertyName];
                const value = cleanValue;
                const field = fields[propertyName];
                const entity = proxy;
                proxy.trigger(this.EVENTS.CHANGE, {field, value, oldValue, cleanValue, entity});
            });
        };

        instance.getDirtyProperties = (exclude = []) => {
            const dirtyChildren = {};
            // Exclude self from dirty child checks in case children has a back reference to this entity
            exclude.push(proxy);
            Object.entries(cleanProperties)
                .filter(entry => dirtyProperties[entry[0]] === undefined)
                .filter(entry => !fields[entry[0]].isVirtual())
                .filter(entry => fields[entry[0]].isCascading())
                .forEach(entry => {
                    if (Array.isArray(entry[1])) {
                        let [fieldName, entities] = entry;
                        let children = entities
                            .filter(entity => !exclude.includes(entity))
                            .filter(entity => entity.isDirty);
                        if (children.some(child => child.isDirty())) {
                            if (!dirtyChildren[fieldName]) {
                                dirtyChildren[fieldName] = [];
                            }
                            children.forEach(entity => {
                                let id = entity.id;
                                let dirtyChildProps = entity.isDirty() ? entity.getDirtyProperties(exclude) : id;
                                dirtyChildren[fieldName].push(dirtyChildProps);
                            });
                        }
                    } else if (entry[1] && !exclude.includes(entry[1]) && entry[1].isDirty && entry[1].isDirty()) {
                        let [fieldName, entity] = entry;
                        dirtyChildren[fieldName] = entity.getDirtyProperties(exclude);
                    }
                });
            if (Object.values(dirtyProperties).length === 0 && Object.values(dirtyChildren).length === 0) {
                if (!cleanProperties.id) {
                    throw new Error(`Clean ${model.getName()}-entity found but it is without ID`);
                }
                return cleanProperties.id;
            }
            let handledDirtyProperties = {};
            Object.entries(dirtyProperties).forEach(entry => {
                let field = fields[entry[0]];
                if (field.isVirtual() && !field.isCascading()) {
                    return;
                }
                let value = entry[1];
                if (value instanceof RestModel) {
                    if (field.isCascading()) {
                        value = value.getDirtyProperties(exclude);
                    } else if (value.id !== undefined) {
                        value = value.id;
                    } else {
                        // If the id is undefined the entity must be unsaved and should be ignored here.
                        return;
                    }
                }
                if (Array.isArray(value)) {
                    value = value.map(arrayValue => {
                        if (arrayValue instanceof RestModel) {
                            if (field.isCascading()) {
                                return arrayValue.getDirtyProperties(exclude);
                            } else {
                                return arrayValue.id || null;
                            }
                        }
                        return arrayValue;
                    });
                }
                handledDirtyProperties[entry[0]] = fields[entry[0]].coerce(value);
            });
            return {id: cleanProperties.id, ...handledDirtyProperties, ...dirtyChildren};
        };

        instance.reloadPromise = null;
        instance.reload = function () {
            if (!instance.reloadPromise) {
                instance.reloadPromise = loadCleanProperties(proxy.id, proxy.getCascadingEntities())
                    .then(getInstance)
                    .catch(error => {
                        if (error.message === NOT_FOUND) {
                            proxy.deleted = true;
                            model.cache.remove(proxy);
                            model.invalidateListFetcherCache();
                            modelProxy.trigger(model.EVENTS.AFTER_DELETE, proxy);
                            proxy.trigger(model.EVENTS.AFTER_DELETE, proxy);
                            return proxy;
                        }
                        throw error;
                    })
                    .finally(() => instance.reloadPromise = null);
            }
            return instance.reloadPromise;
        };

        const triggerBeforeSave = (instance) => {
            modelProxy.trigger(model.EVENTS.BEFORE_SAVE, instance);
            instance.trigger(model.EVENTS.BEFORE_SAVE, instance);
            instance.getCascadingEntities()
                .filter(entity => entity.isDirty())
                .forEach(child => child.trigger(model.EVENTS.BEFORE_SAVE, instance));
        };

        instance.save = function (settings = {}) {
            if (!instance.isDirty()) {
                return Promise.resolve(this);
            }
            try {
                assertNotDeleted();
                setDefaultFieldValues();
                instance.validate();
                assertNotLocked();
            } catch (error) {
                return Promise.reject(error);
            }
            triggerBeforeSave(this);
            instance.lockForRequest();
            if (instance.hasId()) {
                return patchInstance(instance, proxy, settings)
                    .catch(handleRequestError)
                    .then(validateResponse)
                    .then(response => response[DATA])
                    .then(newCleanProperties => handleResponseData(newCleanProperties, model.EVENTS.AFTER_UPDATE));
            } else {
                return postInstance(instance, settings)
                    .catch(handleRequestError)
                    .then(validateResponse)
                    .then(response => response[DATA])
                    .then(newCleanProperties => {
                        cleanProperties.id = newCleanProperties.id;
                        if (newCleanProperties.code) {
                            cleanProperties.code = newCleanProperties.code;
                        }
                        delete dirtyProperties.id;
                        delete dirtyProperties.code;
                        // We should add the instance to the cache before resolving other properties as those might have
                        // back references that would lead to new instances being created if this is not already cached.
                        model.cache.add(proxy);
                        return handleResponseData(newCleanProperties, model.EVENTS.AFTER_INSERT);
                    });
            }
        };

        instance.delete = function () {
            assertNotLocked();
            instance.lockForRequest();
            if (instance.hasId()) {
                return deleteInstance(instance)
                    .catch(handleRequestError)
                    .then(validateResponse)
                    .then(() => isDeleted = true)
                    .then(() => instance.unlockAfterRequest())
                    .then(() => modelProxy.trigger(model.EVENTS.AFTER_DELETE, proxy))
                    .then(() => proxy.trigger(model.EVENTS.AFTER_DELETE));
            }
        };

        instance.copy = function (excludeFields = [], defaultProperties = {}) {
            let underlyingObject = {...this.getProperties(true)};
            excludeFields.forEach(name => delete underlyingObject[name.getName()]);
            let copy = this.createInstance({...underlyingObject, ...defaultProperties});
            copy.makeDirty();
            return copy;
        };

        instance.hasDirtyField = function (field) {
            return !this.hasId() || this.getDirtyProperties().hasOwnProperty(field.getName());
        };

        instance.hasValue = function (field) {
            return dirtyProperties.hasOwnProperty(field.getName()) ||
                cleanProperties.hasOwnProperty(field.getName());
        };
        instance.hasCode = function () {
            return cleanProperties.hasOwnProperty("code");
        };

        instance.hasLoadedValue = function (field) {
            if (this.hasValue(field)) {
                let value = getValueForField(field);
                if (Array.isArray(value)) {
                    return !value.some(child => child.isLazy());
                }
                if (value instanceof RestModel) {
                    return !value.isLazy();
                }
                return value !== undefined;
            }
            return false;
        };

        instance.loadLazyFields = function () {
            const loader = new LazyFieldLoader(this);
            return loader.loadLazyValues();
        };

        instance.getOldValue = function (field) {
            return cleanProperties[field.getName()];
        };

        instance.unlock = function (defer = true) {
            proxy.locked = false;
            proxy.getCascadingEntities().forEach(child => child.unlock());
            return proxy;
        };

        instance.getCascadingEntities = function (recursive = true) {
            return Object.entries(fields)
                .filter(entry => entry[1].isCascading() && instance.hasValue(entry[1]))
                .flatMap(entry => {
                    if (entry[1].isModelList()) {
                        let childList = proxy[entry[0]];
                        if (recursive) {
                            return [...childList, ...childList.flatMap(child => child.getCascadingEntities())];
                        } else {
                            return [...childList];
                        }
                    } else {
                        let child = proxy[entry[0]];
                        if (!child) {
                            return [];
                        }
                        if (recursive) {
                            return [child, ...child.getCascadingEntities()];
                        } else {
                            return [child];
                        }
                    }
                });
        };

        instance.getProperties = (excludeReadOnly = false) => {
            let obj = {...cleanProperties, ...dirtyProperties};
            delete obj.id;
            delete obj.code;
            delete obj.modified;
            if (excludeReadOnly) {
                Object.values(fields)
                    .filter(field => field.isReadOnly())
                    .forEach(field => delete obj[field.getName()]);
            }

            Object.entries(obj).forEach(entry => {
                const [key, value] = entry;
                if (value instanceof RestModel && modelProxy[key].isCascading()) {
                    obj[key] = value.getProperties();
                } else if (Array.isArray(value)) {
                    obj[key] = value.map(arrayValue => {
                        if (arrayValue instanceof RestModel && modelProxy[key].isCascading()) {
                            return arrayValue.getProperties();
                        } else {
                            return arrayValue;
                        }
                    });
                }
            });
            return obj;
        };
        Listenable.apply(instance);

        const hasCleanProperty = (key) => cleanProperties.hasOwnProperty(key);

        let previousCleanProperties = [];
        const shouldTriggerRefresh = (refreshedProperties) => {
            if (Object.keys(refreshedProperties).every(key => !hasCleanProperty(key))) {
                return false;
            }
            return (refreshedProperties.modified && refreshedProperties.modified !== cleanProperties.modified) ||
                Object.entries(refreshedProperties).some(entry => {
                    const [key, refreshedValue] = entry;
                    const cleanValue = cleanProperties[key];
                    if (cleanValue === undefined) {
                        return true;
                    }
                    if (refreshedValue === cleanValue) {
                        return false;
                    }
                    if (cleanValue?.id) {
                        return !(cleanValue.id === refreshedValue || cleanValue.id === refreshedValue?.id);
                    }
                    if (typeof refreshedValue !== "object") {
                        return refreshedValue !== cleanValue;
                    }
                    return false; // Don't check into arrays and objects. We assume the modified timestamp has changed
                    // if such changes has been made
                });
        };
        instance.refresh = (refreshedProperties, refreshContext) => {
            if (previousCleanProperties.includes(refreshedProperties)) {
                // Avoid loops where the same object is being refreshed multiple times
                return;
            }
            previousCleanProperties.push(refreshedProperties);
            const triggerRefresh = shouldTriggerRefresh(refreshedProperties);
            setCleanProperties(refreshedProperties, refreshContext);
            hasInvalidCache = false;
            if (triggerRefresh) {
                let defer = refreshContext ? refreshContext.whenDone : Promise.resolve();
                defer.then(() => {
                    if (lazy) {
                        lazy = false;
                    }
                    proxy.trigger(model.EVENTS.REFRESH);
                    modelProxy.trigger(model.EVENTS.REFRESH, proxy);
                });

            }
        };

        const triggerChange = (field, value, oldValue, cleanValue) => {
            proxy.trigger(model.EVENTS.CHANGE, {field, value, oldValue, cleanValue, entity: proxy});
            proxy.getModel().trigger(model.EVENTS.CHANGE, {field, value, oldValue, cleanValue, entity: proxy});
        };

        instance.setProperty = (propertyName, value, settings = {}) => {
            if (settings.clean) {
                let oldValue = proxy[propertyName];
                cleanProperties[propertyName] = value;
                proxy.trigger(model.EVENTS.REFRESH);
                if (oldValue !== value) {
                    triggerChange(fields[propertyName], value, oldValue, value);
                }
                return;
            }
            proxy[propertyName] = value;
        };
        if (translatable) {
            instance.getTranslatedValueFor = (field, language) => {
                let fieldName = typeof field === "string" ? field : field.getName();
                const entry = proxy.lexiconEntries
                    .find(entry => entry.key === fieldName && entry.language === language);
                return entry?.translation || proxy[fieldName];
            };
        }

        let identifierWhenUnsaved;
        instance.getIdentifier = function () {
            if (this.hasId()) {
                return this.getId();
            }
            if (!identifierWhenUnsaved) {
                identifierWhenUnsaved = getNextUnsavedIdentifier();
            }
            return identifierWhenUnsaved;
        };

        instance.getModel = () => modelProxy;

        const copyAppToChildren = (app) => {
            proxy.getCascadingEntities()
                .filter(child => child.app === null)
                .forEach(child => {
                    console.log("Setting app for child " + child.getModel().getName());
                    child.app = app;
                });
        };

        setCleanProperties(newCleanProperties, createContext);
        proxy.on(this.EVENTS.CHANGE, (event) => {
            if (event.field.getName() === "app" && event.value instanceof App) {
                copyAppToChildren(event.value);
            }
            if (event.field.getModelType()) {
                if (event.field.isModelList()) {
                    event.value.forEach(child => {
                        if (child.app === null) {
                            child.app = proxy.app;
                        }
                    });
                } else {
                    if (event.value && event.value.app === null && proxy.app) {
                        event.value.app = proxy.app;
                    }
                }
            }
        });
        return proxy;
    };

    const getRefreshContext = () => {
        let resolveDonePromise;
        let donePromise = new Promise((innerResolve) => {
            resolveDonePromise = innerResolve;
        });
        donePromise.then(changes => {
            changes.forEach(change => {
                change.entity.trigger(model.EVENTS.CHANGE, {
                    field: change.field,
                    value: change.entity[change.field.getName()],
                    oldValue: change.oldValue,
                    cleanValue: change.entity[change.field.getName()],
                    entity: change.entity
                });
                change.entity.getModel().trigger(model.EVENTS.CHANGE, {
                    field: change.field,
                    value: change.entity[change.field.getName()],
                    oldValue: change.oldValue,
                    cleanValue: change.entity[change.field.getName()],
                    entity: change.entity
                });
            });
        });
        const changes = [];
        return {
            whenDone: donePromise,
            setDone: () => resolveDonePromise(changes),
            addChange: (entity, field, oldValue) => changes.push({entity, field, oldValue})
        };
    };

    const getInstance = (cleanProperties, refreshContext) => {
        if (cleanProperties === null) {
            return null;
        }
        if (typeof cleanProperties === "number") {
            let cachedEntity = this.cache.getById(cleanProperties);
            if (cachedEntity) {
                return cachedEntity;
            }
            let lazyInstance = this.createInstance({id: cleanProperties}, refreshContext);
            lazyInstance.setLazy(true);
            this.cache.add(lazyInstance);
            return lazyInstance;
        }
        let ownContext = false;
        if (!refreshContext) {
            ownContext = true;
            refreshContext = getRefreshContext();
        }
        let instance = Optional.of(this.cache.getById(cleanProperties["id"]))
            .peek((existingInstance => {
                existingInstance.refresh(cleanProperties, refreshContext);
            }))
            .orElseGet(() => {
                const identifierProps = {
                    id: cleanProperties["id"]
                };
                if (cleanProperties["code"]) {
                    identifierProps["code"] = cleanProperties["code"];
                }
                const newInstance = this.createInstance(identifierProps, refreshContext);
                if (newInstance.hasId()) {
                    this.cache.add(newInstance);
                }
                newInstance.refresh(cleanProperties, refreshContext);
                return newInstance;
            });
        if (ownContext) {
            refreshContext.setDone();
        }
        return instance;
    };

    this.getFields = () => Object.values(fields);
    this.getAggregations = () => Object.keys(aggregations);
    this.cache = new EntityCache(this);

    this.getFieldNames = (excludeTypes = []) => {
        return this.getFields()
            .filter(field => !field.isWriteOnly())
            .flatMap(field => {
                let fields = [field.getName()];
                let childModel = field.getModelType();
                if (childModel && !excludeTypes.includes(childModel)) {
                    fields.push(...childModel
                        .getFieldNames([field.getModelType(), ...excludeTypes])
                        .map(fieldName => field.getName() + "." + fieldName));
                }
                return fields;
            })
            .sort();
    };

    this.getName = () => name;
    this.getLabel = () => defaultField ? defaultField.getLabel() : name;

    let defaultOptionsLoader;
    this.setDefaultOptionsLoader = function (newDefaultOptionsLoader) {
        defaultOptionsLoader = newDefaultOptionsLoader;
    };
    this.getDefaultOptionsLoader = function () {
        return defaultOptionsLoader;
    };

    let defaultOrderBy;
    this.setDefaultOrderBy = function (newDefaultOrderBy) {
        defaultOrderBy = newDefaultOrderBy;
    };
    this.getOrderBy = function () {
        return defaultOrderBy;
    };

    let exportField;
    this.setExportField = function (newExportField) {
        exportField = newExportField;
    };
    let defaultField;
    this.setDefaultField = function (newDefaultFiled) {
        defaultField = newDefaultFiled;
    };
    this.getExportField = function () {
        return exportField || this.getOrderBy() || this["name"] || this["code"];
    };

    let defaultCellFactory = () => name;
    this.setDefaultCellFactory = function (newDefaultCellFactory) {
        defaultCellFactory = newDefaultCellFactory;
    };
    this.getDefaultCellFactory = function () {
        return defaultCellFactory;
    };
    this.getDefaultCell = function (...args) {
        if (typeof this.getModel === "function") {
            return this.getModel().getDefaultCellFactory().call(this.getModel(), this, ...args);
        }
        return this.getDefaultCellFactory().call(this, ...args);
    };

    let defaultListCellFactory;
    this.setDefaultListCellFactory = function (newDefaultListCellFactory) {
        defaultListCellFactory = newDefaultListCellFactory;
    };
    this.getDefaultListCellFactory = function () {
        return defaultListCellFactory;
    };

    let defaultItemFactory;
    this.setDefaultItemFactory = function (newDefaultItemFactory) {
        defaultItemFactory = newDefaultItemFactory;
    };
    this.getDefaultItemFactory = function () {
        return defaultItemFactory || defaultCellFactory;
    };
    this.getDefaultItem = function (...args) {
        if (typeof this.getModel === "function") {
            return this.getModel().getDefaultItemFactory().call(this.getModel(), this, ...args);
        }
        return this.getDefaultItemFactory()(...args);
    };

    let defaultEditorFactory;
    this.setDefaultEditorFactory = function (newDefaultEditorFactory) {
        defaultEditorFactory = newDefaultEditorFactory;
    };
    this.getDefaultEditorFactory = function () {
        return defaultEditorFactory;
    };

    let listFetcherCache = {};
    let fromListFetcherCache = (settings) => {
        const key = JSON.stringify(settings);
        return Optional.of(listFetcherCache[key], (otherValue) => {
            listFetcherCache[key] = otherValue;
            otherValue.catch(() => delete listFetcherCache[key]);
        });
    };
    this.invalidateListFetcherCache = (trigger = true) => {
        listFetcherCache = {};
        if (trigger) {
            modelProxy.trigger(model.EVENTS.AFTER_INVALIDATE_LIST_CACHE);
        }
    };

    const triggerLoadEvent = (responseJson, settings) => {
        this.trigger(model.EVENTS.LOAD, {...settings, ...responseJson});
        return responseJson;
    };

    const createResponseObject = (responseJson, settings) => {
        let obj = {};
        let metadata = responseJson[METADATA];
        if (Array.isArray(responseJson.data)) {
            obj.data = responseJson.data.map(data => getInstance(data));
        } else {
            obj.data = getInstance(responseJson.data);
        }
        obj.metadata = {...settings, ...metadata};
        return obj;
    };

    const fetchEntityList = (url, settings) => {
        if (settings.includeAggregations) {
            settings = {...settings, aggregations};
        }
        if (settings.queryParameters) {
            url += "?" + new URLSearchParams(settings.queryParameters).toString();
        }
        const requestHeaders = generateHeadersForListRequest(settings);
        return RestServer.get(url, requestHeaders)
            .then(responseJson => triggerLoadEvent(responseJson, settings))
            .then(responseJson => createResponseObject(responseJson, {...settings, requestHeaders, url}));
    };


    this.fetchDistinct = (field, filter, app) => {
        return RestServer.get(url + "/distinct/" + field.getName(), {"Where": filter.toString(), "App-Id": app?.id});
    };

    this.getList = function (settings = {}) {
        const fetchUrl = settings.url || url;
        if (settings.noCache) {
            return fetchEntityList(fetchUrl, settings);
        } else {
            return fromListFetcherCache(settings).orElseGet(() => fetchEntityList(fetchUrl, {
                ...settings,
                headers: {...generateIsCachedHeader(modelProxy), ...settings.headers}
            }));
        }
    };

    this.saveAll = function (entities) {
        let content = entities
            .filter(entity => entity instanceof this)
            .map(entity => {
                return {id: entity.getId(), ...entity.getDirtyProperties()};
            });
        return RestServer.post(url, content, generateIsCachedHeader(modelProxy))
            .then(response => {
                this.invalidateListFetcherCache();
                return response;
            })
            .then(responseJson => triggerLoadEvent(responseJson))
            .then(responseJson => createResponseObject(responseJson));
    };

    this.export = function (settings = {}) {
        let {
            type = "text/csv",
            columns = getFirstLevelFields(this),
            columnNames,
            summedColumns,
            queryParameters
        } = settings;
        let exportUrl = url;
        const headers = generateHeadersForListRequest(settings);
        delete headers["Max-Rows"];
        delete headers["First-Row"];
        headers["Columns"] = columns.join(",");
        headers["Accept"] = type;
        if (columnNames) {
            headers["Column-Names"] = columnNames.join(",");
        }
        if (summedColumns) {
            headers["Summed-Columns"] = summedColumns.join(",");
        }
        if (queryParameters) {
            exportUrl += "?" + new URLSearchParams(settings.queryParameters).toString();
        }
        return RestServer.download(exportUrl, headers);
    };

    this.getListForDropdown = function (searchString, fields, app, defaultFilter, orderBy = undefined, firstRow = 0) {
        if (!Array.isArray(fields)) {
            fields = [fields];
        }
        orderBy = orderBy || fields[0];
        let filter;
        if (defaultFilter) {
            filter = filter ? Filter.and(filter, defaultFilter) : defaultFilter;
        }
        const maxRows = 15;
        const lastPageMaxRows = 25;
        const suggestionFilter = searchString ? new SuggestionFilter(searchString, orderBy, fields) : null;
        return this.getList({
            app,
            suggestionFilter,
            filter,
            maxRows,
            firstRow,
            lastPageMaxRows,
            orderBy
        }).then(response => {
            const length = response[DATA].length;
            const totalLength = response[METADATA].totalCount;
            if (totalLength === length + firstRow) {
                return response[DATA];
            }
            return new Proxy(response[DATA], {
                get: (target, propertyName) => {
                    if (propertyName === "next") {
                        return () => this.getListForDropdown(searchString, fields, app, defaultFilter, orderBy, firstRow + length);
                    }
                    return Reflect.get(target, propertyName);
                }
            });
        });
    };

    this.getOptional = function (filter, settings = {}) {
        return Optional.of(this.getList({maxRows: 1, filter, ...settings}).then(response => {
            if (response[DATA].length === 0) {
                return null;
            }
            if (response[DATA].length > 1) {
                throw new Error(NOT_UNIQUE);
            }
            return response[DATA][0];
        }));
    };

    this.getOwn = function (appCode) {
        return RestServer.getOwn(url, appCode, generateIsCachedHeader(modelProxy)).then(jsonResponse => {
            if (jsonResponse[DATA] === null) {
                throw new Error(NOT_FOUND);
            }
            return jsonResponse[DATA];
        }).then(getInstance);
    };

    this.getByCode = function (code, appCode, noCache = false) {
        return Optional.of(noCache ? undefined : this.cache.getByCode(code, appCode)).orElseGetPromise(() => {
                let codeFilter = Filter.eq(this["code"], code);
                let filter;
                if (appCode) {
                    let appFilter = Filter.eq("app.code", appCode);
                    filter = Filter.and(codeFilter, appFilter);
                } else {
                    filter = codeFilter;
                }
                return this.getList({maxRows: 1, filter, headers: {"By-Code": true}})
                    .then(response => {
                        if (response[METADATA].totalCount === 0) {
                            throw new Error(NOT_FOUND);
                        }
                        if (response[METADATA].totalCount > 1) {
                            throw new Error(NOT_UNIQUE);
                        }
                        return response[DATA][0];
                    });
            }
        );
    };

    this.fetchCustomEndpoint = function (endpoint, id) {
        return RestServer.get(concatenateUrls(url, endpoint, id), generateIsCachedHeader(modelProxy, [id]));
    };

    const maxRows = 100;
    this.getAll = (filter, orderBy = undefined) => this.getList({maxRows, filter, orderBy}).then(response => {
        const count = response[METADATA].totalCount;
        if (count > maxRows) {
            throw new Error(`There are too many(${count}) entities of type ${name} to get all.`);
        }
        return response[DATA];
    });

    const loadCleanProperties = (id, excludedEntities = []) => {
        return RestServer.getById(url, id, generateIsCachedHeader(modelProxy, [
            id,
            ...excludedEntities
        ])).then(jsonResponse => {
            if (jsonResponse[DATA] === null) {
                throw new Error(NOT_FOUND);
            }
            return jsonResponse[DATA] || jsonResponse;
        });
    };

    this.getById = function (id) {
        return Optional.of(this.cache.getById(id))
            .orElseGetPromise(() => loadCleanProperties(id).then(getInstance));
    };

    this.getCachedDescendants = function () {
        let directDescendants = model
            .getFields()
            .filter(field => !!field.getModelType())
            .map(field => field.getModelType());
        return directDescendants
            .filter(descendant => !excludeFromCachedDescendants.map(d => d.getName()).includes(descendant.getName()))
            .filter(function onlyUnique(value, index, self) {
                return self.map(model => model.getName()).indexOf(value.getName()) === index;
            });
    };
    this.excludeFromCachedDescendants = () => {
        excludeFromCachedDescendants.push(this);
    };

    this.makeTranslatable = () => {
        modelProxy.lexiconEntries = new Field([LexiconEntry]).asCascading();
        translatable = true;
    };

    handler.get = (target, propertyName) => {
        if (!initiated) {
            initiateModel();
        }
        if (fields[propertyName]) {
            return Reflect.get(fields, propertyName);
        }
        if (aggregations[propertyName]) {
            return Reflect.get(aggregations, propertyName);
        }
        if (methods[propertyName]) {
            return Reflect.get(methods, propertyName);
        }
        if (propertyName.indexOf && propertyName.indexOf(".") !== -1) {
            const head = propertyName.substring(0, propertyName.indexOf("."));
            const tail = propertyName.substring(propertyName.indexOf(".") + 1);
            let field = handler.get(target, head);
            return field[tail];
        }
        if (propertyName === Symbol.hasInstance) {
            return (entity) => instanceProxies.has(entity);
        }
        return Reflect.get(this, propertyName);
    };
    let methods = {};

    handler.set = (target, propertyName, newValue) => {
        if (!initiated) {
            initiateModel();
        }
        if (newValue instanceof Field) {
            if (callbackCalled) {
                throw new Error(`Tried to add field with name ${propertyName} on model ${name} after initialization.`);
            }
            newValue.setName(propertyName);
            newValue.setOwner(modelProxy);
            return Reflect.set(fields, propertyName, newValue);
        }
        if (newValue instanceof Aggregation) {
            if (callbackCalled) {
                throw new Error("Model is already defined. Cannot add more aggregations anymore.");
            }
            return Reflect.set(aggregations, propertyName, newValue);
        }
        if (newValue instanceof Method) {
            if (newValue.isStatic()) {
                throw new Error("Not implemented");
            } else {
                return Reflect.set(methods, propertyName, newValue);
            }
        }
        return Reflect.set(this, propertyName, newValue);
    };

    handler.construct = (target, args) => {
        if (!initiated) {
            initiateModel();
        }
        return getInstance(args[0] || {}, args[1]);
    };

    handler.getPrototypeOf = (target) => {
        return RestModel.prototype;
    };

    let callbackCalled = false;
    let initiated = false;
    let initiateModel = () => {
        if (initiated) {
            throw new Error("Trying to initiate model more than once");
        }
        initiated = true;
        callback.call(modelProxy, modelProxy);
        if (!fields["id"]) {
            modelProxy.id = new Field()
                .asReadOnly()
                .asNumber();
        }
        if (!fields["modified"]) {
            modelProxy.modified = new Field().asTimestamp().asReadOnly().withLabel("Last modified").asReadOnly();
        }
        if (!fields["created"]) {
            modelProxy.created = new Field().asTimestamp().asReadOnly().withLabel("Created").asReadOnly();
        }
        if (!fields["deleted"]) {
            modelProxy.deleted = new Field().asBoolean().withLabel("Is deleted").withDefaultValueCallback(() => false);
        }
        if (!fields["modifiedBy"]) {
            modelProxy.modifiedBy = new Field(HeweconUser).withLabel("Modified by").asReadOnly();
        }
        if (!fields["locked"]) {
            modelProxy.locked = new Field().asBoolean()
                .withCellFactory((locked) => {
                    if (locked) {
                        return new Container(function () {
                            this.icon = createIcon("lock");
                        }, [BULMA.HAS_TEXT_RIGHT]);
                    }
                });
        }

        SessionHandler.on(SESSION_HANDLER_EVENTS.SESSION_STARTED, () => {
            this.invalidateListFetcherCache();
        });
        callbackCalled = true;
        this.trigger(this.EVENTS.INITIALIZED, modelProxy(), {persistent: true});
        MODELS_BY_NAME[name] = modelProxy;
    };
    return modelProxy;
}

Listenable.apply(RestModel.prototype);
RestModel.getByName = (name) => MODELS_BY_NAME[name];
export {RestModel as default};
