import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import {fieldDropdownSettings, getSecondLevelFields} from "../../utils/fieldUtils.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import getIconCellFactory from "./utils/getIconCellFactory.js";
import {getDropDownFactory, iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import ContactGroup from "./ContactGroup.js";
import Contact from "./Contact.js";
import DynamicField from "./DynamicField.js";
import Ticket from "./Ticket.js";
import Method from "../../utils/Method.js";
import Filter from "../../utils/Filter.js";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Url Code");

    this.title = new Field()
        .asString()
        .asTranslatableString()
        .withLabel("Title");

    this.icon = new Field()
        .asString()
        .withEditorFactory(iconPickerFactory)
        .withLabel("Icon");

    this.contactGroupFilter = new Field([ContactGroup])
        .withLabel("Contact group filter");

    this.columns = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Contact))
        .withEditorFactory(getDropDownFactory({
            searchable: true,
            searchInputCreator: DynamicField.getSearchInputCreator(Contact),
        }))
        .withLabel("Columns");

    this.editorFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Contact))
        .withLabel("Editor fields");

    this.filterFields = new Field()
        .asEnumList(getSecondLevelFields(Contact))
        .withLabel("Filter fields");

    this.editEnabled = new Field()
        .asBoolean()
        .withLabel("Edit enabled");

    this.createEnabled = new Field()
        .asBoolean()
        .withLabel("Create enabled");

    this.deleteEnabled = new Field()
        .asBoolean()
        .withLabel("Delete enabled");

    this.importEnabled = new Field()
        .asBoolean()
        .withLabel("Import enabled");

    this.exportEnabled = new Field()
        .asBoolean()
        .withLabel("Export enabled");

    this.orderBy = new Field()
        .asEnum(getSecondLevelFields(Ticket))
        .withEditorFactory(getDropDownFactory(fieldDropdownSettings))
        .withLabel("Order by");
    this.orderAscending = new Field().asBoolean().withLabel("Ascending order");

    this.makeTranslatable();
    this.excludeFromCachedDescendants();

    this.getEditorFields = new Method(function () {
        return this.editorFields
            .map(field => {
                if (field.name === Contact.contactGroup.getName()) {
                    let copy = field.copy();
                    copy.withOptionsLoader((searchString, ticket) => {
                        return ContactGroup.getDefaultOptionsLoader()(searchString, ticket, Filter.inOrEmpty(ContactGroup, this.contactGroupFilter));
                    });
                    return copy;
                }
                return field;
            });
    });

    setDefaults(this, {
        filterFields: [this.title],
        linkFactory: (contactList) =>
            createLink(`/${contactList.app.code}/contact-lists/${contactList.code}`, contactList.title, contactList.icon),
        itemFactory: getIconCellFactory(this.icon, this.title),
    });
}, "ContactList", "contact-lists");
