import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import Linguist from "../../../lib/JuiS/Linguist";
import lexicons from "./lexicons/bill/billLexicon";

const linguist = new Linguist(lexicons).withBaseKey("CalculatedPenaltyInterestPeriod");
export default new Model(function () {
    this.currencyCode = new Field().asCurrency().asReadOnly();
    this.unpaid = new Field().asMoney(this.currencyCode).asReadOnly();
    this.interest = new Field().asMoney(this.currencyCode).asReadOnly();
    this.start = new Field().asDate().asReadOnly();
    this.end = new Field().asDate().asReadOnly();
    linguist.withAudience(this);
}, "Calculated penalty interest");
