import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import TimePeriodGroup from "./TimePeriodGroup.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import namedTimePeriodLexicons from "./lexicons/named-time-period/namedTimePeriodLexicons.js";

const linguist = new Linguist(namedTimePeriodLexicons);
export default new Model(function () {
    this.app = new Field(App);
    this.name = new Field().asString();
    this.start = new Field().asTimestamp();
    this.end = new Field().asTimestamp();
    this.periodGroup = new Field(TimePeriodGroup);

    setDefaults(this, {defaultField: this.name, orderByField: this.start});
    linguist.withAudience(this);
}, "NamedTimePeriod");
