/**
 * Simple stupid function for formatting durations for UI. Replace with javascript temporal when available.
 * @param value
 * @returns {string}
 */
export default function formatDuration(value) {
    if (value === null || value === undefined) {
        return "";
    }
    const fullHours = Math.floor(value / 3600);
    const fullMinutes = Math.floor((value % 3600) / 60);
    const seconds = value - (fullHours * 3600) - (fullMinutes * 60);
    if (fullHours && seconds) {
        return `${fullHours}h ${fullMinutes}min ${seconds}s`;
    }
    if (fullHours && fullMinutes) {
        return `${fullHours}h ${fullMinutes}min`;
    }
    if (fullHours) {
        return `${fullHours}h`;
    }
    if (fullMinutes && seconds) {
        return `${fullMinutes}min ${seconds}s`;
    }
    if (fullMinutes) {
        return `${fullMinutes}min`;
    }
    return `${value}s`;
}
