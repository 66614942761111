import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import Contact from "./Contact.js";
import {
    addressSuggestionInputFactory,
    createSimpleField,
    createTextAreaEditor,
    getDropDownFactory,
    iconPickerFactory
} from "../../components/fieldEditors/editorFactories.js";
import TicketType from "./TicketType.js";
import Container from "juis-components/Container.js";
import {createIcon} from "../../bulma_components/Icon.js";
import App from "./App.js";
import Bill from "./Bill.js";
import TicketProduct from "./TicketProduct.js";
import Aggregation from "../../utils/Aggregation.js";
import TicketStatus from "./TicketStatus.js";
import Filter from "../../utils/Filter.js";
import getTicketStatusToggler from "../views/tickets/getTicketStatusToggler.js";
import parseEntityTemplate from "../../utils/parseEntityTemplate.js";
import WorkLog from "./WorkLog.js";
import BillUtils from "../utils/BillUtils.js";
import InputWithSuggestions from "../../components/dropdown/InputWithSuggestions.js";
import {getLocaleDropdownFactory} from "../translations/localeDropdownFactory.js";
import BulmaUtils from "../../bulma_components/BulmaUtils.js";
import TextComponent from "../../components/TextComponent.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import ticketLexicons from "./lexicons/ticket/ticketLexicons.js";
import LOCALES from "../translations/locales.js";
import localeCellFactory from "../translations/localeCellFactory.js";
import Stock from "./Stock.js";
import Transaction from "./Transaction.js";
import StockProduct from "./StockProduct.js";
import Optional from "../../../lib/JuiS/Optional.js";
import TicketMainProduct from "./TicketMainProduct.js";
import DynamicField from "./DynamicField.js";
import Method from "../../utils/Method.js";
import getCreateCustomerForm from "../views/tickets/components/getCreateCustomerForm.js";
import Product from "./Product.js";
import SessionHandler from "../../SessionHandler.js";
import UserRole from "./UserRole.js";
import TicketResource from "./TicketResource.js";
import BULMA from "../../bulma_components/bulmaCssClasses";
import HTML from "../../utils/HTML";

const linguist = new Linguist(ticketLexicons);

const createStatusCell = (status, ticket) => {
    let textComponent = new TextComponent(status ? status.name : "");
    if (ticket.statusTime) {
        textComponent.tooltip = linguist.t("statusSetAt", {ticket});
    }
    return textComponent;
};

const createLinkWithStatus = (ticket) => {
    return new Container(function () {
        this.icon = createTicketIcon(ticket);
        this.icon.getNode().addCssClass("has-tooltip-right");
        this.icon.getNode().setStyle("margin-right", "0.5em");
        const title = `${ticket.title || TicketType.name.getTranslatedValueFor(ticket.type)} #${ticket.number}`;
        this.link = createLink(`/${ticket.app.code}/tickets/${ticket.code}`, title);
    });
};

const createTicketIcon = (ticket) => {
    let icon = createIcon(ticket.type.icon);
    if (ticket.status) {
        icon.color = ticket.status.color;
    }
    icon.tooltip = BulmaUtils.getTooltipProperty(icon);
    icon.tooltip = ticket.status ? TicketStatus.name.getTranslatedValueFor(ticket.status).toLowerCase() : linguist.t("noStatus");
    if (ticket.statusTime) {
        icon.tooltip += " " + linguist.t("statusSetAt", {ticket});
    }
    return icon;
};

let Ticket = new RestModel(function () {
    this.id = new Field().asReadOnly().asNumber();
    this.app = new Field(App);
    this.name = new Field().asString();
    this.locked = new Field()
        .asBoolean();
    this.deleted = new Field()
        .asBoolean();
    this.created = new Field()
        .asDate(Linguist.getLanguage())
        .asReadOnly()
        .withLabel("Created");
    this.date = new Field()
        .asDate(Linguist.getLanguage())
        .withDefaultValueCallback(() => new Date())
        .withLabel("Date");
    this.year = new Field()
        .asNumber()
        .asReadOnly()
        .withLabel("Year");
    this.deadline = new Field()
        .asDate(Linguist.getLanguage())
        .withCellFactory((date, ticket) => {
            if (date === null || date === undefined) {
                return "";
            }
            const dateString = new Intl.DateTimeFormat(Linguist.getLanguage(), {dateStyle: "medium"}).format(date);
            if (!ticket.isFinal() && date < new Date()) {
                return new TextComponent(dateString, HTML.SPAN, BULMA.TYPOGRAPHY.HAS_TEXT_DANGER);
            }
            return dateString;
        })
        .withLabel("Deadline");
    this.code = new Field()
        .asReadOnly()
        .asString();
    this.rank = new Field()
        .asNumber()
        .withLabel("Rank");
    this.customer = new Field(Contact)
        .withCellFactory((customer, ticket) => {
            if (!ticket) {
                return "";
            }
            if (!customer) {
                return ticket.customerName;
            }
            return Contact.getDefaultCellFactory()(customer, ticket);
        })
        .withOptionsLoader((searchString, ticket) => {
            const searchFields = [Contact.name, Contact.street, Contact.city];
            return ticket.type.$customers.then(customerGroups => {
                const groupFilter = Filter.inOrEmpty(Contact.contactGroup, customerGroups);
                return Contact.getListForDropdown(searchString, searchFields, ticket.app, groupFilter)
                    .then(customers => {
                        let childCustomers = ticket.children
                            .filter(child => child.customer)
                            .map(child => child.customer);
                        let customerList = [...childCustomers, ...customers];
                        if (ticket.parent && ticket.parent.customer) {
                            customerList = [ticket.parent.customer, ...customerList];
                        }
                        return customerList
                            .filter(customer => customerGroups.length === 0 || customerGroups.includes(customer.contactGroup))
                            .filter((type, index, self) => self.findIndex(otherType => otherType === type) === index); // Distinct
                    });
            });
        })
        .withCreatorFactory(getCreateCustomerForm)
        .withLabel("Customer");
    this.ticketProducts = new Field([TicketProduct])
        .withLabel("Products")
        .withFilterGetter((ticket) => {
            if (ticket.type.productsGroups.length > 0) {
                return Filter.in(Product.groups, ticket.type.productsGroups);
            }
            return Filter.true;
        })
        .asCascading();
    this.mainProduct = new Field(TicketMainProduct)
        .withLabel("Main product")
        .withFilterGetter((ticket) => {
            let filters = [];
            if (ticket.type.mainProductGroups.length > 0) {
                filters.push(Filter.in(Product.groups, ticket.type.mainProductGroups));
            }
            if (ticket.type.mainProductVariations === "VARIATIONS") {
                filters.push(Filter.eq(Product.variationQuantity, 0));
            }
            if (ticket.type.mainProductVariations === "VARIATION_ROOTS") {
                filters.push(Filter.isNull(Product.variationOf));
            }
            return Filter.every(filters);
        })
        .withDefaultValueCallback((ticket) => {
            let ticketMainProduct = new TicketMainProduct({
                ticket,
                app: ticket.app,
                quantity: 1,
                vatPercentage: 0,
                price: 0,
                priceIncludesVat: true
            });
            ticketMainProduct.makeDirty();
            return ticketMainProduct;
        })
        .asCascading();
    this.assignee = new Field(Contact)
        .withOptionsLoader((searchString, ticket) => ticket.getAssigneesForDropdown(searchString))
        .withLabel("Assignee");
    this.description = new Field()
        .asString()
        .withCellFactory((value) => {
            let cell = new TextComponent(value);
            cell.getNode().setStyle("white-space", "pre-wrap");
            return cell;
        })
        .withEditorFactory(createTextAreaEditor)
        .withLabel("Description");
    this.title = new Field()
        .asString()
        .withLabel("Title");
    this.shortDescription = new Field()
        .asString()
        .withEditorFactory(createTextAreaEditor)
        .withLabel("Short description");
    this.status = new Field(TicketStatus)
        .withLabel("Status")
        .asRequired()
        .withOptionsLoader((filter, ticket) => Promise.resolve(ticket?.type?.statuses ?? []));
    this.statusTime = new Field()
        .asTimestamp(Linguist.getLanguage())
        .asReadOnly();
    this.startTime = new Field()
        .asTimestamp(Linguist.getLanguage())
        .asReadOnly();
    this.finishTime = new Field()
        .asTimestamp(Linguist.getLanguage())
        .asReadOnly();
    this.type = new Field(TicketType).withLabel("Ticket type");
    this.children = new Field([this])
        .withNameField(this.name)
        .asCascading();
    this.workLogs = new Field([WorkLog]).asCascading().withForeignRelationField(WorkLog.ticket);
    this.extraFields = new Field().asJson();
    this.number = new Field()
        .asNumber()
        .withLabel("#");
    this.icon = new Field()
        .asVirtual(this.code)
        .withEditorFactory(iconPickerFactory)
        .withCellFactory((ignore, ticket) => createTicketIcon(ticket));
    this.bill = new Field(Bill)
        .withOrderByField(() => Ticket.bill.id)
        .withLabel("Bill");
    this.activeBill = new Field()
        .asBoolean()
        .withLabel("Has active bill")
        .asReadOnly();
    this.statuses = new Field()
        .asString()
        .asVirtual(this.status.name)
        .withCellFactory((id, ticket) => {
            return new Container(function () {
                ticket.children
                    .map(childTicket => {
                        let icon = Ticket.icon.getCell(childTicket);
                        icon.tooltip = TicketType.name.getTranslatedValueFor(childTicket.type) + " " + icon.tooltip;
                        this.appendChild(icon);
                    });
            }, ["is-inline-block"]);
        })
        .withLabel("Progress");
    this.parent = new Field(this).withLabel("Parent");

    this.getIdentificationString = new Method(function () {
        if (this.title) {
            return this.title;
        }
        if (this.parentStock) {
            return this.parentStock.name;
        }
        if (this.id) {
            return `${TicketType.name.getTranslatedValueFor(this.type)} #${this.number}`;
        }
        return TicketType.name.getTranslatedValueFor(this.type);
    });

    this.identificationString = new Field()
        .asVirtual(this.code, (ticket) => ticket.getIdentificationString())
        .withLabel("#");

    this.version = new Field()
        .asString()
        .withLabel("Version");

    this.rating = new Field()
        .withEditorFactory(function (field, ticket) {
            return createSimpleField(field, ticket, Container(function () {
                let value = ticket.rating;
                this.setValue = (newValue) => value = newValue;
                this.getValue = () => value;
                let refresh = () => {
                    this.destroyAllChildren();
                    [1, 2, 3, 4, 5].forEach(rating => {
                        let icon;
                        if (value >= rating) {
                            icon = this.appendChild(createIcon("star"));
                        } else {
                            icon = createIcon("star");
                            icon.style = "far";
                            this.appendChild(icon);
                        }
                        icon.registerDomEvents("click");
                        icon.on("click", () => {
                            this.setValue(rating);
                            refresh();
                            this.trigger("change");
                        });
                        icon.getNode().setStyle("cursor", "pointer");
                    });
                };
                refresh();
            }, ["is-inline-block"]));
        })
        .withCellFactory((value) => {
            return new Container(function () {
                [1, 2, 3, 4, 5].forEach(rating => {
                    if (value >= rating) {
                        this.appendChild(createIcon("star"));
                    } else {
                        let icon = createIcon("star");
                        icon.style = "far";
                        this.appendChild(icon);
                    }
                });
            }, ["is-inline-block"]);
        })
        .withLabel("Rating");

    this.dropboxLink = new Field().asString().withLabel("Dropbox link");

    this.parentStock = new Field(Stock).withLabel("Parent stock")
        .withOptionsLoader((searchString, ticket) =>
            Stock.getListForDropdown(searchString, [Stock.name], ticket.app, Filter.isNull(Stock.parent)))
        .withEditorFactory(getDropDownFactory({searchable: true}));
    this.grossWeight = new Field().asNumberWithUnit("kilogram").withLabel("Gross weight");
    this.netWeight = new Field().asNumberWithUnit("kilogram").withLabel("Net weight");
    this.packageWeight = new Field().asNumberWithUnit("kilogram").withLabel("Package weight");


    const customerSuggestion = () => {
        return (field, ticket) => {
            const input = new InputWithSuggestions();
            input.setOptionsLoader((searchString) => {
                const searchFields = [Contact.name, Contact.street, Contact.city];
                return ticket.type.$customers.then(customerGroups => {
                    const groupFilter = Filter.inOrEmpty(Contact.contactGroup, customerGroups);
                    return Contact.getListForDropdown(searchString, searchFields, ticket.app, groupFilter);
                });
            });
            if (ticket.customerName) {
                input.setValue(ticket.customerName);
            } else if (ticket.customer) {
                input.setValue(ticket.customer.name);
            }
            input.setSuggestionToTextMapper(contact => contact.name);
            input.setItemFactory(contact => contact.name);
            input.on("input", () => ticket.customer = null);
            input.on("suggestionSelected", (contact) => ticket.customer = contact);
            return createSimpleField(field, ticket, input);
        };
    };
    this.customerName = new Field().asString().withLabel("Name").withEditorFactory(customerSuggestion());
    this.customerStreet2 = new Field().asString().withLabel("Street 2");
    this.customerZipCode = new Field().asString().withLabel("Zip code");
    this.customerCity = new Field().asString().withLabel("City");
    this.customerStreet = new Field().asString().withLabel("Street")
        .withEditorFactory(addressSuggestionInputFactory(this.customerCity, this.customerZipCode, linguist));
    this.customerCountry = new Field().asString().withLabel("Country");
    this.customerPhone = new Field().asString().withLabel("Phone");
    this.customerEmail = new Field().asString().withLabel("Email");
    this.locale = new Field().asString()
        .withLabel("Locale")
        .withOptions(Object.keys(LOCALES))
        .withCellFactory(localeCellFactory)
        .withEditorFactory(getLocaleDropdownFactory());

    this.productsSummary = new Field()
        .asVirtual()
        .withCellFactory((ignore, ticket) => {
            if (ticket.ticketProducts.length > 2) {
                return `${ticket.ticketProducts[0].quantity} ${ticket.ticketProducts[0].name} + more`;
            } else if (ticket.ticketProducts.length > 0) {
                return ticket.ticketProducts.map(product => product.quantity + " " + product.name).join(", ");
            } else {
                return linguist.t("noProducts");
            }
        })
        .withLabel("Products");
    this.ticketResources = new Field([TicketResource])
        .asCascading()
        .withNameField(TicketResource.name, true)
        .withLabel("Resources");

    this.statusToggler = new Field()
        .withLabel("Status")
        .asVirtual(this.status.ordinal)
        .withCellFactory(getTicketStatusToggler);

    this.url = new Field()
        .withLabel("url")
        .asVirtual(this.number, function () {
            return HEWECON_GLOBAL.baseUrl + this.getUrl();
        })
        .asReadOnly();

    this.shortLink = new Field()
        .withLabel("url")
        .asVirtual(this.number)
        .withCellFactory((ignore, ticket) => {
            let cell = createLinkWithStatus(ticket);
            cell.link.text = ticket.number;
            return cell;
        })
        .asReadOnly();

    this.mappedTransactions = new Field([Transaction])
        .withLabel("Mapped transactions");

    this.distinctYears = Aggregation.distinct(this.year);
    this.count = Aggregation.count(this.id, "Count");
    this.summedTypeEstimatedDuration = Aggregation.sum(this.type.estimatedDuration, "Estimated duration");

    this.getExcludeFromCache = (ticket) => ticket.parent ? [ticket.parent] : [];

    this.getUrl = new Method(function (...args) {
        let queryString = "";
        if (args.length > 0) {
            const searchParams = new URLSearchParams();
            args.forEach(arg => searchParams.set(arg, "1"));
            queryString = "?" + searchParams.toString();
        }
        return `/${this.app.code}/tickets/${this.code}${queryString}`;
    });
    this.getAbsoluteUrl = new Method(function (...args) {
        return HEWECON_GLOBAL.baseUrl + this.getUrl(...args);
    });
    this.getEditorValueMapNonEmpty = function (...skip) {
        return this.getEditorValueMap(...skip).filter(obj => obj.value !== null && obj.value !== undefined);
    };
    this.getEditorValueMap = function (...skip) {
        return this.type.editorFields
            .filter(field => !skip.includes(field.getName()))
            .map(field => {
                let label = DynamicField.label.getTranslatedValueFor(field, this.locale);
                let value = this[field.name];
                if (value instanceof RestModel) {
                    value = value.getModel().getExportField().getValueFor(value);
                }
                if (Array.isArray(value) && value[0] instanceof RestModel) {
                    value = value.map(childValue => childValue.getModel().getExportField().getValueFor(childValue));
                }
                return {label, value};
            });
    };

    this.createBill = function (billProperties) {
        return this.type.billType.createBill({
            app: this.app,
            recipient: this.customer,
            recipientName: this.customerName || this.customer?.name,
            recipientStreet: this.customerStreet || this.customer?.street,
            recipientStreet2: this.customerStreet2 || this.customer?.street2,
            recipientZipCode: this.customerZipCode || this.customer?.zipCode,
            recipientCity: this.customerCity || this.customer?.city,
            ...billProperties
        });
    };

    this.canCreateBill = function () {
        return !this.activeBill && this.type.billType && this.isFinal();
    };

    this.getBillItemsHeader = function (bill) {
        let locale = bill ? bill.locale : "default";
        return parseEntityTemplate(this.type.billItemsHeaderMapper, this, locale);
    };

    this.getBillItemsProductListSubHeader = function (bill) {
        let locale = bill ? bill.locale : "default";
        return parseEntityTemplate(this.type.billItemsProductListSubHeaderMapper, this, locale);
    };

    this.getBillItemsWorkLogSubHeader = function (bill) {
        let locale = bill ? bill.locale : "default";
        return parseEntityTemplate(this.type.billItemsWorkLogSubHeaderMapper, this, locale);
    };

    this.createBillItems = function (bill) {
        return BillUtils.createBillItemsFromTicket(this, bill);
    };

    this.gotoNextStatus = function () {
        let currentIndex = this.type.statuses.indexOf(this.status);
        let nextStatus = this.type.statuses[currentIndex + 1];
        if (nextStatus) {
            this.status = nextStatus;
        }
    };

    this.getAssigneesForDropdown = function (searchString) {
        const searchFields = [Contact.name, Contact.street, Contact.city];
        return this.type.$assignees.then(assignees => {
            const groupFilter = assignees ? Filter.eq(Contact.contactGroup, assignees) : null;
            return Contact.getListForDropdown(searchString, searchFields, this.app, groupFilter);
        });
    };

    this.canAssignTo = function (contact) {
        return this.type.$assignees.then(assignees => {
            if (!assignees) {
                return true;
            }
            return assignees.equals(contact.contactGroup);
        });
    };

    this.assignToMeIfPossible = new Method(function (sessionHandler) {
        const me = this.type.assignees?.getMyself(sessionHandler, this.app);
        if (me) {
            this.assignee = me;
        }
    });

    this.setMeAsCustomerIfPossible = new Method(function (sessionHandler) {
        const me = this.type.customers
            .map(contactGroup => contactGroup.getMyself(sessionHandler, this.app))
            .filter(nullableMe => !!nullableMe)[0];
        if (me) {
            this.customer = me;
        }
    });

    this.setNextStatus = function () {
        let statusIndex = this.type.statuses.findIndex(status => status.id === this.status.id);
        let nextStatus = this.type.statuses[statusIndex + 1];
        if (nextStatus) {
            this.status = nextStatus;
        }
    };

    this.isFinal = function () {
        return this.status && this.status.type === TicketStatus.TYPES.FINAL;
    };

    this.copyValuesFromContact = function (contact) {
        this.customerName = contact.name || "";
        this.customerStreet = contact.street || "";
        this.customerStreet2 = contact.street2 || "";
        this.customerZipCode = contact.zipCode || "";
        this.customerCity = contact.city || "";
        this.customerPhone = contact.phoneNumber || "";
        this.customerEmail = contact.email || "";
        this.locale = contact.locale || this.locale;
    };

    this.on("change", (event) => {
        if (event.field === this.customer && event.value) {
            const contact = event.value;
            const ticket = event.entity;
            ticket.copyValuesFromContact(contact);
        }
    });
    this.canEditField = new Method(function (field) {
        const userRoles = SessionHandler.getRolesForApp(this.app);
        if (userRoles.some(userRole => [
            UserRole.ROLE_TYPES.ADMIN,
            UserRole.ROLE_TYPES.COORDINATOR,
            UserRole.ROLE_TYPES.EMPLOYEE
        ].includes(userRole.role))) {
            return true;
        }
        if (userRoles.some(userRole => userRole.role === UserRole.ROLE_TYPES.RESELLER)) {
            const fieldName = field.getName();
            return this.type.forReseller || !(["status", "customer", "assignee"].includes(fieldName));
        }
        if (userRoles.some(userRole => userRole.role === UserRole.ROLE_TYPES.CLIENT)) {
            const fieldName = field.getName();
            return this.type.forClient || !(["status", "customer", "assignee"].includes(fieldName));
        }
        return false;
    });
    this.canEdit = new Method(function () {
        if (!this.id) {
            return true;
        }
        const userRoles = SessionHandler.getRolesForApp(this.app);
        const cleanStatusType = this.getCleanValueForField(Ticket.status)?.type;
        switch (cleanStatusType) {
            case TicketStatus.TYPES.INITIAL:
                return userRoles.length > 0;
            case TicketStatus.TYPES.INTERMEDIATE:
                return userRoles.some(userRole => [
                    UserRole.ROLE_TYPES.ADMIN,
                    UserRole.ROLE_TYPES.COORDINATOR,
                    UserRole.ROLE_TYPES.EMPLOYEE
                ].includes(userRole.role));
            case TicketStatus.TYPES.FINAL:
                return userRoles.some(userRole => [
                    UserRole.ROLE_TYPES.ADMIN,
                    UserRole.ROLE_TYPES.COORDINATOR
                ].includes(userRole.role));
            default:
                console.warn("Ticket status not set");
                return true;
        }
    });
    this.canDelete = new Method(function () {
        const userRoles = SessionHandler.getRolesForApp(this.app);
        let roleTypes = userRoles.map(userRole => userRole.role);
        return (roleTypes.includes(UserRole.ROLE_TYPES.COORDINATOR) && this.type.deletable)
            || roleTypes.includes(UserRole.ROLE_TYPES.ADMIN);
    });

    this.on(this.EVENTS.AFTER_UPDATE, ticket => {
        ticket.mappedTransactions.forEach(transaction => {
            Optional.of(StockProduct.cache
                .find(stockProduct => stockProduct.product.equals(transaction.product) &&
                    stockProduct.stock.equals(transaction.stock)))
                .peek(stockProduct => {
                    stockProduct.reload();
                });
        });
        if (ticket.mappedTransactions.length > 0) {
            StockProduct.invalidateListFetcherCache();
        }
    });

    Bill.on(Bill.EVENTS.AFTER_DELETE, (bill) => Ticket.cache
        .findAll((ticket) => bill.equals(ticket.bill))
        .forEach(ticket => {
            ticket.setProperty("activeBill", false, {clean: true});
            ticket.setProperty("bill", null, {clean: true});
        }));

    Bill.on([Bill.EVENTS.AFTER_UPDATE, Bill.EVENTS.AFTER_INSERT], (bill) => Ticket.cache
        .findAll((ticket) => bill.equals(ticket.bill))
        .forEach(ticket => ticket.reload()));

    setDefaults(this, {
        filterFields: [this.title, this.number, this.recipientName, this.assignee.name],
        linkFactory: createLinkWithStatus,
        itemFactory: ticket => ticket.getIdentificationString(),
        exportField: this.code
    });
    this.getLabel = () => linguist.t("ticket");
    linguist.withAudience(this);
}, linguist.t("ticket"), "tickets");

export {Ticket as default};
