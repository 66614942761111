import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import {getDropDownFactory, iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import ProductGroup from "./ProductGroup.js";
import Product from "./Product.js";
import App from "./App.js";
import {fieldDropdownSettings, getEditorFields, getSecondLevelFields} from "../../utils/fieldUtils.js";
import setDefaults from "./utils/setDefaults.js";
import getIconCellFactory from "./utils/getIconCellFactory.js";
import DynamicField from "./DynamicField.js";
import Method from "../../utils/Method.js";

let ProductList = new Model(function () {
    this.app = new Field(App);

    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Url Code");

    this.name = new Field()
        .asString()
        .withLabel("Name");

    this.title = new Field()
        .asTranslatableString()
        .withLabel("Title");

    this.icon = new Field()
        .asString()
        .withEditorFactory(iconPickerFactory)
        .withLabel("Icon");

    this.color = new Field()
        .asColor()
        .withLabel("Color");

    this.description = new Field()
        .asString()
        .withLabel("Description");

    this.columns = new Field()
        .asEnumList(getSecondLevelFields(Product))
        .withEditorFactory(getDropDownFactory(fieldDropdownSettings))
        .withLabel("Deprecated Columns");

    this.dynamicColumns = new Field([DynamicField])
        .withLabel("Columns")
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Product));

    this.fields = new Field()
        .asEnumList(getEditorFields(Product))
        .withEditorFactory(getDropDownFactory(fieldDropdownSettings))
        .withLabel("Deprecated Editor fields");

    this.dynamicFields = new Field([DynamicField])
        .withLabel("Editor Fields")
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Product));

    this.filterFields = new Field()
        .asEnumList(getSecondLevelFields(Product))
        .withEditorFactory(getDropDownFactory(fieldDropdownSettings))
        .withLabel("Filter fields");

    this.canCreate = new Field()
        .asBoolean()
        .withLabel("Can create");

    this.canEdit = new Field()
        .asBoolean()
        .withLabel("Can edit");

    this.canDelete = new Field()
        .asBoolean()
        .withLabel("Can delete");

    this.enableImport = new Field()
        .asBoolean()
        .withLabel("Enable import");

    this.enableExport = new Field()
        .asBoolean()
        .withLabel("Enable export");

    this.groupFilter = new Field([ProductGroup])
        .withLabel("Product group filter")
        .withHelp("Leave empty for no filtering");

    this.variationFilter = new Field([Product])
        .withLabel("Variation filter")
        .withHelp("Leave empty for no filtering");

    this.groupToggles = new Field([ProductGroup])
        .withLabel("Product group toggles")
        .withHelp("Leave empty for no toggles");

    this.toggleAllGroups = new Field()
        .asBoolean()
        .withLabel("Toggle all groups")
        .withHelp("Select if you wish to always include all product groups in the toggles");

    this.excludeFromCachedDescendants();
    this.getUrl = function () {
        return `/${this.app.code}/product-lists/${this.code}`;
    };
    this.makeTranslatable();

    this.getTitle = new Method(function () {
        return this.title ? ProductList.title.getTranslatedValueFor(this) : this.name;
    });
    setDefaults(this, {
        filterFields: [this.name],
        linkFactory: list => createLink(list.getUrl(), list.name, list.icon, list.color),
        itemFactory: getIconCellFactory(this.icon, this.name, this.color)
    });
}, "ProductList", "product-lists", true);

export {ProductList as default};
