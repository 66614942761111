import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import HTML from "../utils/HTML.js";
import Property from "juis-components/Property.js";
import {BulmaComponent, EVENTS} from "./BulmaComponent.js";

let createTabConstructor = function (isToggle) {
    return function () {
        return constructTabs.call(this, isToggle);
    };
};

let constructTabs = function (isToggle) {
    let activeTab;
    let tabs = {};
    if (isToggle) {
        this.getNode().addWrapper(HTML.DIV, ["tabs", "isToggle", "isToggleRounded"]);
    } else {
        this.getNode().addWrapper(HTML.DIV, ["tabs", "isBoxed"]);
    }

    this.changeTab = (key) => {
        if (!tabs[key]) {
            throw new Error(`No such tab: ${key}`);
        }
        if (!activeTab || key !== activeTab.getKey()) {
            tabs[key].trigger("click");
            return true;
        }
        return false;
    };

    let canDeactivate = false;
    this.canDeactivate = new Property((value) => canDeactivate = value);

    this.addIconTab = function (icon, text) {
        return this.addTab(undefined, text, icon, false);
    };

    this.addTab = function (key, text, icon, active) {
        let tab = null;
        let iconColor;
        tab = new BulmaComponent(function () {
            this.registerDomEvents("click");
            this.on(EVENTS.ACTIVATE, () => {
                let oldValue = undefined;
                if (tab && activeTab === tab) {
                    throw new Error("Tab is already activated");
                }
                if (activeTab) {
                    oldValue = activeTab.getKey();
                    activeTab.deactivate();
                }
                this.trigger("change", {value: key, oldValue});
                activeTab = this;
            });
            this.on(EVENTS.DEACTIVATE, () => {
                if (activeTab) {
                    let oldValue = activeTab.getKey();
                    activeTab = undefined;
                    this.trigger("change", {value: undefined, oldValue});
                }
            });
            if (active) {
                this.activate();
            }
            this.on("click", () => {
                if (!this.isActive()) {
                    this.activate();
                } else if (canDeactivate) {
                    this.deactivate();
                    activeTab = undefined;
                }
            });
            this.getKey = () => key;

            this.icon = new Property((value) => {
                icon = value;
                refreshHTML()
            });
            this.iconColor = new Property((value) => {
                iconColor = value;
                refreshHTML()
            });

            const refreshHTML = () => {
                let tagHTML = "";
                if (this.tag !== undefined) {
                    tagHTML = ` <span class="tag is-rounded is-info tab-tag">${this.tag}</span>`;
                }
                if (text) {
                    this.getNode().setInnerHtml(`
            <a>
                <span class="icon" style="color:${iconColor ?? "inherit"}"><i class="fas fa-${icon}"></i></span>
                <span class="is-hidden-touch">${text}${tagHTML}</span>
            </a>`);
                } else {
                    this.getNode().setInnerHtml(`
            <a>
                <span class="icon" style="color:${iconColor ?? "inherit"}"><i class="fas fa-${icon}"></i></span>
            </a>`);
                }

            };
            this.tag = new Property(() => {
                refreshHTML();
            });
            refreshHTML();
        }, [], HTML.LI);
        this.appendChild(tab);
        tabs[key] = tab;
        return tab;
    }
};


let Tabs = extendComponent(Container, createTabConstructor(false), [], HTML.UL);
let Toggle = extendComponent(Container, createTabConstructor(true), [], HTML.UL);

export {Tabs as default, Toggle}
