import Model from "../../utils/RestModel.js";
import ProductItemBase from "./ProductItemBase.js";
import Field from "../../utils/Field.js";
import {createStringEditor} from "../../components/fieldEditors/editorFactories.js";
import {concatenateUrls} from "juis-router/NavigationUtils.js";
import Bill from "./Bill.js";
import Aggregation from "../../utils/Aggregation.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import billItemLexicon from "./lexicons/bill-item/billItemLexicon.js";
import Method from "../../utils/Method";
import Product from "./Product";
import Filter from "../../utils/Filter";

let BillItem = new Model(function () {
    ProductItemBase.call(this);
    this.bill = new Field(Bill).withLabel("Bill");
    this.periodStart = new Field().asDate(Linguist.getLanguage()).withLabel("Date");
    this.periodEnd = new Field().asDate(Linguist.getLanguage()).withLabel("Date");
    this.textItem = new Field().asBoolean().withLabel("Is text item");
    this.vatPercentage.asRequired();
    this.price.asRequired();
    this.name.withEditorFactory((field, billItem) => {
        if (billItem.textItem) {
            return createStringEditor(field, billItem);
        } else {
            return this.productSuggestionFactory(field, billItem, Filter.inOrEmpty(Product.groups, billItem.bill?.type.productGroups ?? []));
        }
    });
    this.year = new Field()
        .asNumber()
        .asReadOnly()
        .withLabel("Year");

    this.totalQuantity = Aggregation.sum(this.quantity);
    this.totalPriceVatIncludedSum = Aggregation.sum(this.totalPriceVatIncluded);
    this.totalPriceVatExcludedSum = Aggregation.sum(this.totalPriceVatExcluded);
    this.distinctYears = Aggregation.distinct(this.bill.year);

    this.applySalesPrice = new Method(function () {
        this.bill.customer?.salesPriceRules
            .filter(rule => rule.isAvailableForProduct(this.product))
            .forEach(rule => {
                const calculatedPrice = rule.calculatePrice(this.product);
                let currentPrice = (this.price * (this.discountPercentage ?? 100)) / 100;
                if (calculatedPrice < currentPrice) {
                    this.price = rule.getBasePrice(this.product);
                    this.discountPercentage = rule.discountPercentage;
                }
            });
    });

    this.on("change", (event) => {
        if (event.field === BillItem.product && event.value) {
            const product = event.value;
            const billItem = event.entity;
            billItem.copyValuesFromProduct(product, billItem.bill.getFieldsToCopyFromProducts());
            billItem.applySalesPrice();
        }
    });

    this.getSalesReport = (salesReport, settings) =>
        this.getList({...settings, url: concatenateUrls("bill-items", "sales-report", salesReport.id)});
    new Linguist(billItemLexicon).withAudience(this);
}, "BillItem", "bill-items");

export default BillItem;
