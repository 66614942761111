import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import getIconCellFactory from "./utils/getIconCellFactory.js";
import {createTranslatableMultiLineEditor, iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import productGroupLexicon from "./lexicons/product-group/productGroupLexicon.js";
import ProductView from "./ProductView.js";
import ProductList from "./ProductList.js";

let ProductGroup = new Model(function () {
    const linguist = new Linguist(productGroupLexicon);
    this.app = new Field(App);
    this.name = new Field().asTranslatableString().withLabel("Name");
    this.productList = new Field(ProductList).withLabel("Product list")
        .withCellFactory(list => list ? list.getDefaultCell() : "[Use app default]");
    this.productView = new Field(ProductView).withLabel("Product view")
        .withCellFactory(view => view ? view.getDefaultCell() : "[Use app default]");
    this.description = new Field().asTranslatableString().withLabel("Description").withEditorFactory(createTranslatableMultiLineEditor);
    this.code = new Field().asString();
    this.icon = new Field().asString().withLabel("Icon").withEditorFactory(iconPickerFactory);
    this.color = new Field().asColor().withLabel("Color");
    this.handleOnlyVariations = new Field().asBoolean().withLabel("Handle only variations").withHelp("Proudcts of this group should not be used directly, instead they all have variations.");
    this.pricePerNetWeight = new Field().asMoney();
    this.parent = new Field(this);
    this.children = new Field([this]);
    this.specific = new Field().asBoolean().withLabel("Specific");
    this.url = new Field().asVirtual(this.name, (group) => `/${group.app.code}/product-groups/${group.code}`);
    this.excludeFromCachedDescendants();
    linguist.withAudience(this);
    this.makeTranslatable();
    setDefaults(this, {
        orderByField: this.name,
        filterFields: [this.name, this.description],
        linkFactory: (group) => createLink(group.url, ProductGroup.name.getTranslatedValueFor(group), group.icon, group.color),
        itemFactory: getIconCellFactory(this.icon, this.name, this.color)
    });
}, "ProductGroup", "product-groups", true);

export default ProductGroup;
