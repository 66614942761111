import Property from "juis-components/Property.js";
import {concatenateUrls, isMatchingUrl} from "juis-router/NavigationUtils.js";
import {NAVIGATE} from "juis-router/Events.js";

let UrlComponentMixin = function () {
    const createNavigationListener = (navigationComponent) => {
        return navigationComponent.on(NAVIGATE, (event) => {
            let url = this.url;
            if (this.isRelative()) {
                this.baseUrl = event.consumedUrl;
                url = concatenateUrls(this.baseUrl, url);
            }
            if (!url) {
                return;
            }
            this.urlMatch = isMatchingUrl(event.url, url);
        });
    }
    this.url = new Property(undefined, "");
    this.urlMatch = new Property(undefined, false);
    this.baseUrl = new Property(undefined, "");
    this.isRelative = () => !this.url?.startsWith("/");
    this.router = new Property(newRouter => {
        navigationListener.destruct();
        navigationListener = createNavigationListener(newRouter);
    });
    let navigationListener = createNavigationListener(this);
};

export default UrlComponentMixin;
