import Server from "./utils/RestServer.js";
import Listenable from "juis-commons/Listenable.js";
import HeweconUser from "./hewecon/models/HeweconUser.js";
import Optional from "../lib/JuiS/Optional.js";
import Linguist from "../lib/JuiS/Linguist.js";

const SESSION_HANDLER_EVENTS = {
    SESSION_STARTED: "sessionStarted",
    SESSION_ENDED: "sessionEnded",
    SESSION_UPDATED: Symbol(),
};
const NOT_FOUND = 404;
const SessionHandler = function () {
    let currentUserData = null;

    const removeSession = () => {
        currentUserData = null;
        currentUser = null;
        this.trigger(SESSION_HANDLER_EVENTS.SESSION_ENDED);
    };

    this.isLoggedIn = () => {
        return !!currentUserData;
    };
    let currentUser = null;
    const setCurrentUserData = (sessionData, started) => {
        if (sessionData?.language) {
            Linguist.setLanguage(sessionData.language);
        }
        currentUserData = sessionData;
        if (started) {
            this.trigger(SESSION_HANDLER_EVENTS.SESSION_STARTED, currentUserData);
        } else {
            this.trigger(SESSION_HANDLER_EVENTS.SESSION_UPDATED, currentUserData);
        }
        if (currentUser) {
            currentUser.refresh(currentUserData);
        }
        return currentUserData;
    };

    if (initialSession) {
        setCurrentUserData(initialSession);
    }

    this.loadSession = () => {
        return Server.get("session")
            .then(sessionData => !sessionData || sessionData.error ? null : setCurrentUserData(sessionData))
            .catch(error => {
                if (error.status === NOT_FOUND) {
                    return null;
                }
                throw error;
            });
    };

    this.getCurrentUser = () => {
        if (!currentUser && currentUserData) {
            currentUser = Optional.of(HeweconUser.cache.getById(currentUserData.id))
                .map(cachedUser => {
                    cachedUser.refresh(currentUserData);
                    return cachedUser;
                })
                .orElseGet(() => new HeweconUser(currentUserData));
        }
        return currentUser;
    };
    this.isSuperAdmin = () => currentUserData?.username === "superadmin";

    this.getRolesForApp = (app) => {
        const user = this.getCurrentUser();
        if (!user || !app) {
            return [];
        }
        return user.roles.filter(role => role.app.id === app.id);
    };

    this.hasRole = (role, app) => this.getRolesForApp(app).map(role => role.role).includes(role);

    this.login = (username, password) => Server.post("login", {username, password})
        .then(sessionData => setCurrentUserData(sessionData, true));
    this.logout = () => Server.get("logout").then(removeSession);

    this.createAccount = (userData) => Server.post("create-user", userData).then(sessionData => setCurrentUserData(sessionData, true));
    this.changePassword = (currentPassword, newPassword) => {
        if (!currentUserData) {
            throw new Error("Cannot change password when no user is selected");
        }
        return Server.patch("user", currentUserData.id, {
            id: currentUserData.id,
            username: currentUserData.username,
            password: currentPassword,
            newPassword
        });
    };
};
Listenable.apply(SessionHandler.prototype);
let sessionHandlerSingleton = new SessionHandler();
export {sessionHandlerSingleton as default, SESSION_HANDLER_EVENTS};
