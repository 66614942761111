import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults";
import Linguist from "../../../lib/JuiS/Linguist";
import productLexicons from "./lexicons/product/productLexicons";

let linguist = new Linguist(productLexicons);
const formatVat = (value) => new Intl.NumberFormat(Linguist.getLanguage(), {
    style: "percent",
    "minimumFractionDigits": 1
}).format(value / 100);

let VatGroup = new Model(function () {
    this.app = new Field(App);
    this.code = new Field().asString().withLabel("Url Code");
    this.name = new Field().asString();
    this.vatPercentage = new Field()
        .asNumber(0, 100, 0.1)
        .withLabel("VAT %")
        .withCellFactory(formatVat);
    setDefaults(this, {
        defaultField: this.vatPercentage,
        itemFactory: vatGroup => formatVat(vatGroup.vatPercentage)
    });
    linguist.withAudience(this);
}, "VatGroup", "vat-groups");

export default VatGroup;
