import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import TicketType from "./TicketType.js";
import {getDropDownFactory} from "../../components/fieldEditors/editorFactories.js";
import App from "./App.js";
import TicketStatus from "./TicketStatus.js";

let TicketStatusRule = new RestModel(function () {
    this.app = new Field(App);
    this.description = new Field()
        .asString()
        .withLabel("Description");
    this.ticketType = new Field(TicketType).withLabel("Ticket type");
    this.priority = new Field()
        .asNumber()
        .withLabel("Priority");
    this.newStatus = new Field(TicketStatus)
        .withLabel("Status")
        .withEditorFactory(getDropDownFactory())
        .withOptionsLoader((ignore, statusRule) =>
            Promise.resolve(statusRule.ticketType?.statuses || []));
    this.currentStatuses = new Field([TicketStatus])
        .withEditorFactory(getDropDownFactory())
        .withOptionsLoader((ignore, statusRule) =>
            Promise.resolve(statusRule.ticketType?.statuses || []))
        .withLabel("Current status");
    this.allChildStatuses = new Field([TicketStatus])
        .withOptionsLoader((ignore, statusRule) =>
            Promise.resolve(statusRule.ticketType?.children.flatMap(child => child.statuses) || []))
        .withLabel("All children status");
    this.anyChildStatus = new Field([TicketStatus])
        .withOptionsLoader((ignore, statusRule) =>
            Promise.resolve(statusRule.ticketType?.children.flatMap(child => child.statuses) || []))
        .withLabel("Any children status");
    this.anyParentStatus = new Field([TicketStatus])
        .withOptionsLoader((ignore, statusRule) =>
            Promise.resolve(statusRule.ticketType?.parent?.statuses || []))
        .withLabel("Any parent status");
}, "TicketStatusRule", "ticket-status-rules", true);
export default TicketStatusRule;
