import {extendComponent} from "juis-components/ComponentUtils.js";
import Property from "juis-components/Property.js";
import Component from "juis-components/Component.js";
import UrlComponentMixin from "../components/UrlComponentMixin.js";
import {concatenateUrls} from "juis-router/NavigationUtils.js";
import HTML from "../utils/HTML.js";

let createLink = (url, text, icon, iconColor) => new Link(function () {
    this.text = text ?? url;
    this.url = url;
    if (icon || iconColor) {
        this.icon = icon || "circle";
        this.iconColor = iconColor;
    }
});

let createBlankLink = (url) => {
    return new Component(function () {
        this.getNode().setAttribute("href", url);
        this.getNode().setInnerHtml(url);
        this.getNode().setAttribute("target", "_blank");
        this.getNode().setAttribute("rel", "noopener noreferrer");
    }, [], HTML.A);
};

let Link = extendComponent(Component, function () {
    this.callback(UrlComponentMixin);
    this.registerDomEvents("click");
    this.preventDefaultDomEvent("click");


    const setContent = () => {
        if (!this.icon) {
            this.getNode().setText(this.text);
            return;
        }
        let style = "";
        if (this.iconColor) {
            style = `style="color:${this.iconColor};"`;
        }
        this.getNode().setInnerHtml(
            `<span class="icon"><i class="fas fa-${this.icon}" ${style}></i></span>
            <span>${this.text}</span>`);
    };
    this.text = new Property(setContent);
    this.icon = new Property(setContent);
    this.iconColor = new Property(setContent);
    this.autoBlur = false;
    this.on("click", () => {
        if (this.url && this.url !== "#" && this.url !== "/") {
            if (this.isRelative()) {
                this.navigate(concatenateUrls(this.baseUrl, this.url), {});
            } else {
                this.navigate(this.url, {});
            }
        }
        if (this.autoBlur) {
            this.getNode().getActualElement().blur();
        }
    });
    this.extendProperty("url", function (value) {
        this.getNode().setAttribute("href", value);
    });

}, "link", "A");

export {Link as default, createLink, createBlankLink};
