import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import {iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import resourceGroupLexicons from "./lexicons/resourceGroup/resourceGroupLexicons.js";
import {createLink} from "../../bulma_components/Link.js";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field().asString().withLabel("Url Code");
    this.name = new Field().asTranslatableString().withLabel("Name");
    this.description = new Field().asTranslatableString().withLabel("Description");
    this.icon = new Field().asString().withLabel("Icon").withEditorFactory(iconPickerFactory);
    this.color = new Field().asColor().withLabel("Color");
    this.makeTranslatable();
    setDefaults(this, {
            defaultField: this.name,
            linkFactory: group => createLink(`/${group.app.code}/resource-groups/${group.code}/`, group.name)
        }
    );
    new Linguist(resourceGroupLexicons).withAudience(this);
}, "ResourceGroup", "resource-groups");
