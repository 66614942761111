import Input from "../../bulma_components/Input.js";
import {createTextNode, extendComponent} from "juis-components/ComponentUtils.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import Container from "juis-components/Container.js";
import {createIcon} from "../../bulma_components/Icon.js";
import RestModel from "../../utils/RestModel.js";
import Button from "../../bulma_components/Button.js";
import HTML from "../../utils/HTML.js";
import Checkbox from "../../bulma_components/Checkbox.js";
import Component from "juis-components/Component.js";
import {parsePeriod} from "../../utils/PeriodUtils.js";
import CheckList from "../../bulma_components/CheckList.js";
import ButtonToggler from "../../bulma_components/ButtonToggler.js";
import InputWithSuggestions from "../dropdown/InputWithSuggestions.js";
import TagSelect from "../dropdown/TagSelect.js";
import TextComponent from "../TextComponent.js";
import {arrayWrap, removeByValue} from "juis-commons/JuisUtils.js";
import {getLocaleDropdown} from "../../hewecon/translations/localeDropdownFactory.js";
import LexiconEntry from "../../hewecon/models/LexiconEntry.js";
import IconPicker from "../icon-picker/IconPicker.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import fieldEditorLexicons from "./lexicons/fieldEditorLexicons.js";
import "./price-input.css";
import FileUpload from "../FileUpload.js";
import {imageThumbnailCellFactory} from "../ImageThumbnail.js";
import TextArea from "../TextArea.js";
import formatDuration from "../../utils/formatDuration.js";
import ColorButton from "../color-button/ColorButton.js";
import LOCALES from "../../hewecon/translations/locales.js";
import Field from "../../utils/Field";

// Must import restmodel here or get error
RestModel;

const HorizontalFormField = extendComponent(Container, function () {
    let oldLabel;
    this.setLabel = function (label) {
        if (label) {
            if (oldLabel) {
                this.removeChild(oldLabel);
            }
            if (typeof label === "string") {
                label = new TextComponent(label, HTML.LABEL, [BULMA.LABEL]);
            }
            label.getNode().addWrapper(HTML.DIV, [BULMA.FIELD.LABEL, BULMA.IS_NORMAL]);
            this.appendChild(label);
            this.moveFirst(label);
            oldLabel = label;
        }
    };

    this.fieldBody = new Container(function () {

    }, BULMA.FIELD.BODY);

    this.addField = function (...controls) {
        return this.fieldBody.appendChild(new Container(function () {
            let controlCount = 0;
            this.addControl = function (control, cssClasses = []) {
                controlCount++;
                if (controlCount > 1) {
                    this.getNode().addCssClass(BULMA.FIELD.HAS_ADDONS);
                }
                return this.appendChild(new Container(function () {
                    this.control = control;
                }, [BULMA.FIELD.CONTROL, ...arrayWrap(cssClasses)], HTML.SPAN));
            };
            this.setHelp = function (text, classes = []) {
                if (text) {
                    this.help = new TextComponent(text, HTML.P, [BULMA.HELP, ...classes]);
                }
            };
            controls.forEach(control => this.addControl(control));
        }, BULMA.FIELD.FIELD, HTML.DIV));
    };
}, [BULMA.FIELD.FIELD, BULMA.IS_HORIZONTAL]);

const createHorizontalFormField = (label, ...input) => {
    let field = new HorizontalFormField();
    field.setLabel(label);
    field.addField(...input);
    return field;
};

function createSimpleReadOnlyField(field, entity, cell) {
    return createSimpleField(field, entity, cell, true);
}

function createSimpleField(field, entity, input, inputIsReadOnly = false) {
    let fieldComponent = new HorizontalFormField();
    fieldComponent.setLabel(field.getLabel() || field.getName());
    let innerFieldComponent = fieldComponent.addField();
    if (field.readOnly && !inputIsReadOnly) {
        let cell = field.getCell(entity);
        innerFieldComponent.addControl(cell);
    } else {
        innerFieldComponent.addControl(input);
    }
    addListeners(input, field, entity);
    if (!field.readOnly) {
        innerFieldComponent.setHelp(field.getHelp());
    }
    fieldComponent.getInput = () => input; // Todo separate input and fieldcontainers in model definitions so that we
    // can more easily get the input when needed
    return fieldComponent;
}

function addListeners(component, field, entity) {
    let fieldNameHead = field.getNameHead().replace(/\.?extraFields/, "");
    let childEntity = entity;
    let childField = field.proxyBreakout();
    if (fieldNameHead) {
        childEntity = entity[fieldNameHead];
    }
    let entityChangeListener;
    if (childEntity) {
        entityChangeListener = childEntity.on(entity.EVENTS.CHANGE, (data) => {
            if (data.field.equals(childField)) {
                inputChangeListener.pause();
                component.setValue ? component.setValue(data.value) : component.value = data.value;
                inputChangeListener.resume();
            }
        });
    }
    let inputChangeListener = component.on("change", () => {
        entityChangeListener?.pauseOnce();
        entity[field.getName()] = component.getValue();
        entityChangeListener?.resume();
    });
    component.destroy = () => {
        entityChangeListener?.destruct();
        inputChangeListener.destruct();
    };
}

const defaultItemFactory = (itemData) => {
    if (typeof itemData === "string"
        || typeof itemData === "number"
        || typeof itemData === "boolean"
        || itemData === null
        || itemData === undefined) {
        return createTextNode(itemData);
    }
    return itemData;
};
const defaultTagItemFactory = (itemData) => {
    return new Container(function () {
        this.content = defaultItemFactory(itemData);
    }, [BULMA.TAG]);
};

function getDropDown(field, entity, settings = {}) {
    let childModel = field.getModelType();
    const select = new TagSelect();
    select.multiple = Array.isArray(field.getType());
    select.setItemFactory((itemData) => {
        const itemFactory = settings.itemFactory ||
            childModel?.getDefaultItemFactory() ||
            defaultItemFactory;
        return itemFactory(itemData, entity);
    });
    select.setTagFactory((itemData) => {
        const tagFactory = settings.tagFactory ||
            settings.itemFactory ||
            childModel?.getDefaultItemFactory() ||
            defaultTagItemFactory;
        return tagFactory(itemData, entity);
    });
    if (settings.groupBy) {
        select.setGroupBy(settings.groupBy);
    }
    if (settings.groupsClosed) {
        select.setGroupsClosed(true);
    }
    if (settings.groupHeaderFactory) {
        select.setGroupHeaderFactory(settings.groupHeaderFactory);
    }
    if (settings.searchable !== false) {
        select.enableSearch();
    }
    if (settings.creatorFactory) {
        select.enableCreator(settings.creatorFactory(entity));
    } else if (field.getCreatorFactory()) {
        select.enableCreator(field.getCreatorFactory()(entity));
    }
    if (settings.optionsLoader) {
        select.setOptionsLoader(settings.optionsLoader);
    } else {
        select.setOptionsLoader((searchString) => {
            return field.getOptionsPromise(searchString, entity);
        });
    }
    if (settings.searchInputCreator) {
        select.enableSearchInputCreator((input) => settings.searchInputCreator(input, entity), settings.searchMatcher);
    }
    if (settings.refreshOnChange) {
        entity.on("change", function (event) {
            if (settings.refreshOnChange.some(field => event.field.equals(field))) {
                select.search();
            }
        });
    }
    const resolved = resolveField(field, entity);
    if (resolved.entity === null) {
        select.setValue(null);
    } else if (resolved.entity && resolved.entity.isLazy(resolved.field)) {
        select.triggerButton.asLoading();
        field.getLazyValueFor(entity).then(value => {
            select.setValue(value);
            select.triggerButton.asIdle();
        });
    } else {
        select.setValue(field.getValueFor(entity));
    }
    return select;
}

const resolveField = function (field, entity) {
    if (!field.getModelType()) {
        return {};
    }
    const fieldName = field.getName();
    if (!fieldName.includes(".")) {
        return {field, entity};
    }
    const head = fieldName.substring(0, fieldName.indexOf("."));
    let tail = fieldName.substring(fieldName.indexOf(".") + 1);
    let child = entity[head];
    if (!child) {
        return {field, entity: null};
    }
    if (Array.isArray(child)) {
        let index = tail.substring(0, tail.indexOf("."));
        tail = tail.substring(tail.indexOf(".") + 1);
        child = child[index];
    }
    const childField = entity.getModel()[head].getModelType()[tail];
    return resolveField(childField, child);
};

function iconPickerFactory(field, entity) {
    const iconPicker = new IconPicker();
    iconPicker.setValue(field.getValueFor(entity));
    return createSimpleField(field, entity, iconPicker);
}

function getDropDownFactory(settings = {}) {
    return function (field, entity) {
        const select = getDropDown(field, entity, settings);
        return createSimpleField(field, entity, select);
    };
}

function getCheckListFactory(settings = {}) {

    return function (field, entity) {
        let checkList = CheckList();
        if (settings.labelFactory) {
            checkList.labelFactory = settings.labelFactory;
        }
        field.getOptionsPromise()
            .then(products => products.forEach(checkList.addOption))
            .then(() => checkList.setValue(entity[field.getName()]));
        return createSimpleField(field, entity, checkList);
    };
}

function createStringEditor(field, entity) {
    const input = new Input();
    input.maxLength = 250;
    const value = entity[field.getName()];
    if (value) {
        input.value = value;
    }
    return createSimpleField(field, entity, input);
}

function createFileEditor(field, entity) {
    const input = new Input(function () {
        this.type = "file";
        this.getValue = function () {
            return this.getNode().getActualElement().files[0];
        };
        this.setValue = function () {
            // Do nothing, cannot set value of file input programmatically
        };
    });
    return createSimpleField(field, entity, input);
}

function createImageFile(field, entity) {
    let fieldComponent = new HorizontalFormField();
    fieldComponent.setLabel(field.getLabel() || field.getName());
    const fieldBody = fieldComponent.addField();
    fieldBody.getNode().addCssClass("explode-mobile");
    const input = new FileUpload(function () {
        this.showName = false;
        this.icon = "camera";
        this.text = "Upload image";
        this.accept = "image/*";
        this.capture = "environment";
    });
    let initialValue = field.getValueFor(entity);
    if (!field.readOnly) {
        fieldBody.addControl(input, [BULMA_CSS_CLASSES.FIELD.IS_EXPANDED]);
        addListeners(input, field, entity);
        const text = new TextComponent("");
        text.getNode().addCssClass("px-3");
        fieldBody.addControl(text);

        const deleteButton = fieldBody.addControl(new Button(function () {
            this.icon = "trash";
            this.on("click", () => {
                entity[field.getName()] = null;
            });
        }, [BULMA.IS_DANGER]));
        const setValue = (value) => {
            if (value === null) {
                text.text = "";
                deleteButton.getNode().addCssClass(BULMA_CSS_CLASSES.IS_HIDDEN);
            } else if (typeof value === "string") {
                text.text = value;
                deleteButton.getNode().removeCssClass(BULMA_CSS_CLASSES.IS_HIDDEN);
            } else if (value instanceof File) {
                text.text = value.name;
                deleteButton.getNode().removeCssClass(BULMA_CSS_CLASSES.IS_HIDDEN);
            }
        };
        setValue(initialValue);
        entity.on("change", () => {
            console.log(field.getValueFor(entity));
            setValue(field.getValueFor(entity));
        }).filter(change => change.field.equals(field));

    } else if (initialValue) {
        fieldBody.addControl(imageThumbnailCellFactory(initialValue));
    }
    return fieldComponent;
}

function createTranslatableMultiLineEditor(field, entity) {
    return createTranslatableStringEditor(field, entity, TextArea);
}

function createTranslatableStringEditor(field, entity, inputConstructor = Input) {
    const key = field.getName();
    const fieldContainer = new Container();
    fieldContainer.getNode().addCssClass(BULMA_CSS_CLASSES.FIELD.FIELD);
    const fieldComponent = new HorizontalFormField();
    fieldComponent.setLabel(field.getLabel() || field.getName());
    const input = new inputConstructor();
    const value = entity[key];
    if (value) {
        input.setValue(value);
    }
    addListeners(input, field, entity);
    const fieldBody = fieldComponent.addField();
    if (field.readOnly) {
        let cell = field.getCell(entity);
        fieldBody.addControl(cell);
        fieldContainer.appendChild(fieldComponent);
        return fieldContainer;
    }

    const getNextLanguage = () => {
        const existingLanguages = entity.lexiconEntries
            .filter(entry => entry.key === key)
            .map(entry => entry.language);
        return Object.keys(LOCALES).find(langauge => !existingLanguages.includes(langauge));
    };

    fieldBody.addControl(input, BULMA_CSS_CLASSES.FIELD.IS_EXPANDED);
    const languageButton = new Button(function () {
        this.icon = "language";
        this.on("click", () => {
            const entry = new LexiconEntry;
            entry.key = key;
            entry.language = getNextLanguage();
            entity.lexiconEntries.push(entry);
        });
    }, [BULMA.IS_INFO]);
    fieldBody.addControl(languageButton);

    fieldBody.bindListener(entity.on("change", (change) => {
        if (change.field.getName() === "lexiconEntries") {
            refreshTranslationFields();
        }
    }));
    const refreshTranslationFields = () => {
        fieldContainer.removeAllChildren();
        fieldContainer.appendChild(fieldComponent);
        languageButton.disabled = !getNextLanguage();
        entity.lexiconEntries
            .filter(entry => entry.key === key)
            .forEach(entry => fieldContainer.appendChild(getTranslationField(entry, entity, inputConstructor)));
    };
    refreshTranslationFields();
    return fieldContainer;
}

const getTranslationField = (lexiconEntry, owner, inputConstructor = Input) => {
    const translationInput = new inputConstructor();
    let value = lexiconEntry.translation;
    if (value) {
        translationInput.setValue(value);
    }
    addListeners(translationInput, LexiconEntry.translation, lexiconEntry);
    const languageFieldComponent = new HorizontalFormField();
    languageFieldComponent.insertBefore(new Component(function () {

    }, [BULMA_CSS_CLASSES.FIELD.LABEL]));
    let translationFieldBody = languageFieldComponent.addField();
    translationFieldBody.addControl(translationInput, BULMA_CSS_CLASSES.FIELD.IS_EXPANDED);
    const dropdown = getLocaleDropdown();
    dropdown.setValue(lexiconEntry.language);
    addListeners(dropdown, LexiconEntry.language, lexiconEntry);
    translationFieldBody.addControl(dropdown);
    translationFieldBody.addControl(new Button(function () {
        this.icon = "trash";
        this.on("click", () => removeByValue(owner["lexiconEntries"], lexiconEntry));
    }, [BULMA.IS_DANGER]));
    return languageFieldComponent;
};

function createColorEditor(field, entity) {
    let fieldComponent = new HorizontalFormField();
    fieldComponent.setLabel(field.getLabel() || field.getName());
    const fieldBody = fieldComponent.addField();
    const value = entity[field.getName()];
    if (field.readOnly) {
        if (value) {
            fieldBody.addControl(new ColorButton(function () {
                this.backgroundColor = value;
                this.text = value;
            }));
        }
        return fieldComponent;
    }
    const input = new Input();
    input.type = Input.Types.COLOR;
    const notSelected = new Button(function () {
        this.text = "Not selected";
        this.on("click", () => {
            inputControl.getNode().togglePlaceholder(false);
            resetButtonControl.getNode().togglePlaceholder(false);
            notSelectedControl.getNode().togglePlaceholder(true);
        });
    });
    const resetButton = new Button(function () {
        this.icon = "undo";
        this.on("click", () => {
            entity[field.getName()] = null;
            inputControl.getNode().togglePlaceholder(true);
            resetButtonControl.getNode().togglePlaceholder(true);
            notSelectedControl.getNode().togglePlaceholder(false);
        });
    });

    let inputControl = fieldBody.addControl(input, [BULMA_CSS_CLASSES.FIELD.IS_EXPANDED]);
    let notSelectedControl = fieldBody.addControl(notSelected, [BULMA_CSS_CLASSES.FIELD.IS_EXPANDED]);
    let resetButtonControl = fieldBody.addControl(resetButton);
    if (value) {
        input.value = value;
        inputControl.getNode().togglePlaceholder(false);
        resetButtonControl.getNode().togglePlaceholder(false);
        notSelectedControl.getNode().togglePlaceholder(true);
    } else {
        inputControl.getNode().togglePlaceholder(true);
        resetButtonControl.getNode().togglePlaceholder(true);
        notSelectedControl.getNode().togglePlaceholder(false);
    }
    addListeners(input, field, entity);
    return fieldComponent;
}

function createReadOnly(field, entity) {
    let cell = field.getCell(entity);
    let fieldComponent = createSimpleReadOnlyField(field, entity, cell);
    entity.on(entity.EVENTS.CHANGE, (event) => {
        if (event.field.proxyBreakout() === field.proxyBreakout()) {
            let newCell = field.getCell(entity);
            cell.getNode().replaceSelf(newCell.getNode());
            cell = newCell;
        }
    });
    return fieldComponent;
}

function createCheckboxEditor(field, entity) {
    const checkbox = new Checkbox();
    const value = entity[field.getName()];
    if (value) {
        checkbox.value = value;
    }
    return createSimpleField(field, entity, checkbox);
}

function createNumberInput(field, entity) {
    const input = new Input();
    if (entity[field.getName()]) {
        input.value = entity[field.getName()];
    } else if (field.getDefaultValue()) {
        entity[field.getName()] = field.getDefaultValue();
        input.value = entity[field.getName()];
    }
    input.type = Input.Types.NUMBER;
    input.step = field.step;
    input.min = field.min;
    input.max = field.max;
    return input;
}

function createNumberEditor(field, entity) {
    const input = createNumberInput(field, entity);
    return createSimpleField(field, entity, input);
}

function createDurationEditor(field, entity) {
    let fieldComponent = new HorizontalFormField();
    fieldComponent.setLabel(field.getLabel() || field.getName());
    const fieldBody = fieldComponent.addField();
    const value = entity[field.getName()];
    if (field.readOnly) {
        fieldBody.addControl(new TextComponent(formatDuration(value)));
        return fieldComponent;
    }
    let multiplier = 1;
    let unitToggler = ButtonToggler(function () {
        this.addValue("seconds", "Seconds").withCallback(() => multiplier = 1);
        this.addValue("minutes", "Minutes").withCallback(() => multiplier = 60);
        this.addValue("hours", "Hours").withCallback(() => multiplier = 3600);
    }, [BULMA_CSS_CLASSES.COLORS.HAS_BACKGROUND_LIGHT]);
    const input = new Input();
    input.type = Input.Types.NUMBER;
    input.overrideMethod("setValue", (overridden, value) =>
        overridden(new Intl.NumberFormat().format(value / multiplier)));
    input.overrideMethod("getValue", (overridden) => parseFloat(overridden()) * multiplier);
    if (value % 3600 === 0) {
        unitToggler.setValue("hours");
    } else if (value % 60 === 0) {
        unitToggler.setValue("minutes");
    }
    unitToggler.on("change", () => input.trigger("change"));
    if (value) {
        input.setValue(value);
    }
    fieldBody.addControl(input, [BULMA_CSS_CLASSES.FIELD.IS_EXPANDED]);
    fieldBody.addControl(unitToggler);
    addListeners(input, field, entity);
    return fieldComponent;
}

function createMoneyInput(field, entity) {
    const oneCent = 0.01;
    const input = new Input();
    const value = entity[field.getName()];
    if (value) {
        input.value = value.toFixed(2);
    }
    input.overrideMethod("setValue", (overridden, value) => {
        if (typeof value === "string") {
            value = parseInt(value, 10);
        }
        return overridden(value.toFixed(2));
    });
    input.type = Input.Types.NUMBER;
    input.step = oneCent;
    return input;
}

function createMoneyEditor(field, entity) {
    const input = createMoneyInput(field, entity);
    return createSimpleField(field, entity, input);
}

function createDateEditor(field, entity) {
    const input = new Input();
    input.type = Input.Types.DATE;
    const value = entity[field.getName()];
    input.setValue(value);
    return createSimpleField(field, entity, input);
}

function createPeriodEditor(field, entity) {
    let fieldComponent = new HorizontalFormField();
    fieldComponent.setLabel(field.getLabel() || field.getName());
    if (field.getHelp()) {
        fieldComponent.setHelp(field.getHelp());
    }


    const yearFieldBody = fieldComponent.addField();
    const year = new Input();
    year.type = Input.Types.NUMBER;
    year.step = 1;
    year.min = -10;
    year.max = 10;
    year.getNode().setStyle("text-align", "right");
    yearFieldBody.addControl(year, BULMA_CSS_CLASSES.FIELD.IS_EXPANDED);
    let yearText = new TextComponent("Years", HTML.OUTPUT, [
        BULMA_CSS_CLASSES.INPUT,
        BULMA_CSS_CLASSES.COLORS.HAS_BACKGROUND_LIGHT
    ]);
    yearFieldBody.addControl(yearText);

    const monthFieldBody = fieldComponent.addField();
    const month = new Input();
    month.type = Input.Types.NUMBER;
    month.step = 1;
    month.min = -11;
    month.max = 11;
    month.getNode().setStyle("text-align", "right");
    monthFieldBody.addControl(month, BULMA_CSS_CLASSES.FIELD.IS_EXPANDED);
    let monthText = new TextComponent("Months", HTML.OUTPUT, [
        BULMA_CSS_CLASSES.INPUT,
        BULMA_CSS_CLASSES.COLORS.HAS_BACKGROUND_LIGHT
    ]);
    monthFieldBody.addControl(monthText);

    const dayFieldBody = fieldComponent.addField();
    const day = new Input();
    day.type = Input.Types.NUMBER;
    day.step = 1;
    day.min = -29;
    day.max = 29;
    day.getNode().setStyle("text-align", "right");
    dayFieldBody.addControl(day, BULMA_CSS_CLASSES.FIELD.IS_EXPANDED);
    let daysText = new TextComponent("Days", HTML.OUTPUT, [
        BULMA_CSS_CLASSES.INPUT,
        BULMA_CSS_CLASSES.COLORS.HAS_BACKGROUND_LIGHT
    ]);
    dayFieldBody.addControl(daysText);


    fieldComponent.getValue = function () {
        return `P${year.getValue()}Y${month.getValue()}M${day.getValue()}D`;
    };
    fieldComponent.setValue = function (value) {
        const parsedValue = parsePeriod(value);
        console.log(value, parsedValue);
        year.setValue(parsedValue.years);
        month.setValue(parsedValue.months);
        day.setValue(parsedValue.days);
    };
    let value = entity[field.getName()];
    fieldComponent.setValue(value);
    addListeners(fieldComponent, field, entity);
    return fieldComponent;
}

function createTimestampEditor(field, entity) {
    const input = new Input();
    input.type = Input.Types.DATETIME_LOCAL;
    const value = entity[field.getName()];
    input.setValue(value);
    return createSimpleField(field, entity, input);
}

function createTextAreaEditor(field, entity) {
    const input = new Component(function () {
        this.registerDomEvents("input");
        this.registerDomEvents("change");
        this.getValue = () => this.getNode().getElementProperty("value");
        this.setValue = (value) => this.getNode().setElementProperty("value", value);
    }, ["textarea"], "textarea");
    const value = entity[field.getName()];
    if (value) {
        input.setValue(value);
    }
    return createSimpleField(field, entity, input);
}

function createHtmlEditor(field, entity) {
    const value = entity[field.getName()];
    const input = new Container(function () {
        this.downloadButton = new Component(function () {
            this.getNode().setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(value));
            this.getNode().setAttribute("download", entity.name ? entity.name + ".html" : "template.html");
            this.getNode().setInnerHtml("Download");
        }, [BULMA.BUTTON, BULMA.IS_SMALL], HTML.A);
        this.content = new Component(function () {
            this.getNode().setText(value);

            this.getNode().setStyle("line-height", "1em");
            this.getNode().setStyle("max-height", "4em");
        }, ["pre"], "drop-area");


    }, [BULMA.FIELD.CONTROL], HTML.DIV);
    //
    // if (value) {
    //     input.setValue(value);
    // }
    return createSimpleField(field, entity, input);
}

function createChildForm(field, childEntity, entity) {
    return new Container(function () {
        const formFieldComponents = {};
        const setChildEditorFields = () => {
            const entityEditorFields = field.getEntityEditorFields(entity);
            Object.entries(formFieldComponents).forEach(entry => {
                if (!entityEditorFields.map(field => field.getName()).includes(entry[0])) {
                    entry[1].getNode().togglePlaceholder(true);
                }
            });
            entityEditorFields.forEach(childField => {
                const overriddenChildField = {...field, ...childField};
                overriddenChildField.readOnly = field.readOnly;
                if (formFieldComponents[overriddenChildField.getName()]) {
                    formFieldComponents[overriddenChildField.getName()].getNode().togglePlaceholder(false);
                } else {
                    let formFieldComponent = overriddenChildField.getFormComponent(childEntity);
                    formFieldComponents[overriddenChildField.getName()] = formFieldComponent;
                    this.appendChild(formFieldComponent);
                }
            });
        };
        setChildEditorFields();
        entity.on(entity.EVENTS.CHANGE, setChildEditorFields);
        this.getValue = () => childEntity;
    }, ["child-form"]);
}

function createChildFormArray(field, childEntities, entity) {
    return new Container(function () {
        const editors = [];
        this.addChildEditor = function (childEntity) {
            let childEditor = createChildForm(field, childEntity, entity);
            editors.push(childEditor);
            this.appendChild(childEditor);
        };

        this.removeLastChildEditor = function () {
            if (editors.length > 1) {
                let childEditor = editors.pop();
                this.removeChild(childEditor);
                this.trigger("change");
            }
        };

        this.setValue = (values) => values.forEach(value => this.addChildEditor(value));
        this.getValue = () => editors.map(editor => editor.getValue());

        this.setValue(childEntities);
    });
}


function createChildEntityEditor(field, entity) {
    const createChild = () => {
        const child = field.getModelType().createInstance();
        if (entity.app) {
            child.app = entity.app;
        }
        return child;
    };
    let value = entity[field.getName()];
    let isArray = Array.isArray(field.getType());
    if (!value || (Array.isArray(value) && value.length === 0)) {
        value = createChild();
        if (isArray) {
            value = [value];
        }
    }
    let editor;
    let fieldComponent = new HorizontalFormField();
    if (isArray) {
        editor = createChildFormArray(field, value, entity);
        fieldComponent.setLabel(new Container((label) => {
            label.text = new TextComponent(field.getLabel(), HTML.LABEL);
            if (!field.readOnly) {
                label.controls = new Container((controls) => {
                    controls.getNode().setStyle("margin-top", "0.5em");
                    controls.remove = new Button((button) => {
                        button.icon = "minus";
                        button.on("click", () => editor.removeLastChildEditor());
                    }, [BULMA.TYPOGRAPHY.HAS_TEXT_DANGER, "is-small"]);
                    controls.add = new Button((button) => {
                        button.icon = "plus";
                        button.on("click", () => editor.addChildEditor(createChild()));
                    }, [BULMA.TYPOGRAPHY.HAS_TEXT_SUCCESS, "is-small"]);
                }, [BULMA.IS_PULLED_RIGHT, BULMA.FIELD.HAS_ADDONS, "is-no-float-desktop"]);
            }
        }, [BULMA.LABEL]));
    } else {
        editor = createChildForm(field, value, entity);
        fieldComponent.setLabel(field.getLabel());
    }
    fieldComponent.addField(editor);
    editor.on("change", () => {
        entity[field.getName()] = editor.getValue();
    });
    return fieldComponent;
}

function getVatPercentageEditorFactory(vatIncludedField) {
    return function createVatPercentageEditor(field, entity) {
        let fieldComponent = new HorizontalFormField();
        fieldComponent.setLabel(field.getLabel() || field.getName());
        if (field.getHelp()) {
            fieldComponent.setHelp(field.getHelp());
        }
        const fieldBody = fieldComponent.addField();
        if (field.readOnly) {
            let vatGroup = field.getValueFor(entity);
            let vatString = vatIncludedField.getValueFor(entity) ? "included in" : "excluded from";
            fieldBody.addControl(new TextComponent(`${vatGroup.vatPercentage} % ${vatString} price`));
            return fieldComponent;
        }
        const vatPercentage = getDropDown(field, entity);
        fieldBody.addControl(vatPercentage, [BULMA_CSS_CLASSES.FIELD.IS_EXPANDED,]);
        let priceIncludesVat = ButtonToggler(function () {
            this.addValue(false, "Excluded from price");
            this.addValue(true, "Included in price");
            this.getNode().setStyle("width", "12em");
        });
        priceIncludesVat.setValue(entity[vatIncludedField.getName()]);
        fieldBody.addControl(priceIncludesVat);

        addListeners(vatPercentage, field, entity);
        addListeners(priceIncludesVat, vatIncludedField, entity);
        return fieldComponent;
    };
}

const linguist = new Linguist(fieldEditorLexicons);

function getPriceWithVatEditorFactory(vatIncludedField, vatPercentageField, currencyCodeField) {
    return function createVatPercentageEditor(field, entity) {
        let fieldComponent = new HorizontalFormField();
        fieldComponent.setLabel(field.getLabel() || field.getName());
        const fieldBody = fieldComponent.addField();
        if (field.getHelp()) {
            fieldComponent.setHelp(field.getHelp());
        }
        let childEntity = entity;
        let prefix = field.getNameHead();
        if (prefix) {
            childEntity = entity[prefix];
        }
        if (!childEntity) {
            return fieldComponent;
        }
        const currency = currencyCodeField.getValueFor(childEntity);
        if (field.readOnly) {
            let textComponent = new TextComponent();
            fieldBody.addControl(textComponent);
            let entityChangeListener = childEntity.on(entity.EVENTS.CHANGE, (data) => {
                if ([field, vatIncludedField, vatPercentageField].some(someField => data.field.equals(someField))) {
                    setText();
                }
            });
            fieldComponent.bindListener(entityChangeListener);
            const setText = () => {
                let priceString = new Intl.NumberFormat(Linguist.getLanguage(), {style: "currency", currency})
                    .format(field.getValueFor(entity));
                let vatPercentage = vatPercentageField.getValueFor(childEntity);
                if (vatPercentage > 0) {
                    let vatString = vatIncludedField.getValueFor(childEntity) ? linguist.t("priceEditor.including") : linguist.t("priceEditor.excluding");
                    textComponent.text = `${priceString}, ${vatString} ${vatPercentage}% ${linguist.t("priceEditor.VAT")}`;
                } else {
                    textComponent.text = priceString;
                }
            };
            setText();
            return fieldComponent;
        }
        const price = createMoneyInput(field, entity);
        let priceControl = fieldBody.addControl(price, [BULMA_CSS_CLASSES.FIELD.IS_EXPANDED, "has-icons-right"]);
        let icon;
        if (currency === "EUR") {
            icon = createIcon("euro-sign", "is-small", "is-right");
        } else {
            icon = new Component(function () {
                this.innerHTML = currency;
            }, [BULMA.IS_SMALL, BULMA.IS_RIGHT, BULMA.ICON], HTML.SPAN);
        }
        priceControl.appendChild(icon);

        let priceIncludesVat = ButtonToggler(function () {
            this.addValue(false, linguist.t("priceEditor.exclVat"));
            this.addValue(true, linguist.t("priceEditor.inclVat"));
        });
        priceIncludesVat.setValue(childEntity[vatIncludedField.getName()]);
        fieldBody.addControl(priceIncludesVat);

        if (vatPercentageField.getType() === Field.NUMBER) {
            const vatPercentage = createNumberInput(vatPercentageField, childEntity);
            vatPercentage.getNode().setStyle("min-width", "4em");
            const vatPercentageControl = fieldBody.addControl(vatPercentage, ["has-icons-right"]);
            vatPercentageControl.appendChild(createIcon("percent", "is-small", "is-right"));
            addListeners(vatPercentage, vatPercentageField, childEntity);
        } else {
            const vatPercentage = getDropDown(vatPercentageField, childEntity, {searchable: false});
            vatPercentage.getNode().setStyle("min-width", "4em");
            fieldBody.addControl(vatPercentage);
            addListeners(vatPercentage, vatPercentageField, childEntity);
        }
        addListeners(price, field, entity);
        addListeners(priceIncludesVat, vatIncludedField, childEntity);
        fieldComponent.getNode().addCssClass("price-input");
        return fieldComponent;
    };
}

const addressSuggestionInputFactory = (postalCodeNameField, postalCodeField, linguist) => {
    return (field, entity) => {
        const input = new InputWithSuggestions();
        input.setOptionsLoader((searchString) => {
            if (searchString === "" || searchString.length <= 2) {
                return Promise.resolve([]);
            }
            return fetch(`/api/address-suggestions/${encodeURIComponent(searchString)}/${linguist.getLanguage()}`)
                .then(response => response.json());
        });
        const value = entity[field.getName()];
        if (value) {
            input.setValue(value);
        }
        input.setSuggestionToTextMapper(suggestion => suggestion.street);
        input.setItemFactory(result =>
            `${result.street}, ${result.zipCode} ${result.city}`
        );
        input.setKeyFactory(value => value.street + value.city + value.zipCode);
        input.on("suggestionSelected", (suggestion) => {
            if (postalCodeField) {
                entity[postalCodeField.getName()] = suggestion.zipCode;
            }
            if (postalCodeNameField) {
                entity[postalCodeNameField.getName()] = suggestion.city;
            }
        });
        return createSimpleField(field, entity, input);
    };
};

export {
    createSimpleField,
    createCheckboxEditor,
    createStringEditor,
    createNumberEditor,
    createDateEditor,
    createChildEntityEditor,
    createReadOnly,
    getDropDownFactory,
    getCheckListFactory,
    getVatPercentageEditorFactory,
    getPriceWithVatEditorFactory,
    createColorEditor,
    createMoneyEditor,
    createTimestampEditor,
    createTextAreaEditor,
    createPeriodEditor,
    HorizontalFormField,
    createHorizontalFormField,
    addressSuggestionInputFactory,
    createDurationEditor,
    getDropDown,
    addListeners,
    createTranslatableStringEditor,
    iconPickerFactory,
    createFileEditor,
    createImageFile,
    createTranslatableMultiLineEditor
};
