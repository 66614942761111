import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import DynamicField from "./DynamicField.js";
import DynamicEntityTab from "./DynamicEntityTab.js";
import setDefaults from "./utils/setDefaults.js";
import {createLink} from "../../bulma_components/Link.js";
import dynamicModels from "./utils/dynamicModels.js";

const DynamicEntityTabView = new RestModel(function () {
    this.app = new Field(App);
    this.code = new Field()
        .asString()
        .withLabel("Url Code");
    this.id = new Field()
        .asReadOnly()
        .asNumber();
    this.name = new Field().asString();
    this.titleField = new Field(DynamicField)
        .withOptionsLoader(DynamicField.getOptionsLoaderForModelGetter(view => view.model));
    this.tabs = new Field([DynamicEntityTab]).asCascading();
    this.model = new Field()
        .withDefaultValueCallback(() => "Ticket")
        .asEnum(dynamicModels)
        .withLabel("Model");

    this.link = new Field()
        .asVirtual()
        .withLabel("link")
        .withCellFactory((ignore, view) => createLink("/" + view.app.code + "/d-e/" + view.code, view.name));

    setDefaults(this, {
        filterFields: [this.label, this.name],
        linkFactory: view => createLink("/" + view.app.code + "/d-e/settings/" + view.code, view.name),
    });

}, "DynamicEntityTabView", "dynamic-entity-tab-view");
export default DynamicEntityTabView;
