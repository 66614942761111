import Container from "juis-components/Container.js";
import {getDropDownFactory} from "../../../components/fieldEditors/editorFactories.js";
import TextComponent from "../../../components/TextComponent.js";
import HTML from "../../../utils/HTML.js";
import BULMA from "../../../bulma_components/bulmaCssClasses.js";
import App from "../App.js";
import Filter from "../../../utils/Filter.js";

const getFieldComponentCreator = (field) => componentFactoryWrapper((entity, parentEntity) => field.getCell(entity));

const setOrderBy = (model, defaults = {}) => {
    if (defaults.orderByField) {
        model.setDefaultOrderBy(defaults.orderByField);
    } else if (defaults.defaultField) {
        model.setDefaultOrderBy(defaults.defaultField);
    } else if (defaults.filterFields) {
        model.setDefaultOrderBy(defaults.filterFields[0]);
    }
};

const getApp = entity => entity instanceof App ? entity : entity.app;

const setOptionsLoader = (model, defaults = {}) => {
    if (defaults.optionsLoader) {
        model.setDefaultOptionsLoader(defaults.optionsLoader);
    } else if (defaults.filterFields) {
        model.setDefaultOptionsLoader((searchString, owner, filter = Filter.true) =>
            model.getListForDropdown(searchString, defaults.filterFields, getApp(owner), filter, defaults.orderByField));
    } else if (defaults.defaultField) {
        model.setDefaultOptionsLoader((searchString, owner, filter = Filter.true) =>
            model.getListForDropdown(searchString, [defaults.defaultField], getApp(owner), filter, defaults.orderByField));
    }
};

const componentFactoryWrapper = (factory) => {
    return (entity, parentEntity) => {
        if (!entity || entity.isLazy()) {
            return null;
        }
        if (entity?.deleted) {
            return new TextComponent("[ DELETED ]", HTML.SPAN, BULMA.TYPOGRAPHY.HAS_TEXT_DANGER);
        }
        return factory(entity, parentEntity);
    };
};

const setCellFactory = (model, defaults = {}) => {
    if (defaults.cellFactory) {
        model.setDefaultCellFactory(componentFactoryWrapper(defaults.cellFactory));
    } else if (defaults.cellField) {
        model.setDefaultCellFactory(getFieldComponentCreator(defaults.cellField));
    } else if (defaults.linkFactory) {
        model.setDefaultCellFactory(componentFactoryWrapper(defaults.linkFactory));
    } else if (defaults.defaultField) {
        model.setDefaultCellFactory(getFieldComponentCreator(defaults.defaultField));
    }
};

const setListCellFactory = (model, defaults = {}) => {
    if (defaults.listCellFactory) {
        model.setDefaultListCellFactory(defaults.listCellFactory);
    } else if (model.getDefaultCellFactory()) {
        model.setDefaultListCellFactory((values, entity) => {
            return new Container(function () {
                values.forEach(value => {
                    this.appendChild(model.getDefaultCellFactory()(value, entity));
                });
            }, ["cell-list"]);
        });
    }
};

const setItemFactory = (model, defaults = {}) => {
    if (defaults.itemFactory) {
        model.setDefaultItemFactory(defaults.itemFactory);
    } else if (defaults.itemField) {
        model.setDefaultItemFactory(getFieldComponentCreator(defaults.itemField));
    } else if (defaults.defaultField) {
        model.setDefaultItemFactory(getFieldComponentCreator(defaults.defaultField));
    }
};

/**
 *
 * @param  {RestModel} model,
 * @param {{
 * orderByField: Field?,
 * filterFields: Field[]?,
 * optionsLoader:OptionsLoader?,
 * cellField:Field?,
 * cellFactory:FieldComponentCreator?,
 * listCellFactory:FieldComponentCreator?,
 * itemField:Field?,
 * itemFactory:*,
 * editorFactory:FieldComponentCreator?,
 * defaultField:Field?,
 * linkFactory:*
 * }} defaults
 */
export default function (model, defaults = {}) {
    setOrderBy(model, defaults);
    setOptionsLoader(model, defaults);
    setCellFactory(model, defaults);
    setItemFactory(model, defaults);
    setListCellFactory(model, defaults);
    if (defaults.exportField) {
        model.setExportField(defaults.exportField);
    }
    if (defaults.defaultField) {
        model.setDefaultField(defaults.defaultField);
    }
    model.setDefaultEditorFactory(defaults.editorFactory ?? getDropDownFactory());
}
