import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import Menu from "./Menu.js";
import BillTemplate from "./BillTemplate.js";
import BillType from "./BillType.js";
import ContactGroup from "./ContactGroup.js";
import Board from "./Board.js";
import DynamicEntityTabView from "./DynamicEntityTabView.js";
import NamedTimePeriod from "./NamedTimePeriod.js";
import ProductGroup from "./ProductGroup.js";
import ProductList from "./ProductList.js";
import ProductView from "./ProductView.js";
import StockLocation from "./StockLocation.js";
import StockProductList from "./StockProductList.js";
import Stock from "./Stock.js";
import TicketList from "./TicketList.js";
import TicketProductMapper from "./TicketProductMapper.js";
import TicketStatusRule from "./TicketStatusRule.js";
import TicketType from "./TicketType.js";
import TicketTemplateProduct from "./TicketTemplateProduct.js";
import BillList from "./BillList.js";
import ResourceRequirement from "./ResourceRequirement.js";
import TimePeriodGroup from "./TimePeriodGroup.js";
import ResourceGroup from "./ResourceGroup.js";
import ContactList from "./ContactList.js";
import DynamicField from "./DynamicField.js";
import TicketStatus from "./TicketStatus.js";
import ProductionList from "./ProductionList.js";
import SalesReport from "./SalesReport.js";
import SalesPriceRule from "./SalesPriceRule";
import VatGroup from "./VatGroup";

export default new Model(function () {
    this.app = new Field(App);
    this.billLists = new Field([BillList]);
    this.billTemplates = new Field([BillTemplate]);
    this.billTypes = new Field([BillType]);
    this.boards = new Field([Board]);
    this.contactGroups = new Field([ContactGroup]);
    this.contactLists = new Field([ContactList]);
    this.defaultBillType = new Field(BillType);
    this.defaultStockList = new Field(StockProductList);
    this.dynamicEntityTabViews = new Field([DynamicEntityTabView]);
    this.menus = new Field([Menu]);
    this.namedTimePeriods = new Field([NamedTimePeriod]);
    this.productGroups = new Field([ProductGroup]);
    this.productionLists = new Field([ProductionList]);
    this.productList = new Field(ProductList);
    this.productLists = new Field([ProductList]);
    this.productView = new Field(ProductView);
    this.productViews = new Field([ProductView]);
    this.resourceGroups = new Field([ResourceGroup]);
    this.resourceRequirements = new Field([ResourceRequirement]);
    this.stockLocations = new Field([StockLocation]);
    this.stockProductLists = new Field([StockProductList]);
    this.stocks = new Field([Stock]);
    this.ticketLists = new Field([TicketList]);
    this.ticketProductMappers = new Field([TicketProductMapper]);
    this.ticketStatusRules = new Field([TicketStatusRule]);
    this.ticketStatuses = new Field([TicketStatus]);
    this.ticketTemplateProducts = new Field([TicketTemplateProduct]);
    this.ticketTypes = new Field([TicketType]);
    this.timePeriodGroups = new Field([TimePeriodGroup]);
    this.dynamicFields = new Field([DynamicField]);
    this.salesReports = new Field([SalesReport]);
    this.salesPriceRules = new Field([SalesPriceRule]);
    this.vatGroups = new Field([VatGroup]);

}, "AppSettings", "app-settings");
