import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import setDefaults from "./utils/setDefaults.js";
import App from "./App.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import TextComponent from "../../components/TextComponent.js";
import HTML from "../../utils/HTML.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import dynamicFieldOptionLexicons from "./lexicons/dynamic-field-option/dynamicFieldOptionLexicons.js";
import {getDropDownFactory, iconPickerFactory} from "../../components/fieldEditors/editorFactories";
import Container from "juis-components/Container.js";
import {createColorIconForEntity} from "../../bulma_components/Icon";
import Method from "../../utils/Method";
import DynamicField from "./DynamicField";

const linguist = new Linguist(dynamicFieldOptionLexicons);
const DynamicFieldOption = new Model(function () {
    this.app = new Field(App);
    this.value = new Field().asTranslatableString();
    this.textColor = new Field().asColor();
    this.backgroundColor = new Field().asColor();
    this.icon = new Field().asString().withLabel("Icon").withEditorFactory(iconPickerFactory);
    this.iconForegroundColor = new Field().asColor();
    this.iconBackgroundColor = new Field().asColor();
    this.dynamicField = new Field(DynamicField);
    this.makeTranslatable();
    this.getIconCell = new Method(function () {
        return createColorIconForEntity(this);
    });
    this.getItem = new Method(function () {
        let item = new TextComponent(DynamicFieldOption.value.getTranslatedValueFor(this), HTML.DIV);
        if (this.backgroundColor) {
            item.getNode().addCssClass(BULMA.TAG);
            item.getNode().setStyle("background-color", this.backgroundColor);
        }
        if (this.textColor) {
            item.getNode().setStyle("color", this.textColor);
        }
        if (this.icon) {
            return new Container((container) => {
                container.icon = createColorIconForEntity(this);
                item.getNode().addCssClass("ml-2");
                container.text = item;
                container.text.getNode().addCssClass(BULMA.IS_INLINE_BLOCK);
            });
        }
        return item;
    });
    setDefaults(this, {
        cellFactory: option => {
            if (option.dynamicField.renderOptionCellAsIcon) {
                return createColorIconForEntity(option, this.value.getTranslatedValueFor(option));
            }
            return option.getItem();
        },
        itemFactory: option => option.getItem(),
        editorFactory: getDropDownFactory({
            searchable: true,
            searchInputCreator: (input, parent) => {
                const newOption = new DynamicFieldOption();
                newOption.value = input;
                newOption.dynamicField = parent;
                return Promise.resolve(newOption);
            }
        })
    });
    linguist.withAudience(this);
}, "Dynamic field option");
export {DynamicFieldOption as default};
