import {extendComponent, getChildCssClassMixin} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Component from "juis-components/Component.js";
import Link, {createLink} from "../Link.js";
import Property, {CssProperty} from "juis-components/Property.js";
import BULMA from "../bulmaCssClasses.js";
import BULMA_CSS_CLASSES from "../bulmaCssClasses.js";
import HTML from "../../utils/HTML.js";
import UrlComponentMixin from "../../components/UrlComponentMixin.js";
import {createIcon} from "../Icon.js";
import TextComponent from "../../components/TextComponent.js";
import createNavbarUserMenu from "../../components/navbar/createNavbarUserMenu.js";
import createNavbarLoginMenu from "../../components/navbar/createNavbarLoginMenu.js";
import MenuItem from "../../hewecon/models/MenuItem.js";
import {REQUEST_NAVIGATE} from "juis-router/Events.js";
import "./navbar.scss";

let Hamburger = extendComponent(Component, function () {
    this.registerDomEvents("click");
    this.getNode().setInnerHtml("<span></span><span></span><span></span>");
    this.active = new CssProperty(BULMA.IS_ACTIVE);
    this.on("click", () => this.active = !this.active);
}, [BULMA.NAVBAR.BURGER], HTML.DIV);

let Brand = extendComponent(Container, function () {
    this.hamburger = new Hamburger();
    this.overrideMethod("appendChild", (overridden, child) => {
        child.getNode().addCssClass(BULMA.NAVBAR.ITEM);
        this.insertBefore(child, this.hamburger);
    });
    this.overrideMethod("replaceChild", (overridden, newChild, oldChild) => {
        newChild.getNode().addCssClass(BULMA.NAVBAR.ITEM);
        overridden(newChild, oldChild);
    });
}, [BULMA.NAVBAR.BRAND], HTML.DIV);

let NavbarDropdown = extendComponent(Container, function () {
    let notClickableLink = Component(function () {
        this.callback(UrlComponentMixin);
        this.icon = new Property((icon) => {
            this.getNode().setInnerHtml(`<span class="icon"><i class="fas fa-${icon}"></i></span>`);
        });
    }, [BULMA.NAVBAR.LINK], HTML.SPAN);
    let clickableLink = new Link(function () {
    }, [BULMA.NAVBAR.LINK], HTML.SPAN);
    clickableLink.autoBlur = true;
    this.appendChild(notClickableLink);

    let dropdown = new Container();
    dropdown.getNode().addCssClass(BULMA.NAVBAR.DROPDOWN);
    this.appendChild(dropdown);

    this.addItem = function (url, text, icon, before = null) {
        let item = createLink(url, text, icon);
        item.autoBlur = true;
        item.getNode().addCssClass(BULMA.NAVBAR.ITEM);
        if (before) {
            dropdown.insertBefore(item, before);
        } else {
            dropdown.appendChild(item);
        }
        return item;
    };
    this.removeItem = function (item) {
        dropdown.removeChild(item);
    };
    this.addNotification = function (notification) {
        let item = new Container(function () {
            this.icon = createIcon(notification.icon);
            if (!notification.seen) {
                this.icon.getNode().addCssClass(BULMA_CSS_CLASSES.TYPOGRAPHY.HAS_TEXT_DANGER);
            }
            this.header = new TextComponent(notification.header, HTML.SPAN, BULMA_CSS_CLASSES.TYPOGRAPHY.HAS_TEXT_WEIGHT_SEMIBOLD);
            this.text = new TextComponent(notification.text, HTML.DIV);
            this.text.getNode().setStyle("margin-left", "2em");
            this.getNode().setAttribute("href", notification.link);
            this.registerDomEvents("click");
            this.preventDefaultDomEvent("click");
            this.on("click", () => {
                if (notification.link) {
                    this.navigate(notification.link);
                }
            });
        }, [BULMA.NAVBAR.ITEM, "is-block"], HTML.A);
        dropdown.appendChild(item);
        return item;
    };
    this.addDivider = (before = undefined) => {
        let divider = new Component(undefined, [BULMA.NAVBAR.DIVIDER], HTML.HR);
        if (before !== undefined) {
            dropdown.insertBefore(divider, before);
        } else {
            dropdown.appendChild(divider);
        }
        return divider;
    };
    this.url = new Property((url) => {
        clickableLink.url = url;
        notClickableLink.url = url;
    });
    this.text = new Property((text) => {
        clickableLink.getNode().setText(text);
        notClickableLink.getNode().setText(text);
    });
    this.icon = new Property((icon) => {
        clickableLink.icon = icon;
        notClickableLink.icon = icon;
    });
    this.isArrowless = new Property((value) => {
        if (value) {
            clickableLink.getNode().addCssClass("is-arrowless");
            notClickableLink.getNode().addCssClass("is-arrowless");
        }
    }, false);
    this.clickable = new Property(clickable => {
        if (clickable) {
            this.replaceChild(clickableLink, notClickableLink);
        } else {
            this.replaceChild(notClickableLink, clickableLink);
        }
    });
    this.isRight = new Property(value => {
        if (value) {
            dropdown.getNode().addCssClass(BULMA.IS_RIGHT);
        } else {
            dropdown.getNode().removeCssClass(BULMA.IS_RIGHT);
        }
    });
}, [BULMA.NAVBAR.ITEM, BULMA.HAS_DROPDOWN, BULMA.IS_HOVERABLE]);

let Navbar = extendComponent(Container, function () {
    this.brand = new Brand();
    this.setText = (text, url) => {
        if (url) {
            this.brand.logo = createLink(url, text);
        } else {
            this.brand.logo = new TextComponent(text);
        }
    };
    this.setLogo = (logoUrl, alternative, url) => {
        this.brand.logo = new Component(function () {
            this.getNode().setAttribute("src", logoUrl);
            this.getNode().setAttribute("alt", alternative);
            this.getNode().setStyle("height", "2.75rem");
            this.getNode().setStyle("margin-left", "0.25rem");
            this.getNode().setStyle("margin-right", "1.25rem");
            this.getNode().setStyle("margin-top", "0.25rem");
            this.getNode().setStyle("margin-bottom", "0.25rem");
            if (url) {
                this.registerDomEvents("click");
                this.getNode().setStyle("cursor", "pointer");
                this.on("click", () => this.trigger(REQUEST_NAVIGATE, {url}));
            }
        }, [BULMA_CSS_CLASSES.IS_PADDINGLESS], HTML.IMG);
    };
    this.setText = (text, url) => {
        this.brand.logo = new TextComponent(text, "LABEL", ["is-family-primary", "has-text-weight-bold"]);
        if (url) {
            this.brand.logo.registerDomEvents("click");
            this.brand.logo.getNode().setStyle("cursor", "pointer");
            this.brand.logo.on("click", () => this.trigger(REQUEST_NAVIGATE, {url}));
        }
    };
    this.menu = new Container(function () {
        this.active = new CssProperty(BULMA.IS_ACTIVE);
        this.start = new Container(function () {
            this.callback(getChildCssClassMixin(BULMA.NAVBAR.ITEM));
        }, [BULMA.NAVBAR.START]);
    }, [BULMA.NAVBAR.MENU], HTML.DIV);

    this.setShowingApp = (showingApp) => {
        if (showingApp) {
            this.getNode().removeCssClass(BULMA.IS_PRIMARY);
            this.getNode().removeCssClass("is-spaced");
        } else {
            this.getNode().addCssClass(BULMA.IS_PRIMARY);
            this.getNode().addCssClass("is-spaced");
        }
    };
    this.clearItems = function () {
        this.menu.start.removeAllChildren();
    };
    this.addItem = function (item) {
        if (!item.children || item.children.length === 0) {
            let link = createLink(item.getUrl(), MenuItem.text.getTranslatedValueFor(item));
            this.menu.start.appendChild(link);
        } else {
            this.menu.start.appendChild(new NavbarDropdown(function () {
                this.text = MenuItem.text.getTranslatedValueFor(item);
                if (item.getUrl()) {
                    this.url = item.getUrl();
                    this.clickable = true;
                }
                let previousOrdinal;
                item.children.forEach(child => {
                    if (previousOrdinal && previousOrdinal + 1 < child.ordinal) {
                        this.addDivider();
                    }
                    previousOrdinal = child.ordinal;
                    if (child.text === null) {
                        this.addDivider();
                    } else {
                        this.addItem(child.getUrl(), MenuItem.text.getTranslatedValueFor(child));
                    }
                });
            }));
        }
    };

    this.menu.on(REQUEST_NAVIGATE, (event) => {
        this.brand.hamburger.active = false;
        this.menu.triggerOnce(REQUEST_NAVIGATE, event, {skipOrigin: true});
    });
    this.brand.hamburger.extendProperty("active", (value) => this.menu.active = value);
    this.setUser = (isLoggedIn) => {
        if (isLoggedIn) {
            this.menu.end = createNavbarUserMenu();
        } else {
            this.menu.end = createNavbarLoginMenu();
        }
    };
}, [BULMA.NAVBAR.NAVBAR, BULMA.NAVBAR.FIXED_TOP, "has-shadow"], HTML.NAV);


export {Navbar as default, NavbarDropdown};
