import JuiS from "juis-components/JuiS.js";
import "../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "../../node_modules/@fortawesome/fontawesome-free/css/solid.min.css";
import "../../node_modules/@fortawesome/fontawesome-free/css/brands.min.css";
import "../style.scss";
import "../style.css";
import Footer from "./Footer.js";
import Notification, {notificationEvents} from "./components/notification/Notification.js";
import RestServer from "./utils/RestServer.js";
import getHeweconRouter from "./getHeweconRouter.js";
import {AFTER_NAVIGATION, REQUEST_NAVIGATE} from "juis-router/Events.js";
import Navbar from "./bulma_components/navbar/Navbar.js";
import SessionHandler, {SESSION_HANDLER_EVENTS} from "./SessionHandler.js";
import {SHOW_APP_MENU} from "./appView.js";
import App from "./hewecon/models/App.js";
import {concatenateUrls} from "juis-router/NavigationUtils.js";
import EventLoggerSingleton from "./utils/EventLogger.js";
import registerServiceWorker from "./registerServiceWorker.js";

// Import these so they are always preloaded for better performance when loading front-page
import "./hewecon/views/front-page/index.js";
import "./hewecon/views/front-page/style.scss";

const isVersionOutdated = (version) => {
    if (!version || version === HEWECON_GLOBAL.frontendVersion) {
        return false;
    }
    let comparingParts = version.split(".");
    let currentVersionParts = HEWECON_GLOBAL.frontendVersion.split(".");
    if (comparingParts.length !== currentVersionParts.length) {
        return true;
    }
    return comparingParts.some((part, index) => part > currentVersionParts[index]);
};

new JuiS(document.body, function (body) {
    new EventLoggerSingleton();
    body.getNode().addCssClass("has-navbar-fixed-top");
    body.getNode().addCssClass("main");

    let backgroundIndex;
    const setBackground = (backgroundUrl) => {
        body.getNode().setStyle("background-image", `url("${backgroundUrl}")`);
    };
    const unsetBackground = () => {
        body.getNode().setStyle("background-image", "unset");
    };

    body.notification = new Notification();
    body.content = getHeweconRouter();
    body.navbar = new Navbar(function () {
        SessionHandler.on(
            [
                SESSION_HANDLER_EVENTS.SESSION_STARTED,
                SESSION_HANDLER_EVENTS.SESSION_UPDATED,
                SESSION_HANDLER_EVENTS.SESSION_ENDED
            ], this.setUser);
        this.setUser(SessionHandler.isLoggedIn());
        this.redirectEvent(REQUEST_NAVIGATE, body.content);
        body.content.on(SHOW_APP_MENU, ({menu, app}) => {
            this.clearItems();
            menu.items.forEach((item) => this.addItem(item));
            let url = menu.defaultLink ? concatenateUrls("/", app.code, menu.defaultLink) : "";
            if (app.logo) {
                this.setLogo(app.logo, App.name.getTranslatedValueFor(app), url);
            } else {
                this.setText(App.name.getTranslatedValueFor(app), url);
            }
        });
    });
    body.footer = new Footer();
    body.content.redirectEvent(Object.values(notificationEvents), body.notification);
    body.content.on(AFTER_NAVIGATION, (event) => {
        const appCode = event.parameters?.appCode;
        if (appCode) {
            App.ifExists(appCode).then((appData) => setAppData(appCode, appData));
        } else {
            unsetAppData();
            body.navbar.clearItems();
        }
    });

    let notifyIfOutdated = (backendVersion) => {
        if (isVersionOutdated(backendVersion)) {
            return body.notification.notifyNewVersion(backendVersion);
        }
    };
    RestServer.setVersionCallback(notifyIfOutdated);
    body.on("checkVersion", () => fetch("/api/version").then(response => response.text()).then(notifyIfOutdated));

    let manifestTag;
    let appleTouchIcon;
    let addManifest = (appCode) => {
        removeManifest();
        manifestTag = document.createElement("LINK");
        manifestTag.setAttribute("rel", "manifest");
        manifestTag.setAttribute("href", `/api/apps/${appCode}/manifest.json`);
        document.head.appendChild(manifestTag);

        appleTouchIcon = document.createElement("LINK");
        appleTouchIcon.setAttribute("rel", "apple-touch-icon");
        appleTouchIcon.setAttribute("href", `/api/icon-generator/${appCode}/icon-192.png`);
        document.head.appendChild(appleTouchIcon);
    };
    let removeManifest = () => {
        if (manifestTag) {
            document.head.removeChild(manifestTag);
            manifestTag = null;
        }
        if (appleTouchIcon) {
            document.head.removeChild(appleTouchIcon);
            appleTouchIcon = null;
        }
    };

    let appStyle;
    let navigateToAppListOnLogin = SessionHandler.on(SESSION_HANDLER_EVENTS.SESSION_STARTED, (user) => {
        if (user.roles.length === 1) {
            body.content.trigger(REQUEST_NAVIGATE, {url: `/${user.roles[0].appCode}/`});
        } else {
            body.content.trigger(REQUEST_NAVIGATE, {url: `/users/${user.username}/apps`});
        }
    });

    let currentAppCode;
    const setAppData = (appCode, appData) => {
        if (currentAppCode === appCode) {
            return;
        }
        currentAppCode = appCode;
        if (appData.footer) {
            body.footer.getNode().setWrapperStyle("background-image", `url(${appData.footer})`);
            body.footer.getNode().setWrapperStyle("background-size", "auto");
        } else {
            body.footer.getNode().setWrapperStyle("background-image", "unset");
        }
        if (!SessionHandler.getCurrentUser() && appData.loginBackground) {
            setBackground(appData.loginBackground);
            SessionHandler.when(SESSION_HANDLER_EVENTS.SESSION_STARTED).then(unsetBackground);
        }
        if (appData.favicon) {
            document.querySelector("link[rel~='icon']").href = appData.favicon;
        }
        if (appStyle) {
            document.head.removeChild(appStyle);
        }
        if (appData.cssOverride) {
            appStyle = document.createElement("LINK");
            appStyle.href = appData.cssOverride;
            appStyle.rel = "stylesheet";
            document.head.appendChild(appStyle);
        }
        if (appData.logo) {
            body.navbar.setLogo(appData.logo, appData.name);
        } else {
            body.navbar.setText(appData.name);
        }
        body.navbar.setShowingApp(true);
        registerServiceWorker(appCode, appData)
            .then((registration) => {
                let listener = body.on("click", () => {
                    if (SessionHandler.getCurrentUser()) {
                        body.notification.requestNotificationPermission(appCode, registration);
                        listener.destruct();
                    }
                });
            })
            .catch(() => {
                // Probably no service worker available in browser. Do nothing.
            });
        addManifest(appCode);
        document.title = appData.name;
        navigateToAppListOnLogin.pause();
    };
    const unsetAppData = () => {
        currentAppCode = null;
        removeManifest();
        body.footer.getNode().setWrapperStyle("background-image", "url(/src/hewecon/views/front-page/footer8.avif)");
        body.footer.getNode().setWrapperStyle("background-size", "cover");
        const favicon = document.querySelector("link[rel~='icon']");
        if (favicon.href !== "/hewecon-logo-32.png") {
            favicon.href = "/hewecon-logo-32.png";
        }
        if (appStyle) {
            document.head.removeChild(appStyle);
            appStyle = null;
        }
        document.title = "HEWECON";
        body.navbar.setText("HEWECON", "/");
        body.navbar.setShowingApp(false);
        navigateToAppListOnLogin.resume();
    };
    SessionHandler.on(SESSION_HANDLER_EVENTS.SESSION_ENDED, () => body.content.trigger(REQUEST_NAVIGATE, {url: "/"}));
});
