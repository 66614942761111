import Component from "juis-components/Component.js";
import Property from "juis-components/Property.js";
import BulmaUtils from "../bulma_components/BulmaUtils.js";

/**
 * Creates a component containing the given text
 * @param text
 * @param tagName
 * @param classNames
 * @returns Component
 * @constructor
 */
export default function TextComponent(text, tagName = "SPAN", classNames = []) {
    return new Component((component) => {
        component.text = new Property((newText) => component.getNode().setText(newText), text);
        component.tooltip = BulmaUtils.getTooltipProperty(component);
    }, classNames, tagName);
};
