import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import resourceLexicons from "./lexicons/resource/resourceLexicons.js";
import Ticket from "./Ticket.js";
import {createTranslatableMultiLineEditor, iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import ResourceGroup from "./ResourceGroup.js";
import Aggregation from "../../utils/Aggregation.js";
import {createLink} from "../../bulma_components/Link.js";
import Method from "../../utils/Method.js";
import Container from "juis-components/Container.js";
import {createColorIcon} from "../../bulma_components/Icon.js";
import BULMA_COLORS from "../../bulma_components/bulmaColors.js";
import TextComponent from "../../components/TextComponent";
import HTML from "../../utils/HTML";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field().asString().withLabel("Url Code");
    this.name = new Field().asTranslatableString().withLabel("Name");
    this.description = new Field().asTranslatableString().withLabel("Description").withEditorFactory(createTranslatableMultiLineEditor);
    this.icon = new Field().asString().withLabel("Icon").withEditorFactory(iconPickerFactory);
    this.color = new Field().asColor().withLabel("Color");
    this.allowDoubleBookings = new Field().asBoolean().withLabel("Allow double bookings");
    this.currentTicket = new Field(Ticket).asReadOnly().withLabel("Current ticket");
    this.groups = new Field([ResourceGroup]).withLabel("Groups");
    this.doubleBooked = new Field().asBoolean("Is double booked").asReadOnly();
    this.extraFields = new Field().asJson();

    this.ticketType = Aggregation.distinct(this.currentTicket.type);
    this.getUrl = new Method(function () {
        return `/${this.app.code}/resources/${this.code}`;
    });
    this.getIcon = new Method(function () {
        return this.icon ?? this.groups.find(group => group.icon)?.icon;
    });
    this.getColor = new Method(function () {
        return this.color ?? this.groups.find(group => group.color)?.color;
    });
    this.makeTranslatable();
    setDefaults(this, {
        defaultField: this.name,
        itemFactory: resource => {
            const icon = resource.getIcon();
            const color = resource.getColor();
            return new Container((item) => {
                if (icon || color) {
                    item.icon = createColorIcon(icon, color);
                }
                item.text = new TextComponent(this.name.getTranslatedValueFor(resource), HTML.SPAN, "ml-2");
            });
        },
        linkFactory: resource => {
            const link = createLink(resource.getUrl(), this.name.getTranslatedValueFor(resource), resource.getIcon(), resource.getColor());
            if (resource.doubleBooked && !resource.allowDoubleBookings) {
                return new Container((container) => {
                    container.link = link;
                    container.warning = createColorIcon("warning", BULMA_COLORS.RED);
                    container.warning.getNode().setAttribute("title", this.doubleBooked.getLabel());
                    resource.on(this.EVENTS.REFRESH, () => {
                        if (!resource.doubleBooked) {
                            container.warning = null;
                        }
                    });
                });
            }
            return link;
        },
    });
    new Linguist(resourceLexicons).withAudience(this);
}, "Resource", "resources");
