import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import Container from "juis-components/Container.js";
import {createIcon} from "../../bulma_components/Icon.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import HTML from "../../utils/HTML.js";
import TextComponent from "../../components/TextComponent.js";
import {iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import App from "./App.js";

export default new Model(function () {
    this.app = new Field(App);
    this.id = new Field().asReadOnly().asNumber();
    this.deleted = new Field().asBoolean();
    this.header = new Field().asString().withLabel("Header");
    this.text = new Field().asString().withLabel("Text");
    this.link = new Field().asString().withLabel("Link");
    this.icon = new Field().asString().withLabel("Icon").withEditorFactory(iconPickerFactory);
    this.created = new Field().asTimestamp(Linguist.getLanguage()).withLabel("Time");
    this.seen = new Field().asBoolean().withLabel("Seen");

    this.summary = new Field().asVirtual(this.header).withLabel("Notification")
        .withCellFactory((ignore, notification) => {
            return new Container(function () {
                this.icon = createIcon(notification.icon);
                this.icon.getNode().setStyle("vertical-align", "top");
                this.icon.getNode().setStyle("margin-right", "1em");
                this.textContainer = new Container(function () {
                    this.header = new TextComponent(notification.header, HTML.SPAN, BULMA_CSS_CLASSES.TYPOGRAPHY.HAS_TEXT_WEIGHT_SEMIBOLD);
                    this.text = new TextComponent(notification.text, HTML.DIV);
                    this.addCssClass(BULMA.IS_INLINE_BLOCK);
                });
            }, [], HTML.TD);
        });
}, "Notification", "notifications");
