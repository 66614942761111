import Container from "juis-components/Container.js";
import SessionHandler, {SESSION_HANDLER_EVENTS} from "./SessionHandler.js";
import App from "./hewecon/models/App.js";
import getRequestAccessPage from "./hewecon/views/no-access/getNoAccessPage.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import {NAVIGATE} from "juis-router/Events.js";
import LoginPage from "./hewecon/views/login-page/index.js";
import CustomerForm from "./hewecon/models/CustomerForm.js";
import Menu from "./hewecon/models/Menu.js";
import MenuItem from "./hewecon/models/MenuItem.js";
import BULMA from "./bulma_components/bulmaCssClasses.js";
import TextComponent from "./components/TextComponent.js";
import getAppRouter from "./getAppRouter.js";
import HTML from "./utils/HTML.js";
import {createIcon} from "./bulma_components/Icon.js";
import PromiseButton from "./components/PromiseButton.js";
import Linguist from "../lib/JuiS/Linguist.js";
import appViewLexicons from "./hewecon/views/apps/lexicons/appViewLexicons.js";
import handleModificationEvents from "./utils/handleModificationEvents.js";
import {NOT_FOUND} from "juis-commons/Errors.js";

const SHOW_APP_MENU = Symbol();
const linguist = new Linguist(appViewLexicons);
const appView = extendComponent(Container, function () {
    this.when(NAVIGATE).then(event => {
        let appCode = event.parameters["appCode"];
        const sessionUser = SessionHandler.getCurrentUser();
        if (!sessionUser) {
            this.content = new LoginPage();
        } else if (sessionUser.appCodeList.includes(appCode)) {
            initializeApp(appCode, sessionUser);
        } else {
            CustomerForm.getAllPublic(appCode).then(response => {
                if (response.data.length > 0) {
                    initializeApp(appCode, sessionUser);
                } else {
                    this.content = getRequestAccessPage(appCode, sessionUser);
                }
            });
        }
        this.bindListener(SessionHandler.on(
            [SESSION_HANDLER_EVENTS.SESSION_STARTED, SESSION_HANDLER_EVENTS.SESSION_UPDATED],
            sessionUser => initializeApp(appCode, sessionUser)
        ));

        document.addEventListener("visibilitychange", () => {
            if (!document.hidden && document.body.contains(this.getNode().getActualElement()) && SessionHandler.getCurrentUser()) {
                // Wait a second to allow device time to connect to network. This avoids "Failed to fetch" network errors.
                setTimeout(() => App.getByCode(appCode).then(loadAppSettings), 1000);
            }
        });
    });

    const getAdminItems = (app) => ({
        app,
        text: "Admin",
        link: "/settings-cards",
        children: []
    });

    const loadAppSettings = (app) => app.refreshLazyField(App.settings).then(() => app);

    const getMenuStructure = (userRole, app) => {
        const roleType = userRole?.role || null;
        const isAdmin = roleType === "ADMIN";
        let menu = userRole.menu ?? app.settings.menus.find(foundMenu => foundMenu.role === roleType);
        if (!menu && isAdmin) {
            menu = app.settings.menus.find(foundMenu => foundMenu.role === "COORDINATOR");
        }
        if (!menu && isAdmin) {
            menu = app.settings.menus[0];
        }
        if (!menu) {
            menu = new Menu({text: "No menu available", items: []});
        } else {
            menu = menu.copy();
        }
        if (isAdmin) {
            menu.items.push(new MenuItem(getAdminItems(app)));
        }
        return menu;
    };


    const getNotificationBar = (icon, title, subtitle, style) => {
        return new Container(function () {
            this.left = new Container(function () {
                this.icon = createIcon(icon);
                this.icon.is3x = true;
                this.icon.addCssClass(BULMA.IS_PULLED_LEFT);
                this.icon.getNode().setStyle("width", "100%");
                this.icon.getNode().setStyle("height", "100%");
            }, [BULMA.COLUMN, "is-one-fifth"]);
            this.right = new Container(function () {
                this.title = new TextComponent(title, HTML.P, [BULMA.TITLE]);
                this.subtitle = new TextComponent(subtitle, HTML.P, [BULMA.SUBTITLE]);
            }, [BULMA.COLUMN]);
            this.getNode().addWrapper(HTML.DIV, [BULMA.HERO_BODY]);
            this.getNode().addWrapper(HTML.SECTION, [BULMA.HERO, BULMA.IS_SMALL, style]);
        }, ["hero-body", "columns", BULMA.IS_MARGINLESS], HTML.DIV);
    };

    const createDemoBar = (app) => {
        let demoBar = getNotificationBar("person-chalkboard", linguist.t("demo"), linguist.t("convertToRealTip"), BULMA.IS_PRIMARY);
        demoBar.right.appendChild(new PromiseButton(function () {
            this.icon = "handshake";
            this.text = linguist.t("convertToReal");
            this.isSmall = true;
            this.callback = () => {
                if (SessionHandler.getCurrentUser().anonymous) {
                    return this.navigate("/create-account", {});
                }
                return app.fetchCustomEndpoint(`${app.id}/demo-to-real`)
                    .then(() => window.location.reload(true));
            };
        }, [BULMA.IS_SMALL, BULMA.FIELD.CONTROL]));
        return demoBar;
    };
    const createArchivedBar = (app) => {
        return getNotificationBar("box-archive", "Archived", "This app is no longer maintained. It can be used as an archive.", BULMA.IS_DANGER);
    };
    const initializeApp = (appCode, sessionUser) => {
        let role = sessionUser.roles.find(foundRole => foundRole.appCode === appCode);
        App.getByCode(appCode)
            .then(loadAppSettings)
            .then(app => {
                handleModificationEvents(app);
                const menu = getMenuStructure(role, app);
                if (app.demo) {
                    this.demoBar = createDemoBar(app);
                }
                if (app.archived) {
                    this.archivedBar = createArchivedBar(app);
                }
                this.content = getAppRouter(menu?.getUrl());
                this.trigger(SHOW_APP_MENU, {menu, app});
            })
            .catch(e => {
                if (e.message === NOT_FOUND) {
                    this.content = getRequestAccessPage(appCode, sessionUser);
                } else {
                    throw e;
                }
            });
    };
});
export {appView as default, SHOW_APP_MENU};
