function Optional(value, otherCallback = () => {
}) {
    const hasValue = () => {
        return value !== undefined && value !== null;
    };
    const isPromise = () => {
        return value instanceof Promise;
    };
    const handlePromise = (callback) => {
        if (isPromise()) {
            return value.then(realValue => {
                value = realValue;
                return callback();
            });
        }
        return callback();
    };
    this.orElseGet = (getter) => {
        return handlePromise(() => {
            if (hasValue()) {
                return value;
            } else {
                let otherValue = getter.call();
                otherCallback(otherValue);
                return otherValue;
            }
        });
    };
    this.orElse = (other) => {
        return handlePromise(() => {
            if (hasValue()) {
                return value;
            } else {
                otherCallback(other);
                return other;
            }
        });
    };
    this.orElseGetPromise = (promiseGetter) => {
        return handlePromise(() => {
            if (hasValue()) {
                return Promise.resolve(value);
            } else {
                return promiseGetter.call();
            }
        });
    };
    this.orElsePromise = (promise) => {
        return handlePromise(() => {
            if (hasValue()) {
                return Promise.resolve(value);
            } else {
                return promise;
            }
        });
    };
    this.orElseThrow = (error) => {
        return handlePromise(() => {
            if (hasValue()) {
                return value;
            } else {
                throw error;
            }
        });
    };
    this.map = (callback) => {
        if (isPromise()) {
            value = value.then(realValue => {
                if (realValue !== undefined && realValue !== null) {
                    return callback.call(realValue, realValue);
                } else {
                    return realValue;
                }
            });
        } else {
            if (hasValue()) {
                value = callback.call(value, value);
            }
        }
        return this;
    };
    this.peek = (callback) => {
        if (hasValue()) {
            callback.call(value, value);
        }
        return this;
    };
    this.asPromise = function () {
        if (isPromise()) {
            return value;
        }
        if (hasValue()) {
            return Promise.resolve(value);
        }
        return Promise.reject(value);
    };
}

let staticOptional = {"of": (value, otherCallback) => new Optional(value, otherCallback)};

export {staticOptional as default};
