import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import Product from "./Product.js";
import ProductList from "./ProductList.js";
import StockProductList from "./StockProductList.js";
import App from "./App.js";
import {getSecondLevelFields} from "../../utils/fieldUtils.js";
import setDefaults from "./utils/setDefaults.js";
import DynamicField from "./DynamicField.js";
import SalesReport from "./SalesReport.js";

let ProductView = new Model(function () {
    this.app = new Field(App);

    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Url Code");

    this.name = new Field()
        .asString()
        .withLabel("Name");

    this.fields = new Field()
        .asEnumList(getSecondLevelFields(Product))
        .withLabel("Deprecated Fields");

    this.dynamicFields = new Field([DynamicField])
        .withLabel("Editor Fields")
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Product));

    this.showVariationsList = new Field()
        .asBoolean()
        .withLabel("Show variations");

    this.variationList = new Field(ProductList).withLabel("Variation list");

    this.stockProductList = new Field(StockProductList).withLabel("Stock products list");
    this.salesReports = new Field([SalesReport]).withLabel("Sales Reports");

    this.showTransactionList = new Field()
        .asBoolean()
        .withLabel("Show transactions");
    this.excludeFromCachedDescendants();

    this.getUrl = function () {
        return `/${this.app.code}/product-views/${this.code}`;
    };

    setDefaults(this, {
        filterFields: [this.name],
        linkFactory: view => createLink(view.getUrl(), view.name, view.icon, view.color),
        cellField: this.name
    });
}, "ProductView", "product-views", true);

export {ProductView as default};
