import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import NamedTimePeriod from "./NamedTimePeriod.js";
import App from "./App.js";
import {createLink} from "../../bulma_components/Link.js";
import setDefaults from "./utils/setDefaults.js";
import {iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import timePeriodGroupLexicons from "./lexicons/time-period-group/timePeriodGroupLexicons.js";

const linguist = new Linguist(timePeriodGroupLexicons);
export default new Model(function () {
    this.app = new Field(App);
    this.name = new Field().asString();
    this.icon = new Field().asString().withLabel("Icon").withEditorFactory(iconPickerFactory);
    this.code = new Field().asString();
    this.periods = new Field([NamedTimePeriod]).asCascading();
    this.periodQty = new Field().asVirtual().withCellFactory((ignore, group) => group.periods.length);
    this.latestPeriod = new Field().asVirtual().withCellFactory((ignore, group) => {
        let latest;
        group.periods.forEach(period => {
            if (!latest || period.end > latest.end) {
                latest = period;
            }
        });
        return NamedTimePeriod.getDefaultCell(latest);
    });

    setDefaults(this, {
        defaultField: this.name,
        linkFactory: (periodGroup) =>
            createLink(`/${periodGroup.app.code}/time-periods/${periodGroup.code}`,
                periodGroup.name),
    });
    linguist.withAudience(this);
}, "TimePeriodGroup", "time-period-groups");
