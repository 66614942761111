export default [
    "Bill",
    "Contact",
    "Resource",
    "ResourceGroup",
    "Product",
    "Production",
    "Stock",
    "StockProduct",
    "Ticket",
    "TicketProduct",
    "TicketType",
    "Transaction",
    "WorkLog"
];
