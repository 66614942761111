import {extendComponent} from "juis-components/ComponentUtils.js";
import HTML from "../../../utils/HTML.js";
import BULMA from "../../../bulma_components/bulmaCssClasses.js";
import Container from "juis-components/Container.js";
import Linguist from "../../../../lib/JuiS/Linguist.js";
import frontPageLexicons from "./lexicons/frontPageLexicons";
import TextComponent from "../../../components/TextComponent";
import "./style.scss";

const t = new Linguist(frontPageLexicons).t;

export default extendComponent(Container, function () {
    this.body = new Container(function () {
        this.title = new TextComponent("HEWECON", HTML.DIV, [
            BULMA.TITLE,
            BULMA.HAS_TEXT_CENTERED,
            BULMA.TYPOGRAPHY.HAS_TEXT_WEIGHT_BOLD,
            "big-title",
            "mt-2"
        ]);
        this.subtitle = new TextComponent(t("slogan"), HTML.DIV, [
            BULMA.SUBTITLE,
            BULMA.IS_4,
            "is-size-4-mobile",
            BULMA.HAS_TEXT_CENTERED,
            BULMA.TYPOGRAPHY.HAS_TEXT_WEIGHT_BOLD
        ]);
    }, [
        BULMA.HERO_BODY,
        BULMA.CONTAINER,
        BULMA.IS_FLEX,
        "is-justify-content-space-between",
        "is-flex-direction-column",
    ]);
}, [BULMA.HERO, BULMA.IS_PRIMARY, BULMA.IS_UNSELECTABLE, BULMA.IS_BLOCK, "not-fixed", "pb-6"]);
