import Input from "../../bulma_components/Input.js";
import Base from "./Base.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import PromiseThrottler from "../../utils/PromiseThrottler.js";

export default extendComponent(Base, function () {
    let suggestionToTextMapper = (result) => result;
    this.setSuggestionToTextMapper = mapper => suggestionToTextMapper = mapper;


    this.input = new Input(function () {
        this.type = Input.Types.SEARCH;
        this.getNode().setElementProperty("autocomplete", "off");
        this.registerDomEvents("keydown");
        this.getNode().removeCssClass("input");
        this.maxLength = 250;
    }, ["juis-select-search"]);
    this.overrideMethod("search", (overridden, searchString) => {
        this.showDropdown();
        overridden.call(this, searchString);
    });

    const searcher = new PromiseThrottler().withCallback((value) => this.search(value)).defer(250);
    this.input.on("input", () => searcher.load(this.getValue()));

    this.on("change", (data, event) => {
        if (this.equals(event.getOrigin())) {
            // Event is from dropdown
            let suggestion = this.getDropdownValue();
            if (suggestion) {
                this.input.setValue(suggestionToTextMapper(suggestion));
                this.trigger("suggestionSelected", suggestion);
            }
            event.stopPropagation();

        }
    });


    this.getDropdownValue = this.getValue;
    this.getValue = this.input.getValue;
    this.setValue = this.input.setValue;
    this.triggerButton.icon = "";
    this.getNode().removeAttribute("tabindex");
});
