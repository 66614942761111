import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import bankAccountLexicons from "./lexicons/product-group/productGroupLexicon.js";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field().asString().withLabel("Url Code");
    this.name = new Field().asString().withLabel("Name");
    this.bank = new Field().asString().withLabel("Bank");
    this.iban = new Field().asString().withLabel("IBAN").withCellFactory(value => value ? value.replace(/(.{4})/g, '$1 ') : "");
    this.bic = new Field().asString().withLabel("BIC");

    setDefaults(this, {defaultField: this.name});

    this.on([this.EVENTS.BEFORE_SAVE], (account) => {
        if (account.iban) {
            account.iban = account.iban.replace(/\s/g, '');
        }
    });
    new Linguist(bankAccountLexicons).withAudience(this);
}, "BankAccount", "bank-accounts");
