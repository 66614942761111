import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import TicketType from "./TicketType.js";
import DynamicField from "./DynamicField.js";
import Ticket from "./Ticket.js";
import PromiseButton from "../../components/PromiseButton.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import Method from "../../utils/Method.js";
import {ACTIONS} from "../../components/Actions.js";
import {createColorIcon} from "../../bulma_components/Icon.js";
import TicketStatus from "./TicketStatus";

export default new RestModel(function () {
    this.app = new Field(App);
    this.code = new Field().asString();
    this.name = new Field().asString();
    this.title = new Field().asString();
    this.icon = new Field().asString();
    this.target = new Field(TicketType);
    this.owner = new Field(TicketType);
    this.fields = new Field([DynamicField]).withOptionsLoader(DynamicField.getOptionsLoaderForModel(Ticket));
    this.targetStatus = new Field(TicketStatus).withOptionsLoader((filter, copyOption) => Promise.resolve(copyOption?.target?.statuses ?? []));
    this.updateOwnStatus = new Field(TicketStatus).withOptionsLoader((filter, copyOption) => Promise.resolve(copyOption?.owner?.statuses ?? []));
    this.openCopy = new Field().asBoolean();

    this.createCopy = new Method(function (ticket) {
        const status = this.targetStatus ?? this.target.statuses[0];
        let copy = new Ticket({app: this.app, type: this.target, status});
        copy.makeDirty();
        this.fields.forEach(field => {
            let fieldName = field.getName();
            copy[fieldName] = ticket[fieldName];
        });
        return copy;
    });
    this.getButton = new Method(function (ticket) {
        return new PromiseButton((button) => {
            const color = this.target.statuses[0]?.color;
            const icon = this.icon || this.target.icon || "copy";
            button.icon = createColorIcon(icon, color);
            button.hideTextDesktop = true;
            button.getNode().setAttribute("title", this.title);
            button.text = this.title;
            button.callback = () => this.createCopy(ticket)
                .save()
                .then((copy) => {
                    if (this.openCopy) {
                        button.trigger(ACTIONS.EDIT, copy);
                    }
                    if (this.updateOwnStatus) {
                        ticket.status = this.updateOwnStatus;
                        ticket.save();
                    }
                });
            button.onError = (e) => {
                throw e;
            };
        }, [BULMA.IS_SMALL]);
    });
}, "TicketCopyOption");
