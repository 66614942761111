export default (template, entity, locales = "default") => {
    if (!template) {
        return "";
    }
    return template.replace(/\${([^}]*)}/g, (m, $1) => {
        const value = entity[$1];
        if (value === null || value === undefined) {
            return "";
        }
        if (value instanceof Date) {
            return new Intl.DateTimeFormat(locales, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }).format(new Date(value));
        }
        return value;
    });
}
