import {extendComponent} from "juis-components/ComponentUtils.js";
import Property from "juis-components/Property.js";
import Component from "juis-components/Component.js";

export default extendComponent(Component, function () {
    this.registerDomEvents("click");
    this.text = new Property(function (value) {
        this.getNode().setText(value);
    });

}, ["delete"], "Button");
