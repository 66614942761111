import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import {iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import DynamicField from "./DynamicField.js";
import Bill from "./Bill.js";
import getIconCellFactory from "./utils/getIconCellFactory.js";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Url Code");

    this.title = new Field()
        .asTranslatableString()
        .withLabel("Title");
    this.icon = new Field()
        .asString()
        .withEditorFactory(iconPickerFactory)
        .withLabel("Icon");

    this.color = new Field()
        .asColor()
        .withLabel("Color");

    this.editorFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Bill))
        .withLabel("Fields");

    this.excludeFromCachedDescendants();
    this.makeTranslatable();
    setDefaults(this, {
        defaultField: this.title,
        cellFactory: getIconCellFactory(this.icon, this.title, this.color),
    });
}, "BillEditorTab");
