import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import Method from "../../utils/Method.js";
import {concatenateUrls} from "juis-router/NavigationUtils.js";
import setDefaults from "./utils/setDefaults.js";
import TextComponent from "../../components/TextComponent.js";
import MenuItem from "./MenuItem.js";
import {createLink} from "../../bulma_components/Link";
import Menu from "./Menu";

export default new Model(function () {
    this.app = new Field(App);
    this.text = new Field()
        .asTranslatableString()
        .withLabel("Text");
    this.ordinal = new Field().asNumber();
    this.menu = new Field(Menu);
    this.link = new Field()
        .asString()
        .withLabel("Link");
    this.children = new Field([this])
        .asCascading()
        .withLabel("Items");
    this.hasChildren = new Field()
        .asVirtual(undefined, (item) => item.children.length > 0)
        .withLabel("Has children");
    this.childCount = new Field()
        .asVirtual(undefined, (item) => item.children.length)
        .withCellFactory((value, item) => createLink(`/${item.app.code}/settings/menu-items/${item.id}`, value))
        .withLabel("Has children");
    this.makeTranslatable();
    this.getUrl = new Method(function () {
        return this.link ? "/" + concatenateUrls(this.app?.code, this.link) : undefined;
    });
    setDefaults(this, {
        cellFactory: (item) => new TextComponent(MenuItem.text.getTranslatedValueFor(item)),
    });
}, "MenuItem");
