import Field from "./Field.js";
import RestModel from "./RestModel.js";

let operators = ["=", "!=", "<", "<=", ">", ">=", "⊂", "in", "!in", "~", "or", "and", "year", "month"];

function coerceToNumber(value) {
    let number = Number(value);
    if (Number.isNaN(number)) {
        return null;
    }
    return number;
}

function coerceToIsoDateString(value) {
    if (value instanceof Date) {
        return value.toISOString();
    }
    return new Date(value).toISOString();
}

function coerceValue(field, value, operator) {
    if (field instanceof Field) {
        if (Array.isArray(value)) {
            return value.map(singleValue => coerceValue(field, singleValue));
        }
        if (field.getType() === Field.NUMBER && typeof value !== "number") {
            return coerceToNumber(value);
        }
        if (field.getType() === Field.STRING && typeof value !== "string") {
            return value + "";
        }
        if (operator === "month" || operator === "year" && typeof value !== "number") {
            return coerceToNumber(value);
        }
        if (field.getType() === Field.DATE) {
            return coerceToIsoDateString(value);
        }
        if (field.getType() === Field.TIMESTAMP) {
            return coerceToIsoDateString(value);
        }
    }
    return value;
}

const getNoopFilter = (testValue) => {
    return (new function NoopFilter() {
        this.test = () => testValue;
        this.getSimpleObj = () => testValue;
        this.toString = () => JSON.stringify(testValue);
        this.rebase = () => this;
    }());
};

function Filter(field, operator, value, coerce = true) {
    if (!operators.includes(operator)) {
        throw new Error("Invalid filter operator " + operator);
    }
    if (value === undefined) {
        value = null;
    }
    if (value instanceof Set) {
        let newValue = [];
        value.forEach(i => newValue.push(i));
        value = newValue;
    }

    let field2;
    if (value instanceof Field) {
        field2 = value.getName();
        value = undefined;
    } else if (coerce) {
        value = coerceValue(field, value, operator);
    }

    this.rebase = (baseField) => {
        if (operator === "or" || operator === "and") {
            return new Filter(field.rebase(baseField), operator, value.rebase(baseField));
        }
        let rebasedField = field;
        if (field instanceof Field) {
            rebasedField = baseField[field.getName()];
        }
        let rebasedValue = value;
        if (value instanceof Field) {
            rebasedValue = baseField[value.getName()];
        }
        return new Filter(rebasedField, operator, rebasedValue, coerce);
    };

    this.getSimpleObj = () => {
        if (operator === "or" || operator === "and") {
            return {l: field.getSimpleObj(), o: operator, r: value.getSimpleObj()};
        }
        if (field instanceof Field) {
            if (field.getModelType()) {
                if (Array.isArray(value)) {
                    return {f1: field.id.getName(), o: operator, v: value.map(value => value?.id ?? null)};
                } else if (value && value.id) {
                    return {f1: field.id.getName(), o: operator, v: value.id};
                } else if (typeof value === "number") {
                    return {f1: field.id.getName(), o: operator, v: value};
                } else {
                    return {f1: field.getName(), o: operator, v: null};
                }
            } else {
                return {f1: field.getName(), o: operator, v: value, f2: field2};
            }
        } else if (field instanceof RestModel) {
            if (Array.isArray(value)) {
                return {f1: field.id.getName(), o: operator, v: value.map(value => value.id)};
            } else if (value) {
                return {f1: field.id.getName(), o: operator, v: value.id};
            } else {
                return {f1: field.getName(), o: operator, v: null};
            }
        } else if (typeof field === "string") {
            return {f1: field, o: operator, v: value, f2: field2};
        }
        throw new Error("Unknown field: " + field + ", in filter: " + JSON.stringify({operator, value}));
    };

    this.toString = () => {
        return JSON.stringify(this.getSimpleObj());
    };

    this.test = (entity) => {
        if (operator === "and") {
            return field.test(entity) && value.test(entity);
        }
        if (operator === "or") {
            return field.test(entity) || value.test(entity);
        }
        let actualValue = field instanceof Field ? field.getValueFor(entity) : [entity[field]];
        let testValue = field2 ? entity[field2] : value;
        switch (operator) {
            case "=":
                return testValue === actualValue;
            case "!=":
                return testValue !== actualValue;
            case ">":
                return testValue > actualValue;
            case ">=":
                return testValue >= actualValue;
            case "<":
                return testValue < actualValue;
            case "<=":
                return testValue <= actualValue;
            case "in":
                return testValue && testValue.includes(actualValue);
            case "!in":
                return !testValue || !testValue.includes(actualValue);
            case "~":
                return actualValue && actualValue.includes(testValue);
            case "=~":
                return actualValue && actualValue.startsWith(testValue);
            case "year":
                return actualValue && actualValue.getFullYear() === testValue;
            case "month":
                return actualValue && actualValue.getMonth() === testValue - 1;
        }
    };

    this.toJSON = () => {
        return JSON.stringify(this.getSimpleObj());
    };
    this.and = (other) => Filter.and(this, other);
    this.or = (other) => Filter.or(this, other);
}

Filter.eq = (field, value) => new Filter(field, "=", value);
Filter.isNull = (field) => new Filter(field, "=", null, false);
Filter.notNull = (field) => new Filter(field, "!=", null, false);
Filter.ne = (field, value) => new Filter(field, "!=", value);
Filter.gt = (field, value) => new Filter(field, ">", value);
Filter.lt = (field, value) => new Filter(field, "<", value);
Filter.ge = (field, value) => new Filter(field, ">=", value);
Filter.le = (field, value) => new Filter(field, "<=", value);
Filter.in = (field, value) => new Filter(field, "in", value);
Filter.inOrEmpty = (field, value) => value.length > 0 ? new Filter(field, "in", value) : Filter.true;
Filter.notIn = (field, value) => new Filter(field, "!in", value);
Filter.like = (field, value) => new Filter(field, "~", value);
Filter.startsWith = (field, value) => new Filter(field, "=~", value);
Filter.year = (field, value) => new Filter(field, "year", value);
Filter.month = (field, value) => new Filter(field, "month", value);

Filter.or = (filter1, filter2) => {
    if (filter1 === Filter.true || filter2 === Filter.true) {
        return Filter.true;
    }
    if (filter1 === Filter.false) {
        return filter2;
    }
    if (filter2 === Filter.false) {
        return filter1;
    }
    return new Filter(filter1, "or", filter2);
};
Filter.and = (filter1, filter2) => {
    if (filter1 === Filter.false || filter2 === Filter.false) {
        return Filter.false;
    }
    if (filter1 === Filter.true) {
        return filter2;
    }
    if (filter2 === Filter.true) {
        return filter1;
    }
    return new Filter(filter1, "and", filter2);
};
Filter.every = (...filterList) => {
    if (!filterList) {
        return Filter.true;
    }
    return filterList.flat(Filter.MAX_DEPTH).reduce(Filter.and, Filter.true);
};
Filter.someOrTrue = (...filterList) => {
    if (!filterList || filterList.length === 0 || (Array.isArray(filterList[0]) && filterList[0].length === 0)) {
        return Filter.true;
    }
    return Filter.some(filterList);
};
Filter.some = (...filterList) => {
    if (!filterList) {
        return Filter.false;
    }
    return filterList.flat(Filter.MAX_DEPTH).reduce(Filter.or, Filter.false);
};

Filter.true = getNoopFilter(true);
Filter.false = getNoopFilter(false);
Filter.MAX_DEPTH = 10;
export default Filter;
