const modifyMethods = ["push", "pop", "shift", "unshift", "splice"];
const createEntity = (nameField, name) => {
    let model = nameField.getOwner();
    let entity = new model();
    entity[nameField.getName()] = name;
    return entity;
};
export default (array, changeCallback, nameField, dynamicCreate = false) => new Proxy(array, {
    set: function (target, property, item, receiver) {
        let result = Reflect.set(target, property, item, receiver);
        if (array.indexOf(item) !== -1) {
            changeCallback();
        }
        return result;
    },
    get: function (target, propertyKey, receiver) {
        if (modifyMethods.includes(propertyKey)) {
            return function (...args) {
                let result = target[propertyKey].apply(target, args);
                changeCallback();
                return result;
            };
        }
        if (nameField && typeof propertyKey == "string" && propertyKey.startsWith("_")) {
            const name = propertyKey.substring(1);
            let entity = array.find(item => nameField.getValueFor(item) === name);
            if (dynamicCreate && entity === undefined) {
                entity = createEntity(nameField, name);
                array.push(entity);
                changeCallback();
            }
            return entity ?? null;
        }
        return Reflect.get(target, propertyKey, receiver);
    }
});
