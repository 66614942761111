import Container from "juis-components/Container.js";
import {extendComponent} from "juis-components/ComponentUtils.js";


export default extendComponent(Container, function () {
    this.getNode().addWrapper("FOOTER", ["footer"]);
    this.getNode().setWrapperStyle("background-color", "unset");
    this.getNode().setWrapperStyle("background-repeat-y", "no-repeat");
    this.getNode().setWrapperStyle("background-position", "center bottom");
    this.getNode().setWrapperStyle("background-repeat", "repeat-x");
    this.getNode().setWrapperStyle("display", "flex");
    this.getNode().setWrapperStyle("align-items", "end");
    this.getNode().setStyle("margin-left", "auto");
    this.getNode().setStyle("margin-right", "auto");
    this.getNode().setStyle("width", "fit-content");

    let tag = "";
    if (HEWECON_GLOBAL.environment === "dev") {
        tag = "<p class=\"tags has-addons is-inline-block\"><span class=\"tag is-dark\">Environment</span><span class=\"tag is-danger\">DEV</span></p>";
    } else if (HEWECON_GLOBAL.environment === "beta") {
        tag = "<p class=\"tags has-addons is-inline-block\"><span class=\"tag is-dark\">Environment</span><span class=\"tag is-warning\">BETA</span></p>";
    }
    this.innerHTML = `    
    ${tag}
    <p class=\"tags has-addons is-inline-block\"><a href="${HEWECON_GLOBAL.baseUrl}" target="_blank"><span class=\"tag is-dark\">Powered With</span><span class=\"tag is-primary\">HEWECON</span></a></p>
    <p class=\"tags has-addons is-inline-block\"><span class=\"tag is-dark\">Version</span><span class=\"tag is-info\">${HEWECON_GLOBAL.frontendVersion}</span></p>
    <p class=\"tags has-addons is-inline-block\"><span class=\"tag is-dark\">Released</span><span class=\"tag is-success\">${HEWECON_GLOBAL.releaseDate}</span></p>
    `;
}, ["content", "hasTextCentered"], "DIV");
