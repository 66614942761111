import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import Stock from "./Stock.js";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field().asString().withLabel("Url Code");
    this.name = new Field().asString().withLabel("Name");
    this.description = new Field().asString().withLabel("Description");
    this.stocks = new Field([Stock]).withLabel("Stocks");
}, "StockLocation", "stock-locations");
