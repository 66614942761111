import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import TicketType from "./TicketType.js";
import {createLink} from "../../bulma_components/Link.js";
import App from "./App.js";
import TicketProduct from "./TicketProduct.js";
import TicketStatus from "./TicketStatus.js";
import TicketTemplateProduct from "./TicketTemplateProduct.js";
import DynamicField from "./DynamicField.js";
import {getLocaleDropdownFactory} from "../translations/localeDropdownFactory.js";
import Ticket from "./Ticket.js";
import setDefaults from "./utils/setDefaults.js";
import getIconCellFactory from "./utils/getIconCellFactory.js";
import LOCALES from "../translations/locales.js";
import localeCellFactory from "../translations/localeCellFactory.js";
import UserRole from "./UserRole.js";
import Method from "../../utils/Method.js";
import TicketMainProduct from "./TicketMainProduct.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import Contact from "./Contact.js";
import Checkbox from "../../bulma_components/Checkbox.js";
import {HorizontalFormField} from "../../components/fieldEditors/editorFactories.js";

let TicketTemplate = new RestModel(function () {
    this.app = new Field(App);
    this.name = new Field()
        .asString()
        .withLabel("Name");
    this.buttonText = new Field()
        .asTranslatableString()
        .withLabel("Button text");
    this.availableFor = new Field()
        .asEnumList(Object.values(UserRole.ROLE_TYPES))
        .withLabel("Available for");
    this.code = new Field()
        .asString()
        .asReadOnly()
        .withLabel("Url Code");
    this.description = new Field()
        .asString()
        .withLabel("Description");
    this.title = new Field()
        .asString()
        .withLabel("Title");
    this.backgroundColor = new Field()
        .asColor()
        .withLabel("Background color");
    this.textColor = new Field()
        .asColor()
        .withLabel("Text color");
    this.extraFields = new Field().asJson();
    this.ticketType = new Field(TicketType).withLabel("Ticket type");
    this.status = new Field(TicketStatus)
        .withLabel("First Status")
        .withOptionsLoader((quickFilter, template) =>
            Promise.resolve(template.ticketType ? template.ticketType.statuses : []));
    this.assignee = new Field(Contact).withLabel("Assignee");
    this.children = new Field([this]).asCascading().withLabel("Children");
    this.parent = new Field(this).withLabel("Parent");
    this.hasMainProduct = new Field().asVirtual().asBoolean().withEditorFactory(function (field, ticketTemplate) {
        const checkbox = new Checkbox();
        checkbox.setValue(!!ticketTemplate.mainTemplateProduct);
        checkbox.on("change", () => {
            if (checkbox.getValue()) {
                ticketTemplate.mainTemplateProduct = new TicketTemplateProduct();
                ticketTemplate.mainTemplateProduct.quantity = 1;
            } else {
                ticketTemplate.mainTemplateProduct = null;
            }
        });
        return new HorizontalFormField(function () {
            this.setLabel("Has main product");
            this.addField(checkbox);
        });
    });
    this.mainTemplateProduct = new Field(TicketTemplateProduct)
        .withDefaultValueCallback((entity) => TicketTemplateProduct.getEmpty(entity.app))
        .withLabel("Main product")
        .asCascading();
    this.templateProducts = new Field([TicketTemplateProduct])
        .withForeignRelationField(TicketTemplateProduct.ticketTemplate)
        .withLabel("Products").asCascading();
    this.openOnCreate = new Field().asBoolean().withLabel("Open on create");
    this.creatorField = new Field(DynamicField)
        .withLabel("Creator field")
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Ticket));
    this.locale = new Field().asString()
        .withLabel("Locale")
        .withOptions(Object.keys(LOCALES))
        .withCellFactory(localeCellFactory)
        .withEditorFactory(getLocaleDropdownFactory());

    const createTicketProduct = (templateProduct, model = TicketProduct) => {
        if (!templateProduct) {
            return null;
        }
        let ticketProduct = new model();
        ticketProduct.copyValuesFromProductAggregate(templateProduct);
        ticketProduct.product = templateProduct.product;
        ticketProduct.quantity = templateProduct.quantity;
        ticketProduct.stock = templateProduct.stock;
        ticketProduct.billable = templateProduct.billable;
        ticketProduct.makeDirty();
        return ticketProduct;
    };
    const createMainTicketProduct = (templateProduct) => {
        return createTicketProduct(templateProduct, TicketMainProduct);
    };

    this.makeTranslatable();
    this.shallowCopyExtraFields = function () {
        return Object.assign({}, this.extraFields);
    };
    this.getTicketProperties = function () {
        return {
            name: this.name,
            status: this.status.id,
            assignee: this.assignee?.id,
            type: this.ticketType.id,
            description: this.description,
            title: this.title,
            locale: this.locale,
            mainProduct: createMainTicketProduct(this.mainTemplateProduct),
            ticketProducts: this.templateProducts.map(createTicketProduct),
            children: this.children.map(templateChild => {
                return templateChild.getTicketProperties();
            }),
            extraFields: this.shallowCopyExtraFields(),
            app: this.app
        };
    };
    this.createTicket = new Method(function (defaultProperties) {
        let properties = this.getTicketProperties();
        const ticketProperties = {...properties, ...defaultProperties};
        if (!ticketProperties.locale) {
            ticketProperties.locale = Linguist.getLanguage();
        }
        let ticket = new Ticket(ticketProperties);
        if (ticket.mainProduct) {
            ticket.mainProduct.ticket = ticket;
        }
        ticket.ticketProducts.forEach(ticketProduct => ticketProduct.ticket = ticket);
        ticket.makeDirty();
        return ticket;
    });
    this.isAvailableFor = new Method(function (role) {
        if ([UserRole.ROLE_TYPES.ADMIN, UserRole.ROLE_TYPES.COORDINATOR].includes(role)) {
            return true;
        }
        return this.availableFor.includes(role);
    });
    setDefaults(this, {
        itemFactory: getIconCellFactory(this.ticketType.icon, this.name, this.color),
        filterFields: [this.name],
        linkFactory: (template) => createLink(`/${template.app.code}/settings/ticket-templates/${template.code}`,
            template.code, template.icon, template.color),
    });
}, "TicketTemplate", "ticket-templates", true);
export default TicketTemplate;
