import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import App from "./App.js";
import MenuItem from "./MenuItem.js";
import Method from "../../utils/Method.js";
import {concatenateUrls} from "juis-router/NavigationUtils.js";
import UserRole from "./UserRole.js";
import setDefaults from "./utils/setDefaults.js";
import {getDropDownFactory} from "../../components/fieldEditors/editorFactories.js";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Code");

    this.name = new Field()
        .asString()
        .withLabel("Name");

    this.text = new Field()
        .asString()
        .withLabel("Text");

    this.defaultLink = new Field()
        .asString()
        .withLabel("Default link");

    this.role = new Field()
        .asEnum(() => Object.values(UserRole.ROLE_TYPES))
        .withCellFactory(UserRole.getRoleTypeTag)
        .withEditorFactory(getDropDownFactory({
            searchable: false,
            itemFactory: UserRole.getRoleTypeTag
        }))
        .withLabel("User role");

    this.items = new Field([MenuItem]).withLabel("Items").asCascading();
    this.getUrl = new Method(function () {
        return this.defaultLink ? "/" + concatenateUrls(this.app.code, this.defaultLink) : undefined;
    });
    setDefaults(this, {
        filterFields: [this.name],
        defaultField: this.name,
        linkFactory: (menu) => createLink(`/${menu.app.code}/settings/menus/${menu.code}`, menu.name)
    });
}, "Menu", "menus", true);
