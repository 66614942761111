import {extendComponent} from "juis-components/ComponentUtils.js";
import BULMA from "../bulma_components/bulmaCssClasses.js";
import {EVENTS} from "./table-view/TableView2.js";
import Container from "juis-components/Container.js";
import Button from "../bulma_components/Button.js";
import Property from "juis-components/Property.js";
import HTML from "../utils/HTML.js";
import SessionHandler from "../SessionHandler.js";
import UserRole from "../hewecon/models/UserRole.js";
import Linguist from "../../lib/JuiS/Linguist.js";
import actionsLexicons from "./lexicons/actions/actionsLexicons.js";
import {createIcon} from "../bulma_components/Icon.js";

const t = new Linguist(actionsLexicons).t;
const ACTIONS = {
    EDIT: "edit", DELETE: "delete", COPY: Symbol(),
};

const createUnlockButton = (entity) => new Button((button) => {
    button.icon = createIcon("lock-open", BULMA.TYPOGRAPHY.HAS_TEXT_DANGER);
    button.hideTextDesktop = true;
    button.text = t("unlock");
    button.getNode().setAttribute("title", t("unlock"));
    button.on("click", () => {
        button.isLoading = true;
        entity.locked = false;
        entity.save().finally(() => button.isLoading = false);
    });
}, [BULMA.IS_SMALL]);

const createUndeleteButton = (entity) => new Button((button) => {
    button.icon = createIcon("trash-restore", BULMA.TYPOGRAPHY.HAS_TEXT_DANGER);
    button.hideTextDesktop = true;
    button.text = t("undelete");
    button.getNode().setAttribute("title", t("undelete"));
    button.on("click", () => {
        button.isLoading = true;
        entity.deleted = false;
        entity.save().finally(() => button.isLoading = false);
    });
}, [BULMA.TYPOGRAPHY.HAS_TEXT_DANGER, BULMA.IS_SMALL]);

const Actions = extendComponent(Container, function () {
    this.entity = new Property((entity) => {
        if (entity.locked) {
            this.field.edit.getNode().togglePlaceholder(true);
            this.field.delete.getNode().togglePlaceholder(true);
            if (SessionHandler.hasRole(UserRole.ROLE_TYPES.ADMIN, entity.app)) {
                this.field.lock = createUnlockButton(entity);
            }
        } else if (entity.deleted) {
            this.field.edit.getNode().togglePlaceholder(true);
            this.field.delete.getNode().togglePlaceholder(true);
            if (SessionHandler.hasRole(UserRole.ROLE_TYPES.ADMIN, entity.app)) {
                this.field.undelete = createUndeleteButton(entity);
            }
        }
        if (typeof entity.canEdit === "function") {
            if (!entity.canEdit()) {
                this.field.delete.disabled = true;
                this.field.edit.text = t("view");
                this.field.edit.getNode().setAttribute("title", t("view"));
                this.field.edit.icon = "magnifying-glass";
            }
        }
        if (typeof entity.canDelete === "function") {
            if (!entity.canDelete()) {
                this.field.delete.disabled = true;
            }
        }
    });
    this.getNode().setStyle("line-height", 0);
    this.getNode().setStyle("clear", "right");
    this.field = new Container((field) => {
        field.edit = new Button((button) => {
            button.icon = createIcon("pen", BULMA.TYPOGRAPHY.HAS_TEXT_PRIMARY);
            button.hideTextDesktop = true;
            button.text = t("edit");
            button.getNode().setAttribute("title", t("edit"));
            button.on("click", () => this.trigger(ACTIONS.EDIT, this.entity));
            button.getNode().addWrapper(HTML.SPAN, BULMA.FIELD.CONTROL);
        }, [BULMA.IS_SMALL]);
        field.delete = new Button((button) => {
            button.icon = createIcon("trash", BULMA.TYPOGRAPHY.HAS_TEXT_DANGER);
            button.hideTextDesktop = true;
            button.text = t("delete");
            button.getNode().setAttribute("title", t("delete"));
            button.on("click", () => {
                button.isLoading = true;
                this.trigger(ACTIONS.DELETE, this.entity);
                this.triggerOnce(EVENTS.BEFORE_DELETE, this.entity).then(() => {
                    button.isLoading = false;
                });
            });
            button.getNode().addWrapper(HTML.SPAN, BULMA.FIELD.CONTROL);
        }, [BULMA.IS_SMALL]);
    }, [BULMA.FIELD.FIELD, BULMA.FIELD.HAS_ADDONS]);
    this.addControl = (control) => {
        control.getNode().addWrapper(HTML.SPAN, BULMA.FIELD.CONTROL);
        this.field.insertBefore(control);
    };
}, ["row-actions", BULMA.HAS_TEXT_RIGHT], [HTML.SPAN]);

const createActions = (fieldEntity) => new Actions(actions => {
    actions.entity = fieldEntity;
});
const createActionsWithCallback = (callback) => (fieldEntity) => new Actions(actions => {
    actions.entity = fieldEntity;
    callback(actions);
});
const createActionsWithCopy = (fieldEntity) => new Actions(actions => {
    actions.entity = fieldEntity;
    let copyButton = new Button(function () {
        this.icon = "copy";
        this.hideTextDesktop = true;
        this.text = t("copy");
        this.getNode().setAttribute("title", t("copy"));
        this.on("click", function () {
            this.trigger(ACTIONS.COPY, actions.entity);
        });
    }, [BULMA.IS_SMALL]);
    actions.addControl(copyButton);
});
const createDeleteButton = (fieldEntity) => new Actions(actions => {
    actions.entity = fieldEntity;
    actions.field.removeChild(actions.field.edit);
});
const createEditButton = (fieldEntity) => new Actions(actions => {
    actions.entity = fieldEntity;
    actions.field.removeChild(actions.field.delete);
});
const createReloadButton = (fieldEntity) => new Actions(actions => {
    actions.entity = fieldEntity;
    actions.removeChild(actions.field.edit);
    actions.removeChild(actions.field.delete);
    actions.refresh = new Button((button) => {
        button.icon = "sync";
        button.on("click", () => {
            button.isLoading = true;
            actions.entity.reload().finally(() => button.isLoading = false);
        });
        button.getNode().addWrapper(HTML.SPAN, BULMA.FIELD.CONTROL);
    }, [BULMA.IS_SMALL]);
});
export {
    Actions as default,
    ACTIONS,
    createActions,
    createDeleteButton,
    createReloadButton,
    createEditButton,
    createActionsWithCopy,
    createActionsWithCallback
};
