import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Input from "../bulma_components/Input.js";
import Property from "juis-components/Property.js";
import Component from "juis-components/Component.js";
import HTML from "../utils/HTML.js";
import BULMA_CSS_CLASSES from "../bulma_components/bulmaCssClasses.js";
import TextComponent from "./TextComponent.js";

export default extendComponent(Container, function () {
    this.input = new Input((input) => {
        input.type = "file";
        input.on("change", (data, event) => {
            event.stopPropagation();
            this.trigger("change", this.getValue());
        });
    }, ["file-input"]);
    this.getValue = () => this.input.getNode().getActualElement().files[0];
    this.accept = new Property(value => {
        this.input.getNode().setElementProperty("accept", value);
    });
    this.capture = new Property(value => {
        this.input.getNode().setElementProperty("capture", value);
    });
    this.cta = new Component(function () {
    }, ["file-cta"], HTML.SPAN);
    this.fileName = new TextComponent("", HTML.SPAN, ["file-name"]);
    const updateButton = () => this.cta.innerHTML = `
              <span class="file-icon">
                <i class="fas fa-${this.icon}"></i>
              </span>
              <span class="file-label">${this.text}</span>`;
    this.text = new Property(updateButton, "Choose a file…");
    this.icon = new Property(updateButton, "upload");
    this.fullWidth = new Property(value => {
        if (value) {
            this.getNode().addWrapperCssClass("is-fullwidth");
        }
    });
    this.showName = new Property(value => {
        if (value) {
            this.getNode().addWrapperCssClass("has-name");
            this.fileName.getNode().togglePlaceholder(false);
        } else {
            this.fileName.getNode().togglePlaceholder(true);
        }
    });
    this.getNode().addWrapper(HTML.DIV, ["file", BULMA_CSS_CLASSES.IS_INLINE_BLOCK]);
}, ["file-label"], HTML.LABEL);
