import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {iconPickerFactory} from "../../components/fieldEditors/editorFactories.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import getIconCellFactory from "./utils/getIconCellFactory.js";
import DynamicField from "./DynamicField.js";
import Production from "./Production.js";
import Filter from "../../utils/Filter.js";
import Stock from "./Stock.js";
import TimePeriodGroup from "./TimePeriodGroup.js";


let ProductionList = new Model(function () {
    this.app = new Field(App);
    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Code");

    this.name = new Field()
        .asString()
        .withLabel("Name");

    this.icon = new Field()
        .asString()
        .withEditorFactory(iconPickerFactory)
        .withLabel("Icon");

    this.color = new Field()
        .asColor()
        .withLabel("Color");

    this.appDefault = new Field()
        .asBoolean()
        .withLabel("Is default");

    this.columns = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Production))
        .withLabel("Columns");

    this.stock = new Field(Stock).withLabel("Stock");
    this.timePeriodGroup = new Field(TimePeriodGroup).withLabel("Time Period Group");
    this.groupDaily = new Field().asBoolean().withLabel("Group Daily");
    this.groupWeekly = new Field().asBoolean().withLabel("Group Weekly");
    this.groupMonthly = new Field().asBoolean().withLabel("Group Monthly");
    this.groupQuarterly = new Field().asBoolean().withLabel("Group Quarterly");
    this.groupYearly = new Field().asBoolean().withLabel("Group Yearly");

    setDefaults(this, {
        filterFields: [this.title],
        itemFactory: getIconCellFactory(this.icon, this.name, this.color)
    });

    this.getForStock = (stock) =>
        this.cache.queryOne(Filter.eq(ProductionList.stock, stock))
        || stock.parent && this.cache.queryOne(Filter.eq(ProductionList.stock, stock.parent))
        || ProductionList.getAppDefault();

    this.getAppDefault = () => this.cache.queryOne(Filter.eq(ProductionList.appDefault, true));
}, "ProductionList", "production-lists", true);


export {ProductionList as default};
