import {extendComponent, WrapperContainer} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Button from "./Button.js";
import Component from "juis-components/Component.js";
import BULMA from "./bulmaCssClasses.js";
import HTML from "../utils/HTML.js";
import Property from "juis-components/Property.js";


export default extendComponent(Container, function () {
    this.previousButton = new Button(button => {
        button.icon = "caret-left";
        button.on("click", () => {
            button.isLoading = true;
            this.trigger("pageSelected", getCurrentPage() - 1)
        });
        button.getNode().setAttribute("disabled");
    }, [BULMA.PAGINATION.PREVIOUS]);

    this.nextButton = new Button(button => {
        button.icon = "caret-right";
        button.on("click", () => {
            button.isLoading = true;
            this.trigger("pageSelected", getCurrentPage() + 1)
        });
        button.getNode().setAttribute("disabled");
    }, [BULMA.PAGINATION.NEXT]);

    this.paginationList = new WrapperContainer(HTML.LI, [], HTML.UL, [BULMA.PAGINATION.LIST]);

    let getCurrentPage = () => (this.firstRow / this.maxRows) + 1;

    this.refreshButtons = () => {
        this.previousButton.isLoading = false;
        this.nextButton.isLoading = false;
        this.paginationList.destroyAllChildren();
        const lastPage = Math.ceil(this.totalCount / this.maxRows);
        if (lastPage <= 1) {
            this.getNode().togglePlaceholder(true, "pagination");
            return;
        } else {
            this.getNode().togglePlaceholder(false, "pagination");
        }
        const currentPage = getCurrentPage();
        if (currentPage === 1) {
            this.previousButton.getNode().setAttribute("disabled");
        } else {
            this.previousButton.getNode().removeAttribute("disabled");
        }
        if (currentPage === lastPage) {
            this.nextButton.getNode().setAttribute("disabled");
        } else {
            this.nextButton.getNode().removeAttribute("disabled");
        }
        const pageNumbers = calculatePageNumbers(currentPage, lastPage);
        createPageButtons(pageNumbers, currentPage);
    };

    const calculatePageNumbers = (currentPage, lastPage) => {
        const pageNumbers = [1];
        if (!pageNumbers.includes(lastPage)) {
            pageNumbers.push(lastPage);
        }
        if (!pageNumbers.includes(currentPage)) {
            pageNumbers.push(currentPage);
        }
        if (currentPage < lastPage && !pageNumbers.includes(currentPage + 1)) {
            pageNumbers.push(currentPage + 1);
        }
        if (currentPage > 1 && !pageNumbers.includes(currentPage - 1)) {
            pageNumbers.push(currentPage - 1);
        }
        pageNumbers.sort(function (a, b) {
            return a - b;
        });
        return pageNumbers;
    };

    const createPageButtons = (pageNumbers, currentPage) => {
        pageNumbers.forEach((pageNumber, index) => {
            const buttonClasses = [BULMA.PAGINATION.LINK];
            if (pageNumber === currentPage) {
                buttonClasses.push(BULMA.PAGINATION.CURRENT);
            }
            addPaginationButton(pageNumber, buttonClasses);
            if (pageNumbers[index + 1] > pageNumber + 1) {
                addPaginationEllipsis();
            }
        });
    };
    this.maxRows = new Property(undefined, 15);
    this.totalCount = new Property(undefined, 0);
    this.firstRow = new Property(undefined, 0);

    const addPaginationButton = (pageNumber, buttonCssClasses) => {
        this.paginationList.appendChild(new Button((button) => {
            button.text = pageNumber;
            button.on("click", () => {
                button.isLoading = true;
                this.trigger("pageSelected", pageNumber);
            });
        }, buttonCssClasses));
    };

    const addPaginationEllipsis = () => {
        const ellipsis = new Component(function () {
            this.getNode().setInnerHtml("&hellip;");
        }, [BULMA.PAGINATION.ELLIPSIS]);
        this.paginationList.appendChild(ellipsis);
    };

}, [BULMA.PAGINATION.PAGINATION], HTML.NAV);
