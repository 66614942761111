import Container from "juis-components/Container.js";
import RestModel from "../../utils/RestModel.js";
import Icon from "../../bulma_components/Icon.js";
import {BulmaContainer} from "../../bulma_components/BulmaComponent.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import HTML from "../../utils/HTML.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import PromiseThrottler from "../../utils/PromiseThrottler.js";
import ExpandableContainer from "../../bulma_components/ExpandableContainer.js";
import Property from "juis-components/Property.js";
import TextComponent from "../TextComponent.js";
import "./dropdown.css";
import PromiseButton from "../PromiseButton";
import Linguist from "../../../lib/JuiS/Linguist";
import dropdownLexicons from "./dropdownLexicons";

const linguist = new Linguist(dropdownLexicons);
export default extendComponent(Container, function () {
    let itemData = {};
    let items = {};
    let selectedValues = [];

    this.getSelectionForModification = () => selectedValues;
    this.getSelection = () => [...selectedValues];

    this.getItemData = (key) => itemData[key];

    const selectItem = (key) => {
        if (items[key]) {
            items[key].getNode().addCssClass("selected");
        }
    };

    const unselectItem = (key) => {
        if (items[key]) {
            items[key].getNode().removeCssClass("selected");
        }
    };

    let identifiers = new Map;
    const getIdentifier = (value, key) => {
        if (identifiers.has(value)) {
            return identifiers.get(value);
        }

        if (key) {
            identifiers.set(value, key);
            return key;

        }

        if (keyFactory) {
            key = keyFactory(value);
            identifiers.set(value, key);
            return key;
        }

        let identifier;
        if (value instanceof RestModel) {
            identifier = value.getIdentifier();
        } else {
            identifier = value;
        }
        identifiers.set(value, identifier);
        return identifier;
    };
    this.getNode().setAttribute("tabindex", 0);

    this.triggerButton = new Icon(function () {
        this.icon = "angle-down";
        this.asLoading = () => this.getNode().addCssClass("is-loading");
        this.asIdle = () => this.getNode().removeCssClass("is-loading");
    }, ["is-small", "is-right", "trigger", "button", "is-shadowless"]);

    this.menu = new Container((menu) => {
        menu.dontPropagate(["focus", "click", "keydown", "change"]);
        menu.registerDomEvents("click");
        menu.registerDomEvents("keydown");
        menu.registerDomEvents("focus");
        menu.dropdownContent = new BulmaContainer((content) => {
            content.getNode().togglePlaceholder(true);
            content.addItem = (item, key, group) => {
                content.getNode().togglePlaceholder(false);
                if (typeof item === "string") {
                    item = new TextComponent(item, HTML.DIV, [BULMA.IS_UNSELECTABLE]);
                    item.getNode().setStyle("white-space", "nowrap");
                }
                items[key] = item;
                item.getNode().addCssClass(BULMA.DROPDOWN.ITEM);
                item.getNode().addCssClass(BULMA.IS_UNSELECTABLE);
                if (selectedValues.includes(key)) {
                    item.getNode().addCssClass("selected");
                }
                item.registerDomEvents("click");
                item.on("click", (data, event) => {
                    if (this.multiple) {
                        toggleSelection(key);
                    } else {
                        setSingleValueFromKey(key);
                    }
                    this.hideDropdown();
                    event.stopPropagation();
                });
                (group || content).appendChild(item);
            };
            content.addComponent = (component) => {
                content.getNode().togglePlaceholder(false);
                content.appendChild(component);
            };

            content.getNode().setAttribute("tabindex", "-1"); // Prevent focus even if scrolling
        }, [BULMA.DROPDOWN.CONTENT]);
    }, [BULMA.DROPDOWN.MENU]);

    this.multiple = new Property(() => {
    }, false);

    let itemsLoader;
    this.setOptionsLoader = (newOptionsLoader) => {
        itemsLoader = new PromiseThrottler().withCallback(newOptionsLoader);
        itemsLoader.on(PromiseThrottler.IS_LOADING, () => this.triggerButton.asLoading());
        itemsLoader.on(PromiseThrottler.IS_IDLE, () => this.triggerButton.asIdle());
    };
    this.search = (searchText) => itemsLoader.load(searchText || "").then(setOptions);

    this.showDropdown = () => {
        if (itemsLoader && itemsLoader.isNew()) {
            itemsLoader.load("").then(setOptions);
        }
        this.getNode().removeCssClass("hide-dropdown");
    };
    this.hideDropdown = () => {
        this.getNode().addCssClass("hide-dropdown");
        if (focusedItem) {
            focusedItem.getNode().removeCssClass("focus");
            focusedItem = null;
        }
    };
    this.on("click", () => {
        this.showDropdown();
    });
    this.on("focus", () => {
        this.showDropdown();
    });
    this.registerDomEvents("click");
    this.registerDomEvents("focus");
    this.registerDomEvents("keydown");
    let focusedItem;

    const focusItem = (item) => {
        if (focusedItem) {
            focusedItem.getNode().removeCssClass("focus");
        }
        if (item) {
            focusedItem = item;
            focusedItem.getNode().addCssClass("focus");
        }
    };
    const navigateToItem = (item) => {
        if (!item) {
            return;
        }
        this.showDropdown();
        focusItem(item);
        if (item.getNode().getActualElement().scrollIntoView) {
            // The element might be a text node
            item.getNode().getActualElement().scrollIntoView({block: "nearest", inline: "nearest"});
        }
    };
    let itemIterator = this.menu.dropdownContent.getChildIterator();
    this.on("keydown", function (event) {
        switch (event.keyCode) {
            case 40: // Down
                navigateToItem(itemIterator.next());
                event.domEvent.preventDefault();
                break;
            case 38: // Up
                navigateToItem(itemIterator.previous());
                event.domEvent.preventDefault();
                break;
            case 13: // Enter
            case 32: // Space
                if (focusedItem) {
                    focusedItem.trigger("click");
                    event.domEvent.preventDefault();
                }
                break;
            case 9: // Tab
                if (focusedItem) {
                    focusedItem.trigger("click");
                }
                break;
            case 27: // Escape
                this.hideDropdown();
                event.domEvent.preventDefault();
                break;
            case 8: // Backspace
                if (selectedValues.length === 0) {
                    return;
                }
                if (this.multiple) {
                    this.removeLastKeyFromSelection();
                } else {
                    this.clearSelection();
                }
                this.hideDropdown();
                event.domEvent.preventDefault();
                break;
        }
    });

    this.clear = () => {
        this.menu.dropdownContent.destroyAllChildren();
        groups = {};
        items = {};
        focusedItem = null;
        itemIterator = this.menu.dropdownContent.getChildIterator();
    };

    this.getValue = () => {
        if (this.multiple) {
            return selectedValues.map(selectedValue => itemData[selectedValue]);
        } else {
            return itemData[selectedValues[0]] || null;
        }
    };

    this.clearSelection = function (triggerChange = true) {
        selectedValues.forEach(selectedValue => {
            unselectItem(selectedValue);
        });
        selectedValues = [];
        if (triggerChange) {
            this.trigger("change");
        }
    };

    this.addToSelection = function (value, triggerChange = true) {
        let key = getIdentifier(value);
        if (!itemData[key]) {
            itemData[key] = value;
            this.addOption(value);
        }
        return addKeyToSelection(key, triggerChange);
    };

    let toggleSelection = (key) => {
        if (selectedValues.includes(key)) {
            this.removeKeyFromSelection(key);
        } else {
            addKeyToSelection(key);
        }
    };

    let addKeyToSelection = (key, triggerChange = true) => {
        if (!this.multiple && selectedValues.length > 0) {
            throw new Error("This select can only have a single value. Cannot add " + key);
        }
        if (!itemData[key]) {
            throw new Error(`Key note found: ${key}`);
        }
        selectedValues.push(key);
        selectItem(key);
        if (triggerChange) {
            this.trigger("change");
        }
    };

    this.removeFromSelection = function (value, triggerChange = true) {
        let key = getIdentifier(value);
        return this.removeKeyFromSelection(key, triggerChange);
    };

    this.removeKeyFromSelection = (key, triggerChange = true) => {
        let index = selectedValues.indexOf(key);
        if (index !== -1) {
            selectedValues.splice(index, 1);
            unselectItem(key);
            if (triggerChange) {
                this.trigger("change");
            }
        } else {
            console.warn(`Cannot unselect ${key} as it is not selected`);
        }
    };
    this.removeLastKeyFromSelection = (triggerChange = true) => {
        this.removeKeyFromSelection(selectedValues[selectedValues.length - 1], triggerChange);
    };

    let setSingleValue = (value) => {
        let oldValue = this.getValue();
        if (value === oldValue || (value?.equals && value.equals(oldValue))) {
            this.clearSelection(true);
        } else {
            this.clearSelection(false);
            if (value) {
                this.addToSelection(value);
            }
        }
    };

    let setSingleValueFromKey = (key) => {
        setSingleValue(itemData[key]);
    };

    this.setValue = (values) => {
        if (!this.multiple) {
            return setSingleValue(values);
        }
        if (values === null) {
            values = [];
        }
        if (!Array.isArray(values)) {
            throw new Error("Value must be an array");
        }
        if (values.length === 0) {
            if (selectedValues.length > 0) {
                this.trigger("change");
                this.clearSelection();
            }
            return;
        }
        let additions = values.filter(value => !selectedValues.includes(getIdentifier(value)));
        let removals = selectedValues.filter(key => !values.includes(itemData[key]));

        additions.forEach(value => this.addToSelection(value, false));
        removals.forEach(key => this.removeKeyFromSelection(key, false));

        if (additions.length > 0 || removals.length > 0) {
            this.trigger("change");
        }
    };

    let itemFactory = data => new TextComponent(data);
    this.setItemFactory = (newItemFactory) => itemFactory = newItemFactory;

    let setOptions = (options) => {
        this.clear();
        options.forEach(option => this.addOption(option));
        if (options.next) {
            this.addLoadMoreButton(options.next);
        }
        return options;
    };

    this.addLoadMoreButton = (loader) => {
        this.menu.dropdownContent.loadMore = new PromiseButton((button) => {
            button.dontPropagate(["click"]);
            button.text = linguist.t("loadMore");
            button.callback = loader;
            button.onResolve = (options) => {
                options.forEach(option => this.addOption(option));
                if (options.next) {
                    button.callback = options.next;
                    this.menu.dropdownContent.appendChild(button);
                } else {
                    this.menu.dropdownContent.removeChild(button);
                    button.destroy();
                }
                this.menu.dropdownContent.getNode().getActualElement().focus();
            };
        }, ["is-fullwidth", "is-ghost"]);
    };

    this.addOption = (data, key, groupKey) => {
        key = getIdentifier(data, key);
        itemData[key] = data;
        let item = itemFactory(data);
        let group = getGroup(data, groupKey);
        this.menu.dropdownContent.addItem(item, key, group);
    };

    let groups = {};
    let groupByFunction;
    const getGroup = (data, groupKey) => {
        if (groupByFunction) {
            groupKey = groupKey || groupByFunction(data);
            if (!groupKey) {
                return null;
            }
            if (groupKey instanceof RestModel) {
                groupKey = groupKey.id;
            }
            if (groups[groupKey]) {
                return groups[groupKey];
            } else {
                let group = new Container();
                if (groupHeaderFactory) {
                    group.header = new Container(function () {
                        let header = groupHeaderFactory(data);
                        if (header) {
                            this.appendChild(header);
                        }
                    }, [BULMA.IS_UNSELECTABLE, "dropdown-group-header"]);

                } else {
                    group.header = new TextComponent(data, HTML.SPAN,
                        [BULMA.TYPOGRAPHY.HAS_TEXT_WEIGHT_BOLD, "dropdown-group-header"]);
                }
                group.content = new ExpandableContainer(function () {
                    this.getNode().setStyle("margin-left", "0.5em");
                    this.getNode().setStyle("border-left", "1px solid black");
                });
                group.header.registerDomEvents("click");
                group.header.on("click", () => group.content.toggleCollapsed());
                if (groupsClosed) {
                    group.content.collapse();
                }
                group.overrideMethod("appendChild", (overridden, child) => {
                    group.content.appendChild(child);
                });
                this.menu.dropdownContent.appendChild(group);
                groups[groupKey] = group;
                return group;
            }
        }
    };
    let groupsClosed = false;
    this.setGroupsClosed = (value) => {
        groupsClosed = value;
    };
    this.setGroupBy = (newGroupByFunction) => {
        groupByFunction = newGroupByFunction;
    };
    let groupHeaderFactory;
    this.setGroupHeaderFactory = (newGroupHeaderFactory) => {
        groupHeaderFactory = newGroupHeaderFactory;
    };
    let keyFactory;
    this.setKeyFactory = (newKeyFactory) => {
        keyFactory = newKeyFactory;
    };
    this.on("expand", (ignore, event) => event.stopPropagation());
    this.on("collapse", (ignore, event) => event.stopPropagation());
}, [BULMA.INPUT, "juis-select"]);
