const BULMA_COLORS = {
    ORANGE: "hsl(14, 100%, 53%)",
    YELLOW: "hsl(48, 100%, 67%)",
    GREEN: "#118550",
    TURQUOISE: "hsl(171, 100%, 41%)",
    CYAN: "#2379b3",
    BLUE: "#004CC7",
    PURPLE: "hsl(271, 100%, 71%)",
    RED: "#cf3d5a",
    WHITE: "hsl(0, 0%, 100%)",
    GREY_LIGHTER: "hsl(0, 0%, 86%)",
};
export default BULMA_COLORS;