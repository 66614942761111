import EditorCard from "../../../../components/table-view/EditorCard.js";
import Contact from "../../../models/Contact.js";
import SessionHandler from "../../../../SessionHandler.js";
import UserRole from "../../../models/UserRole.js";
import DynamicField from "../../../models/DynamicField.js";
import Filter from "../../../../utils/Filter.js";
import ContactGroup from "../../../models/ContactGroup.js";

const getRoleWithGroup = (ticket) =>
    SessionHandler.getRolesForApp(ticket.app).filter(role => !!role.ownContactGroup)[0];

export default (ticket) => new EditorCard(function () {
    this.setModel(Contact);
    this.title = "Add contact";
    this.addField(Contact.name);
    this.addField(Contact.email);
    this.addField(Contact.phoneNumber);
    this.addField(Contact.street);
    this.addField(Contact.zipCode);
    this.addField(Contact.city);
    this.addField(Contact.locale);
    this.defaultProperties = {app: ticket.app};
    const customerGroups = ticket.type?.customers || [];
    if (customerGroups.length === 0) {
        const roleWithGroup = getRoleWithGroup(ticket);
        if (!roleWithGroup) {
            this.addField(Contact.contactGroup);
            return;
        }
        this.defaultProperties = {...this.defaultProperties, contactGroup: roleWithGroup.ownContactGroup};
        if (roleWithGroup.role !== UserRole.ROLE_TYPES.CLIENT &&
            roleWithGroup.role !== UserRole.ROLE_TYPES.RESELLER) {
            this.addField(Contact.contactGroup);
        }
    } else {
        const contactGroup = customerGroups[0];
        this.defaultProperties = {contactGroup, ...this.defaultProperties};
    }
    if (customerGroups.length > 1) {
        let field = DynamicField.fromRealField(Contact.contactGroup);
        const optionsLoader = (searchString, contact) => {
            return ContactGroup.getDefaultOptionsLoader()(searchString, contact, Filter.in(ContactGroup, customerGroups));
        };
        field.withOptionsLoader(optionsLoader);
        this.addField(field);
    }

    this.refresh();
})
