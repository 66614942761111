import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import Container from "juis-components/Container.js";
import {createIcon, createIconCell} from "../../bulma_components/Icon.js";
import HTML from "../../utils/HTML.js";
import StockProductList from "./StockProductList.js";
import App from "./App.js";
import StockLocation from "./StockLocation.js";
import TextComponent from "../../components/TextComponent.js";
import setDefaults from "./utils/setDefaults.js";
import {
    addListeners,
    getDropDown,
    getDropDownFactory,
    HorizontalFormField,
    iconPickerFactory
} from "../../components/fieldEditors/editorFactories.js";
import Filter from "../../utils/Filter.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import stockLexicons from "./lexicons/stock/stockLexicons.js";
import Contact from "./Contact.js";
import ProductGroup from "./ProductGroup.js";
import Method from "../../utils/Method.js";
import Transaction from "./Transaction";

const createStockLink = (stock) => {
    return new Container(function () {
        if (stock.icon) {
            this.icon = createIcon(stock.icon);
            this.icon.color = stock.color;
        }
        if (stock.parent) {
            this.parentLink = createLink(`/${stock.app.code}/stocks/${stock.parent.code}`, stock.parent.name);
            this.separator = new TextComponent(" · ");
        }
        this.link = createLink(`/${stock.app.code}/stocks/${stock.code}`, stock.name);
    }, [], HTML.SPAN);
};

let Stock = new Model(function () {
    const linguist = new Linguist(stockLexicons);
    this.app = new Field(App);
    this.code = new Field()
        .asString()
        .withLabel("Url Code");
    this.icon = new Field().asString().withLabel("Icon").withEditorFactory(iconPickerFactory)
        .withCellFactory(createIconCell);
    this.color = new Field().asColor().withLabel("Color");
    this.getFormattedName = function () {
        if (this.deleted) {
            return linguist.t("deleted");
        }
        return this.name || linguist.t("unnamed");
    };
    this.getFullName = function () {
        if (this.parent) {
            return this.parent.getFullName() + " · " + this.getFormattedName();
        }
        return this.getFormattedName();
    };
    this.name = new Field().asString().withLabel("Name");
    this.productsLink = new Field()
        .asVirtual()
        .withLabel("Stock")
        .withCellFactory((code) => createLink(`/stocks/${code}/products`, code));
    // this.priceSum = new Field().asMoney().asReadOnly().withLabel("Total price");
    // this.purchasePriceSum = new Field().asMoney().asReadOnly().withLabel("Total purchase Price");
    this.description = new Field().asString().withLabel("Description");
    this.stockProductList = new Field(StockProductList).withLabel("Stock products list");
    this.parent = new Field(this).withLabel("Parent stock");
    this.stockLocation = new Field(StockLocation).withLabel("Location");
    this.productionStock = new Field().asBoolean().withLabel("Production stock");
    this.productionUnits = new Field().asNumber().withLabel("Production units");
    this.manager = new Field(Contact).withLabel("Manager");
    this.productGroups = new Field([ProductGroup]).withLabel("Product groups");
    this.autoLinkNewProducts = new Field().asBoolean().withLabel("Auto link new products").withHelp("Automatically link new products when they are added to one of the referenced groups.");
    this.createChildStock = new Method(function (name) {
        let childStock = new Stock();
        childStock.parent = this;
        childStock.app = this.app;
        childStock.name = name;
        return childStock.save();
    });
    setDefaults(this, {
        filterFields: [this.name, this.parent.name],
        linkFactory: createStockLink,
        itemFactory: stock => stock.getFullName(),
        editorFactory: (field, parentEntity) => {
            if (parentEntity instanceof Transaction && parentEntity.stock) {
                const parentStock = parentEntity.stock;
                let children = Stock.cache.query(Filter.eq(Stock.parent, parentStock));
                if (children.length > 0) {
                    const optionsLoader = (searchString) => Stock.getListForDropdown(searchString, [Stock.name], parentEntity.app,
                        Filter.eq(Stock.parent, parentStock));
                    let dropdownFactory = getDropDownFactory({optionsLoader});
                    return dropdownFactory(field, parentEntity);
                }
            }
            return new HorizontalFormField(function () {
                this.setLabel(field.getLabel() || field.getName());
                const fieldBody = this.addField();
                const parentSelector = getDropDown(field, parentEntity, {
                    optionsLoader: (searchString) =>
                        Stock.getListForDropdown(searchString, [Stock.name], parentEntity.app,
                            Filter.isNull(Stock.parent)),
                });
                let childControl;
                let childSelector;
                fieldBody.addControl(parentSelector, BULMA.FIELD.IS_EXPANDED);
                const refreshChildControl = () => {
                    if (childControl) {
                        fieldBody.removeChild(childControl);
                        childControl = undefined;
                        childSelector = undefined;
                    }
                    let parentStock = parentSelector.getValue();
                    if (parentStock) {
                        Stock.getListForDropdown("", [Stock.name], parentStock.app,
                            Filter.eq(Stock.parent, parentStock)).then(children => {
                            if (children.length > 0) {
                                childSelector = getDropDown(field, null, {
                                    itemFactory: (stock) => stock.name,
                                    optionsLoader: (searchString) =>
                                        Stock.getListForDropdown(searchString, [Stock.name], parentStock.app,
                                            Filter.eq(Stock.parent, parentStock)),
                                    searchable: true
                                });
                                childSelector.enableSearchInputCreator(parentStock.createChildStock, (stock, searchInput) => stock.name === searchInput);
                                childControl = fieldBody.addControl(childSelector, BULMA.FIELD.IS_EXPANDED);
                                childSelector.getNode().setStyle("z-index", "unset");
                            }
                        });
                    }
                };
                parentSelector.on("change", refreshChildControl);
                this.getValue = () => childSelector ? childSelector.getValue() : parentSelector.getValue();
                this.setValue = (stock) => parentSelector.setValue(stock);
                const initialValue = field.getValueFor(parentEntity);
                if (initialValue) {
                    refreshChildControl(initialValue);
                }
                addListeners(this, field, parentEntity);
            });
        }
    });
    linguist.withAudience(this);
}, "Stock", "stock");
export default Stock;
