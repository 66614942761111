import en_US from "./en_US.json";
import sv_FI from "./sv_FI.json";
import fi_FI from "./fi_FI.json";
import productItemLexicon from "./../product-item/productItemLexicon.js";


export default {
    en_US: {...productItemLexicon.en_US, ...en_US},
    sv_FI: {...productItemLexicon.sv_FI, ...sv_FI},
    fi_FI: {...productItemLexicon.fi_FI, ...fi_FI},
}
