import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import Ticket from "./Ticket.js";
import Product from "./Product.js";
import Stock from "./Stock.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import HTML from "../../utils/HTML.js";
import StockProduct from "./StockProduct.js";
import Bill from "./Bill.js";
import App from "./App.js";
import TextComponent from "../../components/TextComponent.js";
import Validation from "../../utils/Validation.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import transactionLexicons from "./lexicons/transaction/transactionLexicons.js";
import Aggregation from "../../utils/Aggregation.js";
import Filter from "../../utils/Filter.js";
import {getDropDownFactory} from "../../components/fieldEditors/editorFactories.js";

let Transaction = new Model(function () {
    this.code = new Field().asString();
    this.app = new Field(App);
    this.description = new Field().asString().withLabel("Description")
        .withHelp("Explain where these quantities are coming from, or going to. (Optional)");
    this.variationOf = new Field(Product)
        .withLabel("Variation of")
        .withEditorFactory(getDropDownFactory({refreshOnChange: [this.stock]}))
        .withFilterGetter(() => Filter.gt(Product.variationQuantity, 0))
        .withFilterGetter((transaction) => {
            if (transaction.stock?.productGroups.length > 0) {
                return Filter.in(Product.groups, transaction.stock.productGroups);
            }
            return Filter.true;
        })
        .asVirtual();
    this.product = new Field(Product).withLabel("Product").asRequired()
        .withEditorFactory(getDropDownFactory({
            refreshOnChange: [this.variationOf, this.stock],
            itemFactory: (product, transaction) => {
                if (transaction.variationOf) {
                    return Product.name.getCell(product);
                } else {
                    return product.getDefaultItem();
                }
            }
        }))
        .withFilterGetter(() => Filter.eq(Product.variationQuantity, 0))
        .withFilterGetter((transaction) => {
            if (transaction.variationOf) {
                return Filter.eq(Product.variationOf, transaction.variationOf);
            }
            return Filter.true;
        })
        .withFilterGetter((transaction) => {
            if (transaction.stock?.productGroups.length > 0) {
                return Filter.or(
                    Filter.in(Product.groups, transaction.stock.productGroups),
                    Filter.in(Product.variationOf.groups, transaction.stock.productGroups)
                );
            }
            return Filter.true;
        });
    this.stock = new Field(Stock).withLabel("To stock").asRequired();
    this.fromStock = new Field(Stock).withLabel("From stock");
    this.quantity = new Field()
        .asNumber()
        .withLabel("Quantity")
        .withHelp("Use negative quantities for removals.")
        .asRequired()
        .withCellFactory((quantity, transaction) => {
            const cssClasses = [];
            if (quantity < 0) {
                cssClasses.push(BULMA_CSS_CLASSES.TYPOGRAPHY.HAS_TEXT_DANGER);
            }
            let text;
            if (transaction?.product?.unit) {
                text = `${quantity} ${transaction.product.unit}`;
            } else {
                text = quantity;
            }
            return new TextComponent(text, HTML.SPAN, cssClasses);
        })
        .withValidationRule(Validation.nonzero());
    this.netWeight = new Field().asNumber().withLabel("Net Weight");
    this.quantityInStockAfterTransaction = new Field()
        .asNumber()
        .asReadOnly()
        .withLabel("Quantity after transaction");
    this.ticket = new Field(Ticket).withLabel("Ticket");
    this.bill = new Field(Bill).withCellFactory((bill, transaction) => {
        let eitherBill = bill || transaction.ticket?.bill;
        if (eitherBill) {
            return Bill.getDefaultCell(eitherBill);
        }
    }).withLabel("Bill");
    this.created = new Field()
        .asTimestamp(Linguist.getLanguage(), {
            dateStyle: "long",
            timeStyle: "short",
        })
        .asReadOnly()
        .withLabel("Created");
    this.year = new Field()
        .asNumber()
        .asReadOnly()
        .withLabel("Year");

    this.totalQuantity = Aggregation.sum(this.quantity);
    this.distinctYears = Aggregation.distinct(this.year);

    this.on(this.EVENTS.BEFORE_SAVE, (entity) => {
        entity.app = entity.stock ? entity.stock.app : entity.product.app;
    });

    this.on(this.EVENTS.AFTER_INSERT, (transaction) => {
        StockProduct.refreshQuantities(transaction.stock, transaction.product);
        if (transaction.fromStock) {
            StockProduct.refreshQuantities(transaction.fromStock, transaction.product);
        }
    });
    new Linguist(transactionLexicons).withAudience(this);
}, "Transaction", "transactions");

export default Transaction;
