class ValidationError extends Error {
    constructor(message, field, entity) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(message);
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ValidationError);
        }
        this.name = 'Validation error';
    }
}

const getValue = (field, entity) => entity[field.getName()];
export default {

    hasValue: () => (field, entity) => {
        const value = getValue(field, entity);
        if (value === undefined || value === null || (value.trim && !value.trim())) {
            throw new ValidationError(`${field.getLabel()} is a required field.`, field, entity);
        }
    },

    maximum: (max) => (field, entity) => {
        if (getValue(field, entity) > max) {
            throw new ValidationError(`${field.getLabel()} cannot be more than ${max}.`, field, entity);
        }
    },

    minimum: (min) => (field, entity) => {
        if (getValue(field, entity) < min) {
            throw new ValidationError(`${field.getLabel()} cannot be less than ${min}.`, field, entity);
        }
    },

    nonzero: () => (field, entity) => {
        if (getValue(field, entity) === 0) {
            throw new ValidationError(`${field.getLabel()} cannot be zero.`, field, entity);
        }
    },

    isEmailAddress: () => (field, entity) => {
        if (!(/^[^\s@]+@[^\s@]+$/.test(getValue(field, entity)))) {
            throw new ValidationError(`${field.getLabel()} is not a valid email address.`, field, entity);
        }
    },

    maxLength: (max) => (field, entity) => {
        let value = getValue(field, entity);
        if (typeof value !== "string" || value.length > max) {
            throw new ValidationError(`${field.getLabel()} is too long.`, field, entity);
        }
    },
};

