import Container from "juis-components/Container.js";


let createControls = function (...controls) {
    let cssClasses = ["field"];
    if (controls.length > 1) {
        cssClasses.push("hasAddons");
    }
    return new Container(function () {
        controls.forEach(field => {
            field.getNode().addWrapper("SPAN", ["control"]);
            this.appendChild(field);
        });
    }, cssClasses);
};

export {createControls};
