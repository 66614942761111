import {extendComponent} from "juis-components/ComponentUtils.js";
import Button from "./Button.js";

export default extendComponent(Button, function () {
    let keys = [];
    let texts = [];
    let icons = [];
    let backgroundColors = [];
    let textColors = [];
    let currentIndex = -1;
    const callbacks = {};

    const triggerChange = () => {
        if (currentIndex >= 0) {
            if (callbacks[keys[currentIndex]]) {
                callbacks[keys[currentIndex]].call();
            }
        }
        this.trigger("change");
    };

    this.updateButton = function () {
        this.text = texts[currentIndex];
        if (icons[currentIndex]) {
            this.icon = icons[currentIndex];
        } else {
            this.icon = null;
        }
        if (backgroundColors[currentIndex]) {
            this.getNode().setStyle("background-color", backgroundColors[currentIndex]);
        } else {
            this.getNode().setStyle("background-color", "");
        }
        if (textColors[currentIndex]) {
            this.getNode().setStyle("color", textColors[currentIndex]);
        } else {
            this.getNode().setStyle("color", "");
        }
    };

    this.addValueFromSettings = function (settings) {
        let {key, text, icon, textColor, backgroundColor} = settings;
        this.addValue(key, text, icon, textColor, backgroundColor);
    };
    this.addValue = function (key, text, icon, textColor, backgroundColor) {
        keys.push(key);
        texts.push(text);
        icons.push(icon);
        backgroundColors.push(backgroundColor);
        textColors.push(textColor);
        if (currentIndex === -1) {
            currentIndex = 0;
            this.updateButton();
            triggerChange();
        }
        return {
            withCallback: (callback) => callbacks[key] = callback
        };
    };

    this.setValue = function (newValue) {
        currentIndex = keys.indexOf(newValue);
        if (currentIndex === -1 && keys.length > 0) {
            currentIndex = 0;
        }
        this.updateButton();
        triggerChange();
    };

    this.getValue = function () {
        return keys[currentIndex];
    };

    this.on("click", () => {
        currentIndex += 1;
        if (currentIndex >= keys.length) {
            currentIndex = 0;
        }
        this.updateButton();
        triggerChange();
    });
});
