let registerServiceWorker = (appCode, appData) => {
    if (!navigator.serviceWorker) {
        return Promise.reject();
    }

    const scope = `${document.location.origin}/${appCode}/`;
    return navigator.serviceWorker.getRegistrations()
        .then((registrations) => registrations.find(registration => registration.scope === scope))
        .then(registration => {
            if (registration) {
                return registration.update().then(() => registration);
            } else {
                return navigator.serviceWorker.register("/service-worker.js", {scope});
            }
        });
};
export default registerServiceWorker;
