import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import StatisticsValues from "./StatisticsValues";

export default new Model(function () {
    this.tickets = new Field(StatisticsValues);
    this.parentTickets = new Field(StatisticsValues);
    this.bills = new Field(StatisticsValues);
    this.transactions = new Field(StatisticsValues);
    this.fetch = () => this.fetchCustomEndpoint().then((json) => this.createInstance(json));
}, "Statistics", "statistics");
