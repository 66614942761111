import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import Resource from "./Resource.js";
import Ticket from "./Ticket.js";
import ResourceRequirement from "./ResourceRequirement.js";
import Filter from "../../utils/Filter.js";

export default new Model(function () {
    this.app = new Field(App);
    this.name = new Field().asString().withLabel("Name");
    this.ticket = new Field(Ticket).withLabel("Ticket");
    this.resourceRequirement = new Field(ResourceRequirement).withLabel("Resource requirement");
    this.resource = new Field(Resource)
        .withFilterGetter((ticketResource) => {
            const group = ticketResource?.resourceRequirement?.resourceGroup;
            return group ? Filter.eq(Resource.groups, group) : Filter.true;
        })
        .withLabel("Resource");
    setDefaults(this, {defaultField: this.name});
}, "TicketResource", "ticket-resources");
