import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createIcon} from "../../bulma_components/Icon.js";
import App from "./App.js";
import Validation from "../../utils/Validation.js";
import setDefaults from "./utils/setDefaults.js";
import Filter from "../../utils/Filter.js";
import {
    createSimpleField,
    createStringEditor,
    createTextAreaEditor,
    getDropDownFactory,
    iconPickerFactory
} from "../../components/fieldEditors/editorFactories.js";
import InputWithSuggestions from "../../components/dropdown/InputWithSuggestions.js";
import TextComponent from "../../components/TextComponent.js";
import DynamicFieldOption from "./DynamicFieldOption.js";
import Optional from "../../../lib/JuiS/Optional.js";
import Method from "../../utils/Method.js";
import {getFieldSuggestions} from "../../utils/fieldUtils.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import dynamicFieldLexicons from "./lexicons/dynamic-field/dynamicFieldLexicons.js";
import dynamicModels from "./utils/dynamicModels.js";
import Container from "juis-components/Container.js";
import {createLink} from "../../bulma_components/Link";
import AppSettings from "./AppSettings";
import EventLoggerSingleton from "../../utils/EventLogger";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses";

const eventLogger = new EventLoggerSingleton();
const linguist = new Linguist(dynamicFieldLexicons);
let DynamicField = new RestModel(function () {
    this.app = new Field(App);
    this.id = new Field()
        .asReadOnly()
        .asNumber();
    this.code = new Field().asString().withLabel("Url Code");
    this.name = new Field()
        .asString()
        .withEditorFactory((field, dynamicField) => {
            const input = new InputWithSuggestions();
            input.setOptionsLoader((searchString) => {
                if (!dynamicField.model) {
                    return Promise.resolve([]);
                }
                let model = RestModel.getByName(dynamicField.model);
                if (!model) {
                    return Promise.resolve([]);
                }
                return getFieldSuggestions(model, searchString);
            });
            input.setValue(dynamicField.name);
            return createSimpleField(field, dynamicField, input);
        })
        .withLabel("Path");
    this.model = new Field()
        .withDefaultValueCallback(() => "Ticket")
        .asEnum(dynamicModels)
        .withLabel("Model");
    this.label = new Field()
        .asTranslatableString()
        .withLabel("Label");
    this.options = new Field([DynamicFieldOption]).withLabel("Options").asCascading();
    this.icon = new Field()
        .asString()
        .withEditorFactory(iconPickerFactory)
        .withCellFactory((icon) => createIcon(icon))
        .withLabel("Icon");
    this.multiline = new Field()
        .asBoolean()
        .withLabel("Multiline");
    this.required = new Field()
        .asBoolean()
        .withLabel("Required");
    this.readOnly = new Field()
        .asBoolean()
        .withLabel("Read Only");
    this.nonzero = new Field()
        .asBoolean()
        .withLabel("Nonzero");
    this.min = new Field()
        .asNumber()
        .asNullable()
        .withLabel("Minimum");
    this.max = new Field()
        .asNumber()
        .asNullable()
        .withLabel("Maximum");
    this.defaultValue = new Field()
        .asString()
        .asNullable()
        .withLabel("Default");
    this.dateStyle = new Field()
        .asEnum(["full", "long", "medium", "short"])
        .asNullable()
        .withLabel("Date Style");
    this.timeStyle = new Field()
        .asEnum(["full", "long", "medium", "short"])
        .asNullable()
        .withLabel("Time Style");
    this.renderOptionCellAsIcon = new Field()
        .asBoolean()
        .withLabel("Render Option as Icon");

    this.localeMatcher = new Field().asString().withLabel("Locale Matcher");
    this.style = new Field().asString().withLabel("Style");
    this.currency = new Field().asString().withLabel("Currency");
    this.currencyDisplay = new Field().asString().withLabel("Currency Display");
    this.currencySign = new Field().asString().withLabel("Currency Sign");
    this.useGrouping = new Field().asBoolean().withLabel("Use Grouping");
    this.minimumIntegerDigits = new Field().asNumber().withLabel("Minimum Integer Digits");
    this.minimumFractionDigits = new Field().asNumber().withLabel("Minimum Fraction Digits");
    this.maximumFractionDigits = new Field().asNumber().withLabel("Maximum Fraction Digits");
    this.minimumSignificantDigits = new Field().asNumber().withLabel("Minimum Significant Digits");
    this.maximumSignificantDigits = new Field().asNumber().withLabel("Maximum Significant Digits");
    this.type = new Field().asEnum([
        Field.STRING,
        Field.NUMBER,
        Field.BOOLEAN,
        Field.DATE,
        Field.FILE,
        Field.IMAGE
    ]).withLabel("Overridden type");
    this.inheritFrom = new Field(this).withLabel("Inherit from")
        .withFilterGetter((current) => Filter.isNull(DynamicField.inheritFrom).and(Filter.ne(DynamicField.id, current.id)));
    this.beforeCell = new Field(this).withLabel("Before Cell")
        .withFilterGetter((current) => Filter.ne(DynamicField.id, current.id));
    this.afterCell = new Field(this).withLabel("After Cell")
        .withFilterGetter((current) => Filter.ne(DynamicField.id, current.id));
    this.optionsSummary = new Field()
        .asVirtual(undefined, (dynamicField => dynamicField.options))
        .withCellFactory((value, field) => {
            if (value.length > 0) {
                return new Container(function () {
                    if (value.length > 0) {
                        let linkText;
                        if (value.length <= 4) {
                            linkText = value.map(option => DynamicFieldOption.value.getTranslatedValueFor(option)).join(", ");
                        } else {
                            linkText = value.slice(0, 3).map(option => DynamicFieldOption.value.getTranslatedValueFor(option)).join(", ") + ", And " + (value.length - 3) + " more...";
                        }
                        if (linkText.length > 50) {
                            this.link = createLink("/" + field.app.code + "/dynamic-field-options/" + field.code, value.length + " Options");
                        } else {
                            this.link = createLink("/" + field.app.code + "/dynamic-field-options/" + field.code, linkText);
                        }
                    }
                });
            }
        })
        .withLabel("Options");

    this.usageSummary = new Field()
        .asVirtual(undefined, dynamicField => dynamicField.getUsage())
        .withCellFactory(usages => {
            return new Container(function () {
                usages.forEach(usage => {
                    this.appendChild(new Container(function () {
                        this.entity = usage.entity.getDefaultCell();
                        this.model = new TextComponent(` (${usage.entity.getModel().getName()} - ${usage.field.getName()})`);
                    }));
                });
            });
        })
        .withLabel("Usage");

    this.makeTranslatable();

    setDefaults(this, {
        editorFactory: getDropDownFactory({
            itemFactory: (dynamicField) => new TextComponent(`${dynamicField.getLabel()} (${dynamicField.model}.${dynamicField.name})`),
        }),
        filterFields: [this.label, this.name],
        cellFactory: (dynamicField) => new TextComponent(dynamicField.getLabel())
    });

    this.fromRealField = (realField) => {
        let dynamicField = new this();
        if (realField instanceof Field) {
            dynamicField.name = realField.getName();
            dynamicField.model = realField.getOwner().getName();
        } else if (realField instanceof RestModel) {
            dynamicField.name = null;
            dynamicField.model = realField.getName();
        }
        return dynamicField;
    };

    this.getOptionsLoaderForModel = (model) => (searchString, entity) => {
        let fields = [DynamicField.name, DynamicField.label];
        let filter = Filter.eq(DynamicField.model, model.getName());
        return DynamicField.getListForDropdown(searchString, fields, entity.app, filter);
    };
    this.getSearchInputCreator = (model) => (input, parent) => {
        const newField = new DynamicField();
        newField.app = parent.app;
        newField.name = input;
        newField.model = model.getName();
        return newField.save();
    };
    this.getOptionsLoaderForModelGetter = (modelNameGetter) => (searchString, entity) => {
        let fields = [DynamicField.name, DynamicField.label];
        let filter = Filter.eq(DynamicField.model, modelNameGetter(entity));
        return DynamicField.getListForDropdown(searchString, fields, entity.app, filter);
    };

    this.getUsage = new Method(function () {
        return AppSettings.getFields()
            .filter(field => field.isModelList())
            .flatMap(field => field.getValueFor(this.app.settings))
            .flatMap(entity => {
                return entity.getFields()
                    .filter(field => field.getModelType() === DynamicField)
                    .filter(field => {
                        let value = field.getValueFor(entity);
                        if (Array.isArray(value)) {
                            return value.includes(this);
                        }
                        return this.equals(value);
                    })
                    .map(field => ({entity, field}));
            });
    });

    this.getLabel = function () {
        if (this instanceof RestModel && !(this instanceof DynamicField)) {
            return "Dynamic Field";
        }
        if (this.deleted) {
            return "[DELETED]";
        }
        if (this.label) {
            return this.getModel().label.getTranslatedValueFor(this);
        }
        if (this.inheritFrom) {
            return this.inheritFrom.getLabel();
        }
        let model = RestModel.getByName(this.model);
        if (model) {
            if (!this.name) {
                return model.getName();
            }
            if (model[this.name]) {
                return model[this.name].getLabel();
            }
        }
        eventLogger.logError(`Could not get label for ${this.model} ${this.name}`);
        return "Dynamic Field";
    };

    this.withOptionsLoader = new Method(function (newOptionsLoader) {
        this.overriddenOptionsLoader = newOptionsLoader;
    });
    this.withCellFactory = new Method(function (newCellFactory) {
        this.overriddenCellFactory = newCellFactory;
    });
    let superGetName = this.getName;
    this.getName = function () {
        if (this instanceof DynamicField) {
            return this.name;
        }
        return superGetName.call(this);
    };
    this.getOrderBy = function () {
        return this.name || RestModel.getByName(this.model).getOrderBy();
    };
    this.getType = function () {
        return this.type || RestModel.getByName(this.model)[this.name].getType();
    };
    this.getDefaultValue = function () {
        return this.defaultValue;
    };
    this.getValueFor = function (entity) {
        return entity ? entity[this.getName()] : undefined;
    };
    this.getNumberFormat = function () {
        let numberFormat = {};
        let addFormat = (field) => {
            let fieldName = field.getName();
            if (this[fieldName] !== null) {
                numberFormat[fieldName] = this[fieldName];
            }
        };
        addFormat(DynamicField.localeMatcher);
        addFormat(DynamicField.style);
        addFormat(DynamicField.currency);
        addFormat(DynamicField.currencyDisplay);
        addFormat(DynamicField.currencySign);
        addFormat(DynamicField.useGrouping);
        addFormat(DynamicField.minimumIntegerDigits);
        addFormat(DynamicField.minimumFractionDigits);
        addFormat(DynamicField.maximumFractionDigits);
        addFormat(DynamicField.minimumSignificantDigits);
        addFormat(DynamicField.maximumSignificantDigits);
        return numberFormat;
    };
    this.dynamicValidate = function (entity) {
        if (this.required) {
            Validation.hasValue()(this, entity);
        }
        if (this.nonzero) {
            Validation.nonzero()(this, entity);
        }
        if (this.min != null) {
            Validation.minimum(this.min)(this, entity);
        }
        if (this.max != null) {
            Validation.maximum(this.max)(this, entity);
        }
    };
    this.referencesField = function (otherField) {
        if (!this.name) {
            return false;
        }
        return this.getField(otherField.getOwner()).equals(otherField);
    };

    this.getFieldProxy = function (model, existingField) {
        return new Proxy(existingField, {
            get: (target, key) => {
                if (key === "getIcon") {
                    return () => this.icon;
                }
                if (key === "getLabel") {
                    return () => this.getLabel();
                }
                if (key === "isReadOnly") {
                    return () => this.readOnly;
                }
                if (key === "readOnly") {
                    return this.readOnly;
                }
                if (key === "getDefaultValue") {
                    return () => this.getDefaultValue() ?? model[this.name].getDefaultValue();
                }
                if (key === "validate") {
                    return (entity) => {
                        this.dynamicValidate(entity);
                        return target.validate(entity);
                    };
                }
                if (key === "getNumberFormat") {
                    return () => ({...target.getNumberFormat(), ...this.getNumberFormat()});
                }
                if (key === "getOptions") {
                    return () => this.getOptions;
                }
                if (key === "getOptionsPromise" && this.overriddenOptionsLoader) {
                    return this.overriddenOptionsLoader;
                }
                if (key === "cellFactory" && this.overriddenCellFactory) {
                    return this.overriddenCellFactory;
                }
                if (key === "getEditorFactory" && this.getOptions()?.length > 0) {
                    return () => (field, entity) => getDropDownFactory({
                        searchable: false,
                        tagFactory: (value) => this.findOption(value)
                            .map(DynamicFieldOption.getDefaultCell)
                            .orElseGet(() => new TextComponent(value)),
                        itemFactory: (value) => this.findOption(value)
                            .map(DynamicFieldOption.getDefaultItem)
                            .orElseGet(() => new TextComponent(value)),
                        optionsLoader: () => Promise.resolve(this.getOptions().map(option => option.value))
                    })(field, entity);
                }
                if (this.type === Field.STRING) {
                    if (key === "getEditorFactory") {
                        if (this.multiline) {
                            return () => createTextAreaEditor;
                        }
                        return () => createStringEditor;
                    }
                }

                if (key === "getType") {
                    return () => this.type || target.getType();
                }
                if (key === "cellFactory") {
                    if (this.getOptions()?.length > 0) {
                        return (value) => this.findOption(value)
                            .map(DynamicFieldOption.getDefaultCell)
                            .orElseGet(() => new TextComponent(value));
                    }
                    if (this.type === Field.DATE) {
                        return this.getDynamicDateCellFactory();
                    }
                    if (this.afterCell || this.beforeCell) {
                        let cellFactory = Reflect.get(target, key);
                        return (...args) => new Container((container) => {
                            if (this.beforeCell) {
                                container.before = this.beforeCell.getField().getCell(...args);
                                container.before.getNode().setStyle("margin-right", "0.5em");
                            }
                            container.cell = cellFactory.call(target, ...args);
                            if (this.afterCell) {
                                container.after = this.afterCell.getField().getCell(...args);
                                container.after.getNode().setStyle("margin-left", "0.5em");
                            }
                        }, [BULMA_CSS_CLASSES.IS_FLEX]);
                    }
                }
                if (key === "getModelType") {
                    return () => this.name ? target.getModelType() : model;
                }
                return Reflect.get(target, key);
            }
        });
    };

    this.getDynamicDateCellFactory = function () {
        return date => {
            if (date === null || date === undefined) {
                return "";
            }
            return new Intl.DateTimeFormat(Linguist.getLanguage(), {
                dateStyle: this.dateStyle ?? undefined,
                timeStyle: this.timeStyle ?? undefined
            }).format(new Date(date));
        };
    };

    this.getOptions = function () {
        return this.options.length > 0 ? this.options : this.inheritFrom?.options.map(inheritedOption => {
            let copiedOption = inheritedOption.copy();
            copiedOption.dynamicField = this;
            return copiedOption;
        });
    };

    this.getField = function (model) {
        if (!model) {
            model = RestModel.getByName(this.model);
            if (!model) {
                throw new Error("Unknown model for dynamic field: " + this.model);
            }
        }
        if (!this.name) {
            return this.getFieldProxy(model, model);
        }
        if (model[this.name] instanceof Field) {
            return this.getFieldProxy(model, model[this.name]);
        } else {
            const field = new Field()
                .setName(this.name)
                .setOwner(model)
                .withIcon(this.icon)
                .withLabel(this.getLabel());
            if (this.type === Field.NUMBER) {
                field.asNumber();
            } else if (this.type === Field.BOOLEAN) {
                field.asBoolean();
            } else if (this.type === Field.IMAGE) {
                field.asImage();
            } else if (this.type === Field.FILE) {
                field.asFile();
            } else if (this.type === Field.DATE) {
                field.asDate().withCellFactory(this.getDynamicDateCellFactory());
            } else {
                field.asString();
                if (this.multiline) {
                    field.withEditorFactory(createTextAreaEditor);
                }
            }
            if (this.required) {
                field.asRequired();
            }
            if (this.readOnly) {
                field.asReadOnly();
            }
            if (this.min) {
                field.withMin(this.min);
            }
            if (this.max) {
                field.withMax(this.max);
            }
            if (this.nonzero) {
                field.withValidationRule(Validation.nonzero(field));
            }

            let options = this.getOptions();
            if (options?.length > 0) {
                field.asEnum(this.options.map(option => option.value));
                field.withEditorFactory(getDropDownFactory({
                    tagFactory: (value) => this.findOption(value)
                        .map(DynamicFieldOption.getDefaultCell)
                        .orElseGet(() => new TextComponent(value)),
                    itemFactory: (value) => this.findOption(value)
                        .map(DynamicFieldOption.getDefaultItem)
                        .orElseGet(() => new TextComponent(value))
                }));
                field.withCellFactory((value) => {
                        if (Array.isArray(value)) {
                            return new Container(container => {
                                value.map(item => this.findOption(item)
                                    .map(DynamicFieldOption.getDefaultCell)
                                    .orElseGet(() => new TextComponent(value))
                                ).forEach(itemComponent => container.appendChild(itemComponent));
                            });
                        }
                        return this.findOption(value)
                            .map(DynamicFieldOption.getDefaultCell)
                            .orElseGet(() => new TextComponent(value));
                    }
                );
            }
            return field;
        }
    };
    this.findOption = new Method(function (optionValue) {
        return Optional.of(this.getOptions().find(option => option.value === optionValue));
    });
    this.excludeFromCachedDescendants();
    linguist.withAudience(this);
}, "DynamicField", "dynamic-fields");
export default DynamicField;
