import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {createLink} from "../../bulma_components/Link.js";
import {
    createTextAreaEditor,
    getDropDownFactory,
    iconPickerFactory
} from "../../components/fieldEditors/editorFactories.js";
import {createIcon, createIconCell} from "../../bulma_components/Icon.js";
import {createTextNode} from "juis-components/ComponentUtils.js";
import App from "./App.js";
import {getEditorFields} from "../../utils/fieldUtils.js";
import TicketProduct from "./TicketProduct.js";
import BillTemplate from "./BillTemplate.js";
import BillType from "./BillType.js";
import TicketTemplate from "./TicketTemplate.js";
import TicketStatus from "./TicketStatus.js";
import TicketList from "./TicketList.js";
import ContactGroup from "./ContactGroup.js";
import DynamicField from "./DynamicField.js";
import Ticket from "./Ticket.js";
import Product from "./Product.js";
import WorkLog from "./WorkLog.js";
import setDefaults from "./utils/setDefaults.js";
import getIconCellFactory from "./utils/getIconCellFactory.js";
import ProductGroup from "./ProductGroup.js";
import TextComponent from "../../components/TextComponent.js";
import TicketCopyOption from "./TicketCopyOption.js";


let TicketType = new RestModel(function () {
    this.app = new Field(App);
    this.id = new Field()
        .asReadOnly()
        .asNumber();
    this.name = new Field()
        .asTranslatableString()
        .withLabel("Name");
    this.icon = new Field()
        .asString()
        .withCellFactory(createIconCell)
        .withEditorFactory(iconPickerFactory)
        .withLabel("Icon");
    this.description = new Field()
        .asString()
        .withLabel("Description");
    this.code = new Field().asString();
    this.statuses = new Field([TicketStatus])
        .asCascading()
        .withLabel("Statuses");
    this.ticketTemplates = new Field([TicketTemplate]).withLabel("Ticket templates");
    this.editorFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Ticket))
        .withLabel("Editor Fields");
    this.productEditorFields = new Field()
        .asEnumList(() => getEditorFields(TicketProduct))
        .withEditorFactory(getDropDownFactory({
            itemFactory: (field) => TicketProduct[field].getLabel(),
        }))
        .withLabel("Product editor fields")
        .withHelp("Deprecated");
    this.dynamicProductEditorFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(TicketProduct))
        .withLabel("Dynamic Product editor fields")
        .withHelp("Overrides the normal fields");

    this.productColumns = new Field()
        .asEnumList(() => getEditorFields(TicketProduct))
        .withEditorFactory(getDropDownFactory({
            itemFactory: (field) => TicketProduct[field].getLabel(),
        }))
        .withLabel("Product columns")
        .withHelp("Deprecated");

    this.dynamicProductColumns = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(TicketProduct))
        .withLabel("Dynamic Product columns")
        .withHelp("Overrides the normal columns");
    this.deleted = new Field()
        .asBoolean();
    this.parentRequired = new Field()
        .asBoolean()
        .withLabel("Require\u00A0parent")
        .withCellFactory((parentRequired) => createTextNode(parentRequired ? "Yes" : "No"))
        .withHelp("Tickets cannot be created without a parent or for a parent of the wrong type.");
    this.children = new Field([this]).asCascading();
    this.parent = new Field(this)
        .withLabel("Parent ticket")
        .withOptionsLoader(() => Promise.resolve(this.getAll()));
    this.ordinal = new Field()
        .asNumber()
        .withLabel("Ordinal");
    this.productListIcon = new Field()
        .asString()
        .withCellFactory((icon) => createIcon(icon))
        .withEditorFactory(iconPickerFactory)
        .withLabel("Products icon");
    this.productListTitle = new Field().asTranslatableString().withLabel("Product List Title");
    this.productListNewProductTitle = new Field().asTranslatableString().withLabel("Add Product Title");
    this.productListEditProductTitle = new Field().asTranslatableString().withLabel("Edit Product Title");
    this.printTemplate = new Field(BillTemplate).withLabel("Print template");
    this.billable = new Field()
        .withLabel("Billable")
        .asBoolean();
    this.commentsEnabled = new Field()
        .withLabel("Comments enabled")
        .asBoolean();
    this.billType = new Field(BillType).withLabel("Bill type");
    this.childTicketList = new Field(TicketList).withLabel("Child ticket list");
    this.viewTypes = {
        PRINT_TEMPLATE: "PRINT_TEMPLATE",
        NONE: "NONE",
        BIG_TEXT_EDITOR: "BIG_TEXT_EDITOR",
        ONLY_EDITOR: "ONLY_EDITOR"
    };
    this.ticketView = new Field()
        .asEnum(Object.keys(this.viewTypes))
        .withDefaultValueCallback(() => this.viewTypes.NONE)
        .withLabel("Ticket View");
    this.nextNumber = new Field()
        .asNumber()
        .withLabel("Next number");
    this.assignees = new Field(ContactGroup).withLabel("Assignees");
    this.customers = new Field([ContactGroup]).withLabel("Customers");
    this.workLogProductGroups = new Field([ProductGroup]).withLabel("Work product groups");
    this.mainProductGroups = new Field([ProductGroup]).withLabel("Main product groups");
    this.mainProductVariations = new Field()
        .asEnum(["BOTH", "VARIATIONS", "VARIATION_ROOTS"])
        .withDefaultValueCallback(() => "BOTH")
        .withLabel("Main product variations");
    this.productsGroups = new Field([ProductGroup]).withLabel("Product groups");
    this.defaultWorkLogProduct = new Field(Product)
        .withLabel("Default worklog product");
    this.workLogFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(WorkLog))
        .withLabel("Work log fields");
    this.customerFields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModel(Ticket))
        .withLabel("Customer fields");
    this.defaultProductListProduct = new Field(Product)
        .withLabel("Default product list product");
    this.billItemsHeaderMapper = new Field().asString().withLabel("Bill items header");
    this.billItemsProductListSubHeaderMapper = new Field().asString().withLabel("Bill items products subheader");
    this.billItemsWorkLogSubHeaderMapper = new Field().asString().withLabel("Bill items work log subheader");
    this.emailSubjectTemplate = new Field().asString().withLabel("Email subject");
    this.emailBodyTemplate = new Field().asString().withLabel("Email body").withEditorFactory(createTextAreaEditor);
    this.hasFileAttachments = new Field().asBoolean().withLabel("Enable attachments");
    this.reverseTransactions = new Field().asBoolean().withLabel("Reverse Transactions");
    this.showChildEditors = new Field().asBoolean().withLabel("Show child editors");
    this.forReseller = new Field().asBoolean().withLabel("For reseller");
    this.forClient = new Field().asBoolean().withLabel("For client");
    this.deletable = new Field().asBoolean().withLabel("Deletable");
    this.estimatedDuration = new Field()
        .asDuration()
        .asSummable()
        .withLabel("Estimated duration")
        .asNullable();
    this.copyOptions = new Field([TicketCopyOption]).asCascading();
    this.saveFirst = new Field().asBoolean().withLabel("Save first");

    this.makeTranslatable();
    const iconCellFactory = getIconCellFactory(this.icon, this.name);
    setDefaults(this, {
        orderByField: this.name,
        filterFields: [this.name],
        itemFactory: (entity) => entity ? iconCellFactory(entity) : new TextComponent("None"),
        linkFactory: type => createLink(`/${type.app.code}/settings/ticket-types/${type.code}/`, this.name.getTranslatedValueFor(type), type.icon)
    });
    this.excludeFromCachedDescendants();

    this.getDefaultProductItemFields = function (ticket) {
        return {
            ticket,
            reverseTransactions: ticket.type.reverseTransactions,
            billable: ticket.type.billable,
            vatPercentage: ticket.app.defaultVat.vatPercentage,
            priceIncludesVat: ticket.app.pricesIncludeVat,
            product: ticket.type.defaultProductListProduct
        };
    };
}, "TicketType", "ticket-types", true);

export {TicketType as default};
