import Container from "juis-components/Container.js";
import {createTextNode, extendComponent} from "juis-components/ComponentUtils.js";
import HTML from "../../utils/HTML.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import Sortable from "sortablejs/modular/sortable.core.esm.js";
import Input from "../../bulma_components/Input.js";
import {createIcon} from "../../bulma_components/Icon.js";
import Base from "./Base.js";
import TextComponent from "../TextComponent.js";
import PromiseThrottler from "../../utils/PromiseThrottler.js";
import EditorCard from "../table-view/EditorCard.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import dropdownLexicons from "./dropdownLexicons.js";

const JUIS_SELECT_TAG = "juis-select-tag";
const JUIS_SELECT_TAG_HANDLE = "juis-select-tag-handle";
const JUIS_SELECT_TAG_CHOSEN = "juis-select-tag-chosen";

function moveInArray(array, from, to) {
    array.splice(to, 0, array.splice(from, 1)[0]);
}

const linguist = new Linguist(dropdownLexicons);

export default extendComponent(Base, function () {
    let tags = [];

    const tagFactory = key => {
        let data = this.getItemData(key);
        let tag = new Container(function () {
            let tagContent = tagContentFactory(data);
            if (typeof tagContent === "string") {
                this.text = new TextComponent(tagContent, HTML.SPAN);
            } else {
                this.text = tagContent;
            }
            this.text.getNode().addWrapperCssClass(JUIS_SELECT_TAG_HANDLE);
        }, [BULMA.TAGS, BULMA.HAS_ADDONS, JUIS_SELECT_TAG], HTML.SPAN);

        tag.getNode().setAttribute("data-item-key", key);
        if (this.multiple) {
            tag.text.getNode().addWrapperCssClass(BULMA.TAG);
            tag.close = new TextComponent("", HTML.A, [BULMA.TAG, BULMA.IS_DELETE]);
            tag.close.registerDomEvents("click");
            tag.close.on("click", () => {
                this.removeKeyFromSelection(key);
            });
        } else {
            tag.registerDomEvents("click");
            tag.on("click", () => this.clearSelection());
        }
        return tag;
    };

    const addTag = (key) => {
        let tag = tagFactory(key);
        tags.push(tag);
        this.insertBefore(tag, searchInput || this.triggerButton);
        if (searchInput) {
            searchInput.setValue("");
            this.search("");
        }
    };

    this.extendProperty("multiple", (value) => {
        if (value) {
            Sortable.create(this.getNode().getActualElement(), {
                group: "tags",
                animation: 150,
                delayOnTouchOnly: true,
                touchStartThreshold: 3,
                delay: 250,
                handle: "." + JUIS_SELECT_TAG_HANDLE,
                draggable: "." + JUIS_SELECT_TAG,
                chosenClass: JUIS_SELECT_TAG_CHOSEN,
                onEnd: (event) => {
                    moveInArray(this.getSelectionForModification(), event.oldDraggableIndex, event.newDraggableIndex);
                    this.trigger("change");
                },
            });
        }
    });
    let tagContentFactory = data => new TextComponent(data);
    this.setTagFactory = (newTagFactory) => tagContentFactory = newTagFactory;

    this.overrideMethod("search", (overridden, searchString) => {
        this.showDropdown();
        return overridden.call(this, searchString);
    });

    let searchInput;
    this.enableSearch = function () {
        this.getNode().removeAttribute("tabindex");
        searchInput = new Input((input) => {
            const searcher = new PromiseThrottler().withCallback((value) => this.search(value)).defer(250);
            input.on("input", () => searcher.load(input.getValue()));
            input.dontPropagate("change");
            input.registerDomEvents("keydown");
            input.on("keydown", function (eventData, event) {
                switch (eventData.keyCode) {
                    case 27: // Escape
                        input.setValue("");
                        break;
                    case 8: // Backspace
                        if (input.getValue() !== "") {
                            event.stopPropagation();
                        }
                        break;
                }
            });
            input.getNode().removeCssClass("input");
        }, ["juis-select-search"]);
        this.insertBefore(searchInput, this.triggerButton);
        toggleSearch();
    };

    const toggleSearch = () => {
        if (!searchInput) {
            return;
        }
        if (this.multiple) {
            searchInput.getNode().togglePlaceholder(false);
            this.getNode().setAttribute("tabindex", "-1");
            searchInput.focus();
        } else {
            if (this.getValue()) {
                searchInput.getNode().togglePlaceholder(true, "Search input");
                this.getNode().setAttribute("tabindex", "0");
            } else {
                searchInput.getNode().togglePlaceholder(false);
                this.getNode().setAttribute("tabindex", "-1");
                searchInput.focus();
            }
        }
    };

    const getCreatorItem = (text, callback) => {
        return new Container((creatorItem) => {
            this.getNode().setAttribute("tabindex", "-1");
            creatorItem.icon = createIcon("plus", BULMA_CSS_CLASSES.TYPOGRAPHY.HAS_TEXT_SUCCESS);
            creatorItem.text = createTextNode(text);
            creatorItem.registerDomEvents("click");
            creatorItem.on("click", callback);
        }, [BULMA.IS_UNSELECTABLE, BULMA.DROPDOWN.ITEM]);
    };

    this.enableCreator = function (creator) {
        let creatorItem = getCreatorItem(linguist.t("createNew"), () => {
            this.menu.removeChild(creatorItem);
            creator.expand();
            this.menu.dropdownContent.hide();
        });

        creator.on("collapse", () => {
            this.menu.insertBefore(creatorItem, this.menu.dropdownContent);
            this.menu.dropdownContent.show();
        });
        creator.on(EditorCard.EVENTS.SUBMITTED, (entity) => {
            if (!this.multiple) {
                this.clearSelection();
            }
            this.addToSelection(entity);
        });
        creator.dontPropagate(["expand", "collapse", "close", "open", "submit", EditorCard.EVENTS.SUBMITTED]);
        this.menu.insertBefore(creator, this.menu.dropdownContent);
        this.menu.insertBefore(creatorItem, this.menu.dropdownContent);
    };

    this.enableSearchInputCreator = (creatorCallback, exactMatch = (resultEntity, searchInput) => true) => {
        const afterSearch = (searchInput, result) => {
            if (searchInput && (result.length === 0 || !result.some(resultEntity => exactMatch(resultEntity, searchInput)))) {
                let creatorButton = getCreatorItem(linguist.t("create", {name: searchInput}), () => {
                    this.triggerButton.asLoading();
                    return creatorCallback(searchInput).then(newItem => {
                        this.addToSelection(newItem);
                        this.triggerButton.asIdle();
                    });
                });
                this.menu.dropdownContent.addComponent(creatorButton);
            }
            return result;
        };
        this.overrideMethod("search", (overridden, searchInput) =>
            overridden(searchInput).then(result => afterSearch(searchInput, result)));
    };

    this.on("change", () => {
        tags.forEach(tag => this.removeChild(tag));
        tags = [];
        this.getSelection().forEach(addTag);
        if (!this.multiple && searchInput) {
            toggleSearch();
        }
    });
    this.on("expand", (ignore, event) => event.stopPropagation());
    this.on("collapse", (ignore, event) => event.stopPropagation());
});
