import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Property from "juis-components/Property.js";

const removeInvisibleCharacters = (text) => {
    return text.replace(/[\u200B-\u200D\uFEFF]/g, '');
};

let Input = extendComponent(Container, function () {
    const coerce = (value) => {
        if (value instanceof Date) {
            switch (this.type) {
                case Input.Types.DATE:
                    value = value.toISOString().substr(0, 10);
                    break;
                case Input.Types.DATETIME_LOCAL:
                    value = (new Date(value.getTime() - value.getTimezoneOffset() * 60000).toISOString()).slice(0, -1);
                    break;
            }
        }
        if (value === undefined || value === null) {
            value = "";
        }
        return value + "";
    };
    let valueSet = false;
    this.value = new Property((value) => {
        value = removeInvisibleCharacters(coerce(value));
        valueSet = true;
        this.getNode().setAttribute("value", value);
        this.getNode().setElementProperty("value", value);
    });
    this.placeholder = new Property((placeholder) => {
        this.getNode().setAttribute("placeholder", placeholder);
    });
    this.autocomplete = new Property((autocomplete) => {
        this.getNode().setAttribute("autocomplete", autocomplete);
    });
    this.disabled = new Property((disabled) => {
        if (disabled) {
            this.getNode().setAttribute("disabled", disabled);
        } else {
            this.getNode().removeAttribute("disabled");
        }
    });
    this.type = new Property(type => this.getNode().setAttribute("type", type));
    this.step = new Property(step => {
        if (step) {
            this.getNode().setAttribute("step", step);
        } else {
            this.getNode().removeAttribute("step");
        }
    });
    this.min = new Property(min => this.getNode().setAttribute("min", min));
    this.max = new Property(max => this.getNode().setAttribute("max", max));
    this.size = new Property(size => this.getNode().setAttribute("size", size));
    this.pattern = new Property(pattern => this.getNode().setAttribute("pattern", pattern));
    this.maxLength = new Property(maxLength => this.getNode().setAttribute("maxlength", maxLength));
    this.registerDomEvents("input");
    this.registerDomEvents("change");
    this.getValue = () => {
        let value = this.getNode().getElementProperty("value");
        value = removeInvisibleCharacters(value);
        if (this.type === Input.Types.NUMBER) {
            let numberValue = parseFloat(value.replace(",", "."));
            return isNaN(numberValue) ? null : numberValue;
        }
        return value;
    };

    this.setValue = (value) => {
        value = removeInvisibleCharacters(coerce(value));
        this.getNode().setElementProperty("value", value);
        if (valueSet) {
            this.trigger("change");
        }
        valueSet = true;
    };
    this.focus = () => this.getNode().callMethod("focus");
}, ["input"], "input");
Input.Types = {
    TEXT: "text",
    NUMBER: "number",
    DATE: "date",
    DATETIME_LOCAL: "datetime-local",
    CHECKBOX: "checkbox",
    SUBMIT: "submit",
    PASSWORD: "password",
    HIDDEN: "hidden",
    COLOR: "color",
    SEARCH: "search"
};
export default Input;
