import TextComponent from "../components/TextComponent.js";
import Field from "./Field.js";

const getEditorFields = (model) => {
    return model.getFields()
        .filter(field => !field.readOnly)
        .filter(field => field.getLabel())
        .flatMap(field => {
            let childModel = field.getModelType();
            if (!childModel || !field.isCascading() || field.isModelList()) {
                return [field.getName()];
            }
            return childModel
                .getFields()
                .map(childField => field.getName() + "." + childField.getName());
        })
        .sort();
};

const getSecondLevelFields = (model) => {
    return model.getFields()
        .flatMap(field => {
            let childModel = field.getModelType();
            if (!childModel) {
                return [field.getName()];
            }
            let list = childModel
                .getFields()
                .map(childField => field.getName() + "." + childField.getName());
            list.push(field.getName());
            return list;
        })
        .sort();
};

const getFirstLevelFields = (model) => {
    return model.getFields()
        .filter(field => !field.getModelType())
        .filter(field => !field.isVirtual())
        .map(field => field.getName())
        .sort();
};

const getFieldSuggestions = (model, searchString) => {
    let head = searchString.slice(0, searchString.lastIndexOf("."));
    if (model[head] instanceof Field && model[head].getModelType()) {
        return Promise.resolve(model[head].getModelType().getFields()
            .map(relatedField => head + "." + relatedField.getName())
            .filter(fieldName => fieldName.startsWith(searchString))
        );
    } else if (model[head] instanceof Field && model[head].getType() === Field.JSON) {
        return [searchString];
    } else {
        return Promise.resolve(model.getFields()
            .map(relatedField => relatedField.getName())
            .filter(fieldName => fieldName.startsWith(searchString))
        );
    }
};

const fieldDropdownSettings = {
    groupBy: (fieldName) => {
        if (fieldName.includes(".")) {
            return fieldName.substring(0, fieldName.indexOf("."));
        }
        return null;
    },
    groupHeaderFactory: (fieldName) => {
        return new TextComponent(fieldName.substring(0, fieldName.indexOf(".")));
    },
    groupsClosed: true,
    searchInputCreator: (input) => Promise.resolve(input),
    searchMatcher: (a, b) => a === b,
};

export {getFirstLevelFields, getEditorFields, getSecondLevelFields, fieldDropdownSettings, getFieldSuggestions};
