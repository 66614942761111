import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import BillType from "./BillType.js";
import salesReportsLexicons from "./lexicons/sales-report/salesReportsLexicons.js";
import Filter from "../../utils/Filter.js";
import {getDropDownFactory} from "../../components/fieldEditors/editorFactories.js";
import {createLink} from "../../bulma_components/Link.js";

const getBillTypesByCurrency = (currencyCode) =>
    Promise.resolve(BillType.cache.query(currencyCode ? Filter.eq(BillType.currencyCode, currencyCode) : Filter.true, BillType.name));

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field().asString();
    this.name = new Field().asTranslatableString();
    this.currencyCode = new Field().asCurrency();
    this.billTypes = new Field([BillType])
        .withEditorFactory(getDropDownFactory({
            searchable: false,
            refreshOnChange: [this.currencyCode],
        }))
        .withOptionsLoader((ignore, salesReport) => getBillTypesByCurrency(salesReport.currencyCode));

    this.makeTranslatable();
    setDefaults(this, {
        defaultField: this.name,
        linkFactory: salesReport => createLink(`/${salesReport.app.code}/sales-reports/${salesReport.code}/`, this.name.getTranslatedValueFor(salesReport)),
    });

    new Linguist(salesReportsLexicons).withAudience(this);

    const currencyChangeListener = this.on(this.EVENTS.CHANGE, ({entity}) => entity.billTypes = [])
        .filter(({field}) => field.equals(this.currencyCode));

    this.on(this.EVENTS.CHANGE, ({entity}) => {
        currencyChangeListener.pause();
        entity.currencyCode = entity.billTypes[0].currencyCode;
        currencyChangeListener.resume();
    }).filter(({field, entity}) =>
        field.equals(this.billTypes) && entity.billTypes.length > 0 && entity.currencyCode === null);

}, "SalesReport", "sales-reports");
