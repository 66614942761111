import Button from "../../bulma_components/Button";
import BULMA from "../../bulma_components/bulmaCssClasses";
import {extendComponent} from "juis-components/ComponentUtils.js";
import ButtonList from "../button-list/ButtonList";

const storage = localStorage ?? {
    setItem(key, value) {
        console.warn(`No local storage. Cannot set ${key} = ${value}`);
    },
    getItem(key) {
        console.warn(`No local storage. Cannot get ${key}`);
    },
    removeItem(key) {
        console.warn(`No local storage. Cannot remove ${key}`);
    }
};
export default extendComponent(ButtonList, function () {
    this.buttonCreator = value => new Button(function () {
        switch (value) {
            case"dark":
                this.icon = "moon";
                break;
            case"light":
                this.icon = "sun";
                break;
        }
        this.getNode().setStyle();
    }, [BULMA.IS_SMALL]);
    let dark = this.addButton("dark");
    let light = this.addButton("light");
    this.on("change", (change) => {
        switch (change.currentValue) {
            case "dark":
            case "light":
                document.body.parentElement.setAttribute("data-theme", change.currentValue);
                storage.setItem("theme", change.currentValue);
                break;
            default:
                document.body.parentElement.removeAttribute("data-theme");
                storage.removeItem("theme");
        }
    });
    let currentValue = storage.getItem("theme");
    if (currentValue) {
        this.setValue(currentValue);
    }
});