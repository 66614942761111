import {BulmaContainer} from "../../bulma_components/BulmaComponent.js";
import DeleteButton from "../../bulma_components/DeleteButton.js";
import Container from "juis-components/Container.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import Button from "../../bulma_components/Button.js";
import commonLexicons from "../../hewecon/common-lexicons/commonLexicons.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import RestServer from "../../utils/RestServer.js";
import Component from "juis-components/Component.js";
import TextComponent from "../TextComponent.js";
import HTML from "../../utils/HTML.js";
import SessionHandler from "../../SessionHandler.js";

let notificationEvents = {
    PROMPT: "prompt",
    NOTIFY: "notify",
};

const linguist = new Linguist(commonLexicons);

let notifyError = (element, message, causeByError) => {
    let text = message;
    const cssClass = BULMA_CSS_CLASSES.IS_DANGER;
    if (causeByError) {
        text = `${text}. Caused by: ${causeByError.message}`;
    }
    element.trigger(notificationEvents.NOTIFY, {text, cssClass});
};

let Notification = extendComponent(BulmaContainer, function () {
    const BEFORE_CLOSE = "before-close";
    this.closeButton = new DeleteButton();
    this.closeButton.on("click", () => this.close());
    this.closeButton.getNode().setStyle("float", "right");
    this.closeButton.getNode().setStyle("margin", "5px");

    this.close = () => {
        this.trigger(BEFORE_CLOSE);
        this.hide();
        this.getNode().removeCssClass(BULMA_CSS_CLASSES.IS_PRIMARY);
        this.getNode().removeCssClass(BULMA_CSS_CLASSES.IS_INFO);
        this.getNode().removeCssClass(BULMA_CSS_CLASSES.IS_SUCCESS);
        this.getNode().removeCssClass(BULMA_CSS_CLASSES.IS_DANGER);
        this.getNode().removeCssClass(BULMA_CSS_CLASSES.IS_WARNING);
        this.getNode().setStyle("bottom", "-5em");
    };

    this.content = new Container();

    let lastVersionNotification;
    const ONE_MINUTE = 60000;
    this.notifyNewVersion = (version) => {
        if (lastVersionNotification && lastVersionNotification > (new Date()).getTime() - ONE_MINUTE) {
            return;
        }
        lastVersionNotification = (new Date()).getTime();
        const updateButton = `
        <button class="juis button is-small"
                type="button"
                style="vertical-align: middle"
                onclick="window.location.reload(true)">
            <span class="juis icon is-small"><i class="juis fas fa-redo"></i></span>
        </button>`;
        this.warn(linguist.t("newVersionNotification", {updateButton, version}));
    };

    this.showPrimary = (html, timeout) => {
        this.showNotification(BULMA_CSS_CLASSES.IS_PRIMARY, html, timeout);
    };
    this.info = (html, timeout) => {
        this.showNotification(BULMA_CSS_CLASSES.IS_INFO, html, timeout);
    };
    this.success = (html, timeout) => {
        this.showNotification(BULMA_CSS_CLASSES.IS_SUCCESS, html, timeout);
    };
    this.error = (html, timeout) => {
        this.showNotification(BULMA_CSS_CLASSES.IS_DANGER, html, timeout);
    };
    this.warn = (html, timeout) => {
        this.showNotification(BULMA_CSS_CLASSES.IS_WARNING, html, timeout);
    };

    let closeTimer = null;
    this.closeWithDelay = (timeout) => {
        closeTimer = setTimeout(() => this.close(), timeout);
    };
    this.clearTimer = () => {
        if (closeTimer) {
            clearTimeout(closeTimer);
            closeTimer = null;
        }
    };

    this.showNotification = (cssClass, html, timeout) => {
        this.clearTimer();
        this.close();
        this.content = new Container();
        this.getNode().addCssClass(cssClass);
        if (html) {
            this.content.innerHTML = html;
        }
        if (timeout) {
            this.closeWithDelay(timeout);
        }
        requestAnimationFrame(() => requestAnimationFrame(() => this.show()));
    };

    this.getNode().setStyle("transition", "bottom 350ms ease-out");
    this.overrideMethod("show", (overridden) => {
        overridden.call();
        requestAnimationFrame(() => requestAnimationFrame(() => this.getNode().setStyle("bottom", "0")));
    });

    this.showNotificationWithComponent = (cssClass, nextListenable, ...components) => {
        this.close();
        this.content = new Container();
        this.content.nextListenable = nextListenable;
        this.content.on("click", () => this.close());
        this.getNode().addCssClass(cssClass);
        components.forEach((component) => this.content.appendChild(component));
        this.show();
    };

    this.hide();

    let createButton = function (text, icon, callback, cssClass) {
        return new Button(function () {
            this.text = text;
            this.icon = icon;
            this.getNode().setStyle("margin-left", "1em");
            this.getNode().setStyle("vertical-align", "middle");
            this.on("click", () => {
                callback.call();
            });
        }, [BULMA_CSS_CLASSES.IS_SMALL, cssClass]);
    };

    this.on(notificationEvents.NOTIFY, (data, event) => {
        event.stopPropagation();
        if (data.text) {
            this.showNotification(data.cssClass, data.text, data.timeout);
        } else {
            this.showNotificationWithComponent(data.cssClass, data.nextListenable || this, ...data.components);
        }
    });

    this.on(notificationEvents.PROMPT, (event) => {
        this.showNotification(event.cssClass, event.text);
        return new Promise(((resolve, reject) => {
            if (Array.isArray(event.components)) {
                event.components.forEach(component => {
                    if (typeof component === "string") {
                        component = new TextComponent(component);
                        component.getNode().setStyle("white-space", "pre");
                    }
                    component.getNode().addCssClass(BULMA_CSS_CLASSES.IS_INLINE_BLOCK);
                    this.content.appendChild(component);
                });
            }
            this.content.buttonContainer = new Container((buttonContainer) => {
                const yesText = event.yesText ?? linguist.t("yes");
                const noText = event.noText ?? linguist.t("no");
                const yesValue = event.yesValue ?? true;
                const noValue = event.noValue ?? false;
                buttonContainer.yes = createButton(yesText, "thumbs-up", () => {
                    resolve(yesValue);
                    this.close();
                }, BULMA_CSS_CLASSES.IS_SUCCESS);
                buttonContainer.no = createButton(noText, "thumbs-down", () => {
                    resolve(noValue);
                    this.close();
                }, BULMA_CSS_CLASSES.IS_DANGER);
            }, [BULMA_CSS_CLASSES.IS_INLINE_BLOCK]);
            this.listenOnce(BEFORE_CLOSE, () => resolve(false));
        }));
    });

    const createAppNotificationComponent = (notificationEntity) => {
        return new Container(function () {
            this.logo = new Component((logo) => {
                logo.innerHTML = `<img src="/api/icon-generator/${notificationEntity.app.code}/icon.svg" alt="${notificationEntity.app.name}">`;
            }, ["is-64x64", "image", BULMA.IS_INLINE_BLOCK, "mr-3"], "FIGURE");
            this.content = new Container(function () {
                this.title = new TextComponent(notificationEntity.header, HTML.DIV, BULMA.TYPOGRAPHY.HAS_TEXT_WEIGHT_BOLD);
                this.body = new TextComponent(notificationEntity.text);
            }, [BULMA.IS_INLINE_BLOCK]);

        });
    };

    navigator.serviceWorker?.addEventListener("message", (event) => {
        if (event.data.type === "Notification") {
            let notification = new Notification(event.data.entity);
            this.trigger(notificationEvents.NOTIFY, {
                components: [createAppNotificationComponent(notification)],
                cssClass: BULMA.IS_INFO
            });
        }
    });

    const getOptions = () => fetch("/api/push-subscriptions/public-key")
        .then(response => response.arrayBuffer())
        .then(applicationServerKey => ({userVisibleOnly: true, applicationServerKey}));

    const requestNotificationPermission = () => {
        if (window.Notification.permission !== "default") {
            return Promise.resolve(window.Notification.permission);
        }
        return window.Notification.requestPermission();
    };

    this.requestNotificationPermission = (appCode, registration) => {
        if (!SessionHandler.isLoggedIn()) {
            return;
        }
        if (!window.Notification) {
            return;
        }
        if (!registration.pushManager) {
            return;
        }
        requestNotificationPermission().then(permission => {
            if (permission !== "granted") {
                return;
            }
            registration.pushManager.getSubscription()
                .then(subscription => subscription || getOptions().then(options => registration.pushManager.subscribe(options)))
                .then(subscription => RestServer.post("push-subscriptions", {
                    ...subscription.toJSON(),
                    appCode
                }));
        });
    };

}, [BULMA_CSS_CLASSES.NOTIFICATION, BULMA_CSS_CLASSES.IS_RADIUSLESS,
    BULMA_CSS_CLASSES.IS_MARGINLESS, "is-fixed-top"]);

export {Notification as default, notificationEvents, notifyError};
