import ButtonToggler from "../../../bulma_components/ButtonToggler.js";
import BULMA_CSS_CLASSES from "../../../bulma_components/bulmaCssClasses.js";

export default (value, ticket) => new ButtonToggler(function () {
    ticket.type.statuses.forEach(status => {
        this.addValue(status.code, status.name, ticket.type.icon, status?.color);
    });
    if (ticket.status) {
        this.setValue(ticket.status.code);
    }

    this.on("change", () => {
        this.isLoading = true;
        ticket.status = ticket.type.statuses.find(status => status.code === this.getValue());
        ticket.save().then(() => {
            this.isLoading = false;
        });
    });
}, [BULMA_CSS_CLASSES.IS_SMALL]);
