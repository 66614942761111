import NotFoundPage from "./NotFoundPage.js";
import ErrorPage from "./ErrorPage.js";
import LoadingPage from "./LoadingPage.js";
import Router from "juis-router/Router.js";
import SessionHandler, {SESSION_HANDLER_EVENTS} from "./SessionHandler.js";
import Container from "juis-components/Container.js";
import {extendComponent} from "juis-components/ComponentUtils.js";

const dynamicImport = (path) => {
    return import(/* webpackChunkName: "[request]" */ `./${path}/index.js`);
};

const getBaseRouter = () => {
    const router = new Router(dynamicImport, new LoadingPage(), new NotFoundPage(), new ErrorPage());
    router.setViewsBasePath("/src/hewecon/views/");
    return router;
};
const getDefaultRouter = () => {
    const router = getBaseRouter();
    router.addComponent(/^\/?([a-z\d\-]+)\/apps/, "apps", {urlParameterNames: ["username"]});
    router.addComponent(/^\/?([a-z\d\-]+)\/settings/, "user-settings", {urlParameterNames: ["username"]});
    router.addComponent(/^\/?([a-z\d\-]+)\/notifications/, "notifications", {urlParameterNames: ["username"]});
    router.addComponent(/^\/?([a-z\d\-]+)/, "user-settings", {urlParameterNames: ["username"]});
    return router;
};
const getLoginRouter = () => {
    const router = getBaseRouter();
    router.addComponent("", "login-page");
    return router;
};
export default extendComponent(Container, function () {
    const initialize = (isLoggedIn) => {
        if (isLoggedIn) {
            this.router = getDefaultRouter();
        } else {
            this.router = getLoginRouter();
        }
    };

    SessionHandler.on([SESSION_HANDLER_EVENTS.SESSION_STARTED, SESSION_HANDLER_EVENTS.SESSION_UPDATED, SESSION_HANDLER_EVENTS.SESSION_ENDED], user => initialize(!!user));
    initialize(SessionHandler.isLoggedIn());
});
