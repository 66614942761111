import Container from "juis-components/Container.js";
import ImageModal from "../bulma_components/ImageModal.js";
import Component from "juis-components/Component.js";
import HTML from "../utils/HTML.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import Property from "juis-components/Property.js";

const ImageThumbnail = extendComponent(Container, function () {
    this.modal = new ImageModal();
    this.image = new Component((image) => {
        image.getNode().setStyle("max-width", "10em");
        image.getNode().setStyle("max-height", "3em");
        image.getNode().setStyle("cursor", "pointer");
        image.registerDomEvents("click");
        image.on("click", () => this.modal.active = true);
    }, [], HTML.IMG);
    this.source = new Property((source) => {
        this.image.getNode().setAttribute("src", source);
        this.modal.imageSource = source;
    });
});

const imageThumbnailCellFactory = source => {
    if (!source) {
        return "";
    }
    return new ImageThumbnail(function () {
        this.source = source;
    });
}

export {ImageThumbnail as default, imageThumbnailCellFactory}
