import {removeByValue, sortByMappedValue} from "juis-commons/JuisUtils.js";

export default function (model) {
    const entitiesPerId = {};
    const entitiesPerCode = {};
    let recentHits = [];
    let hasAll = false;

    const hit = (entity) => {
        if (!entity) {
            return;
        }
        removeByValue(recentHits, entity);
        recentHits.push(entity);
        recentHits = recentHits.slice(0, 100);
    };

    const addEntity = (entity) => {
        if (!entity.id) {
            throw new Error("A cached instance must have an Id");
        }
        if (entitiesPerId[entity.id] && entitiesPerId[entity.id] !== entity) {
            throw new Error(`Another instance of ${model.getName()} #${entity.id} already exists in the cache`);
        }
        entitiesPerId[entity.id] = entity;
        if (entity.isLazy()) {
            entity.listenOnce(entity.EVENTS.REFRESH, () => addEntityByCode(entity));
        } else {
            addEntityByCode(entity);
        }
        hit(entity);
    };

    const getCode = (entity) => {
        return entity.app ? entity.app.code + "/" + entity.code : entity.code;
    };

    const addEntityByCode = (entity) => {
        if (!entity.hasCode()) {
            return;
        }
        if (!entity.getModel().app) {
            entitiesPerCode[getCode(entity)] = entity;
            return;
        }
        entity.listenOnce(entity.EVENTS.REFRESH, () => {
            if (!entity.app) {
                entitiesPerCode[getCode(entity)] = entity;
            } else {
                if (entity.app.isLazy()) {
                    entity.app.listenOnce(entity.EVENTS.REFRESH, () => {
                        entitiesPerCode[getCode(entity)] = entity;
                    });
                } else {
                    entitiesPerCode[getCode(entity)] = entity;
                }
            }
        });
    };

    const removeEntity = (entity) => {
        if (!entity.id) {
            throw new Error("A cached instance must have an Id");
        }
        if (entitiesPerId[entity.id] && entitiesPerId[entity.id] !== entity) {
            throw new Error(`Another instance of ${model.getName()} #${entity.id} already exists in the cache`);
        }
        delete entitiesPerId[entity.id];
        if (entity.code) {
            delete entitiesPerCode[entity.code];
        }
        removeByValue(recentHits, entity);
    };

    this.hasAll = () => hasAll;
    this.getById = (id) => {
        hit(entitiesPerId[id]);
        return entitiesPerId[id];
    };
    this.getByCode = (code, appCode) => {
        let entity;
        if (appCode) {
            entity = entitiesPerCode[appCode + "/" + code];
        } else {
            entity = entitiesPerCode[code];
        }
        hit(entity);
        return entity;
    };
    this.query = (filter, sortField) => {
        let result = this.getAll().filter(filter.test);
        if (sortField) {
            result.sort(sortByMappedValue((value) => sortField.getValueFor(value)));
        }
        return result;
    };
    this.queryOne = (filter) => this.getAll().find(filter.test);
    this.find = (callback) => this.getAll().find(callback);
    this.findAll = (callback) => callback ? this.getAll().filter(callback) : this.getAll();

    /**
     * Add one or more entities to the cache
     * @param addition
     */
    this.add = (addition) => {
        if (Array.isArray(addition)) {
            addition.forEach(addEntity);
        } else {
            addEntity(addition);
        }
        return addition;
    };
    this.remove = (removal) => {
        if (Array.isArray(removal)) {
            removal.forEach(removeEntity);
        } else {
            removeEntity(removal);
        }
        return removal;
    };

    /**
     * Add all existing entities to the cache
     * @param entities
     */
    this.addAll = (entities) => {
        entities.forEach(addEntity);
        hasAll = true;
    };

    this.getAll = () => Object.values(entitiesPerId);
    this.getRecent = () => [...recentHits];
}
