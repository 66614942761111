import Model from "../../utils/RestModel.js";
import ProductItemBase from "./ProductItemBase.js";
import Field from "../../utils/Field.js";
import TicketTemplate from "./TicketTemplate.js";

let TicketTemplateProduct = new Model(function () {
    ProductItemBase.call(this);
    this.billable = new Field().asBoolean().withLabel("Billable");
    this.ticketTemplate = new Field(TicketTemplate).withLabel("Ticket Template");
    this.vatPercentage.withDefaultValueCallback(() => undefined);
    this.discountPercentage.withDefaultValueCallback(() => undefined);
    this.price.withDefaultValueCallback(() => undefined);

    this.getEmpty = (app) => {
        let empty = new TicketTemplateProduct({
            quantity: 1,
            vatPercentage: 0,
            price: 0,
            priceIncludesVat: true,
            app,
            product: null
        });
        empty.makeDirty();
        return empty;
    }
}, "TicketTemplateProduct", "ticket-template-products");

export default TicketTemplateProduct;
