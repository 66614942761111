import Model from "../../utils/RestModel.js";
import ProductItemBase from "./ProductItemBase.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import ticketProductLexicons from "./lexicons/ticket-product/ticketProductLexicons.js";
import TicketProduct from "./TicketProduct.js";
import setDefaults from "./utils/setDefaults.js";
import Product from "./Product.js";
import Filter from "../../utils/Filter.js";
import Method from "../../utils/Method.js";

let TicketMainProduct = new Model(function () {
    ProductItemBase.call(this);
    this.on("change", (event) => {
        if (event.field === TicketMainProduct.product && event.value) {
            const product = event.value;
            const ticketMainProduct = event.entity;
            ticketMainProduct.copyValuesFromProduct(product);
        }
    });
    this.getProductFilter.override(function () {
        let groups = this.ticket.type.mainProductGroups;
        if (groups.length > 0) {
            return Filter.in(Product.groups, groups);
        }
        return Filter.true;
    });
    TicketProduct.createFields(this);

    new Linguist(ticketProductLexicons).withAudience(this);

    setDefaults(this, {
        cellFactory: TicketProduct.ticketProductCellFactory,
        itemFactory: TicketProduct.ticketProductCellFactory,
        optionsLoader: TicketProduct.getDefaultOptionsLoader()
    });

}, "TicketMainProduct");

export default TicketMainProduct;
