const SUM = "sum";
const MIN = "min";
const MAX = "max";
const COUNT = "count";
const AVG = "avg";
const DISTINCT = "distinct";

function Aggregation(aggregation, field, label) {
    let cellFactory = field.getCellFactory();
    this.field = field;
    this.aggregation = aggregation;
    this.label = label || field.getLabel();
    this.toJSON = function () {
        return {name: this.name, aggregation: this.aggregation, field: this.field.getName()};
    };
    this.getCellFactory = () => cellFactory;
    this.withCellFactory = function (factory) {
        cellFactory = factory;
        return this;
    };
}

Aggregation.sum = (field, label) => new Aggregation(SUM, field, label);
Aggregation.min = (field, label) => new Aggregation(MIN, field, label);
Aggregation.max = (field, label) => new Aggregation(MAX, field, label);
Aggregation.count = (field, label) => new Aggregation(COUNT, field, label);
Aggregation.avg = (field, label) => new Aggregation(AVG, field, label);
Aggregation.distinct = (field, label) => new Aggregation(DISTINCT, field, label);
Aggregation.isDistinctYearsAggregation = aggregation =>
    aggregation.field.endsWith("year") &&
    aggregation.aggregation === DISTINCT &&
    aggregation.result.length > 1;

export {Aggregation as default, SUM, MIN, MAX, COUNT, AVG, DISTINCT};
