import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import Contact from "./Contact.js";
import HTML from "../../utils/HTML.js";
import EditorCard from "../../components/table-view/EditorCard.js";
import Button from "../../bulma_components/Button.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import Container from "juis-components/Container.js";
import {notificationEvents} from "../../components/notification/Notification.js";
import TextComponent from "../../components/TextComponent.js";
import ContactGroup from "./ContactGroup.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import userRoleLexicons from "./lexicons/user-role/userRoleLexicons.js";
import {createIcon} from "../../bulma_components/Icon.js";
import {getDropDownFactory} from "../../components/fieldEditors/editorFactories.js";
import Method from "../../utils/Method";
import {createLink} from "../../bulma_components/Link";
import Menu from "./Menu";
import setDefaults from "./utils/setDefaults";

const linguist = new Linguist(userRoleLexicons);
export default new Model(function () {
    this.app = new Field(App);
    this.appCode = new Field().asString();
    this.id = new Field().asReadOnly().asNumber();
    this.deleted = new Field().asBoolean();
    this.publicName = new Field().asString().asReadOnly().withLabel("User");
    this.multiAvatarCode = new Field().asString().asReadOnly().withLabel("MultiAvatar");
    this.ownContactGroup = new Field(ContactGroup);
    this.menu = new Field(Menu).withCellFactory(menu => menu ? menu.getDefaultCell() : "[Use app default]");
    this.contact = new Field(Contact)
        .withLabel("Contact")
        .withCreatorFactory((role) => new EditorCard(function () {
            this.setModel(Contact);
            this.title = "Add contact";
            this.defaultProperties = {app: role.app};
            this.addField(Contact.name);
            this.addField(Contact.contactGroup);
            this.refresh();
        }));
    this.invitationCode = new Field().asString().asReadOnly()
        .withCellFactory((value, role) => {
            if (!value) {
                return value;
            }
            return new Container(function () {
                this.code = new TextComponent(value, HTML.CODE);
                this.copyLinkButton = new Button(function () {
                    this.icon = "link";
                    this.on("click", () => {
                        navigator.clipboard.writeText(`${window.location.origin}/${role.app.code}?invitation-code=${value}`)
                            .then(result => this.trigger(notificationEvents.NOTIFY, {
                                text: "Invite link copied to clip board",
                                cssClass: BULMA_CSS_CLASSES.IS_INFO,
                            }))
                            .catch(error => this.trigger(notificationEvents.NOTIFY, {
                                text: "Could not copy link",
                                cssClass: BULMA_CSS_CLASSES.IS_DANGER
                            }));
                    });
                }, [BULMA_CSS_CLASSES.IS_SMALL]);
            });
        })
        .withLabel("Invitation Code");
    this.ROLE_TYPES = {};
    this.ROLE_TYPES.ADMIN = "ADMIN";
    this.ROLE_TYPES.COORDINATOR = "COORDINATOR";
    this.ROLE_TYPES.CLIENT = "CLIENT";
    this.ROLE_TYPES.RESELLER = "RESELLER";
    this.ROLE_TYPES.EMPLOYEE = "EMPLOYEE";
    const typeCssClasses = {};
    typeCssClasses[this.ROLE_TYPES.ADMIN] = BULMA.IS_DANGER;
    typeCssClasses[this.ROLE_TYPES.COORDINATOR] = BULMA.IS_WARNING;
    typeCssClasses[this.ROLE_TYPES.CLIENT] = BULMA.IS_INFO;
    typeCssClasses[this.ROLE_TYPES.RESELLER] = BULMA.IS_PRIMARY;
    typeCssClasses[this.ROLE_TYPES.EMPLOYEE] = BULMA.IS_SUCCESS;
    const typeIcons = {};
    typeIcons[this.ROLE_TYPES.ADMIN] = "user-ninja";
    typeIcons[this.ROLE_TYPES.COORDINATOR] = "user-ninja";
    typeIcons[this.ROLE_TYPES.CLIENT] = "user";
    typeIcons[this.ROLE_TYPES.RESELLER] = "user-tie";
    typeIcons[this.ROLE_TYPES.EMPLOYEE] = "user";
    this.getAppRoleBadge = new Method(function () {
        let role = this;
        return new Container(function () {
            this.tag = new Container(function () {
                this.icon = createIcon(typeIcons[role.role]);
                this.getNode().setAttribute("title", linguist.t(["roleTypes", role.role]));
            }, [BULMA_CSS_CLASSES.TAG, typeCssClasses[role.role], "mr-2"]);
            this.link = createLink("/" + role.app.code, role.app.name);
        });

    });
    this.getRoleTypeTag = (roleType) => {
        return new Container(function () {
            this.icon = createIcon(typeIcons[roleType]);
            this.text = new TextComponent(linguist.t(["roleTypes", roleType]));
        }, [BULMA_CSS_CLASSES.TAG, typeCssClasses[roleType]]);
    };
    this.role = new Field()
        .asRequired()
        .withLabel("Role")
        .asEnum(Object.values(this.ROLE_TYPES))
        .withCellFactory(this.getRoleTypeTag)
        .withEditorFactory(getDropDownFactory({
            searchable: false,
            itemFactory: this.getRoleTypeTag
        }))
        .withDefaultValueCallback(() => "COORDINATOR");

    linguist.withAudience(this);

    setDefaults(this, {
        defaultField: this.contact,
        itemFactory: (userRole) => {
            const tag = this.getRoleTypeTag(userRole.role);
            tag.text = userRole.contact ? Contact.name.getCell(userRole.contact) : this.publicName.getCell(userRole);
            return tag;
        },
        cellFactory: (userRole) => {
            const tag = this.getRoleTypeTag(userRole.role);
            tag.text = userRole.contact ? Contact.name.getCell(userRole.contact) : this.publicName.getCell(userRole);
            return tag;
        },
    });
}, "UserRole", "user-roles");
