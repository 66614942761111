import {createTextNode, extendComponent} from "juis-components/ComponentUtils.js";
import HTML from "../../../utils/HTML.js";
import BULMA from "../../../bulma_components/bulmaCssClasses.js";
import Container from "juis-components/Container.js";
import Linguist from "../../../../lib/JuiS/Linguist.js";
import frontPageLexicons from "./lexicons/frontPageLexicons";
import TextComponent from "../../../components/TextComponent";
import Statistics from "../../models/Statistics";
import StatisticsValues from "../../models/StatisticsValues";
import Divider from "../../../bulma_components/Divider.js";

const linguist = new Linguist(frontPageLexicons);
const getCard = (title) => new Container(function () {
    this.titleNumber = new Container(function () {
        this.value = createTextNode("-");
    }, [BULMA.TITLE, BULMA.IS_2, BULMA.TYPOGRAPHY.HAS_TEXT_WEIGHT_BOLD]);
    this.title = new TextComponent(title, HTML.DIV, [BULMA.SUBTITLE, BULMA.IS_3]);
    this.hr = new Divider();

    this.refresh = (stats) => {
        this.titleNumber.value = StatisticsValues.foreverCount.getCell(stats);
        this.rowsContainer.yearCount.refresh(stats);
        this.rowsContainer.monthCount.refresh(stats);
        this.rowsContainer.weekCount.refresh(stats);
        this.rowsContainer.dayCount.refresh(stats);
        this.getNode().removeCssClass(BULMA.IS_INVISIBLE);
    };

    const getStatsRow = (field, backupField) => new Container(function () {
        this.title = new TextComponent(field.getLabel(), HTML.DIV);
        this.value = new TextComponent("-");
        this.refresh = stats => {
            let usedField = field.getValueFor(stats) === null && backupField ? backupField : field;
            this.value = usedField.getCell(stats);
            this.title = new TextComponent(usedField.getLabel(), HTML.DIV);
        };
    }, [BULMA.IS_FLEX, "is-justify-content-space-between", "has-text-weight-semibold-hover"]);
    this.rowsContainer = new Container(function () {
        this.yearCount = getStatsRow(StatisticsValues.yearCount, StatisticsValues.lastYearCount);
        this.monthCount = getStatsRow(StatisticsValues.monthCount, StatisticsValues.lastMonthCount);
        this.weekCount = getStatsRow(StatisticsValues.weekCount, StatisticsValues.lastWeekCount);
        this.dayCount = getStatsRow(StatisticsValues.dayCount);
        this.getNode().setStyle("padding-left", "12%");
        this.getNode().setStyle("padding-right", "12%");
    });
}, [BULMA.HAS_TEXT_CENTERED, BULMA.COLUMN, "my-6", "mx-5", "p-5", BULMA.IS_INVISIBLE]);
export default extendComponent(Container, function () {
    this.bills = getCard(linguist.t("bills"));
    this.tickets = getCard(linguist.t("tickets"));
    this.transactions = getCard(linguist.t("transactions"));
    this.refresh = (statistics) => {
        this.bills.refresh(statistics.bills);
        this.tickets.refresh(statistics.tickets);
        this.transactions.refresh(statistics.transactions);
    };
    Statistics.fetch().then(this.refresh);
}, [BULMA.COLUMNS, BULMA.IS_MARGINLESS, "statistics-component"]);
