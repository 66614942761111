import Button from "../../bulma_components/Button.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import Input from "../../bulma_components/Input.js";
import Component from "juis-components/Component.js";
import {createControls} from "../../bulma_components/FormMixin.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import cellFactoryLexicons from "./lexicons/cellFactoryLexicons.js";
import BulmaUtils from "../../bulma_components/BulmaUtils.js";

const linguist = new Linguist(cellFactoryLexicons).withBaseKey("inlineQuantityEditorFactory");

export default (withTransactionButton) => {
    /**
     * This method can be used directly as a cell factory for a field in a model
     * @param value Not used. This is probably used for a virtual field so value will be undefined
     * @param entity The entity that should be edited
     * @returns {*}
     */
    return (value, entity) => {
        const decrease = new Button(function () {
            this.icon = "minus";
            this.getNode().setStyle("z-index", "1");
            this.on("click", () => deltaInput.setValue(getDeltaValue(-1)));
        }, [BULMA.TYPOGRAPHY.HAS_TEXT_DANGER, BULMA.IS_SMALL]);

        const deltaInput = new Input(function () {
            this.size = 1;
            this.type = Input.Types.NUMBER;
            this.max = 999;
            this.min = -999;
            this.step = 1;
            this.setValue("0");
        }, [BULMA.IS_SMALL]);

        const getDeltaValue = (delta) => {
            delta = delta || 0;
            return deltaInput.getValue() + delta;
        };

        const add = new Button(function () {
            this.icon = "plus";
            this.getNode().setStyle("z-index", "1");
            this.on("click", () => deltaInput.setValue(getDeltaValue(1)));
        }, [BULMA.TYPOGRAPHY.HAS_TEXT_SUCCESS, BULMA.IS_SMALL]);
        const submit = new Button(function () {
            this.tooltip = BulmaUtils.getTooltipProperty(this);
            this.icon = "check";
            this.disabled = true;
            this.on("click", () => {
                this.isLoading = true;
                this.triggerOnce("quantityChange", {
                    entity,
                    quantity: getDeltaValue()
                })
                    .then(() => {
                        deltaInput.setValue(0);
                        this.isLoading = false;
                        this.getNode().removeCssClass(BULMA.IS_DANGER);
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.getNode().addCssClass(BULMA.IS_DANGER);
                        console.error(error);
                    });
            });
        }, [BULMA.IS_PRIMARY, BULMA.IS_SMALL, BULMA.HAS_TOOLTIP_LEFT]);
        const spacer = new Component(function () {
        });
        deltaInput.on("change", () => {
            const quantity = getDeltaValue();
            submit.disabled = false;
            if (quantity > 0) {
                submit.tooltip = linguist.t("add", {
                    quantity,
                    product: entity.product.getProductName(),
                    stock: entity.stock.getFullName(),
                    unit: entity.product.unit
                });
            } else if (getDeltaValue() < 0) {
                submit.tooltip = linguist.t("remove", {
                    quantity: quantity * -1,
                    product: entity.product.getProductName(),
                    stock: entity.stock.getFullName(),
                    unit: entity.product.unit
                });
            } else {
                submit.disabled = true;
                submit.tooltip = "";
            }
        });
        let controls;
        if (withTransactionButton) {
            const transactionButton = new Button(function () {
                this.tooltip = BulmaUtils.getTooltipProperty(this);
                this.tooltip = linguist.t("openTransaction");
                this.icon = "exchange-alt";
                this.on("click", () => {
                    this.triggerOnce("openTransaction", {
                        entity,
                        quantity: getDeltaValue()
                    });
                });
            }, [BULMA.IS_INFO, BULMA.IS_SMALL, BULMA.HAS_TOOLTIP_LEFT]);
            controls = createControls(decrease, deltaInput, add, spacer, submit, transactionButton);
        } else {
            controls = createControls(decrease, deltaInput, add, spacer, submit);
        }
        controls.getNode().addCssClass("inline-stock-transaction");
        controls.getNode().addCssClass(BULMA.HAS_TEXT_RIGHT);
        return controls;
    };
}
