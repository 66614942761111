import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import Linguist from "../../../lib/JuiS/Linguist";
import statisticsValuesLexicons from "./lexicons/statistics-values-lexicons/statisticsValuesLexicons";

const compactFormatter = Intl.NumberFormat('en', {notation: 'compact'});
export default new Model(function () {
    this.dayCount = new Field()
        .asNumber()
        .asReadOnly()
        .withCellFactory(compactFormatter.format)
        .withLabel("Day Count");
    this.weekCount = new Field()
        .asNumber()
        .asReadOnly()
        .withCellFactory(compactFormatter.format)
        .withLabel("Week Count");
    this.lastWeekCount = new Field()
        .asNumber()
        .asReadOnly()
        .withCellFactory(compactFormatter.format)
        .withLabel("Last Week Count");
    this.monthCount = new Field()
        .asNumber()
        .asReadOnly()
        .withCellFactory(compactFormatter.format)
        .withLabel("Month Count");
    this.lastMonthCount = new Field()
        .asNumber()
        .asReadOnly()
        .withCellFactory(compactFormatter.format)
        .withLabel("Last Month Count");
    this.yearCount = new Field()
        .asNumber()
        .asReadOnly()
        .withCellFactory(compactFormatter.format)
        .withLabel("Year Count");
    this.lastYearCount = new Field()
        .asNumber()
        .asReadOnly()
        .withCellFactory(compactFormatter.format)
        .withLabel("Last Year Count");
    this.foreverCount = new Field()
        .asNumber()
        .asReadOnly()
        .withCellFactory(compactFormatter.format)
        .withLabel("Total Count");
    new Linguist(statisticsValuesLexicons).withAudience(this);
    this.makeTranslatable();
}, "StatisticsValues");
