import {extendComponent} from "juis-components/ComponentUtils.js";
import {BulmaContainer} from "./BulmaComponent.js";

function collapse(element, callback) {
    // get the height of the element's inner content, regardless of its actual size
    let sectionHeight = element.scrollHeight;

    if (sectionHeight) {
        // temporarily disable all css transitions
        let elementTransition = element.style.transition;
        element.style.transition = '';
        element.style.overflow = 'hidden';
        // on the next frame (as soon as the previous style change has taken effect),
        // explicitly set the element's height to its current pixel height, so we
        // aren't transitioning out of 'auto'
        requestAnimationFrame(function () {
            element.style.height = sectionHeight + 'px';
            element.style.transition = elementTransition;

            // on the next frame (as soon as the previous style change has taken effect),
            // have the element transition to height: 0
            requestAnimationFrame(function () {
                element.style.height = 0;
                element.style.marginTop = 0;
                element.style.marginBottom = 0;
                element.style.paddingTop = 0;
                element.style.paddingBottom = 0;
                element.style.opacity = 0;
                element.addEventListener('transitionend', function (e) {
                    element.style.display = "none";
                    callback();
                }, {once: true});
            });
        });
    } else {
        element.style.overflow = "hidden";
        element.style.height = 0;
        element.style.marginTop = 0;
        element.style.marginBottom = 0;
        element.style.paddingTop = 0;
        element.style.paddingBottom = 0;
        element.style.display = "none";
        callback();
    }

}


function collapseImmediate(element) {
    element.style.display = "none";
    element.style.height = 0;
    element.style.marginTop = 0;
    element.style.marginBottom = 0;
    element.style.paddingTop = 0;
    element.style.paddingBottom = 0;
    element.style.overflow = "hidden";
}


function expand(element, callback, display = "block") {
    // get the height of the element's inner content, regardless of its actual size
    element.style.display = display;
    let sectionHeight = element.scrollHeight;
    if (sectionHeight) {
        requestAnimationFrame(function () {
            // have the element transition to the height of its inner content
            element.style.height = sectionHeight + 'px';
            element.style.marginTop = null;
            element.style.marginBottom = null;
            element.style.paddingTop = null;
            element.style.paddingBottom = null;
            element.style.opacity = 1;
            element.scrollIntoView({behavior: "smooth", block: "center"});
            // when the next css transition finishes (which should be the one we just triggered)
            element.addEventListener('transitionend', function (e) {
                // remove "height" from the element's inline styles, so it can return to its initial value
                element.style.height = null;
                element.style.overflow = null;
                callback();
            }, {once: true});
        });
    } else {
        element.style.height = null;
        element.style.overflow = null;
        element.style.marginTop = null;
        element.style.marginBottom = null;
        element.style.paddingTop = null;
        element.style.paddingBottom = null;
        element.scrollIntoView({behavior: "smooth", block: "center"});
        callback();
    }
}

export default extendComponent(BulmaContainer, function () {
    let collapsed = false;
    let isAnimating = false;
    let originalDisplay = this.getNode().getActualElement().style.display;
    this.expand = function () {
        if (!collapsed || isAnimating) {
            this.getNode().getActualElement().scrollIntoView({behavior: "smooth", block: "center"});
            return;
        }
        collapsed = false;
        isAnimating = true;
        expand(this.getNode().getActualElement(), () => {
            isAnimating = false;
            this.trigger("expand");
        }, originalDisplay);
    };
    this.collapse = function (immediate = false) {
        if (collapsed || isAnimating) {
            return;
        }
        collapsed = true;
        if (immediate) {
            collapseImmediate(this.getNode().getActualElement());
        } else {
            isAnimating = true;
            collapse(this.getNode().getActualElement(), () => {
                isAnimating = false;
                this.trigger("collapse");
            });
        }
    };
    this.toggleCollapsed = function () {
        collapsed ? this.expand() : this.collapse();
        return collapsed;
    };
    this.isCollapsed = () => collapsed;

    this.on("close", (data, event) => {
        event.stopPropagation();
        this.collapse();
    });

}, ["expandable-container"]);
