import Container from "juis-components/Container.js";
import TextComponent from "../TextComponent.js";
import HTML from "../../utils/HTML.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import Property from "juis-components/Property.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import Icon from "../../bulma_components/Icon.js";

export default extendComponent(Container, function () {
    let iconComponent;
    const getIconComponent = () => {
        if (iconComponent) {
            return iconComponent;
        }
        iconComponent = this.insertBefore(new Icon());
        iconComponent.getNode().setStyle("margin-right", "0.5em");
        iconComponent.is3x = true;
        iconComponent.getNode().togglePlaceholder(true);
        return iconComponent;
    };

    let title = this.appendChild(new TextComponent("", HTML.SPAN, [BULMA.TITLE, "is-size-1-desktop", "is-size-2-tablet"]));
    title.getNode().togglePlaceholder(true);

    this.text = new Property((value) => {
        title.text = value;
        title.getNode().togglePlaceholder(false);
    });

    this.iconComponent = new Property((component) => {
        iconComponent = this.insertBefore(component);
    });

    this.icon = new Property(icon => {
        if (icon) {
            getIconComponent().icon = icon;
            getIconComponent().getNode().togglePlaceholder(false);
        } else {
            getIconComponent().getNode().togglePlaceholder(true);
        }
    });
    this.color = new Property(color => {
        getIconComponent().color = color;
        getIconComponent().getNode().togglePlaceholder(false);
    });
}, ["title"]);
