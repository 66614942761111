import appView from "./appView.js";
import NotFoundPage from "./NotFoundPage.js";
import ErrorPage from "./ErrorPage.js";
import LoadingPage from "./LoadingPage.js";
import Router from "juis-router/Router.js";
import Component from "juis-components/Component.js";
import {NAVIGATE} from "juis-router/Events.js";
import UsersIndex from "./UsersIndex.js";

const dynamicImport = (path) => {
    return import(/* webpackChunkName: "[request]" */ `./${path}/index.js`);
};

const getLazyHtmlComponent = (path) => {
    return new Component(function () {
        this.when(NAVIGATE).then(() => fetch("../../src/hewecon/views/" + path + "/index.html")
            .then((response) => response.text())
            .then(body => this.innerHTML = body)
        );
        this.innerHTML = "Page has not loaded";
    });
};
let router;
export default () => {
    if (router) {
        return router;
    }
    router = new Router(dynamicImport, new LoadingPage(), new NotFoundPage(), new ErrorPage());
    router.setViewsBasePath("/src/hewecon/views/");
    router.addComponent("/users/", UsersIndex, {alwaysReload: false});
    router.addComponent("/apps", "apps");
    router.addComponent("/login", "login-page");
    router.addComponent("/create-account", "create-account");
    router.addComponent("/recover-account", "recover-account");
    router.addComponent("/forgot-password", "forgot-password");
    router.addComponent("/admin/users", "admin/users");
    // router.addComponent("/pricing", getLazyHtmlComponent("pricing"));
    // router.addComponent("/about", getLazyHtmlComponent("about"));
    router.addComponent("/pdf-generation", getLazyHtmlComponent("pdf-generation"));
    router.addComponent(/\/redirect\/dropbox/, "integrations/dropbox/redirect");

    router.addComponent(/\/([a-z\d\-]+)/, appView, {
        urlParameterNames: ["appCode"],
        alwaysReload: true
    });

    router.addComponent("/", "front-page");
    router.setFallbackRoute("/");
    return router;
}
