import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import TicketType from "./TicketType.js";
import ProductGroup from "./ProductGroup.js";
import Stock from "./Stock.js";
import TicketStatus from "./TicketStatus.js";
import {HorizontalFormField} from "../../components/fieldEditors/editorFactories.js";
import HTML from "../../utils/HTML.js";
import Input from "../../bulma_components/Input.js";
import TextComponent from "../../components/TextComponent.js";
import {createIcon} from "../../bulma_components/Icon.js";
import Button from "../../bulma_components/Button.js";
import {WrapperContainer} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";

const createMapperRow = (ticketField, productField) => {
    let row = new WrapperContainer(HTML.TD, [], HTML.TR, []);
    row.ticketInput = new Input();
    row.ticketInput.setValue(ticketField);
    row.arrow = createIcon("arrow-right");
    row.productInput = new Input();
    row.productInput.setValue(productField);
    row.getValue = () => ({
        ticketField: row.ticketInput.getValue(),
        productField: row.productInput.getValue()
    });
    return row;
};

const createMapperEditor = (field, mapping) => {
    let input = new Container(function () {
        this.headers = new Container(function () {
            this.row = new Container(function () {
                this.ticketHeader = new TextComponent("Ticket", HTML.TH);
                this.empty = new TextComponent("", HTML.TH);
                this.productHeader = new TextComponent("Product", HTML.TH);
            }, [], HTML.TR);
        }, [], HTML.THEAD);
        this.body = new Container((body) => {
            body.on("change", () => {
                console.log(this.getValue());
                mapping.ticketFields = this.getValue().map(valuePair => valuePair.ticketField);
                mapping.productFields = this.getValue().map(valuePair => valuePair.productField);
            });
        }, [], HTML.TBODY);
        let rows = [];
        this.getValue = () => rows.map(row => row.getValue()).filter(rowValue => !!rowValue.ticketField);
        const addRow = (ticketField, productField) => {
            let row = createMapperRow(ticketField, productField);
            rows.push(row);
            this.body.appendChild(row);
        };
        this.footers = new Container(function () {
            this.row = new Container(function () {
                this.buttonContainer = new Container(function () {
                    this.getNode().setAttribute("colspan", 3);
                    this.getNode().setStyle("text-align", "right");
                    this.button = new Button(function () {
                        this.text = "Add mapping";
                        this.icon = "plus";
                        this.on("click", () => addRow());
                    });
                }, [], HTML.TD);
            }, [], HTML.TR);
        }, [], HTML.TFOOT);
        mapping.ticketFields.forEach((ticketField, index) => addRow(ticketField, mapping.productFields[index]));
        addRow();

        this.getNode().setStyle("width", "100%");
    }, [], HTML.TABLE);
    let fieldComponent = new HorizontalFormField();
    fieldComponent.setLabel(field.getLabel() || field.getName());
    let innerFieldComponent = fieldComponent.addField();
    innerFieldComponent.addControl(input);
    return fieldComponent;
}


export default new Model(function () {
    this.app = new Field(App);

    this.ticketType = new Field(TicketType);
    this.productGroup = new Field(ProductGroup);
    this.stock = new Field(Stock);
    this.activationStatuses = new Field([TicketStatus]);
    this.ticketFields = new Field([Field.STRING]);
    this.productFields = new Field([Field.STRING]);
    this.map = new Field().asVirtual()
        .withLabel("Mapping")
        .withEditorFactory(createMapperEditor);

}, "Ticket Product Mapper", "ticket-product-mappers");
