import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Component from "juis-components/Component.js";
import HTML from "../../utils/HTML";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses";
import Linguist from "../../../lib/JuiS/Linguist";
import "./progress.scss"


let Progress = extendComponent(Container, function () {
    this.progress = new Component(function () {
        this.getNode().setAttribute("max", "1");
    }, [BULMA_CSS_CLASSES.PROGRESS], HTML.PROGRESS);
    this.progressValue = new Component(function () {

    }, ["progress-value"], HTML.P);
    this.setValue = (value) => {
        if (typeof value !== "number") {
            value = 0;
        }
        if (value > 0.5) {
            this.getNode().addCssClass("is-more-than-half");
        } else {
            this.getNode().removeCssClass("is-more-than-half");
        }
        this.progress.getNode().setAttribute("value", value);
        this.progress.innerHTML = new Intl.NumberFormat(Linguist.getLanguage(), {style: "percent"}).format(value);
        this.progressValue.innerHTML = new Intl.NumberFormat(Linguist.getLanguage(), {style: "percent"}).format(value);
    }

}, ["progress-wrapper"]);

export {Progress as default};
