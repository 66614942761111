import Container from "juis-components/Container.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import HTML from "../../utils/HTML.js";
import Component from "juis-components/Component.js";
import BULMA from "../../bulma_components/bulmaCssClasses.js";
import ButtonToggler from "../../bulma_components/ButtonToggler";

export default extendComponent(Container, function () {

    this.dropdown = new Container(function () {
        this.registerDomEvents("change");
        this.getNode().addWrapper(HTML.DIV, [BULMA.SELECT, BULMA.IS_FULLWIDTH]);
        this.getNode().addWrapper(HTML.DIV, [BULMA.FIELD.CONTROL, BULMA.FIELD.IS_EXPANDED]);
    }, [], HTML.SELECT);
    this.button = new ButtonToggler(function () {
        this.addValueFromSettings({key: "asc", icon: "arrow-down-wide-short"});
        this.addValueFromSettings({key: "desc", icon: "arrow-up-short-wide"});
        this.getNode().addWrapper(HTML.DIV, [BULMA.FIELD.CONTROL]);
    });

    this.addValue = function (key, value) {
        this.dropdown.appendChild(new Component(function () {
            this.getNode().setInnerHtml(value);
            this.getNode().setAttribute("value", key);
        }, ["mobile-order-by"], HTML.OPTION));
    };
    this.getValue = () => this.dropdown.getNode().getElementProperty("value");
    this.setValue = (value) => this.dropdown.getNode().setElementProperty("value", value);

}, [BULMA.IS_HIDDEN_TABLET, BULMA.FIELD.FIELD, BULMA.HAS_ADDONS, BULMA.IS_FLEX, "sort-dropdown"], HTML.TR);
