import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Button from "../../bulma_components/Button";
import BULMA from "../../bulma_components/bulmaCssClasses";
import "./buttonList.scss";

const ChangeEvent = function (currentValue, previousValue) {
    this.currentValue = currentValue;
    this.previousValue = previousValue;
};
export default extendComponent(Container, function () {
    let currentValue;
    const buttons = new Map();

    this.buttonCreator = (value) => new Button(function () {
        this.text = value;
    });
    this.clear = (triggerChange = true) => {
        if (currentValue !== undefined) {
            let currentButton = buttons.get(currentValue);
            currentButton.getNode().removeCssClass(BULMA.IS_SELECTED);
            currentButton.getNode().removeCssClass(BULMA.IS_INFO);
            if (triggerChange) {
                this.trigger("change", new ChangeEvent(undefined, currentValue));
            }
            currentValue = undefined;
        }
    };
    this.setValue = function (value, triggerChange = true) {
        const event = new ChangeEvent(value, currentValue);
        this.clear(false);
        if (value !== undefined) {
            currentValue = value;
            let currentButton = buttons.get(currentValue);
            currentButton.getNode().addCssClass(BULMA.IS_SELECTED);
            currentButton.getNode().addCssClass(BULMA.IS_INFO);
        }
        if (triggerChange) {
            this.trigger("change", event);
        }
    };
    this.getValue = () => currentValue;
    this.addButton = (value, isParent = false) => {
        let button = this.buttonCreator(value);
        buttons.set(value, button);
        button.on("click", () => {
            if (value === currentValue) {
                this.setValue(undefined);
            } else {
                this.setValue(value);
            }
        });
        if (isParent === true) {
            button.getNode().addCssClass("parent-button");
        }
        this.appendChild(button);
        return button;
    };
    this.getButtonMap = () => new Map(buttons);
}, [BULMA.BUTTONS, BULMA.HAS_ADDONS, "button-list"]);