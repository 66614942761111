import Container from "juis-components/Container.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import Property from "juis-components/Property.js";

export default extendComponent(Container, function () {
    let entity;
    let fieldsAndSettings = [];
    let fieldComponents = {};

    this.reset = () => {
        Object.values(fieldComponents)
            .filter(component => component.destroy)
            .forEach(component => component.destroy());
        fieldComponents = {};
        this.destroyAllChildren();
    };

    this.resetFieldComponents = () => {
        this.reset();
        fieldsAndSettings.forEach(fieldAndSettings =>
            this.createComponent(fieldAndSettings.field, {...fieldAndSettings.settings, readOnly: this.readOnly})
        );
    };

    this.readOnly = new Property(readOnly => {
        this.resetFieldComponents();
    }, true);

    const addField = (field, settings = {}, storage) => {
        if (typeof settings !== "object") {
            settings = {};
        }
        if (settings.readOnly !== true) {
            settings.readOnly = this.readOnly;
        }
        storage.push({field, settings});
        if (entity) {
            this.createComponent(field, settings);
        }
    };

    this.addField = (field, settings = {}) => {
        addField(field, settings, fieldsAndSettings);
    };

    this.getFields = () => {
        return fieldsAndSettings.map(fieldAndSetting => fieldAndSetting.field);
    };
    this.setFieldReadOnly = (field, value = true) => {
        const fieldSettings = fieldsAndSettings.find(fieldAndSettings => fieldAndSettings.field === field)?.settings;
        fieldSettings.readOnly = value;
    };

    const removeField = (field, storage) => {
        const index = storage.findIndex(fieldAndSettings => fieldAndSettings.field === field);
        if (index > -1) {
            storage.splice(index, 1);
            this.removeChild(fieldComponents[field.getName()]);
        } else {
            throw new Error("Field not found + " + field.getName());
        }
    };

    this.removeField = (field) => {
        removeField(field, fieldsAndSettings);
    };

    this.setEntity = newEntity => {
        entity = newEntity;
        this.resetFieldComponents();
    };

    this.resetFields = () => {
        fieldsAndSettings = [];
    };

    this.getEntity = () => {
        return entity;
    };

    this.createComponent = (field, settings) => {
        if (field.isReadOnly()) {
            // Never override a readonly flag on the field
            delete settings.readOnly;
        }
        const fieldComponent = field.getFormComponent(entity, settings);
        fieldComponents[field.getName()] = fieldComponent;
        return this.appendChild(fieldComponent);
    };
});
