import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import App from "./App.js";
import Product from "./Product.js";
import Contact from "./Contact.js";
import Ticket from "./Ticket.js";
import {createTextAreaEditor, HorizontalFormField} from "../../components/fieldEditors/editorFactories.js";
import Filter from "../../utils/Filter.js";
import Aggregation from "../../utils/Aggregation.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import ButtonToggler from "../../bulma_components/ButtonToggler.js";
import Input from "../../bulma_components/Input.js";
import HTML from "../../utils/HTML.js";
import Validation from "../../utils/Validation.js";
import TextComponent from "../../components/TextComponent.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import workLogLexicons from "./lexicons/work-log/workLogLexicons.js";

let linguist = new Linguist(workLogLexicons);

function timeEditor(field, workLog) {
    let fieldComponent = new HorizontalFormField();
    fieldComponent.setLabel(field.getLabel() || field.getName());
    const fieldBody = fieldComponent.addField();

    const setDuration = () => {
        const hours = hoursInput.getValue() ? parseInt(hoursInput.getValue()) : 0;
        const minutes = minutesInput.getValue() ? parseInt(minutesInput.getValue()) : 0;
        const seconds = hours * 3600 + minutes * 60;
        workLog.duration = seconds;
    };

    const hoursInput = new Input(function () {
        this.type = Input.Types.NUMBER;
        this.step = 1;
        this.placeholder = "00";
        this.getNode().setStyle("text-align", "center");
        this.getNode().setStyle("width", "4em");
        this.on("change", setDuration);
    });
    const minutesInput = new Input(function () {
        this.type = Input.Types.NUMBER;
        this.step = 15;
        this.min = 0;
        this.max = 59;
        this.placeholder = "00";
        this.getNode().setStyle("text-align", "center");
        this.getNode().setStyle("width", "4em");
        this.on("change", setDuration);
    });
    const timestamp = new Input(function () {
        this.type = Input.Types.DATETIME_LOCAL;
        this.on("change", () => {
            if (toggler.getValue()) {
                workLog.endTime = this.getValue() || null;
            } else {
                workLog.startTime = this.getValue() || null;
            }
        });
    });
    const toggler = ButtonToggler(function () {
        this.addValue(true, linguist.t("finishedAt"));
        this.addValue(false, linguist.t("startedAt"));
        this.getNode().setStyle("width", "6em");
        this.on("change", () => {
            if (this.getValue()) {
                workLog.startTime = null;
                workLog.endTime = timestamp.getValue() || null;
            } else {
                workLog.endTime = null;
                workLog.startTime = timestamp.getValue() || null;
            }
        });
    }, [BULMA_CSS_CLASSES.COLORS.HAS_BACKGROUND_LIGHT]);

    if (workLog.startTime) {
        timestamp.setValue(workLog.startTime);
        toggler.setValue(false);
    } else if (workLog.endTime) {
        timestamp.setValue(workLog.endTime);
        toggler.setValue(true);
    }
    const hours = Math.floor(workLog.duration / 3600);
    const minutes = Math.floor((workLog.duration % 3600) / 60);
    if (hours) {
        hoursInput.setValue(hours);
    }
    if (minutes) {
        minutesInput.setValue(minutes);
    }
    fieldBody.addControl(hoursInput);
    fieldBody.addControl(new TextComponent(":", HTML.SPAN, BULMA_CSS_CLASSES.INPUT));
    fieldBody.addControl(minutesInput);
    fieldBody.addControl(toggler);
    fieldBody.addControl(timestamp, [BULMA_CSS_CLASSES.FIELD.IS_EXPANDED]);
    return fieldComponent;
}

let WorkLog = new RestModel(function () {
    this.app = new Field(App);
    this.id = new Field()
        .asReadOnly()
        .asNumber();
    this.description = new Field()
        .asString()
        .withEditorFactory(createTextAreaEditor)
        .withLabel("Description");
    this.product = new Field(Product)
        .withLabel("Product")
        .withOptionsLoader((searchString, workLog) => {
            let filter;
            if (workLog.ticket && workLog.ticket.type.workLogProductGroups) {
                filter = Filter.in(Product.groups, workLog.ticket.type.workLogProductGroups);
            }
            return Product.getListForDropdown(searchString, Product.SEARCH_FIELDS, workLog.app, filter);
        });
    this.worker = new Field(Contact)
        .withLabel("Worker")
        .withOptionsLoader((searchString, workLog) => workLog.ticket.getAssigneesForDropdown(searchString));
    this.ticket = new Field(Ticket).withLabel("Ticket");
    this.startTime = new Field()
        .asTimestamp(Linguist.getLanguage())
        .withLabel("Started at")
        .asNullable();
    this.endTime = new Field()
        // Time with minute precision
        .withDefaultValueCallback(() => new Date(Math.round(new Date().getTime() / 60000) * 60000))
        .asTimestamp(Linguist.getLanguage())
        .withLabel("Finished at")
        .asNullable();
    this.duration = new Field()
        .withDefaultValueCallback(() => 0)
        .withValidationRule(Validation.nonzero())
        .asDuration()
        .withLabel("Hours worked")
        .asNullable();
    this.year = new Field()
        .asNumber()
        .asReadOnly()
        .withLabel("Year");
    this.time = new Field()
        .asVirtual(this.duration)
        .withEditorFactory(timeEditor)
        .withLabel("Time");
    this.billable = new Field().asBoolean().withLabel("Billable");

    this.totalDuration = Aggregation.sum(this.duration);
    this.distinctYears = Aggregation.distinct(this.year);
    linguist.withAudience(this);

    this.on([this.EVENTS.AFTER_INSERT, this.EVENTS.AFTER_UPDATE, this.EVENTS.AFTER_DELETE], (worklog) => {
        if (worklog?.ticket) {
            worklog.ticket.refreshLazyField(Ticket.workLogs);
        }
    });
}, "WorkLog", "work-logs", true);

export {WorkLog as default};
