import Container from "juis-components/Container.js";
import {createIcon} from "../../../bulma_components/Icon.js";
import HTML from "../../../utils/HTML.js";
import TextComponent from "../../../components/TextComponent.js";
import BulmaUtils from "../../../bulma_components/BulmaUtils.js";
import BULMA_CSS_CLASSES from "../../../bulma_components/bulmaCssClasses.js";

export default (iconField, labelField, colorField, tooltipField) => (entity) => new Container(function () {
    this.icon = createIcon(entity[iconField.getName()]);
    if (colorField) {
        this.icon.color = entity[colorField.getName()];
    }
    if (tooltipField) {
        this.icon.tooltip = BulmaUtils.getTooltipProperty(this.icon);
        this.icon.tooltip = tooltipField.getTranslatedValueFor(entity);
        this.icon.getNode().addCssClass(BULMA_CSS_CLASSES.HAS_TOOLTIP_RIGHT);
    }
    this.text = new TextComponent(labelField.getTranslatedValueFor(entity));
}, [], HTML.SPAN);
