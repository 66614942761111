import RestModel from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import DynamicField from "./DynamicField.js";
import App from "./App.js";
import dynamicModels from "./utils/dynamicModels.js";

export default new RestModel(function () {
    this.app = new Field(App);
    this.url = new Field().asString();
    this.title = new Field().asString();
    this.icon = new Field().asString();
    this.model = new Field()
        .asEnum(dynamicModels);
    this.parentField = new Field(DynamicField)
        .withOptionsLoader(DynamicField.getOptionsLoaderForModelGetter(view => view.model));
    this.childField = new Field(DynamicField)
        .withOptionsLoader(DynamicField.getOptionsLoaderForModelGetter(view => view.model));
    this.fields = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModelGetter(view => view.model));
    this.filterToggles = new Field([DynamicField])
        .withOptionsLoader(DynamicField.getOptionsLoaderForModelGetter(view => view.model));
    this.type = new Field().asEnum(["FORM", "TABLE", "STATIC_TABLE"]);
    this.canEdit = new Field().asBoolean();
    this.canCreate = new Field().asBoolean();
    this.canDelete = new Field().asBoolean();
}, "DynamicEntityTab");
