import Component from "juis-components/Component.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import Property from "juis-components/Property.js";

export default extendComponent(Component, function () {
    this.registerDomEvents("input");
    this.registerDomEvents("change");
    this.getValue = () => this.getNode().getElementProperty("value");
    this.setValue = (value) => this.getNode().setElementProperty("value", value);
    this.placeholder = new Property((placeholder) => {
        this.getNode().setAttribute("placeholder", placeholder);
    });
}, ["textarea"], "textarea");
