import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Component from "juis-components/Component.js";
import Button from "./Button.js";
import BULMA from "./bulmaCssClasses.js";
import HTML from "../utils/HTML.js";
import Property, {CssProperty} from "juis-components/Property.js";

export default extendComponent(Container, function () {
    this.active = new CssProperty(BULMA.IS_ACTIVE);
    let onClose = () => {
        this.active = false;
    };
    this.appendChild(new Component((background) => {
        background.registerDomEvents("click");
        background.on("click", onClose)
    }, [BULMA.MODAL.BACKGROUND], HTML.DIV));
    let content = new Container(function () {
        this.getNode().addWrapper(HTML.DIV, BULMA.MODAL.CONTENT);
        this.image = new Component(function () {
            this.source = new Property((source) => {
                this.getNode().setAttribute("src", source);
            });
        }, [], HTML.IMG);
    }, [BULMA.IMAGE], HTML.P);
    this.appendChild(content);
    this.loadingButton = new Button(function () {
        this.isLoading = true;
    }, [BULMA.IS_LARGE]);
    this.loading = new Property((value) => {
        content.getNode().togglePlaceholder(value);
        this.loadingButton.getNode().togglePlaceholder(!value);
    }, false);
    this.closeButton = new Button((button) => {
        button.on("click", onClose);
    }, [BULMA.MODAL.CLOSE, BULMA.IS_LARGE]);
    this.imageSource = new Property(imageSource => content.image.source = imageSource);

}, [BULMA.MODAL.MODAL], HTML.DIV);
