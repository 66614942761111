import {extendComponent} from "juis-components/ComponentUtils.js";
import Button from "../bulma_components/Button.js";

export default extendComponent(Button, function () {
    this.onError = () => {
    };
    this.onResolve = () => {
    };
    this.callback = () => Promise.resolve();
    this.on("click", () => {
        this.isLoading = true;
        this.callback()
            .then(this.onResolve)
            .catch(this.onError)
            .finally(() => this.isLoading = false);
    });
});
