import Input from "../../../bulma_components/Input.js";
import SessionHandler from "../../../SessionHandler.js";
import {CenteredForm, FORM_EVENTS} from "../../../components/Form.js";
import {extendComponent} from "juis-components/ComponentUtils.js";
import HTML from "../../../utils/HTML.js";
import BULMA from "../../../bulma_components/bulmaCssClasses.js";
import Linguist from "../../../../lib/JuiS/Linguist.js";
import loginPageLexicons from "./lexicons/loginPageLexicons.js";
import {NAVIGATE} from "juis-router/Events.js";
import App from "../../models/App.js";
import Button from "../../../bulma_components/Button.js";
import {createLink} from "../../../bulma_components/Link.js";

const linguist = new Linguist(loginPageLexicons);

let LoginPage = extendComponent(CenteredForm, function () {

    this.on(NAVIGATE, (event) => {
        if (event.parameters?.appCode) {
            App.ifExists(event.parameters.appCode).then(setWithApp).else(setNoApp);
        } else {
            setNoApp();
        }
    });
    const setNoApp = () => {
        this.title = linguist.t("welcomeToHewecon");
    };
    const setWithApp = (appData) => {
        if (appData.logo) {
            this.logo = appData.logo;
            return;
        }
        let lexiconEntry = appData.lexiconEntries
            .filter(entry => entry.language === linguist.getLanguage())
            .filter(entry => entry.key = "name")[0];
        let appName = lexiconEntry?.translation || appData.name;
        this.title = linguist.t("welcomeToApp", {appName});
        this.subtitle = linguist.t("signIn");
    };
    const [USERNAME, PASSWORD] = ["username", "password"];
    this.addField(USERNAME, {
        type: Input.Types.TEXT,
        autocomplete: "username",
        icon: "user",
        placeholder: linguist.t("username")
    });
    this.addField(PASSWORD, {
        type: Input.Types.PASSWORD,
        autocomplete: "current-password",
        icon: "key",
        placeholder: linguist.t("password")
    });
    this.setSubmitButton(linguist.t("signIn"), "sign-in-alt", BULMA.IS_FULLWIDTH);

    this.on(FORM_EVENTS.FORM_SUBMIT, function (value) {
        return SessionHandler
            .login(value.username, value.password);
    });

    this.forgotPassword = createLink("/forgot-password", linguist.t("forgotPassword"));
    this.forgotPassword.getNode().addWrapper(HTML.DIV, [BULMA.FIELD.FIELD]);
    this.createAccount = new Button(function () {
        this.text = linguist.t("createNewAccount");
        let invitationCode;
        this.listenOnce("navigate", (eventData) => {
            invitationCode = eventData.parameters["invitation-code"];

        });
        this.on("click", () => {
            const createAccountUrl = "/create-account";
            const url = invitationCode ? createAccountUrl + "?invitation-code=" + invitationCode : createAccountUrl;
            this.navigate(url);
        });
    }, [BULMA.IS_FULLWIDTH]);
}, [BULMA.HAS_TEXT_CENTERED, "max-25-em"]);
export {LoginPage as default};
