import Container from "juis-components/Container.js";
import BULMA_CSS_CLASSES from "../../../bulma_components/bulmaCssClasses.js";
import HTML from "../../../utils/HTML.js";
import Input from "../../../bulma_components/Input.js";
import {createHorizontalFormField} from "../../../components/fieldEditors/editorFactories.js";
import Button from "../../../bulma_components/Button.js";
import RestServer from "../../../utils/RestServer.js";
import HtmlContainer from "../../../components/HtmlContainer.js";
import TextComponent from "../../../components/TextComponent.js";

const tryInvitationCode = function (invitationCode) {
    return RestServer.get(`accept-invitation?code=${invitationCode.trim().toLowerCase()}`);
}

export default (appCode, sessionUser) => new Container(function () {
    this.title = new TextComponent("Access to this app is restricted", HTML.H1, BULMA_CSS_CLASSES.TITLE);
    this.subtitle = new HtmlContainer(`${sessionUser.username}, here is what you can do:`, [BULMA_CSS_CLASSES.SUBTITLE], HTML.H2);
    let input = new Input();
    input.type = Input.Types.TEXT;
    this.on("navigate", (eventData, event) => {
        if (eventData.parameters["invitation-code"]) {
            input.setValue(eventData.parameters["invitation-code"]);
        }
    });

    this.columns = new Container(function () {
        this.invitationBox = new Container(function () {
            this.text = new TextComponent("Use invitation code", HTML.DIV, BULMA_CSS_CLASSES.MESSAGE.HEADER);
            this.body = new Container(function () {
                let errorContainer = new TextComponent(``, HTML.DIV, BULMA_CSS_CLASSES.TYPOGRAPHY.HAS_TEXT_DANGER);
                this.text = new TextComponent(`You can use an invitation code to gain access to hewecon.com/${appCode}.`, HTML.DIV, "has-margin-bottom");
                let submit = new Button(function () {
                    this.icon = "paper-plane";
                    this.on("click", () => {
                        errorContainer.text = "";
                        this.isLoading = true;
                        tryInvitationCode(input.getValue())
                            .then(sessionUser => {
                                document.location.pathname = "/" + appCode;
                                window.location.reload();
                            })
                            .catch(error => errorContainer.text = "Code verification failed")
                            .finally(() => this.isLoading = false);
                    });
                });
                this.field = createHorizontalFormField("Invitation code", input, submit);
                this.appendChild(errorContainer);
            }, BULMA_CSS_CLASSES.MESSAGE.BODY);
            this.getNode().addWrapper(HTML.DIV, BULMA_CSS_CLASSES.COLUMN);
            this.getNode().setStyle("min-height", "15em");
        }, [BULMA_CSS_CLASSES.MESSAGE.MESSAGE, BULMA_CSS_CLASSES.IS_SUCCESS]);


        this.myApps = new Container(function () {
            this.text = new TextComponent("Go to another app", HTML.DIV, BULMA_CSS_CLASSES.MESSAGE.HEADER);
            this.body = new Container(function () {
                this.text = new HtmlContainer(`On the <a href="/users/${sessionUser.username}/apps/">Apps page</a> you can create and manage apps.`, ["has-margin-bottom"]);
                if (sessionUser.roles.length > 0) {
                    let apps = sessionUser.appCodeList.map(appCode => `<li><a href="/${appCode}">hewecon.com/${appCode}</a></li>`).join("");
                    this.appList = new HtmlContainer(`Here are some apps you can access: <ul>${apps}</ul>`, ["content"]);
                }
            }, BULMA_CSS_CLASSES.MESSAGE.BODY);
            this.getNode().addWrapper(HTML.DIV, BULMA_CSS_CLASSES.COLUMN);
            this.getNode().setStyle("min-height", "15em");
        }, [BULMA_CSS_CLASSES.MESSAGE.MESSAGE, BULMA_CSS_CLASSES.IS_INFO]);
    }, [BULMA_CSS_CLASSES.COLUMNS]);
    this.getNode().addWrapper(HTML.SECTION, BULMA_CSS_CLASSES.SECTION)
}, [BULMA_CSS_CLASSES.CONTAINER]);
